import React, { useMemo, useState } from "react";
import "./cardsmatch.scss";
import { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useRef } from "react";
import { getApi, patchApi, postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import Modal from "react-modal";
import MatchesLoader from "../../layout/Loader/MatchesLoader";
import axios from "axios";
import Footer2 from "../../layout/Footer/Footer2";
import NameLoader from "../../layout/Loader/NameLoader";
import { Icon } from "@iconify/react";
import Navbar3 from "../../layout/BottomNavbar/Navbar3";
import Check from "./Check";
import TwoZodiac from "./TwoZodiac";
import PayImg from "../../assets/Pay.png";
import payWhite from "../../assets/pay-white.png";
import ButtonLoader from "../../layout/Loader/ButtonLoader";
import Footer from "../../layout/Footer/Footer";
import { GiStarShuriken } from "react-icons/gi";
import Circle from "../../assets/lightPrice.png";
import { updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "800 !important",
    backgroundColor: "white !important",
    width: "450px",
    padding: "15px",
  },
};

const prodConfig = {
  baseURL: `https://api.stripe.com`,
  withCredentials: true,
};

const axiosInstance = axios.create(prodConfig);

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// stripe
const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "12px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const CardScreen = () => {
  const [btn_pressed, setBtn_pressed] = useState();
  const [count, setCount] = useState(0);
  const connections = localStorage.getItem("connection");
  const oppGen = localStorage.getItem("gender_pref");
  const chips = localStorage.getItem("Chips");
  const MinAge = localStorage.getItem("min-age");
  const MaxAge = localStorage.getItem("max-age");
  const DistanceRange = localStorage.getItem("distance-range");
  const [gen] = useState(oppGen);
  const [Loading, setLoading] = useState(false);
  const [LoadingReport] = useState(false);
  const [Subscription, setSubscription] = useState(false);
  const [data, setData] = useState([]);
  const [dataNone, setDataNone] = useState(false);
  const [nove] = useState(false);
  const [SelectedUSerName, setselectedUserName] = useState("");
  const [relationReport, setselectedUserrelationReport] = useState([]);
  const [SelectedUSerId, setselectedUserId] = useState("");
  const [freeSwipAndReport, setFreeSwipAndReport] = useState(false);
  const [SuccessSubscribe, setSuccessSubscribet] = useState(false);
  const [NeedTORemoveCards, setNeedTORemoveCards] = useState([]);
  const [pass_id, setpass_id] = useState();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    async function SettingData() {
      const intresede_in = localStorage.getItem("connection");
      setBtn_pressed(parseInt(intresede_in));
      try {
        const res = await getApi(API_PATH.SETTING.USER_SETTING);
        if (res.status) {
          const checkB = res.data.date_name.split(",");
          localStorage.setItem("birthdaysign", checkB[0]);
          localStorage.setItem("gender_pref", res.data.gender_preference);
          if (!intresede_in) {
            localStorage.setItem("connection", res.data.interested_in.id);
            setBtn_pressed(res.data.interested_in.id);
          }
          localStorage.setItem("Name", res.data.name);
          localStorage.setItem("birthday", res.data.date_name);
          localStorage.setItem("gender", res.data.gender.id);
          localStorage.setItem("gender_pref", res.data.gender_preference);
          localStorage.setItem("country_name", res.data.country_name);
          localStorage.setItem("instagram", res.data.instagram);
        }
      } catch (error) {
        console.log(error);
      }
    }
    SettingData();
    localStorage.removeItem("flag");
    localStorage.removeItem("mail_data");
    const requestLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            localStorage.setItem("Lat", `${latitude}`);
            localStorage.setItem("Long", `${longitude}`);
          },
          (error) => {
            console.log(error.message);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };
    requestLocation();
  }, []);
  const lat = localStorage.getItem("Lat");
  const long = localStorage.getItem("Long");
  const getLocation = async () => {
    localStorage.setItem("Api_hit", true);
    const MatchCards = JSON.parse(localStorage.getItem("matches"));

    setDataNone(false);
    if (MatchCards) {
      setData(MatchCards);
    } else {
      setLoading(true);
    }
    const location = await axios.get("https://ipapi.co/json");

    matchData(location.data);
  };
  function getLocationInfo(lat, long) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyDwmD3sDnP10dsvEysi-yktJDfaKF8bK7k`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          localStorage.setItem(
            "location",
            data.results[0].address_components[3].long_name
          );
          // setLocation(data.results[0].formatted);
        } else {
        }
      })
      .catch((error) => console.error(error));
  }
  const tok = localStorage.getItem("token");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${tok}`,
    },
  };
  const updateDataName = async (data) => {
    const birthday = localStorage.getItem("birthday");
    const nameval = localStorage.getItem("Name");
    const gen = localStorage.getItem("gender");
    const oppgen = localStorage.getItem("gender_pref");
    const location = localStorage.getItem("country_name");
    const InstaName = localStorage.getItem("instagram");
    const checkKey = {
      date_name: birthday,
      name: nameval,
      interested_in: data,
      gender: parseInt(gen),
      gender_preference: parseInt(oppgen),
      country_name: location,
      instagram: InstaName,
    };
    try {
      const res = await patchApi(
        API_PATH.UPDATE_PROFILE.UPDATE,
        checkKey,
        axiosConfig
      );
      if (res.status) {
        setPage(1);
        localStorage.removeItem("matches", JSON.stringify([]));
        setLoading(true);
        setData([]);
        getLocation();
        // matchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // getLocation()
    if (count >= 7 && data.length == 2) {
      setPage(page + 1);
    }
    if (data.length == 0) {
      setDataNone(true);
    }
  }, [count]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // getLocation()
    getLocation();
    // matchData();
  }, [page]);

  const removeObjectsById = (array, idsToRemove) => {
    return array.filter((item) => !idsToRemove.includes(item.id));
  };
  const matchData = async (loc) => {
    const connections = localStorage.getItem("connection");
    const lat = localStorage.getItem("Lat");
    const long = localStorage.getItem("Long");
    getLocationInfo(lat, long);
    try {
      const res = await postApi(`${API_PATH.MATCHES.MATCH_PROFILE2}`, {
        gender: gen ? parseInt(gen) : 2,
        interestedIn: connections ? parseInt(connections) : 1,
        // lat: loc.latitude ? loc.latitude.toString() : "44.152264",
        // lng: loc.longitude ? loc.longitude.toString() : "44.152264",
        lat: lat ? lat : "44.152264",
        lng: long ? long : "44.152264",
        idealFor: chips ? JSON.parse(chips) : [],
        minAge: MinAge ? parseInt(MinAge) : 18,
        maxAge: MaxAge ? parseInt(MaxAge) : 99,
        range: DistanceRange ? parseInt(DistanceRange) : 15000,
      });
      if (res.status) {
        setCount(0);
        // if (page === 1) {
        //   if(page === 1 && !MatchCards){
        //     setData(res.data);
        //     localStorage.setItem("matches", JSON.stringify(res.data));
        //   }else{
        //     localStorage.setItem("matches", JSON.stringify([...data, ...res.data]));
        //   }

        // } else {
        if (page === 1) {
          const filteredArr = res.data.reduce((acc, current) => {
            const x = acc.find((item) => item.id === current.id);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          setData(filteredArr);
          localStorage.setItem("matches", JSON.stringify(filteredArr));
        } else {
          const new_friend = data.concat(res.data);
          let filteredObjects;
          if (NeedTORemoveCards) {
            filteredObjects = removeObjectsById(new_friend, NeedTORemoveCards);
            setNeedTORemoveCards([]);
            const filteredArr = filteredObjects.reduce((acc, current) => {
              const x = acc.find((item) => item.id === current.id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            localStorage.setItem("matches", JSON.stringify(filteredArr));
            setData(filteredArr);
          } else {
            const filteredArr = new_friend.reduce((acc, current) => {
              const x = acc.find((item) => item.id === current.id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            localStorage.setItem("matches", JSON.stringify(filteredArr));
            setData(filteredArr);
          }
        }

        if (res.data.length > 0) {
          setLoading(false);
        }
      }
      if (res.data.length === 0) {
        setDataNone(true);
      }
      setLoading(false);
      localStorage.setItem("Api_hit", false);
      const isFirstTime = localStorage.getItem("firstTime");
      if (isFirstTime != null) {
        setFreeSwipAndReport(true);
        setTimeout(() => {
          localStorage.removeItem("firstTime");
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const addLeft = async (did) => {
    if (did !== undefined) {
      const d = NeedTORemoveCards.concat(did);
      setNeedTORemoveCards(d);
    }
    const MatchCards = JSON.parse(localStorage.getItem("matches"));
    try {
      const res = await postApi(API_PATH.MATCHES.SWIPE_LEFT, {
        id: did,
      });
      if (res.data.status === "success") {
        const updated = MatchCards.filter(
          (deleteItem) => deleteItem.id !== did
        );
        localStorage.setItem("matches", JSON.stringify(updated));
        setData(updated);
        // onNextClick(did);
        setCount(count + 1);
        if (data.length === 0) {
          setDataNone(true);
        }

        // if (count === 5) {
        //   getLocation();
        // }
        // setData(update);
        // if (count === data.length  ) {
        //   matchData();
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Com_load, setCom_load] = useState(false);

  const getStat = async (id) => {
    setCom_load(true);
    try {
      const res = await getApi(API_PATH.SUBSCRIPTION.CHECK_StATUS);
      if (res.data.isVerified === 1) {
        addRight(id);
        setSubscription(false);
      } else {
        setSubscription(true);
        openModalCommunication();
      }
    } catch (err) {}
  };
  const addRight = async (did) => {
    const MatchCards = JSON.parse(localStorage.getItem("matches"));
    try {
      const res = await postApi(API_PATH.COMMUNITY.SENDREQUEST, {
        id: did,
      });
      if (res.data.status === "success") {
        const updated = MatchCards.filter(
          (deleteItem) => deleteItem.id !== did
        );
        localStorage.setItem("matches", JSON.stringify(updated));
        setData(updated);
        // onNextClick(did);
        setCount(count + 1);
        if (data.length === 0) {
          setDataNone(true);
        }
        AllRequest(did);
      }
    } catch (error) {}
  };

  const [chatData1, setChatData] = useState([]);
  const AllRequest = async (id) => {
    const userId = localStorage.getItem("id");
    const ProfilePic = localStorage.getItem("Image");
    const MyName = localStorage.getItem("Name");
    try {
      const res = await getApi(`${API_PATH.CHAT.PENDING_LIST}`);
      if (res.status) {
        let pendingData = [];
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          if (element.status === 1) {
            pendingData.push(element);
          }
        }
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          if (element.status === 2) {
            pendingData.push(element);
          }
        }
        let chatData = [];
        res.data.map((item) => {
          item.myId = userId;
          item.myProfilePic = ProfilePic;
          item.myName = MyName;
          item.userInfo = {
            id: item.user.id,
            displayName: item.chatName,
            profilePhoto: item.user.image,
          };
          chatData.push(item);
        });
        setChatData(chatData);

        if (chatData1) {
          localStorage.setItem("checking_State", JSON.stringify(chatData[0]));
          localStorage.setItem(
            "ReqReport",
            JSON.stringify(chatData[0].message.content[0].message)
          );
          getLocation();
          // matchData();
          navigate({
            pathname: "/Reqchat",
            search: `?rid=/matches`,
            state: { data: chatData[0] },
          });
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const ref = useRef(null);

  const onNextClick = async (did) => {
    // const update = data.slice(index, 1);
    // console.log(update, "update", index);
    // setNov(false);
    ref.current.next();
    //  const updates = data.filter((idx) => idx.id !== did);
    // setData(updates)
  };

  const [modalIsOpenCommunicate, setIsOpenCommunicate] = React.useState(false);
  // eslint-disable-next-line no-unused-vars

  const getRelationshipreport = async (data, name) => {
    const ourBirthday = localStorage.getItem("birthdaysign");
    const My_B = ourBirthday.split(",");
    try {
      const res = await postApi(
        API_PATH.REPORT.RELATIONSHIP_REPORT_WITHOUT_CHECK,
        {
          birthday_1: My_B[0],
          birthday_2: data,
        }
      );
      if (res) {
        setselectedUserrelationReport(res.data);
        openModalCommunication(name);
        localStorage.setItem("rreport", res.data.report);
      }
    } catch {
      // closeModalCommunication()
      setSubscription(true);
      // openModal()
    }
  };
  //Communication
  function openModalCommunication(data) {
    setselectedUserName(data);
    // setselectedUserReport(report)
    setIsOpenCommunicate(true);
  }

  function afterOpenModalCommunication() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModalCommunication() {
    setIsOpenCommunicate(false);
    setCom_load(false);
    setStartPayment(false);
    setSubscription(false);
  }
  const datafilters = () => {
    navigate("/datafilter");
  };

  const [startPayment, setStartPayment] = useState(false);
  const pay = () => {
    // window.open(
    //   "https://apps.apple.com/us/app/idealfor/id1558133582",
    //   "_blank"
    // );
    // window.open("https://buy.stripe.com/test_6oE8z78aCcCL676cMN", "_blank");
  };

  function openModalFree() {
    setFreeSwipAndReport(true);
  }

  function afterOpenModalFree() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }
  function afterOpenModalSucssessSub() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModalFree() {
    setFreeSwipAndReport(false);
  }
  function closeModalSucssessSub() {
    setSuccessSubscribet(false);
    getStat(pass_id);
  }
  const theme = localStorage.getItem("dark");
  const Api_hit = localStorage.getItem("Api_hit");
  const queryParams = new URLSearchParams(window.location.search);
  const sessionID = queryParams.get("session_id");
  const SubsessionID = queryParams.get("Sub_session_id");
  const [Subrecipt, setSubRecipt] = useState("");

  // New Stripe Flow

  const [Paymentloading, setPaymentLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [isValidName, setisValidName] = useState(true);
  const [isValidEmail, setisValidEmail] = useState(true);
  const [isValidCard, setisValidCard] = useState(true);
  const [isValidExpiry, setisValidExpiry] = useState(true);
  const [isValidCvc, setisValidCvc] = useState(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    var nameField = document.getElementById("cc-name");
    if (name === "") {
      nameField.focus();
      setisValidName(false);
    } else {
      setisValidName(true);
    }

    if (email === "") {
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }

    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }

    const cardNumber = elements.getElement("cardNumber");
    const cardExpiry = elements.getElement("cardExpiry");
    const cardCvc = elements.getElement("cardCvc");
    const isInvalidCard = cardNumber._invalid;
    const isEmptyCard = cardNumber._empty;

    const isInvalidExpiry = cardExpiry._invalid;
    const isEmptyExpiry = cardExpiry._empty;

    const isInvalidCvc = cardCvc._invalid;
    const isEmptyCvc = cardCvc._empty;

    const isValidCard = !(isEmptyCard || isInvalidCard);
    const isValidExpiry = !(isInvalidExpiry || isEmptyExpiry);
    const isValidCvc = !(isInvalidCvc || isEmptyCvc);
    setisValidCard(isValidCard);
    setisValidExpiry(isValidExpiry);
    setisValidCvc(isValidCvc);
    if (!stripe || !elements || !isValidCard || !isValidExpiry || !isValidCvc) {
      setPaymentLoading(false);
      return;
    }

    try {
      setPaymentLoading(true);

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });
      payload.email = email;
      payload.name = name;
      //   payload.product_id = "prod_PWUrmEVkHkegOH"
      payload.paymentId = payload.paymentMethod.id;
      const mydata = await stripeApiHandler(payload);
      const confirm = await stripe.confirmCardPayment(mydata.clientSecret);

      if (confirm.error) {
        return alert("Payment unsuccessful!");
      }
      confirm.paymentIntent.sub_id = mydata.sub_id;
      let objJsonStr = JSON.stringify(confirm.paymentIntent);
      var encoded = btoa(objJsonStr);
      await addReciept(encoded);
      // alert("Payment Successful! Subscription active.");
    } catch (error) {
      console.log(error);
      setPaymentLoading(false);
    }
  };

  async function stripeApiHandler(data) {
    try {
      const res = await postApi(
        API_PATH.SUBSCRIPTION.CREATE_SUBSCRIPTION,
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      setPaymentLoading(false);
      throw err;
      //   res.status(500).json({ message: "Internal server error" });
    }
  }

  async function addReciept(reciept) {
    try {
      const res = await postApi(API_PATH.SUBSCRIPTION.SUBSCRIPTION, {
        receipt: reciept,
      });
      if (res.status) {
        closeModalCommunication();
        setSuccessSubscribet(true);
        setPaymentLoading(false);
      }
    } catch (error) {
      console.log(error);
      setPaymentLoading(false);
    }
  }

  return (
    <>
      <div className="container-fluid profileScreen bg-alternative">
        <div className="container-lg personalityContent">
          <div className="row personalityBtn mx-auto fix_width">
            <div className="position-fixed top-0 z-2  mx-auto px-0 ">
              <div className=" backColor fix_width mx-auto">
                <div className="fix_width mx-auto">
                  <div className="d-flex justify-content-between pt-3 pb-2 px-2">
                    <h6 className="head head-light mb-0">
                      Connections ideal for:
                    </h6>
                    <Icon
                      icon="lucide:settings-2"
                      color={theme === "true" ? "white" : "black"}
                      className="fs-3 btn-hover"
                      onClick={() => datafilters()}
                    />
                  </div>
                  <div className="d-flex justify-content-between overflow-scroll scrollhide scrollhide pb-2 px-2 gap-2">
                    <button
                      className={
                        btn_pressed === 1 || connections === "1"
                          ? "btn_common_card px-2 py-2 btn-color"
                          : "btn_common_unpressed_card px-2 py-2 btn-color"
                      }
                      onClick={() => {
                        setBtn_pressed(1);
                        localStorage.setItem("connection", 1);
                        updateDataName(1);
                      }}
                    >
                      Romance
                    </button>
                    <button
                      className={
                        btn_pressed === 2 || connections === "2"
                          ? "btn_common_card px-2 py-2 btn-color"
                          : "btn_common_unpressed_card px-2 py-2 btn-color"
                      }
                      onClick={() => {
                        setBtn_pressed(2);
                        localStorage.setItem("connection", 2);
                        updateDataName(2);
                      }}
                    >
                      Friendship
                    </button>
                    <button
                      className={
                        btn_pressed === 3 || connections === "3"
                          ? "btn_common_card px-2 py-2 btn-color"
                          : "btn_common_unpressed_card px-2 py-2 btn-color"
                      }
                      onClick={() => {
                        setBtn_pressed(3);
                        localStorage.setItem("connection", 3);
                        updateDataName(3);
                      }}
                    >
                      Business
                    </button>
                    <button
                      className={
                        btn_pressed === 0
                          ? "btn_common_card px-2 py-2 btn-color"
                          : "btn_common_unpressed_card px-2 py-2 text-decoration-none"
                      }
                      onClick={() => {
                        setBtn_pressed(0);
                        localStorage.setItem("connection", 0);
                        setLoading(true);
                        getLocation();
                        // matchData();
                      }}
                    >
                      All
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Loading ? (
            <MatchesLoader />
          ) : (
            <div className="row fix_width mx-auto">
              {data.length === 0 && dataNone === true ? (
                <div className="  text-center position-absolute top-50 start-50 translate-middle fix_width">
                  <p className=" err-head fs-5 mb-0 mx-auto px-3">
                    Congrats! You have gone through everyone in your filter
                    settings. Feel free to invite people to idealfor.
                  </p>
                </div>
              ) : (
                <Carousel
                  ref={ref}
                  activeIndex={0}
                  // onSelect={handleSelect}
                  indicators={false}
                  controls={false}
                  interval={null}
                  className="carousel-fade px-2"
                  touch={false}
                >
                  {data.map((item) => (
                    <Carousel.Item key={item.id} className="impCarousel">
                      <div className=" mx-auto">
                        <div className=" text-center section1 scrollbar rounded-top-3">
                          <div
                            className={
                              modalIsOpenCommunicate === true
                                ? "scrl_manage ff1 pt-5 mt-5"
                                : "ff1 pt-5 mt-5"
                            }
                          >
                            <div className="">
                              <div className="section2 my-2 pb-2">
                                <Carousel
                                  fade
                                  interval={null}
                                  autoPlay={false}
                                  controls={
                                    item.images.length > 1 ? true : false
                                  }
                                  indicators={
                                    item.images.length > 1 ? true : false
                                  }
                                  touch={false}
                                >
                                  {item.images.map((el) => (
                                    <Carousel.Item key={el.id}>
                                      <div className="col d-flex justify-content-center card_img height-card  ">
                                        <img
                                          src={el.image}
                                          alt=""
                                          className=" card_img w-100 object-fit-cover h-auto"
                                          // style={{ objectPosition: "0 0" }}
                                        />
                                        <div
                                          className="matchInnerbox rounded-4 text-start d-flex justify-content-between px-3"
                                          id="section-1"
                                        >
                                          <div className="">
                                            <h5 className="m-0 text-light text-capitalize">
                                              {item.name}, {item.age}
                                            </h5>
                                            <p className="m-0 paraMatch fw-semibold text-light">
                                              {item.distance}
                                            </p>
                                            <p className="m-0 text-light">
                                              idealfor :
                                              <span className="text-light ms-1  fw-bold text-capitalize">
                                                {item.ideal_for}
                                              </span>
                                            </p>
                                          </div>

                                          <div className="">
                                            <Check data={item.user_birthday} />
                                          </div>
                                        </div>
                                      </div>
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                              </div>
                              <div className="btn_actions d-flex justify-content-evenly  pt-3 pb-4 px-2 btn-hover align-items-center ">
                                <div
                                  className="Add_btn  px-3 ps-sm-3    py-2  d-flex justify-content-start align-items-center"
                                  onClick={() => {
                                    // openModalCommunication(
                                    //   item.name,
                                    //   item.user_birthday
                                    // );
                                    setpass_id(item.id);
                                    getRelationshipreport(
                                      item.user_birthday,
                                      item.name
                                    );
                                    setselectedUserId(item.id);
                                  }}
                                >
                                  <Icon
                                    icon="zondicons:add-solid"
                                    color="#7add84"
                                    className=" me-2"
                                    style={{ fontSize: "30px" }}
                                  />
                                  <span className="fs-5">Connect</span>
                                </div>

                                <div
                                  className="Add_btn px-3 ps-sm-3  py-2  btn-hover d-flex justify-content-start align-items-center"
                                  onClick={() => {
                                    Api_hit === "true"
                                      ? addLeft()
                                      : addLeft(item.id);
                                  }}
                                >
                                  <Icon
                                    icon="ph:x-circle-fill"
                                    color="red"
                                    className=" me-2"
                                    style={{ fontSize: "33px" }}
                                    rotate={2}
                                  />
                                  <span className="fs-5">Ignore</span>
                                </div>
                                {item.instagram !== "" ? (
                                  <div>
                                    <a
                                      href={`https://www.instagram.com/${item.instagram}`}
                                      target="blank"
                                      className=""
                                    >
                                      <Icon
                                        icon="jam:instagram"
                                        className="fs-1  p-1 text-light insta  rounded-circle"
                                      />
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className=" px-2   ">
                              <div className="Z-signs my-4">
                                <TwoZodiac data={item.user_birthday} />
                              </div>

                              <div
                                className="d-flex justify-content-center "
                                style={{ height: "60px" }}
                              >
                                <div className="img_bg_back d-flex justify-content-center">
                                  <img
                                    src={item.rel_image}
                                    alt=""
                                    width={100}
                                    className="my-3 object-fit-contain"
                                  />
                                </div>
                              </div>
                              <div className="card_sln pt-5 mx-2">
                                <p className="fs-5 pt-3 pb-0 mb-1">
                                  {item.sln}
                                </p>
                                <p
                                  className="pb-3 px-3"
                                  style={{ color: "#8270FF" }}
                                >
                                  {item.looking_for}
                                </p>
                              </div>
                              <p className="line-para my-4 px-3">
                                {item.sln_description}
                              </p>

                              <div className="col personalityBtns  ">
                                <ul className="nav  px-auto switch_div  w-100">
                                  <li className="nav-item tab-all w-50">
                                    <a
                                      className="nav-link  show py-1 px-3 active "
                                      href="#tab1"
                                      data-bs-toggle="tab"
                                    >
                                      <button className="side01  fs-5 fw-bold">
                                        Relationship
                                      </button>
                                    </a>
                                  </li>
                                  <li className="nav-item tab-all p-l-20 w-50">
                                    <a
                                      className="nav-link  py-1 px-4 "
                                      href="#tab2"
                                      data-bs-toggle="tab"
                                    >
                                      <button className="side01 fs-5 fw-bold">
                                        Advice
                                      </button>
                                    </a>
                                  </li>
                                </ul>
                              </div>

                              <div className="tab-content">
                                <div
                                  className="tab-pane fontawesome-demo active"
                                  id="tab1"
                                >
                                  <div className="">
                                    <p className="   line-para mt-4  mb-0 px-2">
                                      {item.report}
                                    </p>
                                  </div>
                                </div>
                                <div className="tab-pane" id="tab2">
                                  <div className="container-fluid">
                                    <div className="text-center">
                                      <div className="text-center mt-4">
                                        <div className=" text-center content-match-li">
                                          <p className="advice-p pb-4 line-para">
                                            {item.advice}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pb-5 mb-5">
                                <Footer2 />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
            </div>
          )}
        </div>
        <div className="col-12 navMatchbottom1 nav-bottom  px-0 mx-0">
          <div className="fix_width mx-auto">
            <Navbar3 />
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpenCommunicate}
        onAfterOpen={afterOpenModalCommunication}
        onRequestClose={closeModalCommunication}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {LoadingReport === false ? (
          <div className="text-center form-width ">
            {Subscription === false ? (
              <div className="">
                <div
                  className="btn-hover text-end"
                  onClick={() => closeModalCommunication()}
                >
                  <RxCross1 className=" btn-hover" />
                </div>
                <div className="communicate-report">
                  {LoadingReport === true ? (
                    <h2 style={{ height: "350px" }}>
                      <NameLoader />
                    </h2>
                  ) : (
                    <div className="container personalityContent  relationreport-cards border-bottom border-secondary">
                      <div className="row personalityBtn mx-0 ">
                        {/* <div className="text-center py-2 reportrelation fs-5">idealfor Birthdays & Relationships </div> */}
                        <div className="col py-1 clr-text  mx-3 rounded-3 manage_color">
                          <div className="weekPersonality m-0 w-100">
                            <div className="week-blockPersonality  ">
                              <span className="week-headerPersonality text-center  fs-6 lh-sm">
                                {relationReport.birthday_1}
                              </span>
                              <span className="datePersonality text-center ">
                                {relationReport.birthday_1_name}
                              </span>
                            </div>
                            <span className="andPersonality px-3 ">&amp;</span>
                            <div className="week-blockPersonality ">
                              <span className="week-headerPersonality text-center  fs-6 lh-sm">
                                {relationReport.birthday_2}
                              </span>
                              <span className="datePersonality text-center ">
                                {relationReport.birthday_2_name}
                              </span>
                            </div>
                          </div>
                          <div className="calledPersonality ">IS CALLED</div>
                          <div className="image-sectionPersonality text-center ">
                            <img
                              src={relationReport.image}
                              className="border-iconPersonality img_filter"
                              alt=""
                              width={150}
                            />
                          </div>
                          <div
                            className="rel-namePersonality  lh-base"
                            style={{ fontSize: "18px" }}
                          >
                            {relationReport.name}
                          </div>
                          <div className="lh-sm">
                            <div className="week-block2Personality ">
                              <span
                                className="date1Personality lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.s1},
                              </span>
                              <span
                                className="date1Personality ms-1 lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.s2},
                              </span>
                              <span
                                className="date1Personality ms-1 lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.s3},
                              </span>
                              <br />
                              <span
                                className="date1Personality ms-1 lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.w1},
                              </span>
                              <span
                                className="date1Personality ms-1 lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.w2},
                              </span>
                              <span
                                className="date1Personality ms-1 lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.w3},
                              </span>
                            </div>
                          </div>
                          <div
                            className="idealPersonality "
                            style={{ fontSize: "18px" }}
                          >
                            Ideal for :
                            <span className="reIdealPersonality">
                              {" "}
                              {relationReport.ideal_for}{" "}
                            </span>
                          </div>
                        </div>

                        <div className="tab-content">
                          <div
                            className="tab-pane fontawesome-demo active"
                            id="tab23"
                          >
                            <div className="">
                              <div className="description-section pt-0">
                                <p className="section-title mt-1 mb-1 fs-5 text-capitalize">
                                  Relationship report
                                </p>
                                <p className="section-description fs-6 w-100 lh-base">
                                  {relationReport.report}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" footer-relation pb-1  px-0">
                          <Footer />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {Com_load !== true ? (
                  <button
                    className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                    onClick={() => getStat(SelectedUSerId)}
                    style={{ height: "45px" }}
                  >
                    Communicate
                  </button>
                ) : (
                  <button
                    className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                    style={{ height: "45px" }}
                  >
                    <ButtonLoader />
                  </button>
                )}{" "}
                <br />
                <span className=" " style={{ fontSize: "12px" }}>
                  {" "}
                  When {SelectedUSerName} accepts your request you will be able
                  to freely communicate
                </span>
              </div>
            ) : (
              <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
                {startPayment === false ? (
                  <div className="container-xxl">
                    <div className="row suv mx-0 ">
                      <div className="col  subcription-bg ">
                        <div className="mb-4">
                          <img
                            src={Circle}
                            alt=""
                            className="img_clr_sub "
                            width={250}
                          />
                          <p className="sub-content pt-2 fw-normal w-75 mx-auto">
                            See Who You're Matched With And Connect!
                          </p>
                          <div className="Sub-content2">
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Messaging
                              <GiStarShuriken className="star" />
                            </span>{" "}
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Plus All The Standard Features
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Birthday Reports
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Relationship Reports
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Matchmaking Reports
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                          </div>
                        </div>

                        <div className="d-grid justify-content-center my-2">
                          <button
                            className="sub-btn-1 mb-3"
                            onClick={() => {
                              // Subscribe();
                              // pay();
                              setStartPayment(true);
                            }}
                            style={{
                              backgroundColor: "#8270FF",
                              borderColor: "#8270FF",
                            }}
                          >
                            Subscribe
                          </button>
                          <button
                            className="sub-btn-2 border-0 border-none"
                            onClick={() => {
                              setSubscription(false);
                              closeModalCommunication();
                              setCom_load(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                      <div className="px-2  ">{/* <Footer /> */}</div>
                    </div>
                  </div>
                ) : (
                  <div className="container-xxl ">
                    <div className="row suv mx-0 ">
                      <div className="col  subcription-bg ">
                        <h4 className="d-flex justify-content-center align-items-center my-3 text-center">
                          Pay with card
                        </h4>
                        <form onSubmit={handleSubmit}>
                          <div className="row text-start">
                            <div
                              className="col-12 mb-3"
                              style={{ minHeight: "90px" }}
                            >
                              <label htmlFor="cc-name">Name on card</label>
                              <input
                                id="cc-name"
                                type="text"
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                              {!isValidName ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  Invalid Name
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className="col-12 mb-3"
                              style={{ minHeight: "90px" }}
                            >
                              <label htmlFor="cc-email">Email</label>
                              <input
                                id="cc-email"
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {!isValidEmail ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  Invalid Email Address
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="row text-start">
                            <div
                              className="col-md-12 mb-3"
                              style={{ minHeight: "90px" }}
                            >
                              <label htmlFor="cc-number">Card Number</label>
                              <CardNumberElement
                                id="cc-number"
                                className="form-control"
                                options={CARD_ELEMENT_OPTIONS}
                              />
                              {!isValidCard ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  Invalid Card Number
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="row text-start">
                            <div
                              className="col-6 mb-3"
                              style={{ minHeight: "90px" }}
                            >
                              <label htmlFor="expiry">Expiration Date</label>
                              <CardExpiryElement
                                id="expiry"
                                className="form-control"
                                options={CARD_ELEMENT_OPTIONS}
                              />
                              {!isValidExpiry ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  Invalid Expiration Date
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className="col-6 mb-3"
                              style={{ minHeight: "90px" }}
                            >
                              <label htmlFor="cvc">CVC</label>
                              <CardCvcElement
                                id="cvc"
                                className="form-control"
                                options={CARD_ELEMENT_OPTIONS}
                              />
                              {!isValidCvc ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  {" "}
                                  Invalid CVC
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <hr className="mb-4" />
                          <p style={{ fontSize: "12px" }}>
                            Note - We do not store your CVV/CVC number
                          </p>
                          {Paymentloading === false ? (
                            <button
                              type="submit"
                              className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                              style={{ height: "45px" }}
                            >
                              Pay
                            </button>
                          ) : (
                            <button
                              className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                              style={{ height: "45px" }}
                            >
                              <ButtonLoader />
                            </button>
                          )}
                          {errorMsg && (
                            <div className="text-danger mt-2">{errorMsg}</div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="text-center form-width" style={{ height: "505px" }}>
            {" "}
            <h2>
              <NameLoader />
            </h2>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={freeSwipAndReport}
        onAfterOpen={afterOpenModalFree}
        onRequestClose={closeModalFree}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="text-center form-width">
          <div className="btn-hover text-end" onClick={() => closeModalFree()}>
            <RxCross1 className=" btn-hover" />
          </div>
          <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
            <div className="container-xxl">
              <div className="row suv mx-0 ">
                <div className="col  subcription-bg ">
                  <div className="mb-5">
                    {/* {theme === "true" ? (
                          <img src={PayImg} alt="" width={250} />
                        ) : (
                          <img src={payWhite} alt="" width={250} />
                        )} */}
                    <div className="fs-1 text-center pt-3 pb-3 section-heading">
                      idealfor
                    </div>
                    <p className="mb-0   fs-4">You Have Unlocked 🎉</p>
                    <p className="mb-0 mt-3  fs-6">5 Free Connects</p>
                    <p className="mb-0   fs-6">5 Free Reports</p>
                  </div>
                </div>
                <div className="px-2 ">
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={SuccessSubscribe}
        onAfterOpen={afterOpenModalSucssessSub}
        onRequestClose={closeModalSucssessSub}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="text-center form-width">
          <div
            className="btn-hover text-end"
            onClick={() => closeModalSucssessSub()}
          >
            <RxCross1 className=" btn-hover" />
          </div>
          <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
            <div className="container-xxl">
              <div className="row suv mx-0 ">
                <div className="col  subcription-bg ">
                  <div className="mb-5">
                    <div className="fs-4 text-center pt-3 pb-3 section-heading">
                      idealfor Subscription Confirmed!
                    </div>
                    {/* <p className="mb-0   fs-4">
                          You successfully started a{" "}
                          <span className="active">
                            IdealFor Subscription - 1 Month
                          </span>
                        </p> */}

                    <p>
                      Your 1-Month idealfor Subscription is now active. Please
                      note that your account will be automatically billed every
                      month once any existing subscription period concludes. If
                      you ever wish to cancel your idealfor subscription, you
                      have the flexibility to do so at any time through your
                      profile settings.
                    </p>
                  </div>
                </div>
                <div className="px-2 ">{/* <Footer /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CardScreen;
