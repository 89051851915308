import React, { useRef } from "react";
import "./profile.css";
import Footer2 from "../../layout/Footer/Footer2";
import { useEffect } from "react";
import { useState } from "react";
import { getApi, postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../layout/Loader/Loader";
import Navbar3 from "../../layout/BottomNavbar/Navbar3";
import { MdArrowBackIosNew } from "react-icons/md";
import { Icon } from "@iconify/react";
import dp from "../../assets/Dp.png";
import InfiniteScroll from "react-infinite-scroll-component";

const Pendings = () => {
  const location = useLocation();
  const { PendingLength } = location.state;
  const [PendingLengthUpdate, setPendingLength] = useState();
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [call_api, setCall_api] = useState(true);
  const [pageCount] = useState(0);
  useEffect(() => {
    const pendingData = localStorage.getItem("pendingPageData");
    if (pendingData) {
      setData(JSON.parse(pendingData));
    } else {
      setLoading(true);
    }
    PendingList();
    if (page === 1) {
      window.scrollTo({ top: -50, left: 0, behavior: "instant" });
    }
    setPendingLength(PendingLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  async function PendingList() {
    try {
      const res = await getApi(`${API_PATH.PROFILE.PENDING_LIST}/${page}`);
      if (res.status) {
        if (page === 1) {
          setData(res.data);
          localStorage.setItem("pendingPageData", JSON.stringify(res.data));
        } else {
          const new_friend = data.concat(res.data);
          setData(new_friend);
          localStorage.setItem("pendingPageData", JSON.stringify(new_friend));
        }
        // setData([...data, ...res.data]);
        localStorage.setItem(
          "pendingPageData",
          JSON.stringify([...data, ...res.data])
        );
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  // handle next
  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1);
  };
  // useEffect(() => {
  //   const pagedatacount = Math.ceil(data.length / 8);
  //   setPageCount(pagedatacount);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data])
  const deletefriendbutton = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.COMMUNITY.WITHDRAW, {
        id: id,
      });
      if (res.data.status === "success") {
        const updatedData = data.filter((item) => item.id !== id);
        setData(updatedData);
        setPendingLength(PendingLength - 1);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const nextpage = (id) => {
    navigate({
      pathname: `/userprofile`,
      search: `?pendingprofileId=${id}`,
    });
  };

  // const containerRef_friends = useRef(null);

  const handleScroll_friends = (e) => {
    // const container = containerRef_friends.current;
    // console.log(window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight);
    // if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
    //   handleNext();
    // }
  };

  return (
    <>
      {Loading ? <Loader /> : null}
      {/* <div className="headerBAr">
        <Header />
      </div> */}
      <div className="container-fluid ">
        {/* <div className="col fs-3 text-light text-center py-3 profileHeading headerBAr ">
          My Pending Requests
        </div> */}

        <div className="container-fluid   mx-0 px-0 Match-screen">
          <div className="row pendingFilter mx-auto fix_width">
            <div className="  mx-auto">
              <div className="profileUpperpart navMatchbottom1  py-1 position-sticky top-0 backColor">
                <div className="  py-3  " onClick={() => navigate(-1)}>
                  <div className="arrow_back  btn-hover">
                    <MdArrowBackIosNew />{" "}
                    <span className="align-middle"> Go Back</span>
                  </div>
                </div>
                <div className="col fs-5  text-center py-3  ">
                  <p className="subHeadOne mb-0 px-0">
                    Pending ({data.length})
                  </p>
                </div>
                <div className="col-2 "></div>
              </div>
              <div className=" mb-2 profile-border2">
                <InfiniteScroll
                  dataLength={data.length}
                  next={handleNext}
                  hasMore={true}
                >
                  {data.map((p) => {
                    return (
                      <div
                        key={p.id}
                        className="pendingPara py-2 profile-border "
                        onWheel={handleScroll_friends}
                        // ref={containerRef_friends}
                        onSc
                      >
                        <div
                          className=""
                          onClick={() => {
                            nextpage(p.id);
                          }}
                        >
                          <img
                            src={p.image !== "undefined" || null ? p.image : dp}
                            className="rounded-circle PointAdd  object-fit-cover img_brdr "
                            height={"60px"}
                            width={"60px"}
                            alt=""
                          />

                          <div className="d-inline-block pendingContent ps-3 PointAdd">
                            <p className="  mb-0 text-start">{p.name}</p>
                            <span className="famousContent text-start  ">
                              Ideal For:{" "}
                              <span
                                className="text-capitalize"
                                // style={{ color: "#8270FF" }}
                              >
                                {p.ideal}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <Icon
                            icon="ph:x-circle-fill"
                            color="#FF5151"
                            className="btn-hover"
                            style={{ fontSize: "35px" }}
                            rotate={2}
                            onClick={() => deletefriendbutton(p.id)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </div>
              {data.length === 0 && !Loading ? (
                <p className="text-center position-absolute start-50 top-50 translate-middle fs-4 w-75">
                  Sorry you don't have request
                </p>
              ) : null}

              {data.length >= 6 ? (
                <div className="backColor" style={{ height: "80px" }}>
                  <Footer2 />
                </div>
              ) : null}
              <div
                className="position-relative  "
                style={{ height: "100px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        {data.length < 6 ? (
          <div className="backColor" style={{ height: "80px" }}>
            <Footer2 />
          </div>
        ) : null}
        <div className="fix_width mx-auto">
          <Navbar3 />
        </div>
      </div>
    </>
  );
};

export default Pendings;
