import React, { useEffect, useRef, useState } from "react";
import "./setting.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Footer2 from "../../layout/Footer/Footer2";
import { useUserAuth } from "../../auth/context/userAuthContext";
import { deletetApi, getApi, patchApi, postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import Loader from "../../layout/Loader/Loader";
import axios from "axios";
import { deleteUser, getAuth } from "@firebase/auth";
import Modal from "react-modal";
import { RxCross1 } from "react-icons/rx";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../Firebase";
import { updateProfile } from "firebase/auth";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import dp from "../../assets/Dp.png";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { FaCamera } from "react-icons/fa";
import Footer from "../../layout/Footer/Footer";
import { GiStarShuriken } from "react-icons/gi";
import Circle from "../../assets/lightPrice.png";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import ButtonLoader from "../../layout/Loader/ButtonLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const Setting = () => {
  const [Loading, setLoading] = useState(false);
  const { logOut } = useUserAuth();
  const navigate = useNavigate();
  const birthday = localStorage.getItem("birthdaysign");
  const nameval = localStorage.getItem("Name");
  const gen = localStorage.getItem("gender");
  const oppgen = localStorage.getItem("gender_pref");
  const tok = localStorage.getItem("token");
  const location = localStorage.getItem("location");
  const intrestedIn = localStorage.getItem("connection");
  const update_img = localStorage.getItem("image_update");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [checkSubAvailable, setCheckSubAvailable] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [showError, setShowError] = useState(true);

  const handleLogout = async () => {
    setLoading(true);
    try {
      const res = await getApi(`${API_PATH.AUTH.LOGOUT}`);
      if (res.status) {
        localStorage.clear();
        await logOut();
        navigate("/");
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${tok}`,
    },
  };

  const updateDataName = async (data) => {
    const birthday = localStorage.getItem("birthday");
    const photo_id = localStorage.getItem("photo_id");
    const nameval = localStorage.getItem("Name");
    const conn = localStorage.getItem("connection");
    const gen = localStorage.getItem("gender");
    const oppgen = localStorage.getItem("gender_pref");
    const location = localStorage.getItem("location");
    const InstaName = localStorage.getItem("instagram");
    const checkKey = {
      date_name: birthday,
      name: nameval,
      interested_in: parseInt(conn),
      gender: parseInt(gen),
      gender_preference: parseInt(oppgen),
      country_name: location,
      instagram: InstaName,
    };
    try {
      const res = await patchApi(
        API_PATH.UPDATE_PROFILE.UPDATE,
        checkKey,
        axiosConfig
      );
      if (res.status) {
        setLoading(false);

        navigate("/profile");
        toast.success("Updated");
        // setTimeout(() => {
        //   SettingData();
        // }, 1000);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const auth = getAuth();
  function DeleteUser() {
    const user = auth.currentUser;
    deleteUser(user)
      .then(() => {
        // console.log("done");
      })
      .catch((error) => {});
  }

  const hit = async () => {
    setLoading(true);
    DeleteUser();
    try {
      const res = await deletetApi(`${API_PATH.UPDATE_PROFILE.USER_DELETE}`);
      if (res.status) {
        // localStorage.clear()
        navigate("/");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const fetchData = async (data) => {
    const lat = localStorage.getItem("Lat");
    const long = localStorage.getItem("Long");
    const location = localStorage.getItem("location");
    const res = await postApi(API_PATH.LOCATION.USER_LOCATION, {
      lat: lat,
      lng: long,
      address: location,
      city: location,
    });
    if (res.status) {
      setLoading(false);
    }
  };

  const checkSubscription = async () => {
    try {
      const res = await getApi(API_PATH.SUBSCRIPTION.CHECK_AVAILABLE);
      if (res.status) {
        if (res.data.showCancleButton && res.data.isVerified == 1) {
          setCheckSubAvailable(true);
          setShowCancelButton(true);
        } else if (!res.data.showCancleButton && res.data.isVerified == 1) {
          setCheckSubAvailable(true);
          setShowCancelButton(false);
        } else if (!res.data.showCancleButton && res.data.isVerified == 0) {
          setCheckSubAvailable(false);
          setShowCancelButton(false);
        } else {
          setCheckSubAvailable(true);
          setShowCancelButton(false);
        }
        setShowError(false);
      } else {
        setShowError(true);
      }
    } catch (error) {
      console.log(error);
      setShowError(true);
    }
  };

  const CancelSub = async () => {
    const res = await postApi(API_PATH.SUBSCRIPTION.CANCEL_SUB);
    if (res.status) {
      checkSubscription();
      closeModalName();
    }
  };
  useEffect(() => {
    // if (!location && location !== undefined) {
    fetchData();
    checkSubscription();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const permission = localStorage.getItem("location_permission");

  useEffect(() => {
    async function SettingData() {
      setLoading(true);
      try {
        const res = await getApi(API_PATH.SETTING.USER_SETTING);
        if (res.status) {
          localStorage.setItem("birthday", res.data.date_name);
          setData(res.data);
          setupdateName(res.data.name);
          setupdateInsta(res.data.instagram);
          setData1(res.data.interested_in);
          setData2(res.data.gender);
          localStorage.setItem("gender", res.data.gender.id);
          localStorage.setItem("gender_pref", res.data.gender_preference);
          localStorage.setItem("connection", res.data.interested_in.id);
          localStorage.setItem("location", res.data.country_name);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    SettingData();
  }, [birthday, nameval, gen, location]);

  const [checkModal, setCheckModal] = useState(1);
  const [ModalMessage, setModalMessage] = useState("");
  const [modalIsOpenName, setIsOpenName] = React.useState(false);
  const [updateName, setupdateName] = useState("");
  const [updateInsta, setupdateInsta] = useState("");
  function closeModalName() {
    setIsOpenName(false);
    setStartPayment(false);
  }
  const {
    register: registerName,
    handleSubmit: handleSubmitName,
    formState: { errors },
  } = useForm();
  const onSubmitName = (data) => {
    if (data.updatename !== "") {
      localStorage.setItem("Name", data.updatename);
    }
    if (data.Instagram !== "") {
      localStorage.setItem("instagram", data.Instagram);
    }

    // eslint-disable-next-line no-lone-blocks
    updateDataName(data);
    // closeModalName();
  };

  //Insta
  const [modalIsOpenInsta, setIsOpenInsta] = React.useState(false);

  function closeModalInsta() {
    setIsOpenInsta(false);
  }

  const image = localStorage.getItem("Image");

  const [modalIsOpenwarning, setIsOpenWarning] = React.useState(false);

  function closeModalWarning() {
    setIsOpenWarning(false);
  }

  //Gender ////////////////////////////////
  const [gender, setGender] = useState([]);
  const [gendersel, setGenderSel] = useState("");
  useEffect(() => {
    async function GenderData() {
      try {
        const res = await getApi(API_PATH.AUTH.GENDER);
        if (res.status) {
          setGender(res.data);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    GenderData();
  }, []);

  // ///////////////////Opp Gender ////////////////////////

  const [oppositegender, setGOppositegnder] = useState([]);
  const oppgender = oppositegender.slice(0, 2);
  const [genderselOpp, setGenderSelOpp] = useState("");
  useEffect(() => {
    async function OppoGen() {
      try {
        const res = await getApi(API_PATH.AUTH.GENDER_PREFERENCE);
        if (res.status) {
          setGOppositegnder(res.data);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    OppoGen();
  }, []);

  // ////////////////// Location
  // const inputRef = useRef();
  const handlePlaceChanged = (place) => {
    // const [place] = inputRef.current.getPlaces();
    if (place) {
      // console.log(place.formatted_address);
      // console.log(place.geometry.location.lat());
      // console.log(place.geometry.location.lat());
      localStorage.setItem("location", place.formatted_address);
      navigate("/profilesetting");
    }
  };

  // ////////////////////  Connections
  const [connection, setConnection] = useState([]);

  useEffect(() => {
    async function ConnectionData() {
      try {
        const res = await getApi(API_PATH.AUTH.INTRESTED_IN);
        if (res.status) {
          setConnection(res.data);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    ConnectionData();
    window.scroll({
      top: -50,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const intrested_Update = () => {
    navigate({
      pathname: "/verify-yourself",
      search: "?update=3",
    });
  };

  const genUpdate = () => {
    navigate({
      pathname: "/verify-yourself",
      search: "?update=1",
    });
  };

  const birthdayUpdate = () => {
    navigate({
      pathname: "/verify-yourself",
      search: "?update=2",
    });
  };

  const pay = () => {
    window.open("https://buy.stripe.com/test_6oE8z78aCcCL676cMN", "_blank");
  };
  const getStat = async (id) => {
    try {
      const res = await getApi(API_PATH.SUBSCRIPTION.CHECK_AVAILABLE);
      if (res.data.isVerified === 1) {
        openModal();
        setModalMessage(
          res.data.message === "Vip by admin" ? "Vip by admin" : "success"
        );
      } else {
        openModal();
        setModalMessage("No payment Found");
      }
    } catch (err) {}
  };
  const openModal = () => {
    setIsOpenName(true);
    setCheckModal(3);
  };

  ///
  const {
    register: registerSub,
    handleSubmit: handleSubmitSub,
    formState: { errors: errorsSub },
  } = useForm();

  const [Paymentloading, setPaymentLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [SuccessSubscribe, setSuccessSubscribet] = useState(false);
  const [startPayment, setStartPayment] = useState(false);

  const [isValidName, setisValidName] = useState(true);
  const [isValidEmail, setisValidEmail] = useState(true);
  const [isValidCard, setisValidCard] = useState(true);
  const [isValidExpiry, setisValidExpiry] = useState(true);
  const [isValidCvc, setisValidCvc] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();

    var nameField = document.getElementById("cc-name");
    var nameValue = nameField.value.trim();
    if (name === "") {
      setisValidName(false);
      nameField.focus();
    } else {
      setisValidName(true);
    }
    var emailField = document.getElementById("cc-email");
    var emailValue = emailField.value.trim();
    if (email === "") {
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }

    const cardNumber = elements.getElement("cardNumber");
    const cardExpiry = elements.getElement("cardExpiry");
    const cardCvc = elements.getElement("cardCvc");
    const isInvalidCard = cardNumber._invalid;
    const isEmptyCard = cardNumber._empty;

    const isInvalidExpiry = cardExpiry._invalid;
    const isEmptyExpiry = cardExpiry._empty;

    const isInvalidCvc = cardCvc._invalid;
    const isEmptyCvc = cardCvc._empty;

    const isValidCard = !(isEmptyCard || isInvalidCard);
    const isValidExpiry = !(isInvalidExpiry || isEmptyExpiry);
    const isValidCvc = !(isInvalidCvc || isEmptyCvc);
    setisValidCard(isValidCard);
    setisValidExpiry(isValidExpiry);
    setisValidCvc(isValidCvc);
    if (!stripe || !elements || !isValidCard || !isValidExpiry || !isValidCvc) {
      return;
    }
    try {
      setPaymentLoading(true);
      if (!stripe || !elements) {
        setPaymentLoading(false);
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });
      payload.email = email;
      payload.name = name;
      //   payload.product_id = "prod_PWUrmEVkHkegOH"
      payload.paymentId = payload.paymentMethod.id;
      const mydata = await stripeApiHandler(payload);
      const confirm = await stripe.confirmCardPayment(mydata.clientSecret);

      if (confirm.error) {
        return alert("Payment unsuccessful!");
      }
      confirm.paymentIntent.sub_id = mydata.sub_id;
      let objJsonStr = JSON.stringify(confirm.paymentIntent);
      var encoded = btoa(objJsonStr);
      await addReciept(encoded);
      // alert("Payment Successful! Subscription active.");
    } catch (error) {
      console.log(error);
      setPaymentLoading(false);
    }
  };

  async function stripeApiHandler(data) {
    try {
      const res = await postApi(
        API_PATH.SUBSCRIPTION.CREATE_SUBSCRIPTION,
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      setPaymentLoading(false);
      throw err;
      //   res.status(500).json({ message: "Internal server error" });
    }
  }

  async function addReciept(reciept) {
    try {
      const res = await postApi(API_PATH.SUBSCRIPTION.SUBSCRIPTION, {
        receipt: reciept,
      });
      if (res.status) {
        closeModalName();
        setSuccessSubscribet(true);
        setPaymentLoading(false);
      }
    } catch (error) {
      setPaymentLoading(false);
    }
  }
  function afterOpenModalSucssessSub() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }
  function closeModalSucssessSub() {
    setSuccessSubscribet(false);
    checkSubscription();
  }
  return (
    <>
      {Loading ? <Loader /> : null}
      <ToastContainer />
      <div className="container-fluid setting-screen ">
        <div className="container-xxl ">
          <div className="row d-flex flex-column screen-row mx-0">
            <div className="row mx-0 position-sticky top-0 backColor fix_width z-1">
              <div className="row  mx-auto px-0">
                <div className="profileUpperpart navMatchbottom1 py-1 fix_width mx-auto">
                  <div
                    className="col text-start py-3  "
                    onClick={() => navigate("/profile")}
                  >
                    <div className="arrow_back btn-hover">
                      <MdArrowBackIosNew />{" "}
                      <span className="align-middle"> Go Back</span>
                    </div>
                  </div>
                  <div className="col fs-6  text-center py-3 ">
                    <span className="align-middle">Edit Profile</span>
                  </div>
                  <div
                    className="col text-end fs-6  py-3 align-middle btn-hover"
                    type="submit"
                    form="myform"
                  >
                    <input
                      type="submit"
                      form="myform"
                      value="Save"
                      style={{
                        color: "#8270FF",
                        backgroundColor: "transparent",
                      }}
                      className="border-0 backColor fw-normal "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row fix_width overflow-hidden">
              <div className="col d-flex justify-content-between text-light  fix_width mx-auto">
                <form
                  id="myform"
                  className="col  d-flex flex-column justify-content-center"
                  onSubmit={handleSubmitName(onSubmitName)}
                >
                  <div className=" mx-auto">
                    <img
                      src={image ? image : dp}
                      alt=""
                      className=" rounded-circle object-fit-cover uploadimageUser img_brdr"
                      width={100}
                      height={100}
                      onClick={() => navigate("/changeimage")}
                      style={{ objectPosition: "center" }}
                    />

                    <p
                      className="my-2 active btn-hover  "
                      onClick={() => navigate("/changeimage")}
                    >
                      Edit Photos
                    </p>
                  </div>

                  <div className="col text-start mb-2 ">
                    <div className=" col fs-6 line-para text-start mb-2">
                      Gender
                    </div>
                    <div
                      className="col d-flex justify-content-between text-light screenBootom  PointAdd Settingbottom  ps-3 py-3 rounded-5"
                      onClick={() => genUpdate()}
                    >
                      <div className=" col-10 screenInp ">
                        {gen === "1"
                          ? "Male"
                          : null || gen === "2"
                          ? "Female"
                          : null || gen === "3"
                          ? "Neutral"
                          : null || gen === "4"
                          ? "Trans Woman"
                          : null || gen === "5"
                          ? "Transsexual"
                          : null || gen === "6"
                          ? "Transsexual Female"
                          : null || gen === "7"
                          ? "Transsexual Male"
                          : null || gen === "8"
                          ? "Transgender"
                          : null || gen === "9"
                          ? "Transfeminine"
                          : null
                          ? gen === "1"
                            ? "Male"
                            : null || gen === "2"
                            ? "Female"
                            : null || gen === "3"
                            ? "Neutral"
                            : null || gen === "4"
                            ? "Trans Woman"
                            : null || gen === "5"
                            ? "Transsexual"
                            : null || gen === "6"
                            ? "Transsexual Female"
                            : null || gen === "7"
                            ? "Transsexual Male"
                            : null || gen === "8"
                            ? "Transgender"
                            : null || gen === "9"
                            ? "Transfeminine"
                            : null
                          : data2.gender_name}
                      </div>
                    </div>
                  </div>
                  <div className="col text-start mb-2 ">
                    <div className=" col fs-6 line-para text-start  mb-2">
                      {" "}
                      Gender Preference
                    </div>
                    <div
                      className="col d-flex justify-content-between   screenBootom  PointAdd Settingbottom  ps-3 py-3 rounded-5"
                      onClick={() => intrested_Update()}
                    >
                      <div className=" col-10 screenInp ">
                        {oppgen === "1"
                          ? "Male"
                          : null || oppgen === "2"
                          ? "Female"
                          : null || oppgen === "0"
                          ? "Everyone"
                          : null
                          ? oppgen === "1"
                            ? "Male"
                            : null || oppgen === "2"
                            ? "Female"
                            : null || oppgen === "0"
                            ? "Everyone"
                            : null
                          : data.gender_preference === 1
                          ? "Male"
                          : null || data.gender_preference === 2
                          ? "Female"
                          : null || data.gender_preference === 0
                          ? "Everyone"
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="col text-start mb-2 ">
                    <div className=" col fs-6  line-para text-start mb-2">
                      Interested In
                    </div>
                    <div
                      className="col d-flex justify-content-between  screenBootom  PointAdd Settingbottom  ps-3 py-3 rounded-5"
                      // onClick={openModalWarning}
                      onClick={() => intrested_Update()}
                    >
                      <div className=" col-10 screenInp ">
                        {intrestedIn === 1
                          ? "Romance"
                          : null || intrestedIn === 2
                          ? "FriendShip"
                          : null || intrestedIn === 3
                          ? "Business"
                          : null
                          ? intrestedIn === 1
                            ? "Romance"
                            : null || intrestedIn === 2
                            ? "FriendShip"
                            : null || intrestedIn === 3
                            ? "Business"
                            : null
                          : data1.name}
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className=" col fs-6 line-para   text-start mb-2 ">
                      {" "}
                      Full Name
                    </div>
                    <input
                      id="formField"
                      // border-danger  SettingName
                      className={
                        errors.updatename
                          ? "  flex-center w-100   SettingName ps-2 screenBootom PointAdd  ps-3 py-3 rounded-5"
                          : "border-none screenInp  flex-center w-100 ps-2 screenBootom PointAdd  Settingbottom  ps-3 py-3 rounded-5"
                      }
                      type="text"
                      name="updatename"
                      defaultValue={data.name}
                      {...registerName("updatename", {
                        required: updateName === "",
                        onChange: (e) => setupdateName(e.target.value),
                      })}
                    />
                  </div>
                  <div className="col text-start mb-2">
                    <div className=" col fs-6 line-para text-start  mb-2">
                      {" "}
                      Location
                    </div>
                    <div
                      className="col  text-light "
                      onClick={() => {
                        navigate("/location");
                        // getLocation();
                        // loc();
                      }}
                    >
                      <input
                        type="text"
                        className="border-none   screenInp flex-center w-100 ps-2 screenBootom PointAdd Settingbottom  ps-3 py-3 rounded-5"
                        onClick={() => {
                          navigate("/location");
                          // getLocation();
                          // loc();
                        }}
                        placeholder="Enter Location"
                        defaultValue={location}
                        // disabled={
                        //   permission === "true" || location ? true : false
                        // }
                        {...registerName("updatelocation")}
                      />
                      {/* <LoadScript
                        googleMapsApiKey="AIzaSyDwmD3sDnP10dsvEysi-yktJDfaKF8bK7k"
                        libraries={["places"]}
                      >
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputRef.current = ref)}
                          onPlacesChanged={handlePlaceChanged}
                        >
                         
                        </StandaloneSearchBox>
                      </LoadScript> */}
                      {/* <div className=" col-10 screenInp ">{location}</div>
                  <div className="col-2 text-end ">
                    <MdKeyboardArrowRight className="fs-3 profileIcon" />
                  </div> */}
                      {/* <ReactGoogleAutocomplete
                        className="border-none   screenInp flex-center w-100 ps-2 screenBootom PointAdd Settingbottom  ps-3 py-3 rounded-5"
                        apiKey="AIzaSyDwmD3sDnP10dsvEysi-yktJDfaKF8bK7k"
                        onPlaceSelected={(place) => {
                          localStorage.setItem(
                            "location",
                            place.address_components[0].long_name
                          );
                        }}
                        defaultValue={location}
                        disabled={
                          permission === "true" || location ? true : false
                        }
                      /> */}
                    </div>
                  </div>
                  <div className="col text-start mb-2  ">
                    <div className=" col-10 fs-6 line-para text-start   mb-2">
                      {" "}
                      Birth
                    </div>
                    <div
                      className="col d-flex justify-content-between border-none"
                      onClick={() => birthdayUpdate()}
                    >
                      <div
                        className="col d-flex justify-content-between   screenBootom  PointAdd Settingbottom  ps-3 py-3 rounded-5"
                        onClick={() => birthdayUpdate()}
                      >
                        <div className=" col-10 screenInp ">
                          {data.date_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className=" col fs-6 line-para  text-start mb-2 ">
                      Instagram Username
                    </div>
                    <input
                      id="formField"
                      className="border-none  screenInp flex-center w-100 ps-2 screenBootom PointAdd  Settingbottom  ps-3 py-3 rounded-5"
                      type="text"
                      name="updatename"
                      placeholder={
                        data.instagram ? data.instagram : "Add username"
                      }
                      defaultValue={data.instagram}
                      {...registerName("Instagram", {
                        onChange: (e) => setupdateInsta(e.target.value),
                      })}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className=" fw-semibold fs-5 mt-3">
              <button
                className="active bg-transparent border border-0 text-decoration-underline"
                onClick={() => getStat()}
              >
                {" "}
                Subscription
              </button>
            </div>

            <div
              className="col text-end fs-5 text-danger align-middle btn-hover text-center pt-3 pb-2"
              onClick={() => handleLogout()}
            >
              <span className="align-middle text-decoration-underline">
                {" "}
                Logout
              </span>
            </div>

            <div className=" fw-semibold fs-6 px-3">
              <button
                className="text-danger bg-transparent border border-0 text-decoration-underline"
                onClick={() => setIsOpenName(true)}
              >
                {" "}
                Delete Account
              </button>
            </div>

            <div className="pb-5 px-2 mb-5">
              <Footer2 />
            </div>
          </div>
          <Modal
            isOpen={modalIsOpenName}
            onRequestClose={closeModalName}
            style={customStyles}
            contentLabel="Example Modal Connections"
            ariaHideApp={false}
          >
            <div className="text-end">
              <RxCross1
                className=" modal-icon btn-hover"
                onClick={() => closeModalName()}
              />
            </div>
            {checkModal === 2 ? (
              <div className="">
                <h5>Cancel Subscription</h5>
                <p>Do you really want to Cancel your Subscription ?</p>
                <p>
                  You will lose all access to unlimited birthday reports and
                  matchmaking until your current subscription period ends.
                </p>
                <div className="d-flex justify-content-end">
                  <p
                    className="border-none btn-hover"
                    onClick={() => closeModalName()}
                  >
                    NO
                  </p>
                  <p className="ms-3 btn-hover" onClick={() => CancelSub()}>
                    YES
                  </p>
                </div>
              </div>
            ) : checkModal == 3 ? (
              <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
                <div className="container-xxl">
                  <div className="row suv mx-0 ">
                    {startPayment !== true ? (
                      <div className="px-0">
                        <div className="col  subcription-bg ">
                          <div className="mb-4">
                            <img
                              src={Circle}
                              alt=""
                              className="img_clr_sub "
                              width={250}
                            />
                            <p className="sub-content pt-2 fw-normal w-100 mx-auto">
                              See Who You're Matched With And Connect!
                            </p>
                            <div className="Sub-content2">
                              <span className="sub2Content">
                                <GiStarShuriken className="star" />
                                Unlimited Messaging
                                <GiStarShuriken className="star" />
                              </span>{" "}
                              <br />
                              <span className="sub2Content">
                                <GiStarShuriken className="star" />
                                Plus All The Standard Features
                                <GiStarShuriken className="star" />
                              </span>
                              <br />
                              <span className="sub2Content">
                                <GiStarShuriken className="star" />
                                Unlimited Birthday Reports
                                <GiStarShuriken className="star" />
                              </span>
                              <br />
                              <span className="sub2Content">
                                <GiStarShuriken className="star" />
                                Unlimited Relationship Reports
                                <GiStarShuriken className="star" />
                              </span>
                              <br />
                              <span className="sub2Content">
                                <GiStarShuriken className="star" />
                                Unlimited Matchmaking Reports
                                <GiStarShuriken className="star" />
                              </span>
                              <br />
                            </div>
                          </div>

                          <div className="d-grid justify-content-center my-2">
                            <button
                              className="sub-btn-1 mb-3 px-2"
                              onClick={() => {
                                // Subscribe();
                                // pay();
                                setStartPayment(true);
                              }}
                              disabled={ModalMessage !== "No payment Found"}
                              style={{
                                backgroundColor: "#8270FF",
                                borderColor: "#8270FF",
                                minWidth: "200px",
                              }}
                            >
                              {ModalMessage === "Vip by admin"
                                ? "Already have all access"
                                : ModalMessage !== "No payment Found"
                                ? "Already Subscribed"
                                : "Subscribe"}
                            </button>

                            {!showError ? (
                              checkSubAvailable && showCancelButton ? (
                                <div className=" fw-semibold fs-5 px-3">
                                  <button
                                    className="text-danger text-decoration-underline bg-transparent border border-0 fs-6"
                                    onClick={() => {
                                      setIsOpenName(true);
                                      setCheckModal(2);
                                    }}
                                  >
                                    Cancel Subscription
                                  </button>
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col  subcription-bg ">
                        <h4 className="d-flex justify-content-center align-items-center mb-3 text-center">
                          Pay with card
                        </h4>
                        <form onSubmit={handleSubmit}>
                          <div className="row text-start">
                            <div
                              className="col-12 mb-3"
                              style={{ minHeight: "90px" }}
                            >
                              <label htmlFor="cc-name">Name on card</label>
                              <input
                                id="cc-name"
                                type="text"
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                              {!isValidName ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  Invalid Name
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className="col-12 mb-3"
                              style={{ minHeight: "90px" }}
                            >
                              <label htmlFor="cc-email">Email</label>
                              <input
                                id="cc-email"
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {!isValidEmail ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  Invalid Email Address
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="row text-start">
                            <div
                              className="col-md-12 mb-3"
                              style={{ minHeight: "90px" }}
                            >
                              <label htmlFor="cc-number">Card Number</label>
                              <CardNumberElement
                                id="cc-number"
                                className="form-control"
                                options={CARD_ELEMENT_OPTIONS}
                              />
                              {!isValidCard ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  Invalid Card Number
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="row text-start">
                            <div
                              className="col-6 mb-3"
                              style={{ minHeight: "90px" }}
                            >
                              <label htmlFor="expiry">Expiration Date</label>
                              <CardExpiryElement
                                id="expiry"
                                className="form-control"
                                options={CARD_ELEMENT_OPTIONS}
                              />
                              {!isValidExpiry ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  Invalid Expiration Date
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className="col-6 mb-3"
                              style={{ minHeight: "90px" }}
                            >
                              <label htmlFor="cvc">CVC</label>
                              <CardCvcElement
                                id="cvc"
                                className="form-control"
                                options={CARD_ELEMENT_OPTIONS}
                              />
                              {!isValidCvc ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  {" "}
                                  Invalid CVC
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <hr className="mb-4" />
                          <p style={{ fontSize: "12px" }}>
                            Note - We do not store your CVV/CVC number
                          </p>
                          {Paymentloading === false ? (
                            <button
                              type="submit"
                              className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                              style={{ height: "45px" }}
                            >
                              Pay
                            </button>
                          ) : (
                            <button
                              className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                              style={{ height: "45px" }}
                            >
                              <ButtonLoader />
                            </button>
                          )}
                          {errorMsg && (
                            <div className="text-danger mt-2">{errorMsg}</div>
                          )}
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="">
                <h5>Delete Account</h5>
                <p>Do you really want to delete your account ?</p>
                <div className="d-flex justify-content-end">
                  <p
                    className="border-none btn-hover"
                    onClick={() => closeModalName()}
                  >
                    NO
                  </p>
                  <p className="ms-4 btn-hover" onClick={() => hit()}>
                    YES
                  </p>
                </div>
              </div>
            )}
          </Modal>
          <Modal
            isOpen={SuccessSubscribe}
            onAfterOpen={afterOpenModalSucssessSub}
            onRequestClose={closeModalSucssessSub}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className="text-center form-width">
              <div
                className="btn-hover text-end"
                onClick={() => closeModalSucssessSub()}
              >
                <RxCross1 className="btn-hover" />
              </div>
              <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
                <div className="container-xxl">
                  <div className="row suv mx-0 ">
                    <div className="col  subcription-bg ">
                      <div className="mb-5">
                        <div className="fs-4 text-center pt-3 pb-3 section-heading">
                          idealfor Subscription Confirmed!
                        </div>
                        {/* <p className="mb-0   fs-4">
                          You successfully started a{" "}
                          <span className="active">
                            IdealFor Subscription - 1 Month
                          </span>
                        </p> */}

                        <p>
                          Your 1-Month idealfor Subscription is now active.
                          Please note that your account will be automatically
                          billed every month once any existing subscription
                          period concludes. If you ever wish to cancel your
                          idealfor subscription, you have the flexibility to do
                          so at any time through your profile settings.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={modalIsOpenInsta}
            onRequestClose={closeModalInsta}
            style={customStyles}
            contentLabel="Example Modal Connections"
            ariaHideApp={false}
          >
            <div className="text-end">
              <RxCross1
                className="btn-hover modal-icon"
                onClick={() => closeModalInsta()}
              />
            </div>
            <div>
              <form
                className="col  gender-form d-flex flex-column justify-content-center"
                // onSubmit={handleSubmit(onSubmit)}
              >
                {oppgender.map((i) => (
                  <div key={i.id} className="text-center">
                    <input
                      type="radio"
                      id={i.name}
                      value={i.id}
                      onClick={(e) => {
                        localStorage.setItem("opp-Gender", i.id);
                        localStorage.setItem("gender_pref", i.id);
                        setGenderSelOpp(i.id);
                        closeModalInsta();
                      }}
                    />

                    <label
                      htmlFor={i.name}
                      className={
                        genderselOpp !== i.gender_name
                          ? " mb-2 ctn_btn-gender-unselect my-2 py-2 w-75"
                          : " mb-2 ctn_btn-gender my-2 py-2 w-75"
                      }
                    >
                      {i.gender_name}
                    </label>
                  </div>
                ))}
                <div className="text-center">
                  <input
                    type="radio"
                    value="3"
                    name="gender"
                    id="every"
                    className="form-input "
                    onClick={(e) => {
                      localStorage.setItem("opp-Gender", "3");
                      localStorage.setItem("gender_pref", "3");
                      setGenderSelOpp("3");
                      closeModalInsta();
                    }}
                    // {...register("oppositegender", { required: true })}
                  />
                  <label
                    htmlFor="every"
                    className={
                      genderselOpp !== 3
                        ? " mb-2 ctn_btn-gender-unselect my-2 py-2 w-75"
                        : " mb-2 ctn_btn-gender my-2 py-2 w-75"
                    }
                  >
                    All
                  </label>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Setting;
