/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { getApi, postApi } from "../../../Api/Api";
import { API_PATH } from "../../../Api/ApiPAth";
import { BsArrowLeft } from "react-icons/bs";
import "./chat.scss";
import Navbar3 from "../../../layout/BottomNavbar/Navbar3";
import { useContext } from "react";
import { useEffect } from "react";
import {
  Timestamp,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { auth, db, storage } from "../../../Firebase";
import { AuthContext } from "../../../auth/context/AuthContext";
import { ChatContext } from "../../../auth/context/ChatContext";
import nochat from "../../../assets/no_chat.png";
import Message from "./Message";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Footer2 from "../../../layout/Footer/Footer2";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FaArrowUp } from "react-icons/fa";
import Moment from "react-moment";
import moment from "moment";
import { IoMdAttach } from "react-icons/io";
import dp from "../../../assets/Dp.png";
import MatchesLoader from "../../../layout/Loader/MatchesLoader";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import InfiniteScroll from "react-infinite-scroll-component";

const prodConfig = {
  baseURL: `https://api.stripe.com`,
  withCredentials: true,
};

const axiosInstance = axios.create(prodConfig);

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const ChatHome = () => {
  // localStorage.setItem("tab2", "tab1");
  const queryParams = new URLSearchParams(window.location.search);
  const Uid = queryParams.get("Uid");
  const shareId = queryParams.get("rid");
  if (Uid) {
    localStorage.setItem("uid", Uid);
  }
  const navigate = useNavigate();
  const [check1, setCheck1] = useState([]);
  const [Chat, setChat] = useState(Uid || shareId ? true : false);
  const [ChatwUid] = useState(false);
  const [ReportShow, setReportShow] = useState(false);
  const [active, setActive] = useState(false);
  const [ReportContent, setReportContent] = useState("");
  const [ShowFooter, setshowfooter] = useState(false);
  const [friends, setFriends] = useState([]);
  const [friendsList, setFriendsList] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Nolist, setNolist] = useState(false);
  const [page, setPage] = useState(1);
  const [Fpage, setPageF] = useState(1);
  const [pageCount] = useState(0);
  const [setDataNone3] = useState(false);
  const [setDataNone4] = useState(false);

  const uid = localStorage.getItem("uid");
  //User Id for Chat

  const [chats, setChats] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);

  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        setChats(doc.data());
      });

      return () => {
        unsub();
      };
    };

    currentUser.uid && getChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.uid]);

  // user name screen
  const { data } = useContext(ChatContext);
  // console.log(data)
  // messages

  const [messages, setMessages] = useState([]);
  const [messagesReq, setMessagesReq] = useState("");

  useEffect(() => {
    async function userdata() {
      // setLoading(true);
      try {
        const res = await getApi(API_PATH.PROFILE.USER_DATA);
        if (res.status) {
          setuser(res.data);
          localStorage.setItem("id", res.data.id);
          localStorage.setItem(
            "birthday1",
            res.data.birthday_report.date_name.slice(0, -6)
          );
          setName(`${res.data.name}`);
          setcheckName(true);
          if (auth.currentUser) {
            // console.log(chek1);
            //create user on firestore
            await setDoc(doc(db, "users", res.data.id), {
              uid: auth.currentUser.uid,
              displayName: `${res.data.name}`,
              photoURL: `${res.data.image}`,
              UserDob: `${res.data.birthday_report.date_name}`,
              UserID: `${res.data.id}`,
            });

            //create empty user chats on firestore
            await setDoc(doc(db, "userChats", res.data.id), {});
            // console.log("Created");
          }
        }
      } catch (error) {
        setLoading(false);
      }
    }
    userdata();
  }, []);

  const [Data, setData] = useState([]);
  const [uid_data, setuid_data] = useState([]);
  const Friends = async () => {
    const userId = localStorage.getItem("id");
    const ProfilePic = localStorage.getItem("Image");
    const MyName = localStorage.getItem("Name");

    try {
      const res = await getApi(`${API_PATH.CHAT.CHAT}`);
      if (res.status) {
        // if (Fpage === 1) {
        //   setFriendsList(res.data);
        //   localStorage.setItem("friendList", JSON.stringify(res.data));
        // } else {
        //   const new_friend = friendsList.concat(res.data);
        //   setFriendsList(new_friend);
        // }
        setFriendsList(res.data);
        localStorage.setItem("friendList", JSON.stringify(res.data));
        if (res.data.length === 0) {
          setNolist(true);
          localStorage.setItem("show_mingle", true);
        } else {
          localStorage.setItem("show_mingle", false);
        }
        if (Uid && uid !== "" && uid !== null) {
          let pendingData = [];
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            if (element.user.id === parseInt(uid)) {
              pendingData.push(element);
            }
          }
          setuid_data(pendingData[0]);
          setCheck1(pendingData[0]);
          localStorage.setItem("friendstatus", true);
          setActive(true);
          chatSet(pendingData[0].user.id);
          handleSelectChange(pendingData[0].user.id);
          setChat(true);
          setshowfooter(false);
          // statusCheck(pendingData[0].user.id);
          const combinedId =
            userId > pendingData[0].user.id
              ? userId + pendingData[0].user.id
              : pendingData[0].user.id + userId;
          findChat(combinedId);
        }
        let chatData = [];
        res.data.map((item) => {
          item.myId = userId;
          item.myProfilePic = ProfilePic;
          item.myName = MyName;
          item.userInfo = {
            id: item.user.id,
            displayName: item.chatName,
            profilePhoto: item.user.image,
          };
          chatData.push(item);
        });
        localStorage.setItem(
          "friendList",
          res.data ? JSON.stringify(chatData) : []
        );
        setData(chatData);
        setLoading(false);
        if (!Chat) {
          setshowfooter(true);
        }
      }
      if (res.data.length === 0 && Fpage > 1) {
        setDataNone4(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const Fri = localStorage.getItem("friendList");
  // console.log(Fri)
  useEffect(() => {
    localStorage.setItem("friendList", JSON.stringify([]));
    Friends();
    localStorage.setItem("friendstatus", true);
  }, []);

  const AllReq = localStorage.getItem("AllRequests");
  // console.log(AllReq);

  useEffect(() => {
    if (Fri) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    const interval = setInterval(Friends, 15000);
    Fri === null || "" ? setFriendsList([]) : setFriendsList(JSON.parse(Fri));
    AllReq === null || "" ? setFriends([]) : setFriends(JSON.parse(AllReq));
    return () => clearInterval(interval);
  }, []);

  const AllRequest = async () => {
    localStorage.setItem("AllReq", JSON.stringify([]));
    const userId = localStorage.getItem("id");
    const ProfilePic = localStorage.getItem("Image");
    const MyName = localStorage.getItem("Name");
    try {
      const res = await getApi(`${API_PATH.CHAT.PENDING_LIST}`);
      if (res.status) {
        if (shareId !== "" && shareId !== null) {
          localStorage.setItem("oppid", shareId);
          let pendingData = [];
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            if (element.user.id === parseInt(shareId)) {
              pendingData.push(element);
            }
          }
          if (pendingData[0].status === 1) {
            setCheck1(pendingData[0]);
            setReportContent(pendingData[0].message.content[0].message);
            findChat(combinedId);
            setReciverStatus(true);
            setshowfooter(false);
            if (check1) {
              localStorage.setItem("friendstatus", false);
              setChat(true);
            }
          } else if (pendingData[0].status === 2) {
            setReciverStatus(false);
            setReportContent(pendingData[0].message.content[0].message);
            setCheck1(pendingData[0]);

            setshowfooter(false);
            if (check1) {
              localStorage.setItem("friendstatus", false);
              setChat(true);
            }
          }
        }
        let pendingData = [];
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          if (element.status === 1) {
            pendingData.push(element);
          }
        }
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          if (element.status === 2) {
            pendingData.push(element);
          }
        }
        // if (page === 1) {
        //   setFriends(pendingData);
        // } else {
        //   const new_friend = friends.concat(pendingData);
        //   setFriends(new_friend);
        // }

        setFriends(pendingData);
        localStorage.setItem(
          "AllRequests",
          res.data ? JSON.stringify(pendingData) : []
        );
        let chatData = [];
        res.data.map((item) => {
          item.myId = userId;
          item.myProfilePic = ProfilePic;
          item.myName = MyName;
          item.userInfo = {
            id: item.user.id,
            displayName: item.chatName,
            profilePhoto: item.user.image,
          };
          chatData.push(item);
        });
        setData(chatData);
        setLoading(false);
        if (!Chat) {
          setshowfooter(true);
        }
      }
      if (res.data.length === 0 && page > 1) {
        setDataNone3(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    // localStorage.setItem("AllRequests", JSON.stringify([]));
    if (AllReq) {
      setLoading(false);
      AllReq === null || "" ? setFriends([]) : setFriends(JSON.parse(AllReq));
    } else {
      setLoading(true);
    }
    AllRequest();
  }, []);

  const [search_check, setSearch] = useState(false);
  // handle next
  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1);
  };

  // handle next Friends
  const handleNextF = () => {
    if (Fpage === pageCount) return Fpage;
    setPageF(Fpage + 1);
    setNolist(false);
    if (Fpage > 1) {
      setSearch(true);
    }
  };

  const nextpage = (id) => {
    navigate({
      pathname: `/userprofile`,
      search: `?friendprofileId=${id}`,
    });
  };
  const [userSearch, setUserSearch] = useState({});

  const chatSet = async (params) => {
    localStorage.setItem("oppid", params);
    const q = query(
      collection(db, "users"),
      where("UserID", "==", JSON.stringify(params))
      // where("displayName", "==", params)
    );
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUserSearch(doc.data());
        handleSelectSearch(doc.data());
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSelectSearch = async (userSearch) => {
    //check whether the group(chats in firestore) exists, if not create
    const combinedId =
      user.id > userSearch.UserID
        ? user.id + userSearch.UserID
        : userSearch.UserID + user.id;
    localStorage.setItem("combinedId", combinedId);
    try {
      const res = await getDoc(doc(db, "chats", combinedId));
      if (!res.exists()) {
        //create a chat in chats collection
        // await setDoc(doc(db, "chats", `${combinedId}`), { messages: [] });
        //create user chats
        await updateDoc(doc(db, "userChats", user.id), {
          [combinedId + ".userInfo"]: {
            uid: userSearch.uid,
            displayName: userSearch.displayName,
            photoURL: userSearch.photoURL,
            UserDob: userSearch.UserDob,
            UserID: userSearch.UserID,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", user.id), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }
    } catch (err) {}
    setUserSearch(null);
  };
  const handleSelectChange = async (u) => {
    const q = query(
      collection(db, "users"),
      where("UserID", "==", JSON.stringify(u))
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      dispatch({ type: "CHANGE_USER", payload: doc.data() });
    });

    setChat(true);
    setshowfooter(false);
  };

  const [username, setUsername] = useState("");

  const handleKey = (e) => {
    e.code === "Enter" && Friends();
  };
  const search = (data) => {
    if (data.length > 0) {
      const q = friendsList.filter((blog) =>
        blog.chatName.toLowerCase().includes(data.toLowerCase())
      );
      setFriendsList(data.length === 0 ? friendsList : q);

      if (q.length === 0) {
        setSearch(true);
        setNolist(true);
      }
    } else {
      Friends();
    }
  };
  useEffect(() => {
    search(username);
    if (username.length > 0) {
      search(username);
    } else if (username.length === 0) {
      Friends();
      setSearch(false);
    }
  }, [username, username.length]);

  const findChat = (id) => {
    const unSub = onSnapshot(doc(db, "chats", id), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });

    return () => {
      unSub();
    };
  };

  const [user, setuser] = useState([]);

  const [displayname, setName] = useState("");
  const [checkName, setcheckName] = useState(false);
  const Phone = localStorage.getItem("PhoneNumber");
  //User Details Update with firebase

  const [reportdata, setReportdata] = useState([]);

  const myId = localStorage.getItem("id");
  const combinedId = myId > uid ? myId + uid : uid + myId;

  const tab2 = localStorage.getItem("tab2");

  const [reciverstatus, setReciverStatus] = useState(false);

  //

  const IdealForbanner = localStorage.getItem("IdealFor");

  ///INput
  const [img, setImg] = useState(null);
  const [type, setType] = useState("");
  const [text, setText] = useState("");
  const friendstatus = localStorage.getItem("friendstatus");
  const id = localStorage.getItem("id");

  const Opp_id = localStorage.getItem("oppid");

  const uploadImageFirebase = async (image) => {
    const combinedId =
      check1.myId > check1.userInfo.id
        ? check1.myId + check1.userInfo.id
        : check1.userInfo.id + check1.myId;
    // console.log(image, "image");
    try {
      let ab = image.name;
      let cc = ab.split(".").pop();
      // console.log(cc);
      if (cc == "mp4") {
        // console.log("vid");
        // console.log(combinedId);
        const storageRef = ref(storage, ab);
        const uploadTask = uploadBytesResumable(storageRef, img);
        // console.log(uploadTask);
        uploadTask.then(() => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            // console.log(downloadURL, "downloadURL");
            await handleSend("", downloadURL);
          });
        });
      } else {
        // console.log("img");
        const storageRef = ref(storage, ab);
        const uploadTask = uploadBytesResumable(storageRef, img);
        uploadTask.then(() => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            // console.log(downloadURL, "downloadURL");
            await handleSend(downloadURL, "");
          });
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const messagesEndRef = useRef(null);

  const handleSend = async (imageUrl, videoUrl) => {
    // console.log(imageUrl, videoUrl, "vid");
    localStorage.setItem("text", text);
    setText("");
    const Text = localStorage.getItem("text");
    let cc;
    if (img !== null) {
      let ab = img.name;
      cc = ab.split(".").pop();
    }
    const combinedId =
      check1.myId > check1.userInfo.id
        ? check1.myId + check1.userInfo.id
        : check1.userInfo.id + check1.myId;

    const cmb1 = check1.myId + check1.userInfo.id;
    const cmb2 = check1.userInfo.id + check1.myId;
    const unique_id = uuid();
    const msg = {};
    msg._id = unique_id;
    msg.createdAt = new Date();
    msg.text = Text ? Text : "";
    msg.user = { _id: check1.myId };
    let myMsg;
    myMsg = {
      ...msg,
      createdAt: Date.parse(msg.createdAt),
      image: imageUrl ? imageUrl : "",
      video: videoUrl ? videoUrl : "",
    };

    await chatSet(check1.user.id);
    const res = await getDoc(doc(db, "chats", cmb1));
    const res2 = await getDoc(doc(db, "chats", cmb2));
    const combinedId_loc = localStorage.getItem("combinedId");
    const combineId = check1.myId + check1.userInfo.id;
    const combineId2 = check1.userInfo.id + check1.myId;
    const Opp_id = localStorage.getItem("oppid");

    if (!res.exists() || !res2.exists()) {
      try {
        // await setDoc(
        //   doc(db, "chats", data.chatId !== "null" ? data.chatId : combinedId),
        //   {
        //     messages: arrayUnion({
        //       ...myMsg,
        //       senderId: check1.myId,
        //       receiverId: check1.userInfo.id,
        //       createdAt: Date.parse(msg.createdAt),
        //       date: Timestamp.now(),
        //     }),
        //   }
        // );

        await setDoc(doc(db, "chats", cmb1), {
          messages: arrayUnion({
            ...myMsg,
            senderId: check1.myId,
            receiverId: check1.userInfo.id,
            createdAt: Date.parse(msg.createdAt),
            date: Timestamp.now(),
          }),
        });
        await setDoc(doc(db, "chats", cmb2), {
          messages: arrayUnion({
            ...myMsg,
            senderId: check1.userInfo.id,
            receiverId: check1.myId,
            createdAt: Date.parse(msg.createdAt),
            date: Timestamp.now(),
          }),
        });
        // console.log("q1");
        if (friendstatus === "true") {
          // console.log(data.user.UserID);
          await postApi(
            `${API_PATH.CHAT.MESSAGE}/${
              data.chatId !== "null" ? data.user.UserID : check1.user.id
            }`,
            {
              type: "text",
              content: Text
                ? Text
                : Text || img.type.slice(0, 5) !== "video"
                ? "Image"
                : "video",
            }
          );
        }
        setType("");
        await updateDoc(doc(db, "userChats", check1.myId), {
          [data.chatId + ".lastMessage"]: {
            myMsg,
            senderId: check1.myId,
            receiverId: check1.userInfo.id,
          },
          [data.chatId + ".date"]: serverTimestamp(),
        });
        await updateDoc(doc(db, "userChats", check1.userInfo.id), {
          [data.chatId + ".lastMessage"]: {
            myMsg,
            senderId: check1.myId,
            receiverId: check1.userInfo.id,
          },
          [data.chatId + ".date"]: serverTimestamp(),
        });
        // console.log("qr");

        // console.log("qs");

        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        // console.log("qD1");
        await setText("");
        await setImg(null);
        await setType("");
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        // console.log("1");
        setType("");
        await updateDoc(doc(db, "chats", cmb1), {
          messages: arrayUnion({
            ...myMsg,
            senderId: check1.myId,
            receiverId: check1.userInfo.id,
            createdAt: Date.parse(msg.createdAt),
            date: Timestamp.now(),
          }),
        });
        await updateDoc(doc(db, "chats", cmb2), {
          messages: arrayUnion({
            ...myMsg,
            senderId: check1.userInfo.id,
            receiverId: check1.myId,
            createdAt: Date.parse(msg.createdAt),
            date: Timestamp.now(),
          }),
        });
        // }
        // console.log("q");
        if (friendstatus === "true") {
          await postApi(
            `${API_PATH.CHAT.MESSAGE}/${
              data.chatId !== "null" ? data.user.UserID : check1.user.id
            }`,
            {
              type: "text",
              content: Text
                ? Text
                : Text || img.type.slice(0, 5) !== "video"
                ? "Image"
                : "video",
            }
          );
        }
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        // console.log("qD");
        await setText("");
        await setImg(null);
        await setType("");
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [messages]);
  // Mobile code
  const [state, setstate] = useState([]);
  function removeDuplicates() {
    // Create an array of objects
    const jsonObject = messages.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse);

    generateItems(uniqueArray);
  }

  function groupedDays(messages) {
    return messages.reduce((acc, el, i) => {
      const messageDay = moment(el.createdAt).format("YYYY-MM-DD");
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  }

  function generateItems(messages) {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort(
      (x, y) => moment(y, "YYYY-MM-DD").unix() - moment(x, "YYYY-MM-DD").unix()
    );
    const items = sortedDays.reduce((acc, date) => {
      const sortedMessages = days[date].sort(
        (x, y) => new Date(y.createdAt) - new Date(x.createdAt)
      );

      return acc.concat([...sortedMessages, { type: "day", date, id: date }]);
    }, []);
    setstate(items);
    return items;
  }

  //  console.log(state)

  const element = () => {
    const element = document.querySelector("#container_chat");

    element.addEventListener("wheel", (event) => {
      event.preventDefault();
      // console.log("wheel" , event)
      element.scrollBy({
        left: event.deltaY < 0 ? -1 : 1,
      });
    });
  };

  //
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const groupedMessages = {};

  messages.forEach((message) => {
    const date = formatDate(message.createdAt);
    if (!groupedMessages[date]) {
      groupedMessages[date] = [];
    }
    groupedMessages[date].push(message);
  });

  // scroll
  const [leftscroll, setscrollShow] = useState(false);
  const [rightscroll, setscrollRShow] = useState(true);
  const containerRef = useRef(null);
  const handleScroll = (e) => {
    const container = containerRef.current;
    if (container) {
      container.scrollLeft += e.deltaY;
    }
    if (container.scrollLeft === 0) {
      setscrollShow(false);
      setscrollRShow(true);
    }
    var maxScrollLeft = container.scrollWidth - container.clientWidth;
    if (container.scrollLeft >= maxScrollLeft) {
      setscrollShow(true);
      setscrollRShow(false);
    }

    if (container.scrollLeft >= maxScrollLeft) {
      handleNext();
    }
  };

  const scrollLeft = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollLeft -= 50;
    }
    setscrollRShow(true); // Adjust the scrolling distance as needed
    if (container.scrollLeft === 0) {
      setscrollShow(false);
    }
    var maxScrollLeft = container.scrollWidth - container.clientWidth;
    if (container.scrollLeft === maxScrollLeft) {
      handleNext();
    }
  };

  const scrollRight = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollLeft += 100;

      setscrollShow(true); // Adjust the scrolling distance as needed
      var maxScrollLeft = container.scrollWidth - container.clientWidth; 
      if (container.scrollLeft >= maxScrollLeft) {
        // handleNext();
        setscrollRShow(false);
      }
    }
  };

  const containerRef_friends = useRef(null);

  const handleScroll_friends = (e) => {
    const container = containerRef_friends.current;
    if (container.scrollHeight === container.clientHeight) {
      handleNextF();
    }
  };

  const show_mingle = localStorage.getItem("show_mingle");
  return (
    <>
      <ToastContainer />
      {Loading ? (
        <MatchesLoader />
      ) : (
        <div className=" container-chat-app position-relative">
          {!Chat ? (
            <div className="row mainChatdiv backColor">
              <div
                className={
                  ReportShow === true
                    ? " contentPart-chat-app1 border-0 px-0 overflow-hidden"
                    : " contentPart-chat-app border-0 px-0 "
                }
                // style={friendsList.length == 0 ? { height: "560.5px" } : null}
              >
                <div
                  className={
                    Chat || ChatwUid
                      ? "tab-content  fix_width mx-auto text-start"
                      : "tab-content  fix_width mx-auto overflow-scroll text-start scrollhide"
                  }
                >
                  <div
                    className={
                      tab2 === "tab1" || tab2 === null
                        ? "tab-pane active fontawesome-demo active "
                        : "tab-pane  fontawesome-demo "
                    }
                    id="tab1"
                  >
                    <div className="row pendingFilter mx-0 ">
                      <div
                        className="col-12 "
                        style={
                          Chat || ChatwUid
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        {friendsList.length > 0 || search_check === true ? (
                          <div className="mt-2">
                            <p className="line-para mb-2"> Chats:</p>
                            <input
                              type="text"
                              placeholder="Search"
                              className="border-none  screenInp flex-center w-100 ps-2 screenBootom PointAdd  Settingbottom  ps-3 py-2 rounded-5"
                              onKeyDown={handleKey}
                              onChange={(e) => setUsername(e.target.value)}
                              // value={username}
                            />
                          </div>
                        ) : null}
                        {friends.length > 0 ? (
                          <p className="line-para my-2">
                            {" "}
                            Pending Connections:
                          </p>
                        ) : null}
                        {friends.length > 0 ? (
                          <div className="Req  overflow-x-scroll d-flex align-items-center scrollhide">
                            {friends.length > 6 ? (
                              <button
                                className={
                                  leftscroll === true
                                    ? " ms-1  border-none scroll scrollhide rounded-5 z-1"
                                    : "z-n1 d-none"
                                }
                                style={{ height: "30px", width: "30px" }}
                                onClick={scrollLeft}
                              >
                                <HiOutlineArrowSmLeft />
                              </button>
                            ) : null}
                            <div
                              id="container_chat"
                              onWheel={handleScroll}
                              // onWheelCapture={element}
                              ref={containerRef}
                              style={{
                                width: "100%",
                                overflowX: "scroll",
                                whiteSpace: "nowrap",
                                height: "100px",
                                scrollbarWidth: "none",
                              }}
                              className="overflow-scroll d-flex flex-row align-items-center scrollhide"
                              // onWheelCapture={element}
                            >
                              <InfiniteScroll
                                dataLength={friends.length}
                                hasMore={true}
                                next={handleNext}
                                horizontal={true}
                                style={{
                                  display: "flex",
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {friends.map((p) => (
                                  <Link
                                    to={{
                                      pathname: "/Reqchat",
                                      search: `?rid=/chat`,
                                    }}
                                    state={p}
                                  >
                                    <img
                                      key={p.id}
                                      src={
                                        p.image !== "undefined" ? p.image : dp
                                      }
                                      className="  rounded-circle PointAdd ms-2 object-fit-cover img_brdr"
                                      height={70}
                                      width={70}
                                      style={{ flexShrink: "0" }}
                                      alt=""
                                      onClick={() => {
                                        localStorage.setItem(
                                          "ReqReport",
                                          JSON.stringify(
                                            p.message.content[0].message
                                          )
                                        );
                                        localStorage.setItem(
                                          "reqMessage",
                                          p.status
                                        );
                                      }}
                                    />
                                  </Link>
                                ))}
                              </InfiniteScroll>
                            </div>
                            {friends.length > 6 ? (
                              <button
                                className={
                                  rightscroll === true
                                    ? " ms-1  border-none scroll scrollhide rounded-5 z-1"
                                    : "z-n1 d-none"
                                }
                                style={{ height: "30px", width: "30px" }}
                                onClick={scrollRight}
                              >
                                <HiOutlineArrowSmRight />
                              </button>
                            ) : null}
                          </div>
                        ) : null}

                        {search_check !== true && show_mingle === "true" ? (
                          <div className="d-flex flex-column justify-content-center align-content-center ">
                            <div className="text-center position-absolute top-50 start-50 translate-middle fix_width">
                              <p className="line-para  mx-auto text-center  mb-1">
                                Once a mutual match is made conversations <br />
                                happens here
                              </p>
                              <div className="text-center my-2">
                                <img
                                  src={nochat}
                                  alt=""
                                  width={150}
                                  height={100}
                                  className="object-fit-contain"
                                />
                              </div>
                              <button
                                className="btn_common w-50 py-2 mx-auto"
                                onClick={() => navigate("/matches")}
                              >
                                Mingle
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="">
                            <div
                              className={
                                friendsList.length ? "profile-border2" : ""
                              }
                            >
                              {friendsList.length ? (
                                <p className="line-para mt-2  mb-0">
                                  Connections:
                                </p>
                              ) : null}
                              <InfiniteScroll
                                dataLength={friendsList.length}
                                hasMore={true}
                                // next={handleNextF}
                              >
                                {friendsList.map((p) => (
                                  <div
                                    key={p.id}
                                    className="pendingPara py-2 profile-border pe-2"
                                    // ref={containerRef_friends}
                                    // onWheel={handleScroll_friends}
                                  >
                                    <div
                                      className="PointAdd d-flex align-items-center w-100 overflow-hidden"
                                      onClick={() =>
                                        navigate({
                                          pathname: "/chatHome",
                                          search: `?Uid=${p.user.id} `,
                                        })
                                      }
                                    >
                                      <img
                                        src={
                                          p.image !== "undefined" ? p.image : dp
                                        }
                                        className="rounded-circle PointAdd object-fit-cover img_brdr"
                                        height={"70px"}
                                        width={"70px"}
                                        alt=""
                                        style={{ flexShrink: "0" }}
                                        // onClick={() => nextpage(p.user.id)}
                                      />
                                      <div
                                        className="d-flex justify-content-between overflow-hidden"
                                        // style={{ width: "100%" }}
                                        // onClick={() => {
                                        //   setActive(true);
                                        //   chatSet(p.user.id);
                                        //   handleSelectChange(p.user.id);
                                        //   setChat(true);
                                        //   setshowfooter(false);
                                        //   statusCheck(p.id);
                                        //   localStorage.setItem("tab2", "tab1");
                                        //   localStorage.setItem(
                                        //     "IdealFor",
                                        //     p.user.ideal_for
                                        //   );
                                        //   removeDuplicates();
                                        // }}
                                      >
                                        <div className="d-inline-block pendingContent ps-3 overflow-hidden w-75">
                                          <p className="lh-1 line-para mb-0 text-start fs-6 text-capitalize">
                                            {p.chatName}
                                          </p>
                                          <span
                                            className=" lh-1 famousContent text-start  text-capitalize"
                                            style={{ fontSize: "12px" }}
                                          >
                                            Ideal For: {p.user.ideal_for}
                                          </span>
                                          <p
                                            className="lh-1 pb-1 mb-1 last-message  line-para"
                                            style={{ fontSize: "13px" }}
                                          >
                                            {" "}
                                            {p.message
                                              ? p.message.content[0].message
                                              : "..."}
                                          </p>
                                        </div>
                                        <div className="">
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              marginTop: "14px",
                                            }}
                                            className="line-para"
                                          >
                                            {p.message
                                              ? p.message.created_at
                                              : null}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </InfiniteScroll>
                            </div>
                          </div>
                        )}
                        {search_check === true ? (
                          <div className="line-para text-center my-4">
                            No User Found
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* {ShowFooter === true  ? ( */}
              <div className="pb-5  mb-5 d-flex align-items-end">
                <Footer2 />
              </div>
              {/* ) : null} */}
            </div>
          ) : null}
        </div>
      )}
      {!Chat ? (
        <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
          <div className="fix_width mx-auto">
            <Navbar3 />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ChatHome;
