/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useUserAuth } from "../context/userAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import { Alert, Form } from "react-bootstrap";
import { MdArrowBackIosNew } from "react-icons/md";
import "./SignUp.css";
import Footer2 from "../../layout/Footer/Footer2";
import ButtonLoader from "../../layout/Loader/ButtonLoader";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../../Firebase";
import OTPInput from "react18-input-otp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OtpInput from "react18-input-otp";
import { ref } from "firebase/storage";

const Otp = () => {
  const { register: register1, handleSubmit: handleSubmit1 } = useForm({
    mode: "onBlur",
  });
  const { state } = useLocation();
  const inputref = useRef();
  //   const result = JSON.parse(state);
  const [error, setError] = useState("");
  const [number] = useState("");
  const [setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState();
  const [Loading, setLoading] = useState(false);
  const { setUpRecaptha } = useUserAuth();
  const navigate = useNavigate();
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [reError, setReError] = useState("");
  const [showRecaptcha, setshowRecaptcha] = useState(false);
  const [recaptcharesult, setRecaptchResult] = useState("");
  
  
  useEffect(() => {
    const dark = localStorage.getItem("dark")
    const systemDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container-resend",
      {
        size: "normal",
        theme: systemDarkMode.matches === true? "dark" : "light",
        callback: (response) => {
          console.log("reCAPTCHA solved");
          setRecaptchaVerified(true);
          setReError("")
        },
        "expired-callback": () => {
          setRecaptchaVerified(false);
        },
      },
      auth
    );

    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  }, []);

  const onSubmit1 = async (data) => {
    setLoading(true);
    const number = localStorage.getItem("Phone");
    try {
      await postApi(API_PATH.AUTH.GENRATE_OTP, {
        phoneNumber: number,
        otp: otp,
      });
    } catch (error) {
      if (error.response.status === 404) {
        setLoading(false);
        verifyOtp(404);
      } else if (error.response.status === 400) {
        verifyOtp();
      }
    }
    // verifyOtp();
  };

  const verifyOtp = async (e) => {
    setError("");
    if (otp === "" || otp === null) return;
    try {
      setLoading(true);
      // window.confirmationResult
      //   .confirm(otp)
      const credential = PhoneAuthProvider.credential(
        recaptcharesult ? recaptcharesult : state.id,
        otp
      );
      await signInWithCredential(auth, credential)

        .then((result) => {
          if (e === 404) {
            localStorage.removeItem("flag");
            navigate("/verify-yourself");
          } else {
            Login();
          }
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            setError("Wrong code");
            toast.error("Wrong code");
          }, 2000);
          setLoading(false);
        });
    } catch (err) {
      setError(err.message);
      console.log(err);
      setLoading(false);
    }
  };
  const Login = async () => {
    const number = localStorage.getItem("Phone");
    try {
      const res = await postApi(API_PATH.AUTH.LOGIN, {
        phoneNumber: number,
      });
      if (res.status) {
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("connection", res.data.interestedIn);
        localStorage.setItem("gender_pref", res.data.gender_preference);
        window.location.replace("/matches");
        // setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const ResendOtp = async () => {
    const CompleteNumber = localStorage.getItem("Phone");
    setshowRecaptcha(true)
    if (!recaptchaVerified) {
      console.log("Please solve the reCAPTCHA first");
      return setReError("Please solve the reCAPTCHA first");
    }

    const appVerifier = window.recaptchaVerifier;
    try {
      // const response = await resendOTP(CompleteNumber);
      // setResult(response);
      const res = await signInWithPhoneNumber(
        auth,
        CompleteNumber,
        appVerifier
      ).then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setRecaptchResult(confirmationResult.verificationId);

      });
    } catch (error) {
      console.log(error);
    }
  };
  const Ref = useRef(null);

  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:00:30");

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const flag = localStorage.getItem("flag");
  const vid = localStorage.getItem("vid");
  useEffect(() => {
    // if (flag === "true" && (vid !== null || undefined)) {
    clearTimer(getDeadTime());
    // }
    window.scrollTo({ top: -50, left: 0, behavior: "instant" });
  }, []);

  const onClickReset = () => {
    clearTimer(getDeadTime());
  };
  useEffect(() => {
    const CompleteNumber = localStorage.getItem("Phone");
    const onSubmit = async () => {
      setError("");
      try {
        const response = await setUpRecaptha(CompleteNumber);
        setResult(response);
        localStorage.setItem("vid", response.verificationId);
        localStorage.setItem("flag", true);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    if (CompleteNumber) {
      // onSubmit();
    }
    window.scrollTo({ top: -50, left: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    if (otp.length === 6) {
      onSubmit1();
    }
  }, [otp.length]);

  return (
    <div className="container-fluid mx-0 px-0 Match-screen">
      <ToastContainer />
      <div className="login-page  fix_width mx-auto">
        <div className="mx-auto">
          <div className="row fix_width">
            <div className="row my-3 fix_width">
              <div className="arrow_back  btn-hover">
                <MdArrowBackIosNew className="btn-hover" />{" "}
                <span
                  className="align-middle btn-hover"
                  onClick={() => {
                    navigate("/auth-Phone");
                  }}
                >
                  Go Back
                </span>
              </div>
            </div>
            <div className="div">
              <h6 className="Phone_head">Verify Yourself</h6>
              <p className="subHeadOne">
                We have sent you a code confirming your number
              </p>
            </div>
          </div>

          <div className="row px-0 mx-0">
            <div className="col-12">
              <form action="" onSubmit={handleSubmit1(onSubmit1)}>
                <div className="text-center" style={{ height: "397px" }}>
                  <div className="row  mx-auto  phone_input_width">
                    <div className="col text-center  ">
                      <OtpInput
                        id="myInput"
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        isSuccessed={false}
                        errorStyle="error"
                        successStyle="success"
                        separateAfter={3}
                        shouldAutoFocus={true}
                        otpType="number"
                        isInputNum={true}
                        className="verficationInputCard justify-content-center  mx-auto"
                      />
                    </div>
                  </div>
                  <div id="recaptcha-container" className="d-none"></div>
                  <div
                    id="recaptcha-container-resend"
                    className={showRecaptcha !== true ? "d-none" :" my-4 d-flex align-items-center justify-content-center"}
                  ></div>
                  {reError ? (
                    <p className="text-danger">
                      Please solve reCAPTCHA first !
                    </p>
                  ) : null}
                  <p className="subHeadOne my-4">
                    {timer === "00:00:00" ? (
                      <span
                        onClick={() => {
                          ResendOtp();
                          onClickReset();
                        }}
                        className=" btn-hover"
                      >
                        Resend Code
                      </span>
                    ) : (
                      timer
                    )}
                  </p>
                </div>
                <div className="btn-div text-center mx-auto phone_input_width text-center">
                  {Loading ? (
                    <button
                      className="ctn_btn py-2 border border-0 mx-auto  rounded-5 w-100"
                      style={{ height: "50px" }}
                    >
                      <ButtonLoader />
                    </button>
                  ) : (
                    <button
                      className="ctn_btn   border border-0 mx-auto  rounded-5 lh-base w-100"
                      style={{ height: "50px" }}
                      type="submit"
                      onClick={(e) => onSubmit1(e.preventDefault())}
                    >
                      Verify
                    </button>
                  )}
                  <div className="">
                    <Footer2 />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
