/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../Profile/profile.css";
import Footer2 from "../../../layout/Footer/Footer2";
import "react-toastify/dist/ReactToastify.css";
import { getApi } from "../../../Api/Api";
import { API_PATH } from "../../../Api/ApiPAth";
import Loader from "../../../layout/Loader/Loader";
import Navbar3 from "../../../layout/BottomNavbar/Navbar3";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "@iconify/react";
import { RWebShare } from "react-web-share";

const Breport6 = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const [personality, setPersonality] = useState([]);
  const [famous, setFamous] = useState([]);
  const [Loading, setLoading] = useState(false);
  const tok = localStorage.getItem("token");
  const date = localStorage.getItem("bdate");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${tok}`,
    },
  };
  const imgReg = useRef(null);
  useEffect(() => {
    async function PersonalityData() {
      setLoading(true);
      try {
        const res = await getApi(
          `${API_PATH.BIRTHDAYPAID.BIRTH_WAY}/${id}`,
          {
            birthday: date,
          },
          axiosConfig
        );
        if (res.status) {
          setPersonality(res.data);
          setFamous(res.data.famous);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    if (date) {
      // PersonalityData();
      const ReportData = JSON.parse(
        localStorage.getItem("PurchasedBirthdayReport")
      );
      setPersonality(ReportData.way_report);
      setFamous(ReportData.way_report.famous);
    }
    window.scroll({
      top: -50,
      left: 0,
      behavior: "instant",
    });
    imgReg.current?.scrollIntoView({
      behavior: "instant",
    });
  }, []);

  return (
    <>
      {Loading ? <Loader /> : null}

      <div className="container-fluid profileScreen">
        <div className="container-lg personalityContent">
          <div className="row personalityBtn mx-auto fix_width">
            <div className="  mx-auto  " ref={imgReg}>
              <div className="position-sticky top-0 backColor">
                <div className="profileUpperpart navMatchbottom1 ">
                  <div className="  py-3  " onClick={() => navigate(-1)}>
                    <div className="arrow_back  btn-hover">
                      <MdArrowBackIosNew />{" "}
                      <span className="align-middle"> Go Back</span>
                    </div>
                  </div>
                  {/* <div className="col fs-6 text-light text-center py-3 profileHeading ">
                  {personality.day_name}
                </div> */}
                  <div className="fs-5  py-3 profileHeading me-3">
                    <RWebShare
                      data={{
                        text: `https://secretlanguage.network/v1/way/share?id=${personality.id}`,
                        url: `https://secretlanguage.network/v1/way/share?id=${personality.id}`,
                        title: "Share Way Report",
                      }}
                      // onClick={() =>
                      //   toast("url copied")
                      // }
                    >
                      <Icon
                        icon="mdi:share-outline"
                        className="fs-3 btn-hover"
                      />
                    </RWebShare>
                  </div>
                </div>
                {personality.famous ? (
                  <div className="col personalityBtns pb-2">
                    <ul className="nav  px-auto switch_div w-100">
                      <li className="nav-item tab-all w-50">
                        <a
                          className="nav-link  show py-1 px-3 active"
                          href="#tab1"
                          data-bs-toggle="tab"
                          onClick={() =>
                            imgReg.current?.scrollIntoView({
                              behavior: "instant",
                            })
                          }
                        >
                          <button className="side01  fs-5 fw-bold">
                            Personality
                          </button>
                        </a>
                      </li>
                      <li className="nav-item tab-all p-l-20 w-50">
                        <a
                          className="nav-link  py-1 px-4 "
                          href="#tab2"
                          data-bs-toggle="tab"
                          onClick={() =>
                            imgReg.current?.scrollIntoView({
                              behavior: "instant",
                            })
                          }
                        >
                          <button className="side01 fs-5 fw-bold">
                            Famous
                          </button>
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              <div className="col pb-2">
                <div className="col imagePersonality">
                  <div className="col bg-light m-2 rounded-3 text-dark">
                    <div className="text-center py-4 fw-semibold ">
                      {" "}
                      Their Life Journey Runs
                    </div>
                    <div className="weekPersonality ">
                      <div className="week-blockPersonality">
                        <span className="text-center">From</span>
                        <span className="datePersonality text-start">
                          {personality.week_from}
                        </span>
                      </div>
                      <div className="week-blockPersonality">
                        <span className=" text-center">To</span>
                        <span className="datePersonality text-end">
                          {personality.week_to}
                        </span>
                      </div>
                    </div>
                    <div className="image-sectionPersonality ">
                      <img
                        src={personality.image}
                        className="border-iconPersonality personalityImg"
                        alt=""
                      />
                    </div>
                    <div className="rel-namePersonality">
                      The way of {personality.name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div
                  className="tab-pane active row personalityBtn mx-0 "
                  id="tab1"
                >
                  <div className="description-sectionPersonality pt-0">
                    <p className="section-titlePersonality my-2 text-capitalize">
                      Personality
                    </p>
                    <p className="section-descriptionPersonality line-para">
                      {personality.report}
                    </p>
                  </div>
                  <div className="fs-4 fw-semibold  line-para mt-4">
                    <p className="mb-1 text-center text-capitalize">
                      Suggestions
                    </p>
                    <span className="personality6data">
                      &nbsp;{personality.suggestion}
                    </span>
                  </div>
                  <div className="fs-4 fw-semibold line-para   mt-4">
                    <p className="mb-1 text-center text-capitalize">Lesson</p>
                    <span className="personality6data">
                      &nbsp;{personality.lesson}
                    </span>
                  </div>
                  <div className="fs-4 fw-semibold line-para   mt-4">
                    <p className="mb-1 text-center text-capitalize">Goal</p>
                    <span className="personality6data">
                      &nbsp;{personality.goal}
                    </span>
                  </div>
                  <div className="fs-4 fw-semibold line-para   mt-4">
                    {" "}
                    <p className="mb-1 text-center text-capitalize">
                      Need to release
                    </p>
                    <span className="personality6data">
                      &nbsp;{personality.release}
                    </span>
                  </div>
                  <div className="fs-4 fw-semibold line-para   mt-4">
                    {" "}
                    <p className="mb-1 text-center text-capitalize">
                      Expected Reward
                    </p>
                    <span className="personality6data">
                      &nbsp;{personality.reward}
                    </span>
                  </div>
                  <div className="fs-4 fw-semibold  line-para  mt-4">
                    {" "}
                    <p className="mb-1 text-center text-capitalize">
                      {" "}
                      Must Learn To Balance
                    </p>
                    <span className="personality6data">
                      &nbsp;{personality.balance}
                    </span>
                  </div>
                </div>
                <div className="tab-pane" id="tab2">
                  <div className="container-fluid">
                    <div className="text-center">
                      {famous.map((q) => (
                        <div
                          className="py-5 famousbottom mx-2 mb-3 rounded-3"
                          key={q.id}
                        >
                          <img
                            src={q.image}
                            className="rounded-circle mb-2"
                            height={"80px"}
                            width={"80px"}
                            alt=""
                          />
                          <div className="fs-5  text-light">{q.sln}</div>
                          <div className="fs-6  text-light">{q.name}</div>
                          <span className="famousContent text-capitalize">
                            {q.age}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-5 mb-5">
                <Footer2 />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        <div className="fix_width mx-auto">
          <Navbar3 />
        </div>
      </div>
    </>
  );
};

export default Breport6;
