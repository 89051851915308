/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./navbar3.css";
import { BiUser } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { getApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import dp from "../../assets/Dp.png";
const Navbar3 = () => {
  const pathname = useLocation().pathname;
  const [img, setImg] = useState();
  const SettingData = async () => {
    try {
      const res = await getApi(API_PATH.PROFILE.USER_DATA);
      if (res.status) {
        localStorage.setItem("Image", res.data.image);
        localStorage.setItem("Name", res.data.name);
        setImg(res.data.image);
        if (res) {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    SettingData();
    const localImage = localStorage.getItem("Image");
    setImg(localImage);
  }, []);

  const P_img = localStorage.getItem("Image");
  return (
    <nav className="navv ">
      <Link
        to="/matches"
        className={`${
          pathname === "/matches" ? "nav__linkk active" : "nav__linkk"
        }`}
      >
        <i className="material-icons nav__iconn">
          <Icon icon="carbon:connect" />
        </i>
      </Link>
      <Link
        to="/chat"
        className={`${
          pathname === "/chat" ? "nav__linkk active" : "nav__linkk"
        }`}
      >
        <i className="material-icons nav__iconn">
          <Icon icon="ant-design:message-outlined" />
        </i>
      </Link>
      <Link
        to="/reports"
        className={`${
          pathname === "/reports" || pathname === "/birthdayreport"
            ? "nav__linkk active"
            : "nav__linkk"
        }`}
      >
        <i className="material-icons nav__iconn">
          {" "}
          <Icon icon="ph:cake-light" />
        </i>
      </Link>
      <Link
        to="/profile"
        className={`${
          pathname === "/profile" ? "nav__linkk active" : "nav__linkk"
        }`}
      >
        <i className="material-icons nav__iconn">
          <img
            src={img ? img : dp}
            width={30}
            height={30}
            className={
              pathname === "/profile"
                ? "circle rounded-circle img_border_active object-fit-cover"
                : "circle rounded-circle  object-fit-cover"
            }
          />
        </i>
      </Link>
    </nav>
  );
};

export default Navbar3;
