import React, { useMemo, useRef } from "react";
import "./profile.css";
import Footer2 from "../../layout/Footer/Footer2";
import { useEffect } from "react";
import { useState } from "react";
import { getApi, postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../layout/Loader/Loader";
import { MdArrowBackIosNew } from "react-icons/md";
import Navbar3 from "../../layout/BottomNavbar/Navbar3";
import { Icon } from "@iconify/react";
import Modal from "react-modal";
import dp from "../../assets/Dp.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { Timestamp, arrayUnion, doc, setDoc } from "firebase/firestore";
import { db } from "../../Firebase";
import { v4 as uuid } from "uuid";
import ButtonLoader from "../../layout/Loader/ButtonLoader";
import Footer from "../../layout/Footer/Footer";
import { RxCross1 } from "react-icons/rx";
import circle from "../../assets/lightPrice.png";
import { GiStarShuriken } from "react-icons/gi";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

// stripe
const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "12px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const Requests = () => {
  const location = useLocation();
  const { requestsLength } = location.state;
  const [RequestsLengthUpdate, setRequestsLength] = useState();
  const [request, setRequest] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount] = useState(0);
  const [SubModal, setSubModal] = useState(false);
  const [request_id, setyRequest_id] = useState();

  useEffect(() => {
    setRequestsLength(requestsLength);
    const requestData = localStorage.getItem("requestPageData");
    if (requestData) {
      setRequest(JSON.parse(requestData));
    } else {
      setLoading(true);
    }
    RequestData();
    if (page === 1) {
      window.scrollTo({ top: -50, left: 0, behavior: "instant" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  async function RequestData() {
    try {
      const res = await getApi(`${API_PATH.PROFILE.REQUEST_LIST}/${page}`);
      if (res.status) {
        if (page === 1) {
          setRequest(res.data);
          localStorage.setItem("requestPageData", JSON.stringify(res.data));
        } else {
          const new_friend = request.concat(res.data);
          setRequest(new_friend);
          localStorage.setItem("requestPageData", JSON.stringify(new_friend));
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const [LoadingChat, setLoadingChat] = useState(false);
  // handle next
  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1);
  };

  // useEffect(() => {
  //   const pagedatacount = Math.ceil(request.length / 8);
  //   setPageCount(pagedatacount);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [request])

  const handleReport = (report) => {
    const removeLastLine = report.split("*").shift();
    localStorage.setItem("acceptreport", removeLastLine);
  };
  const getStat = async (id) => {
    setLoadingChat(true);
    try {
      const res = await getApi(API_PATH.SUBSCRIPTION.CHECK_StATUS);
      if (res.data.isVerified === 1) {
        Accept(id);
        setSubModal(false);
        openModalName();
      } else {
        openModalName();
        setSubModal(true);
      }
    } catch (err) {}
  };
  const Accept = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.USER_PROFILE_DATA.ACCEPT_REQUEST, {
        id: id,
      });
      if (res.data.status === "success") {
        handleSend(id);
        const updatedData = request.filter((item) => item.id !== id);
        setRequest(updatedData);
        setRequestsLength(requestsLength - 1);
        // RequestData();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSend = async (otherid) => {
    // console.log(imageUrl, videoUrl, "vid");
    const myId = localStorage.getItem("id");
    const myName = localStorage.getItem("Name");
    const myProfilePic = localStorage.getItem("Image");
    const Report = localStorage.getItem("acceptreport");
    const cmb1 = myId + otherid;
    const cmb2 = otherid + myId;
    const unique_id = uuid();
    const msg = {};
    msg._id = unique_id;
    msg.createdAt = new Date();
    msg.text = Report;
    msg.user = { _id: myId, name: myName + ",m", avatar: myProfilePic };
    let myMsg;
    myMsg = {
      ...msg,
      createdAt: Date.parse(msg.createdAt),
      image: "",
      video: "",
      thumbnail: "",
    };
    try {
      await setDoc(doc(db, "chats", cmb1), {
        messages: arrayUnion({
          ...myMsg,
          senderId: otherid,
          receiverId: myId,
          createdAt: Date.parse(msg.createdAt),
          date: Timestamp.now(),
        }),
      });
      // console.log("jj");
      await setDoc(doc(db, "chats", cmb2), {
        messages: arrayUnion({
          ...myMsg,
          senderId: otherid,
          receiverId: myId,
          createdAt: Date.parse(msg.createdAt),
          date: Timestamp.now(),
        }),
      });

      // img.type.slice(0, 5) !== "video" condition for sending image and video in api

      await postApi(`${API_PATH.CHAT.MESSAGE}/${otherid}`, {
        type: "text",
        content: "Report",
      });
      localStorage.removeItem("acceptreport");
      setLoadingChat(false);
    } catch (error) {
      console.log(error);
      setLoadingChat(false);
    }
  };
  const Reject = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.USER_PROFILE_DATA.REJECT_REQUEST, {
        id: id,
      });
      if (res.data.status === "success") {
        // RequestData();
        const updatedData = request.filter((item) => item.id !== id);
        setRequest(updatedData);
        setRequestsLength(requestsLength - 1);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const nextpage = (id, data) => {
    navigate(`/userprofile?requestprofileId=${id}`, {
      state: { data },
    });
  };
  const nextpageFriends = (id) => {
    navigate({
      pathname: `/userprofile`,
      search: `?friendprofileId=${id}`,
    });
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "450px",
    },
  };

  const [modalIsOpenName, setIsOpenName] = React.useState(false);
  const [userinfo, setUserinfo] = React.useState("");
  function openModalName(data) {
    setIsOpenName(true);
  }

  function closeModalName() {
    setIsOpenName(false);
    setSubModal(false);
    setStartPayment(false);
    setPaymentLoading(false);
  }

  const chatMove = (data) => {
    navigate({
      pathname: "/chatHome",
      search: `?Uid=${data}`,
    });
  };

  const containerRef_friends = useRef(null);

  const handleScroll_friends = (e) => {
    const container = containerRef_friends.current;
    if (container.scrollHeight === container.clientHeight) {
      handleNext();
    }
  };

  function afterOpenSub() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModalSub() {
    setSubModal(false);
  }
  const pay = () => {
    window.open("https://buy.stripe.com/test_6oE8z78aCcCL676cMN", "_blank");
  };

  //Subscription

  const [Paymentloading, setPaymentLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [SuccessSubscribe, setSuccessSubscribet] = useState(false);
  const [startPayment, setStartPayment] = useState(false);

  const [isValidName, setisValidName] = useState(true);
  const [isValidEmail, setisValidEmail] = useState(true);
  const [isValidCard, setisValidCard] = useState(true);
  const [isValidExpiry, setisValidExpiry] = useState(true);
  const [isValidCvc, setisValidCvc] = useState(true);
  const handleSubmit = async (event) => {
    event.preventDefault();

    var nameField = document.getElementById("cc-name");
    if (name === "") {
      nameField.focus();
      setisValidName(false);
    } else {
      setisValidName(true);
    }

    if (email === "") {
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }

    const cardNumber = elements.getElement("cardNumber");
    const cardExpiry = elements.getElement("cardExpiry");
    const cardCvc = elements.getElement("cardCvc");
    const isInvalidCard = cardNumber._invalid;
    const isEmptyCard = cardNumber._empty;

    const isInvalidExpiry = cardExpiry._invalid;
    const isEmptyExpiry = cardExpiry._empty;

    const isInvalidCvc = cardCvc._invalid;
    const isEmptyCvc = cardCvc._empty;

    const isValidCard = !(isEmptyCard || isInvalidCard);
    const isValidExpiry = !(isInvalidExpiry || isEmptyExpiry);
    const isValidCvc = !(isInvalidCvc || isEmptyCvc);
    setisValidCard(isValidCard);
    setisValidExpiry(isValidExpiry);
    setisValidCvc(isValidCvc);
    if (!stripe || !elements || !isValidCard || !isValidExpiry || !isValidCvc) {
      setPaymentLoading(false);
      return;
    }

    try {
      setPaymentLoading(true);

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });
      payload.email = email;
      payload.name = name;
      //   payload.product_id = "prod_PWUrmEVkHkegOH"
      payload.paymentId = payload.paymentMethod.id;
      const mydata = await stripeApiHandler(payload);
      const confirm = await stripe.confirmCardPayment(mydata.clientSecret);

      if (confirm.error) {
        return alert("Payment unsuccessful!");
      }
      confirm.paymentIntent.sub_id = mydata.sub_id;
      let objJsonStr = JSON.stringify(confirm.paymentIntent);
      var encoded = btoa(objJsonStr);
      await addReciept(encoded);
      // alert("Payment Successful! Subscription active.");
    } catch (error) {
      console.log(error);
      setPaymentLoading(false);
    }
  };

  async function stripeApiHandler(data) {
    try {
      const res = await postApi(
        API_PATH.SUBSCRIPTION.CREATE_SUBSCRIPTION,
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      setPaymentLoading(false);
      throw err;
      //   res.status(500).json({ message: "Internal server error" });
    }
  }

  async function addReciept(reciept) {
    try {
      const res = await postApi(API_PATH.SUBSCRIPTION.SUBSCRIPTION, {
        receipt: reciept,
      });
      if (res.status) {
        closeModalName();
        setSuccessSubscribet(true);
        setPaymentLoading(false);
      }
    } catch (error) {
      setPaymentLoading(false);
    }
  }
  function afterOpenModalSucssessSub() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }
  function closeModalSucssessSub() {
    setSuccessSubscribet(false);
    getStat(request_id);
    // Accept(request_id);
    // openModalName();
    // setSubModal(true)
  }
  return (
    <>
      {Loading ? <Loader /> : null}
      <div className="container-fluid  Match-screen">
        <div className="container-lg">
          <div className="row pendingFilter mx-auto fix_width">
            <div className="  mx-auto">
              <div className="profileUpperpart navMatchbottom1  py-1 position-sticky top-0 backColor">
                <div className="  py-3  " onClick={() => navigate(-1)}>
                  <div className="arrow_back   btn-hover">
                    <MdArrowBackIosNew />{" "}
                    <span className="align-middle"> Go Back</span>
                  </div>
                </div>
                <div className="col fs-5   text-center py-3  ">
                  <p className="subHeadOne mb-0 px-0">
                    Requests ({request.length})
                  </p>
                </div>
                <div className="col-2"></div>
              </div>

              <div className="col  mb-4 profile-border2 element">
                <InfiniteScroll
                  dataLength={request.length}
                  hasMore={true}
                  next={handleNext}
                >
                  {request.map((p) => (
                    <div
                      key={p.id}
                      className="pendingPara py-2 profile-border align-items-center"
                    >
                      <div className="" onClick={() => nextpage(p.id, p)}>
                        <img
                          src={p.image !== "undefined" || null ? p.image : dp}
                          className="rounded-circle PointAdd  object-fit-cover img_brdr"
                          height={60}
                          width={60}
                          alt=""
                        />

                        <div className="d-inline-block pendingContent ps-3 PointAdd">
                          <p className="  mb-0 text-start">{p.name}</p>
                          <span className="famousContent text-start  ">
                            Ideal For:{" "}
                            <span className=" text-capitalize">{p.ideal}</span>
                          </span>
                        </div>
                      </div>
                      <div className="align-middle">
                        <Icon
                          icon="zondicons:add-solid"
                          color="#7ADD84"
                          className="  btn-hover"
                          style={{ fontSize: "28px" }}
                          onClick={() => {
                            handleReport(
                              p.result[0].message.content[0].message
                            );
                            getStat(p.id);
                            setUserinfo(p);
                            setyRequest_id(p.id);
                          }}
                        />

                        <Icon
                          icon="ph:x-circle-fill"
                          color="red"
                          style={{ fontSize: "35px" }}
                          className="  ms-3 btn-hover"
                          rotate={2}
                          onClick={() => Reject(p.id)}
                        />
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
              {request.length === 0 && !Loading ? (
                <p className="text-center position-absolute start-50 top-50 translate-middle fs-4 w-75">
                  Sorry you don't have request
                </p>
              ) : null}
              {request.length >= 6 ? (
                <div className="backColor" style={{ height: "80px" }}>
                  <Footer2 />
                </div>
              ) : null}
              <div
                className="position-relative  "
                style={{ height: "100px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        {request.length < 6 ? (
          <div className="backColor" style={{ height: "80px" }}>
            <Footer2 />
          </div>
        ) : null}
        <div className="fix_width mx-auto">
          <Navbar3 />
        </div>
      </div>

      <Modal
        isOpen={modalIsOpenName}
        onRequestClose={closeModalName}
        style={customStyles}
        contentLabel="Example Modal Connections"
        ariaHideApp={false}
      >
        {SubModal ? (
          <div className="text-center">
            <div
              className="btn-hover text-end"
              onClick={() => closeModalName()}
            >
              <RxCross1 className=" btn-hover" />
            </div>
            <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
              <div className="container-xxl">
                <div className="row suv mx-0 ">
                  {startPayment !== true ? (
                    <div className="px-0">
                      <div className="col  subcription-bg ">
                        <div className="mb-4">
                          <img
                            src={circle}
                            alt=""
                            className="img_clr_sub "
                            width={250}
                          />
                          <p className="sub-content pt-2 fw-normal  mx-auto">
                            See Who You're Matched With And Connect!
                          </p>
                          <div className="Sub-content2">
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Messaging
                              <GiStarShuriken className="star" />
                            </span>{" "}
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Plus All The Standard Features
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Birthday Reports
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Relationship Reports
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Matchmaking Reports
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                          </div>
                        </div>

                        <div className="d-grid justify-content-center my-2">
                          <button
                            className="sub-btn-1 mb-3"
                            onClick={() => {
                              // Subscribe();
                              // pay();
                              setStartPayment(true);
                            }}
                            style={{
                              backgroundColor: "#8270FF",
                              borderColor: "#8270FF",
                            }}
                          >
                            Subscribe
                          </button>
                          <button
                            className="sub-btn-2 border-0 border-none"
                            onClick={() => {
                              closeModalName();
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>

                      <div className="px-2  ">{/* <Footer /> */}</div>
                    </div>
                  ) : (
                    <div className="col  subcription-bg ">
                      <h4 className="d-flex justify-content-center align-items-center mb-3 text-center">
                        Pay with card
                      </h4>
                      <form onSubmit={handleSubmit}>
                        <div className="row text-start">
                          <div
                            className="col-12 mb-3"
                            style={{ minHeight: "90px" }}
                          >
                            <label htmlFor="cc-name">Name on card</label>
                            <input
                              id="cc-name"
                              type="text"
                              className="form-control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                            {!isValidName ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                Invalid Name
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="col-12 mb-3"
                            style={{ minHeight: "90px" }}
                          >
                            <label htmlFor="cc-email">Email</label>
                            <input
                              id="cc-email"
                              type="email"
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {!isValidEmail ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                Invalid Email Address
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="row text-start">
                          <div
                            className="col-md-12 mb-3"
                            style={{ minHeight: "90px" }}
                          >
                            <label htmlFor="cc-number">Card Number</label>
                            <CardNumberElement
                              id="cc-number"
                              className="form-control"
                              options={CARD_ELEMENT_OPTIONS}
                            />
                            {!isValidCard ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                Invalid Card Number
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="row text-start">
                          <div
                            className="col-6 mb-3"
                            style={{ minHeight: "90px" }}
                          >
                            <label htmlFor="expiry">Expiration Date</label>
                            <CardExpiryElement
                              id="expiry"
                              className="form-control"
                              options={CARD_ELEMENT_OPTIONS}
                            />
                            {!isValidExpiry ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                Invalid Expiration Date
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="col-6 mb-3"
                            style={{ minHeight: "90px" }}
                          >
                            <label htmlFor="cvc">CVC</label>
                            <CardCvcElement
                              id="cvc"
                              className="form-control"
                              options={CARD_ELEMENT_OPTIONS}
                            />
                            {!isValidCvc ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                {" "}
                                Invalid CVC
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <hr className="mb-4" />
                        <p style={{ fontSize: "12px" }}>
                          Note - We do not store your CVV/CVC number
                        </p>
                        {Paymentloading === false ? (
                          <button
                            type="submit"
                            className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                            style={{ height: "45px" }}
                          >
                            Pay
                          </button>
                        ) : (
                          <button
                            className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                            style={{ height: "45px" }}
                          >
                            <ButtonLoader />
                          </button>
                        )}
                        {errorMsg && (
                          <div className="text-danger mt-2">{errorMsg}</div>
                        )}
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <img
              src={userinfo.image !== "undefined" || null ? userinfo.image : dp}
              alt=""
              className="rounded-3 object-fit-cover"
              width={100}
              height={130}
            />
            <div className="emoji">
              <Icon
                icon="noto:party-popper"
                color="white"
                className="fs-3 party_emoji"
              />
            </div>

            <p className="subHeadOne fs-4">Keep it up!</p>
            <p className="subHeadOne">
              Now you are friends with {userinfo.name}!
            </p>

            {LoadingChat ? (
              <button
                className="btn_common px-5 w-100 border-none rounded-5"
                onClick={() => chatMove(userinfo.id)}
                style={{
                  height: "50px",
                }}
              >
                <ButtonLoader />
              </button>
            ) : (
              <button
                className="btn_common px-5 w-100  border-none rounded-5"
                onClick={() => chatMove(userinfo.id)}
                style={{
                  height: "50px",
                }}
              >
                Chat with {userinfo.name}
              </button>
            )}

            <p
              className="subHeadOne fs-6 mt-2 btn-hover"
              onClick={() => nextpageFriends(userinfo.id)}
            >
              View {userinfo.name} Profile
            </p>
            <p className="fs-6 btn-hover" onClick={() => closeModalName()}>
              Skip
            </p>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={SuccessSubscribe}
        onAfterOpen={afterOpenModalSucssessSub}
        onRequestClose={closeModalSucssessSub}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="text-center form-width">
          <div
            className="btn-hover text-end"
            onClick={() => closeModalSucssessSub()}
          >
            <RxCross1 className=" btn-hover" />
          </div>
          <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
            <div className="container-xxl">
              <div className="row suv mx-0 ">
                <div className="col  subcription-bg ">
                  <div className="mb-5">
                    <div className="fs-4 text-center pt-3 pb-3 section-heading">
                      idealfor Subscription Confirmed!
                    </div>
                    {/* <p className="mb-0   fs-4">
                          You successfully started a{" "}
                          <span className="active">
                            IdealFor Subscription - 1 Month
                          </span>
                        </p> */}

                    <p>
                      Your 1-Month idealfor Subscription is now active. Please
                      note that your account will be automatically billed every
                      month once any existing subscription period concludes. If
                      you ever wish to cancel your idealfor subscription, you
                      have the flexibility to do so at any time through your
                      profile settings.
                    </p>
                  </div>
                </div>
                <div className="px-2 ">{/* <Footer /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Requests;
