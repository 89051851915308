import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./setting.css";
import { MdArrowBackIosNew, MdKeyboardArrowLeft } from "react-icons/md";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { patchApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import ReactGoogleAutocomplete from "react-google-autocomplete";

const UserLocation = () => {
  const inputRef = useRef();
  const birthday = localStorage.getItem("birthday");
  const nameval = localStorage.getItem("Name");
  const conn = localStorage.getItem("connection");
  const gen = localStorage.getItem("gender");
  const oppgen = localStorage.getItem("opp-Gender");
  const InstaName = localStorage.getItem("InstaName");
  const handlePlaceChanged = (place) => {
    // console.log(place.formatted_address);
    // console.log(place.geometry.location.lat());
    // console.log(place.geometry.location.lat());
    localStorage.setItem("location", place.address_components[0].long_name);
    UpdateDetails(place.address_components[0].long_name);
  };
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const { register, handleSubmit } = useForm({
    defaultValues: {
      updatename: "",
    },
  });
  const UpdateDetails = async (data) => {
    const birthday = localStorage.getItem("birthdaysign");
    const gender = localStorage.getItem("gender");
    const gender_more = localStorage.getItem("gender_more");
    const name = localStorage.getItem("Name");
    const OppConn = localStorage.getItem("gender_pref");
    const intrestedIn = localStorage.getItem("connection");
    try {
      const res = await patchApi(API_PATH.UPDATE_PROFILE.UPDATE, {
        date_name: birthday,
        name: name,
        interested_in: parseInt(intrestedIn),
        gender: gender_more ? parseInt(gender_more) : parseInt(gender),
        gender_preference: parseInt(OppConn),
        country_name: data,
        instagram: "",
      });
      if (res.status === 200) {
        navigate("/profilesetting");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="container-fluid  fix_width">
        <div className="col text-start py-3  " onClick={() => navigate(-1)}>
          <div className="arrow_back btn-hover">
            <MdArrowBackIosNew /> <span className="align-middle"> Go Back</span>
          </div>
        </div>
        <div className="container-xxl">
          <div className="row verification mx-0">
            <div className=" py-4 mx-auto pt-0 ">
              <div className=" py-3  rounded">
                <div className="fs-6 pb-2">Search Place</div>
                <div className="d-flex flex-row justify-content-center">
                  <form className="col-12 d-flex flex-column justify-content-center">
                    {/* <LoadScript
                        googleMapsApiKey={process.env.REACT_GOOGLE_MAPS_API_KEY}
                        libraries={["places"]}
                        >
                        <StandaloneSearchBox
                            onLoad={(ref) => (inputRef.current = ref)}
                            onPlacesChanged={handlePlaceChanged}
                        >
                            <input
                            type="text"
                            className="border-none   screenInp flex-center w-100 ps-2 screenBootom PointAdd Settingbottom  ps-3 py-3 rounded-5"
                            placeholder="Enter Location"
                            {...register("updatelocation")}
                            />
                        </StandaloneSearchBox>
                        </LoadScript> */}

                    <ReactGoogleAutocomplete
                      className="border-none   screenInp flex-center w-100 ps-2 screenBootom PointAdd Settingbottom  ps-3 py-3 rounded-5"
                      apiKey="AIzaSyDwmD3sDnP10dsvEysi-yktJDfaKF8bK7k"
                      onPlaceSelected={(place) => {
                        handlePlaceChanged(place);
                        // UpdateDetails(place.address_components[0].long_name);
                      }}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLocation;
