import React, { useEffect, useRef, useState } from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import "./SignUp.css";
import ButtonLoader from "../../layout/Loader/ButtonLoader";
import { useForm } from "react-hook-form";
import { Alert } from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import countryCodeArray from "../../countryList.json";
import OTPInput from "otp-input-react";
import { useUserAuth } from "../context/userAuthContext";
import { useNavigate } from "react-router-dom";
import { postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../Firebase";
import axios from "axios";
import Footer2 from "../../layout/Footer/Footer2";
import { ToastContainer, toast } from "react-toastify";
import Otp from "./Otp";

const PhoneNew = () => {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");
  const [ReportShow, setReportShow] = useState(false);
  const [result, setResult] = useState(""); 
  const { setUpRecaptha } = useUserAuth();
  const flag = localStorage.getItem("flag");
  const [username, setUsername] = useState("");
  const [countryCode, setCountryCode] = useState(countryCodeArray);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [reError, setReError] = useState("");


  
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // getLocation() 
    const systemDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        theme: systemDarkMode.matches === true ? "dark" : "light",
        callback: (response) => {
          console.log("reCAPTCHA solved");
          setRecaptchaVerified(true);
        },
        'expired-callback': () => {
          setRecaptchaVerified(false);
        }
      },
      auth
    );

    window.recaptchaVerifier.render().then(widgetId => {
      window.recaptchaWidgetId = widgetId;
    });
    const getLocation = async () => {
      const location = await axios.get("https://ipapi.co/json");
      localStorage.setItem("country", location.data.country_name);
      setCode(location.data.country_calling_code);
    };

    getLocation();
    window.scrollTo({ top: -50, left: 0, behavior: "instant" });
  }, []);
  const search = (data) => {
    if (data.length > 0) {
      const q = countryCode.filter((blog) =>
        blog.name.toLowerCase().includes(data.toLowerCase())
      );

      setCountryCode(data.length === 0 ? countryCodeArray : q);

      if (q.length === 0) {
        // setSearch(true);
        // setNolist(true);
      }
    } else {
      // Friends();
    }
  };
  useEffect(() => {
    search(username);
    if (username.length > 0) {
      search(username);
    } else if (username.length === 0) {
      // setSearch(false);
      setCountryCode(countryCodeArray);
    }
  }, [username, username.length]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const CompleteNumber = code + data.mobile;
    localStorage.setItem("Phone", CompleteNumber);
    localStorage.setItem("PhoneNumber", true);
    setError("");
    if (!recaptchaVerified) {
      console.log("Please solve the reCAPTCHA first");
      return setReError("Please solve the reCAPTCHA first");
    }

    const appVerifier = window.recaptchaVerifier;
    
    if (CompleteNumber === "" || CompleteNumber === undefined)
      return setError("Please enter a valid phone number!");
    try {
      setLoading(true);
      // navigate("/otp");
      // localStorage.setItem("flag", true);
      // await setUpRecaptha(CompleteNumber);
      const res = await signInWithPhoneNumber(
        auth,
        CompleteNumber,
        appVerifier
      ).then(async(confirmationResult) => {
        window.confirmationResult = confirmationResult;
        await navigate("/otp", {
          state: { id: confirmationResult.verificationId },
        });
      });
     
      // navigate("/otp");
      // setResult(response);
      // navigate("/otp", { state: JSON.stringify(response) });
      // localStorage.setItem("vid", response.verificationId);
      localStorage.setItem("flag", true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      {
        error && toast.error(error);
      }
      setError(err.message);
      setLoading(false);
    }
  };

  const ResendOtp = async () => {
    const CompleteNumber = localStorage.getItem("Phone");
    try {
      // const response = await resendOTP(CompleteNumber);
      // setResult(response);

      const recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container-resend",
        { size: "invisible" },
        auth
      );
      recaptchaVerifier.render();
      const res = await signInWithPhoneNumber(
        auth,
        CompleteNumber,
        recaptchaVerifier
      );
      recaptchaVerifier.clear();
    } catch (error) {
      console.log(error);
    }
  };
  const [code, setCode] = React.useState("+1");

  const changeCode = (code) => {
    setCode(code);
    setReportShow(false);
    setUsername("");
  };

  const onSubmit1 = async (data) => {
    setLoading(true);
    const number = localStorage.getItem("Phone");
    try {
      await postApi(API_PATH.AUTH.GENRATE_OTP, {
        phoneNumber: number,
        otp: otp,
      });
    } catch (error) {
      if (error.response.status === 404) {
        setLoading(false);
        verifyOtp(404);
      } else if (error.response.status === 400) {
        verifyOtp();
      }
    }
    // verifyOtp();
  };

  useEffect(() => {
    if (otp.length === 6) {
      onSubmit1();
    }
  }, [otp.length]);

  const Login = async () => {
    const number = localStorage.getItem("Phone");
    try {
      const res = await postApi(API_PATH.AUTH.LOGIN, {
        phoneNumber: number,
      });
      if (res.status) {
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("token", res.data.token);
        window.location.reload();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const verifyOtp = async (e) => {
    setError("");
    if (otp === "" || otp === null) return;
    try {
      setLoading(true);
      result
        .confirm(otp)
        .then((result) => {
          if (e === 404) {
            localStorage.removeItem("flag");
            navigate("/verify-yourself");
          } else {
            Login();
          }
        })
        .catch((err) => {
          console.log(err);
          setError("Wrong code");
          setLoading(false);
        });
    } catch (err) {
      setError(err.message);
      console.log(err);
      setLoading(false);
    }
  };

  const Ref = useRef(null);

  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:00:30");

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  useEffect(() => {
    if (flag === "true") {
      clearTimer(getDeadTime());
    }
  }, [flag]);

  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  return (
    <>
      <div className="container-fluid mx-0 px-0 Match-screen">
        <div className="row mx-auto fix_width">
          <div className="  mx-auto">
            <div className="row my-3">
              <div className="arrow_back  btn-hover">
                <MdArrowBackIosNew />{" "}
                <span
                  className="align-middle"
                  onClick={() => {
                    flag === "true"
                      ? localStorage.setItem("flag", false)
                      : navigate("/");
                  }}
                >
                  Go Back
                </span>
              </div>
            </div>

            {/* {flag !== "true" ? ( */}
            <div className="">
              <div className="row pt-3">
                <div className="div">
                  <h6 className="Phone_head">Continue With Phone</h6>
                  <p className="subHeadOne mb-4">
                    Using your phone number, you can log in to an existing
                    account or create a new one automatically
                  </p>
                </div>
              </div>

              <div className="row px-0 mx-0">
                <div className="col-12  ">
                  <ToastContainer />
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formSignup"
                  >
                    <div
                      className="phone_input_width mx-auto"
                      style={{ height: "350px" }}
                    >
                      <label htmlFor="username" className="  mb-2">
                        Phone Number
                      </label>
                      <div
                        className={
                          errors.mobile
                            ? "d-flex Phone-input inputUsername border-danger"
                            : "d-flex Phone-input inputUsername  mx-auto"
                        }
                      >
                        <div
                          className="rounded p-2 code-div ms-3 m-2"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={() => setReportShow(true)}
                        >
                          {" "}
                          {code} <RiArrowDropDownLine className="fs-5" />
                        </div>
                        <input
                          type="tel"
                          autoFocus={true}
                          placeholder="Enter Phone Number"
                          style={{ width: "65%" }}
                          maxLength="10" 
                          className={
                            errors.mobile
                              ? "numInput mt-2 mb-3 border-danger ms-2"
                              : " numInput mt-2 mb-2 ms-2"
                          }
                          {...register("mobile", {
                            required: true,
                            minLength: {
                              value: 6,
                              message: "Phone number must be at least 6 digits"
                            },
                            maxLength: {
                              value: 10,
                              message: "Phone number must not exceed 10 digits"
                            },
                            pattern: {
                              value: /^\d{10}$/,
                              message: "Enter a valid phone number",
                            },
                          })}
                        />
                      </div>
                      {errors.mobile && (
                        <p className="text-danger">{errors.mobile.message}</p>
                      )}
                      <div id="recaptcha-container" className=" my-4 d-flex align-items-center justify-content-center"></div>

                      {
                        reError ? <p className=" text-danger">Please solve reCAPTCHA first !</p> : null
                      }
                      
                    </div>
                    <div
                      className="btn-div border border-0 mx-auto phone_input_width text-center"
                      style={{ height: "150px" }}
                    >
                      {Loading ? (
                        <button
                          className="ctn_btn py-2 border border-0 mx-auto rounded-5  w-100"
                          style={{ height: "50px" }}
                        >
                          <ButtonLoader />
                        </button>
                      ) : (
                        <button
                          className="ctn_btn py-2 border border-0 mx-auto rounded-5  w-100"
                          style={{ height: "50px" }}
                          type="submit"
                          // onClick={() => setFlag(!flag)}
                        >
                          Continue
                        </button>
                      )}
                      <div className="">
                        <Footer2 />
                      </div>
                    </div>

                    <div
                      className={
                        ReportShow === false
                          ? "card p-4 card-report-pwa vert-move-card-pwa bg-body-secondary   mx-auto"
                          : "card p-4 card-report-pwa vert-move-out-card-pwa bg-body-secondary  mx-auto "
                      }
                      style={
                        ReportShow === false
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      <hr
                        className="  mt-0  mx-auto"
                        onClick={() => setReportShow(false)}
                      />
                      <input
                        type="text"
                        placeholder="Search"
                        className="border-none  screenInp flex-center w-100 ps-2 screenBootom PointAdd  Settingbottom  ps-3 py-2 rounded-5"
                        value={username}
                        // onKeyDown={handleKey}
                        onChange={(e) => setUsername(e.target.value)}
                        // value={username}
                      />

                      {countryCode.map((a) => (
                        <ul
                          key={a.code}
                          className="border-bottom-secondary py-2 code-list mt-2 ms-0 px-3"
                          onClick={() => changeCode(a.dial_code)}
                        >
                          <div className="d-flex justify-content-between btn-hover">
                            <span>{a.name}</span> <span>{a.dial_code}</span>
                          </div>
                        </ul>
                      ))}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* ) : ( */}
            {/* <div className="">
                <div className="row fix_width">
                  <div className="div">
                    {error && <Alert variant="danger">{error}</Alert>}
                    <h6 className="Phone_head">Verify Yourself</h6>
                    <p className="subHeadOne">
                      We have sent you a code confirming your number
                    </p>
                  </div>
                </div>

                <div className="row px-0 mx-0">
                  <div className="col-12">
                    <form action="">
                      <div className="" style={{ height: "420px" }}>
                        <div className="row">
                          <div className="col text-center">
                            <OTPInput
                              value={otp}
                              onChange={setOtp}
                              autoFocus
                              OTPLength={6}
                              otpType="number"
                              disabled={false}
                              className="verficationInputCard justify-content-center"
                            />
                          </div>
                        </div>
                        <div
                          id="recaptcha-container-resend"
                          className="d-none"
                        ></div>
                        <p className="subHeadOne my-4 btn-hover">
                          {timer === "00:00:00" ? (
                            <span
                              onClick={() => {
                                ResendOtp();
                                onClickReset();
                              }}
                            >
                              Resend Code
                            </span>
                          ) : (
                            timer
                          )}
                        </p>
                      </div>
                      <div className="btn-div text-center mx-auto w-100 text-center">
                        {Loading ? (
                          <button
                            className="ctn_btn py-2 border border-0 mx-auto  rounded-5 w-75"
                            style={{ height: "50px" }}
                          >
                            <ButtonLoader />
                          </button>
                        ) : (
                          <button
                            className="ctn_btn   border border-0 mx-auto  rounded-5 lh-base w-75"
                            style={{ height: "50px" }}
                            type="submit"
                            onClick={(e) => onSubmit1(e.preventDefault())}
                          >
                            Verify
                          </button>
                        )}
                        <div className="">
                          <Footer2 />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneNew;
