/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"; 
import "../../Profile/profile.css";
import Footer2 from "../../../layout/Footer/Footer2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApi } from "../../../Api/Api";
import { API_PATH } from "../../../Api/ApiPAth";
import Loader from "../../../layout/Loader/Loader";
import Navbar3 from "../../../layout/BottomNavbar/Navbar3";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "@iconify/react";
import { RWebShare } from "react-web-share";

const Breport5 = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const [personality, setPersonality] = useState([]);
  const [Loading, setLoading] = useState(false);
  const date = localStorage.getItem("bdate");
  // eslint-disable-next-line no-unused-vars
  const notify = () => toast("No Famous Data Found!");
  const tok = localStorage.getItem("token");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${tok}`,
    },
  };
  const imgReg = useRef(null);
  useEffect(() => {
    async function PersonalityData() {
      setLoading(true);
      try {
        const res = await getApi(
          `${API_PATH.BIRTHDAYPAID.BIRTH_PATH}/${id}`,
          {
            birthday: date,
          },
          axiosConfig
        );
        if (res.status) {
          setPersonality(res.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    if (date) {
      // PersonalityData();
      const ReportData = JSON.parse(
        localStorage.getItem("PurchasedBirthdayReport")
      );
      setPersonality(ReportData.path_report);
    }
    window.scroll({
      top: -50, 
      left: 0, 
      behavior: 'instant',
    });
    imgReg.current?.scrollIntoView({
      behavior: "instant",
    });
  }, []);
  return (
    <>
      {Loading ? <Loader /> : null} 
      <ToastContainer />
      <div className="container-fluid profileScreen">
        <div className="container-lg personalityContent">
          <div className="row personalityBtn mx-auto fix_width">
            <div className="  mx-auto  "  ref={imgReg}>
              <div className="position-sticky top-0 backColor">
                <div className="profileUpperpart navMatchbottom1 ">
                  <div className="  py-3  " onClick={() => navigate(-1)}>
                    <div className="arrow_back  btn-hover">
                      <MdArrowBackIosNew />{" "}
                      <span className="align-middle"> Go Back</span>
                    </div>
                  </div>
                  <div className="fs-5  py-3 profileHeading me-3">
                  <RWebShare
                    data={{
                      // text: `https://secretlanguage.network/v1/path/share?id=${personality.id}`,
                      url: `https://secretlanguage.network/v1/path/share?id=${personality.id}`,
                      title: "Share Path Report",
                    }}
                    // onClick={() =>
                    //   toast("url copied")
                    // }
                  >
                    <Icon icon="mdi:share-outline" className="fs-3 btn-hover" />
                  </RWebShare>
                    {/* <CopyToClipboard
                      text={`https://secretlanguage.network/v1/day/share?id=${personality.id}`}
                      onCopy={() => toast("url copied")}
                    > 
                      <Icon icon="mdi:share-outline" className="fs-3" />
                    </CopyToClipboard> */}
                  </div>
                </div>
                {personality.famous ? (
                  <div className="col personalityBtns pb-2">
                    <ul className="nav  px-auto switch_div w-100">
                      <li className="nav-item tab-all w-50">
                        <a
                          className="nav-link  show py-1 px-3 active"
                          href="#tab1"
                          data-bs-toggle="tab"
                          onClick={()=>imgReg.current?.scrollIntoView({
                            behavior: "instant",
                          })}
                        >
                          <button className="side01  fs-5 fw-bold">
                            Personality
                          </button>
                        </a>
                      </li>
                      <li className="nav-item tab-all p-l-20 w-50">
                        <a
                          className="nav-link  py-1 px-4 "
                          href="#tab2"
                          data-bs-toggle="tab"
                          onClick={()=>imgReg.current?.scrollIntoView({
                            behavior: "instant",
                          })}
                        >
                          <button className="side01 fs-5 fw-bold">
                            Famous
                          </button>
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              <div className="col pb-2">
                <div className="col imagePersonality">
                  <div className="col bg-light py-3 m-2 rounded-3">
                    <p className="mb-0 text-dark">{personality.way_name}</p>
                    <img
                      src={personality.image}
                      className="personalityImg my-3"
                      alt=""
                    />
                    <div className="fs-4 fw-semibold text-dark">
                      {personality.name_long}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div className="tab-pane fontawesome-demo active" id="tab1">
                  <div className="">
                    <p className="line-para mb-2 fs-4 fw-bold">Challenge</p>
                    <p className="line-para">{personality.challenge}</p>
                  </div>
                  <div className="">
                    <p className="line-para mb-2 fs-4 fw-bold">Fulfillment</p>
                    <p className="line-para mb-0">{personality.fulfillment}</p>
                  </div>
                  <div className="">
                    <div className="fs-3 fw-bold personalityPara py-2 text-center">
                      Personality
                    </div>
                    <p className=" mb-0 line-para"> {personality.report}</p>
                  </div>
                </div>
                <div className="tab-pane" id="tab2">
                  <div className="container-fluid">
                    <div className="text-center"></div>
                  </div>
                </div>
              </div>
              <div className="pb-5 mb-5">
                <Footer2 />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        <div className=" fix_width  mx-auto">
          <Navbar3 />
        </div>
      </div>
    </>
  );
};

export default Breport5;
