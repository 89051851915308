import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <>
      <div className="container py-3">
        <div className="col text-center footer">
          <p
            className=" mb-0 "
            style={{ fontSize: "12px", lineHeight: "20px" }}
          >
            {" "}
            Powered by Secret Language of Birthdays & Relationships
            <br />{" "}
            <a
              href="https://doejo.com/"
              target="blank"
              className="color-manage px-1"
            >
              Made by doejo{" "}
            </a>{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
