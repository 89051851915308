import React, { useEffect, useState } from "react";
import "./share.css";
import {
  isAndroid,
  isDesktop,
  isIOS,
  isMacOs,
  isMobile,
  isWindows,
  isMobileSafari,
  isSafari,
} from "react-device-detect";
import invitePic from "../../assets/invite.png";
const Share = () => {
  const [OS, setOS] = useState("");
  const DetectDevice = () => {
    const navigator = window.navigator;
    const userAgent = navigator.userAgent;
    const normalizedUserAgent = userAgent.toLowerCase();
    const standalone = navigator.standalone;
    const isIos =
      /ip(ad|hone|od)/.test(normalizedUserAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    const Android = /android/.test(normalizedUserAgent);
    const isSafari = /safari/.test(normalizedUserAgent);
    const isWebview =
      (Android && /; wv\)/.test(normalizedUserAgent)) ||
      (isIos && !standalone && !isSafari);

    const osText = isIos ? "iOS" : Android ? "Android" : "Other";
    const webviewText = isWebview ? "Yes" : "No";
    // return setOS(osText);
    // if (isIos || isMobileSafari) {
    //   window.location.replace(
    //     "https://apps.apple.com/in/app/idealfor/id1558133582",
    //     "_blank"
    //   );
    // }
    if (osText === "iOS"  || isIos || isMacOs) { 
      window.location.replace(
        "https://apps.apple.com/in/app/idealfor/id1558133582",
        "_blank"
      );
    } else if (osText === "Android" || isAndroid) {
      // alert("android");
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.sln.app&hl=en_IN&gl=US",
        "_blank"
      );
    } else if (osText === "Other") {
      // alert("window");
      // window.open("https://idealfor.xyz/", "_blank");
    }
    if (isAndroid && isMobile) {
      // alert("android web");
      window.open(
        "https://play.google.com/store/apps/details?id=com.sln.app&hl=en_IN&gl=US",
        "_blank"
      );
    } else if (isWindows && isDesktop) {
      // alert("window");
      window.location.replace("https://idealfor.xyz/", "_blank");
    }
  };

  useEffect(() => {
    DetectDevice();
  }, []);
  return (
    <>
      <div className="container-fluid  main-share w-100 py-5 overflow-hidden position-absolute start-50 left-50 translate-middle">
        <div className="mx-3 first-div">
          <h2 className="h3 ">Idealfor</h2>
          <p className=" ">
            It’s both the Secret Language of Birthday’s and Relationship books
            in your pocket!
          </p>
          <div className=" btn-share">
            {/* <!-- App Store button --> */}
            <a
              href="https://apps.apple.com/in/app/idealfor/id1558133582"
              target="_blank"
              className="market-btn apple-btn rounded-5"
              role="button"
            >
              <span className="market-button-subtitle">Download on the</span>
              <span className="market-button-title">App Store</span>
            </a>

            {/* <!-- Google Play button --> */}
            <a
              href="https://play.google.com/store/apps/details?id=com.sln.app&hl=en_IN&gl=US"
              target="_blank"
              className="market-btn google-btn rounded-5"
              role="button"
            >
              <span className="market-button-subtitle">Download on the</span>
              <span className="market-button-title">Google Play</span>
            </a>

            {/* <!-- Windows store button --> */}
            <a
              href="https://idealfor.xyz/"
              target="_blank"
              className="market-btn windows-btn rounded-5"
              role="button"
            >
              <span className="market-button-subtitle">Download as</span>
              <span className="market-button-title">PWA</span>
            </a>
          </div>
        </div>

        <div className="phone_view text-start  mx-3">
          <img
            src={invitePic}
            alt=""
            height={500}
            className="object-fit-contain phn_img"
          />
        </div>
      </div>
    </>
  );
};

export default Share;
