import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router";
import dp from "../../../assets/Dp.png";
import { API_PATH } from "../../../Api/ApiPAth";
import { getApi, postApi } from "../../../Api/Api";
import { IoMdAttach } from "react-icons/io";
import { FaAngleLeft, FaArrowUp, FaChevronLeft } from "react-icons/fa";
import Modal from "react-modal";
import Circle from "../../../assets/lightPrice.png";
import Footer from "../../../layout/Footer/Footer";
import { RxCross1 } from "react-icons/rx";
import { GiStarShuriken } from "react-icons/gi";
import {
  Timestamp,
  arrayUnion,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../Firebase";
import { v4 as uuid } from "uuid";
import { data } from "jquery";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import ButtonLoader from "../../../layout/Loader/ButtonLoader";
import { MdOutlineArrowUpward } from "react-icons/md";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "800 !important",
    backgroundColor: "white !important",
    width: "450px",
    padding: "10px",
  },
};

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const RequestChatUI = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const updateConnection = queryParams.get("rid");
  const { state } = useLocation();
  const checking_State = JSON.parse(localStorage.getItem("checking_State"));
  const navigate = useNavigate();
  const [check1, setCheck1] = useState([]);
  const [SubModal, setSubModal] = useState(false);
  const [Loading, setLoading] = useState(false);
  const ReqMessage = localStorage.getItem("reqMessage");
  const Report = JSON.parse(localStorage.getItem("ReqReport"));
  useEffect(() => {
    setCheck1(checking_State ? checking_State : state);
    window.scroll({
      top: -50,
      left: 0,
      behavior: "instant",
    });
  }, []);
  const [request_id, setrequest_id] = useState();
  const getStat = async (id) => {
    setLoading(true);
    try {
      const res = await getApi(API_PATH.SUBSCRIPTION.CHECK_StATUS);
      if (res.data.isVerified === 1) {
        Accept(id);
      } else {
        setSubModal(true);
      }
    } catch (err) {}
  };
  const Accept = async (id) => {
    try {
      const res = await postApi(API_PATH.USER_PROFILE_DATA.ACCEPT_REQUEST, {
        id: id,
      });
      if (res.data.status === "success") {
        handleSend();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSend = async () => {
    // console.log(imageUrl, videoUrl, "vid");
    const removeLastLine = Report.split("*").shift();
    const cmb1 = check1.myId + check1.userInfo.id;
    const cmb2 = check1.userInfo.id + check1.myId;
    const unique_id = uuid();
    const msg = {};
    msg._id = unique_id;
    msg.createdAt = new Date();
    msg.text = removeLastLine;
    msg.user = {
      _id: check1.userInfo.id,
      name: check1.myName + ",m",
      avatar: check1.myProfilePic,
    };
    let myMsg;
    myMsg = {
      ...msg,
      createdAt: Date.parse(msg.createdAt),
      image: "",
      video: "",
      thumbnail: "",
    };
    try {
      await setDoc(doc(db, "chats", cmb1), {
        messages: arrayUnion({
          ...myMsg,
          senderId: check1.myId,
          receiverId: check1.userInfo.id,
          createdAt: Date.parse(msg.createdAt),
          date: Timestamp.now(),
        }),
      });
      // console.log("jj");
      await setDoc(doc(db, "chats", cmb2), {
        messages: arrayUnion({
          ...myMsg,
          senderId: check1.myId,
          receiverId: check1.userInfo.id,
          createdAt: Date.parse(msg.createdAt),
          date: Timestamp.now(),
        }),
      });

      // img.type.slice(0, 5) !== "video" condition for sending image and video in api

      await postApi(`${API_PATH.CHAT.MESSAGE}/${check1.userInfo.id}`, {
        type: "text",
        content: "Report",
      });
      localStorage.setItem("friendstatus", "");
      localStorage.removeItem("checking_State");
      localStorage.removeItem("ReqReport");
      localStorage.setItem("uid", "");
      navigate(`/chat`);
      setLoading(false);
      // // console.log("q1");
      // await updateDoc(doc(db, "userChats", check1.myId), {
      //   [cmb1 + ".lastMessage"]: {
      //     myMsg,
      //     senderId: check1.userInfo.id,
      //     receiverId: check1.myId,
      //   },
      //   [cmb1 + ".date"]: serverTimestamp(),
      // });
      // // console.log("qr");
      // await updateDoc(doc(db, "userChats", check1.userInfo.id), {
      //   lastMessage: myMsg,
      //   [cmb2 + ".lastMessage"]: {
      //     myMsg,
      //     senderId: check1.userInfo.id,
      //     receiverId: check1.myId,
      //   },
      //   [cmb2 + ".date"]: serverTimestamp(),
      // });
    } catch (error) {
      console.log(error);
    }
  };
  //   console.log(check1);

  const renderContentWithLineBreaks = (content) => {
    const lines = content?.split(/\\r?\\n/); // Split the content by '\r\n' or '\n'
    return lines?.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  const stateObj = { foo: "bar" };
  // history.pushState(stateObj, "", "/matches");

  function afterOpenSub() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModalSub() {
    setSubModal(false);
    setStartPayment(false);
    setPaymentLoading(false);
  }

  const pay = () => {
    // window.open(
    //   "https://apps.apple.com/us/app/idealfor/id1558133582",
    //   "_blank"
    // );
    window.open("https://buy.stripe.com/test_6oE8z78aCcCL676cMN", "_blank");
  };

  // stripe
  const [Paymentloading, setPaymentLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [SuccessSubscribe, setSuccessSubscribet] = useState(false);
  const [startPayment, setStartPayment] = useState(false);

  const [isValidName, setisValidName] = useState(true);
  const [isValidEmail, setisValidEmail] = useState(true);
  const [isValidCard, setisValidCard] = useState(true);
  const [isValidExpiry, setisValidExpiry] = useState(true);
  const [isValidCvc, setisValidCvc] = useState(true);
  const handleSubmit = async (event) => {
    event.preventDefault();

    var nameField = document.getElementById("cc-name");
    if (name === "") {
      nameField.focus();
      setisValidName(false);
    } else {
      setisValidName(true);
    }

    if (email === "") {
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }

    const cardNumber = elements.getElement("cardNumber");
    const cardExpiry = elements.getElement("cardExpiry");
    const cardCvc = elements.getElement("cardCvc");
    const isInvalidCard = cardNumber._invalid;
    const isEmptyCard = cardNumber._empty;

    const isInvalidExpiry = cardExpiry._invalid;
    const isEmptyExpiry = cardExpiry._empty;

    const isInvalidCvc = cardCvc._invalid;
    const isEmptyCvc = cardCvc._empty;

    const isValidCard = !(isEmptyCard || isInvalidCard);
    const isValidExpiry = !(isInvalidExpiry || isEmptyExpiry);
    const isValidCvc = !(isInvalidCvc || isEmptyCvc);
    setisValidCard(isValidCard);
    setisValidExpiry(isValidExpiry);
    setisValidCvc(isValidCvc);
    if (!stripe || !elements || !isValidCard || !isValidExpiry || !isValidCvc) {
      setPaymentLoading(false);
      return;
    }

    try {
      setPaymentLoading(true);

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });
      payload.email = email;
      payload.name = name;
      //   payload.product_id = "prod_PWUrmEVkHkegOH"
      payload.paymentId = payload.paymentMethod.id;
      const mydata = await stripeApiHandler(payload);
      const confirm = await stripe.confirmCardPayment(mydata.clientSecret);

      if (confirm.error) {
        return alert("Payment unsuccessful!");
      }
      confirm.paymentIntent.sub_id = mydata.sub_id;
      let objJsonStr = JSON.stringify(confirm.paymentIntent);
      var encoded = btoa(objJsonStr);
      await addReciept(encoded);
      // alert("Payment Successful! Subscription active.");
    } catch (error) {
      console.log(error);
      setPaymentLoading(false);
    }
  };

  async function stripeApiHandler(data) {
    try {
      const res = await postApi(
        API_PATH.SUBSCRIPTION.CREATE_SUBSCRIPTION,
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      setPaymentLoading(false);
      throw err;
      //   res.status(500).json({ message: "Internal server error" });
    }
  }

  async function addReciept(reciept) {
    try {
      const res = await postApi(API_PATH.SUBSCRIPTION.SUBSCRIPTION, {
        receipt: reciept,
      });
      if (res.status) {
        closeModalSub();
        setSuccessSubscribet(true);
        setPaymentLoading(false);
      }
    } catch (error) {
      setPaymentLoading(false);
    }
  }
  function afterOpenModalSucssessSub() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }
  function closeModalSucssessSub() {
    setSuccessSubscribet(false);
    Accept(request_id);
  }

  return (
    <div className="container-chat-app_Home   mx-auto">
      <div className="col px-0 fix_width">
        <div className="chat fix_width mx-auto">
          <div className="chatInfo d-flex flex-space-between border-bottom border-dark">
            <FaChevronLeft
              className=" ms-1 pointerIcon fs-5 line-para"
              onClick={() => {
                setCheck1([]);
                localStorage.setItem("friendstatus", "");
                localStorage.removeItem("checking_State");
                localStorage.removeItem("ReqReport");
                localStorage.setItem("uid", "");
                navigate(-1);
                // navigate(`${updateConnection}`, {
                //   search: "",
                //   replace: true,
                // });
              }}
            />
            <div
              className="d-flex align-items-center btn-hover ms-3"
              onClick={() =>
                navigate({
                  pathname: `/userprofile`,
                  search: `?friendprofileId=${check1?.id}`,
                })
              }
            >
              <img
                src={check1?.image != "undefined" ? check1?.image : dp}
                alt=""
                height="40px"
                width="40px"
                className="rounded-circle ms-1 ms-auto me-2 object-fit-cover img_brdr"
              />
              <div className="">
                <span className="mx-auto line-para text-capitalize">
                  {check1?.chatName}
                </span>
              </div>
            </div>
            <div className="chatIcons" key={check1?.id}></div>
          </div>
          {/* Custom Chat */}
          <div className="messages">
            <div className={ReqMessage === "1" ? `message ` : `message owner`}>
              <div className="messageInfo"></div>
              <div className="messageContent mt-2">
                <p className="ms-2 mt-3 mb-4">
                  {renderContentWithLineBreaks(
                    state ? state.message.content[0].message : Report
                  )}
                </p>

                {check1?.status === 1 ? (
                  <div className="text-center ms-2" style={{ width: "70%" }}>
                    {Loading ? (
                      <button
                        className="btn_common px-4 py-1 mb-3 rounded-5"
                        style={{ width: "150px" }}
                      >
                        <ButtonLoader />
                      </button>
                    ) : (
                      <button
                        className="btn_common px-4 py-2 mb-3 "
                        style={{ width: "150px" }}
                        onClick={() => {
                          getStat(check1.user.id);
                          setrequest_id(check1.user.id);
                          // Accept(check1?.user.id);
                        }}
                      >
                        Communicate
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-12 ">
            {/* <Input /> */}
            <form action="">
              <div className="input border-top border-dark">
                <input
                  type="text"
                  className="ms-0"
                  placeholder="Write a message..."
                  onBlur={(e) => e.preventDefault()}
                  disabled={true}
                  style={{ width: "80%" }}
                />
                <div className="send me-3 ">
                  <label htmlFor="file">
                    <IoMdAttach className="attachfile fs-2" />
                  </label>
                </div>
                <button
                  className="btn_common p-2 rounded-circle me-3 my-2"
                  disabled={true}
                  onKeyDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  <MdOutlineArrowUpward className="fs-5" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        isOpen={SubModal}
        onAfterOpen={afterOpenSub}
        onRequestClose={closeModalSub}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="text-center form-width">
          <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
            <div className="container-xxl">
              <div className="row suv mx-0 ">
                {startPayment === false ? (
                  <div className="px-0">
                    <div className="col  subcription-bg ">
                      <div className="mb-4">
                        <img
                          src={Circle}
                          alt=""
                          className="img_clr_sub "
                          width={250}
                        />
                        <p className="sub-content pt-2 fw-normal w-75 mx-auto">
                          See Who You're Matched With And Connect!
                        </p>
                        <div className="Sub-content2">
                          <span className="sub2Content">
                            <GiStarShuriken className="star" />
                            Unlimited Messaging
                            <GiStarShuriken className="star" />
                          </span>{" "}
                          <br />
                          <span className="sub2Content">
                            <GiStarShuriken className="star" />
                            Plus All The Standard Features
                            <GiStarShuriken className="star" />
                          </span>
                          <br />
                          <span className="sub2Content">
                            <GiStarShuriken className="star" />
                            Unlimited Birthday Reports
                            <GiStarShuriken className="star" />
                          </span>
                          <br />
                          <span className="sub2Content">
                            <GiStarShuriken className="star" />
                            Unlimited Relationship Reports
                            <GiStarShuriken className="star" />
                          </span>
                          <br />
                          <span className="sub2Content">
                            <GiStarShuriken className="star" />
                            Unlimited Matchmaking Reports
                            <GiStarShuriken className="star" />
                          </span>
                          <br />
                        </div>
                      </div>

                      <div className="d-grid justify-content-center my-2">
                        <button
                          className="sub-btn-1 mb-3"
                          onClick={() => {
                            setStartPayment(true);
                          }}
                          style={{
                            backgroundColor: "#8270FF",
                            borderColor: "#8270FF",
                          }}
                        >
                          Subscribe
                        </button>
                        <button
                          className="sub-btn-2 border-0 border-none"
                          onClick={() => {
                            closeModalSub();
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className="px-2 ">{/* <Footer /> */}</div>
                  </div>
                ) : (
                  <div className="col  subcription-bg ">
                    <h4 className="d-flex justify-content-center align-items-center my-3 text-center">
                      Pay with card
                    </h4>
                    <form onSubmit={handleSubmit}>
                      <div className="row text-start">
                        <div
                          className="col-12 mb-3"
                          style={{ minHeight: "90px" }}
                        >
                          <label htmlFor="cc-name">Name on card</label>
                          <input
                            id="cc-name"
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          {!isValidName ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              Invalid Name
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className="col-12 mb-3"
                          style={{ minHeight: "90px" }}
                        >
                          <label htmlFor="cc-email">Email</label>
                          <input
                            id="cc-email"
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {!isValidEmail ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              Invalid Email Address
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="row text-start">
                        <div
                          className="col-md-12 mb-3"
                          style={{ minHeight: "90px" }}
                        >
                          <label htmlFor="cc-number">Card Number</label>
                          <CardNumberElement
                            id="cc-number"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                          />
                          {!isValidCard ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              Invalid Card Number
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div
                        className="row text-start"
                        style={{ minHeight: "105px" }}
                      >
                        <div
                          className="col-6 mb-3"
                          style={{ minHeight: "90px" }}
                        >
                          <label htmlFor="expiry">Expiration Date</label>
                          <CardExpiryElement
                            id="expiry"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                          />
                          {!isValidExpiry ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              Invalid Expiry Date
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className="col-6 mb-3 "
                          style={{ minHeight: "90px" }}
                        >
                          <label htmlFor="cvc">CVC</label>
                          <CardCvcElement
                            id="cvc"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                          />
                          {!isValidCvc ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {" "}
                              Invalid CVC
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <hr className="mb-4" />

                      <p style={{ fontSize: "12px" }}>
                        Note - We do not store your CVV/CVC number
                      </p>
                      {Paymentloading === false ? (
                        <button
                          type="submit"
                          className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                          style={{ height: "45px" }}
                        >
                          Pay
                        </button>
                      ) : (
                        <button
                          className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                          style={{ height: "45px" }}
                        >
                          <ButtonLoader />
                        </button>
                      )}
                      {errorMsg && (
                        <div className="text-danger mt-2">{errorMsg}</div>
                      )}
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={SuccessSubscribe}
        onAfterOpen={afterOpenModalSucssessSub}
        onRequestClose={closeModalSucssessSub}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="text-center form-width">
          <div
            className="btn-hover text-end"
            onClick={() => closeModalSucssessSub()}
          >
            <RxCross1 className=" btn-hover" />
          </div>
          <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
            <div className="container-xxl">
              <div className="row suv mx-0 ">
                <div className="col  subcription-bg ">
                  <div className="mb-5">
                    <div className="fs-4 text-center pt-3 pb-3 section-heading">
                      idealfor Subscription Confirmed!
                    </div>
                    {/* <p className="mb-0   fs-4">
                          You successfully started a{" "}
                          <span className="active">
                            IdealFor Subscription - 1 Month
                          </span>
                        </p> */}

                    <p>
                      Your 1-Month idealfor Subscription is now active. Please
                      note that your account will be automatically billed every
                      month once any existing subscription period concludes. If
                      you ever wish to cancel your idealfor subscription, you
                      have the flexibility to do so at any time through your
                      profile settings.
                    </p>
                  </div>
                </div>
                <div className="px-2 ">{/* <Footer /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RequestChatUI;
