import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./matches.scss";
import { Chip } from "@react-md/chip";
import Footer2 from "../../layout/Footer/Footer2";
import { getApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import { useNavigate } from "react-router-dom";
import Loader from "../../layout/Loader/Loader";
import ReactSlider from "react-slider";
import { MdArrowBackIosNew } from "react-icons/md";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import { RxActivityLog } from "react-icons/rx";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};

const distancesRef = [
  {
    label: "1-20",
    value: "20",
  },
  {
    label: "20-50",
    value: "50",
  },
  {
    label: "50-100",
    value: "100",
  },
  {
    label: "100-200",
    value: "200",
  },
  {
    label: "200-300",
    value: "300",
  },
];
const Datafilter = () => {
  const connections = localStorage.getItem("connection");
  const oppGen = localStorage.getItem("opp-Gender");
  const AgeMin = localStorage.getItem("min-age");
  const AgeMax = localStorage.getItem("max-age");
  const Distance = localStorage.getItem("distance-range");
  const { register, handleSubmit } = useForm();
  const [chip, setChip] = useState([]);
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [Oppsel, setOppSel] = useState();

  const FilterData = async () => {
    // setLoading(true);
    try {
      const res = await getApi(API_PATH.DATA_FILTER.CHIP_FILTER);
      if (res.status) {
        setChip(res.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    FilterData();
  }, []);

  const [selectedAmenities, setSelected] = useState(
    JSON.parse(localStorage.getItem("Chips"))
      ? JSON.parse(localStorage.getItem("Chips"))
      : []
  );

  const Unselect = () => {
    setSelected([]);
    closeModalWarning();
  };
  const [setChipData] = useState([]);
  const [rangervalues, setRangervalues] = useState([]);
  const [rangerChanges, setRangerChnage] = useState(false);
  const [singlerangervalues, setSinglerangervalues] = useState(
    parseInt(Distance) ? parseInt(Distance) : 15000
  );
  const [distanceChanges, setDistanceChnage] = useState(false);
  const minAge = rangervalues.slice(0, 1);
  const maxAge = rangervalues.slice(1);
  // const minAge = 18;
  // const maxAge = 99;

  const onSubmit = async (data) => {
    setLoading(true);
    if (rangerChanges === true) {
      localStorage.setItem("min-age", minAge);
      localStorage.setItem("max-age", maxAge);
    }
    if (distanceChanges === true) {
      localStorage.setItem("distance-range", Oppsel ? Oppsel : singlerangervalues);
    }
    localStorage.setItem("Chips", JSON.stringify(selectedAmenities));
    // localStorage.setItem(
    //   "Chips",
    //   JSON.stringify(selectedAmenities)
    // );
    localStorage.setItem("connection", data.connections);
    localStorage.setItem("opp-Gender", data.oppgender);
    setLoading(false);
    navigate("/matches");
  };

  const [modalIsOpenwarning, setIsOpenWarning] = React.useState(false);

  function openModalWarning() {
    if (selectedAmenities.length > 0) {
      setIsOpenWarning(true);
    }
    if (selectedAmenities.length === 0) {
      toast.error("There is nothing to clear");
    }
  }

  function closeModalWarning() {
    setIsOpenWarning(false);
  }
  return (
    <>
      {Loading ? <Loader /> : null}
      <ToastContainer />
      <div className="container-fluid   mx-auto px-0 height-adj ">
        <div className="row mx-auto fix_width">
          <div className="  mx-auto ">
            <div className="row mx-0">
              <div className="  py-2 px-0  d-flex justify-content-between align-items-end">
                <div
                  className="arrow_back  btn-hover"
                  onClick={() => navigate(-1)}
                >
                  <MdArrowBackIosNew />{" "}
                  <span className="align-middle"> Go Back</span>
                </div>
                <div
                  className="text-end btn-hover mb-0"
                  onClick={(e) => {
                    // openModalWarning();
                    localStorage.setItem("min-age", 18);
                    localStorage.setItem("max-age", 99);
                    localStorage.setItem("distance-range", 15000);
                    setRangervalues([18, 99]);
                    setSinglerangervalues(15000);
                    // navigate("/matches");
                  }}
                >
                  Reset
                </div>
              </div>
            </div>

            <div className="container-xxl">
              <div className="row dataFilter mx-2">
                <div className="col  mx-0 px-0">
                  <form className="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="datafilterBg px-2 d-none">
                      <div className="col-xs-12 dataFilterMedia">
                        <label className="option pt-1 PointAdd">
                          <input
                            type="radio"
                            name="optradio"
                            value="1"
                            defaultChecked={oppGen === "1"}
                            {...register("oppgender")}
                          />
                          <span className="btn-option rounded w-100">Male</span>
                        </label>
                        <label className="option pt-1 PointAdd">
                          <input
                            type="radio"
                            name="optradio"
                            value="2"
                            defaultChecked={oppGen === "2"}
                            {...register("oppgender")}
                          />
                          <span className="btn-option rounded w-100 text-center">
                            Female
                          </span>
                        </label>
                        <label className="option pt-1 PointAdd">
                          <input
                            type="radio"
                            name="optradio"
                            value="3"
                            defaultChecked={oppGen === "3"}
                            {...register("oppgender")}
                          />
                          <span className="btn-option rounded w-100">
                            Everyone
                          </span>
                        </label>
                      </div>
                      <div className="forBottom">
                        <div className="col dataFilterMedia pt-5">
                          <label className="text-light dataFilter2">
                            <input
                              type="radio"
                              name="category1"
                              value="0"
                              defaultChecked={connections === "0"}
                              {...register("connections")}
                            />
                            <span className=" w-100 dataFilterButton fw-semibold PointAdd ">
                              All
                            </span>
                          </label>
                          <label className="text-light dataFilter2">
                            <input
                              type="radio"
                              name="category1"
                              value="1"
                              defaultChecked={connections === "1"}
                              {...register("connections")}
                            />
                            <span className=" w-100 dataFilterButton fw-semibold PointAdd">
                              Romance
                            </span>
                          </label>
                          <label className="text-light dataFilter2">
                            <input
                              type="radio"
                              name="category1"
                              value="2"
                              defaultChecked={connections === "2"}
                              {...register("connections")}
                            />
                            <span className=" w-100 dataFilterButton fw-semibold PointAdd">
                              Friendship
                            </span>
                          </label>
                          <label className="text-light dataFilter2">
                            <input
                              type="radio"
                              name="category1"
                              value="3"
                              defaultChecked={connections === "3"}
                              {...register("connections")}
                            />
                            <span className=" w-100 dataFilterButton fw-semibold PointAdd">
                              Business
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="col">
                        <p className=" pt-3">
                          Age Range:(
                          {rangervalues[0]
                            ? rangervalues[0]
                            : parseInt(AgeMin) || "18"}
                          -
                          {rangervalues[1]
                            ? rangervalues[1]
                            : parseInt(AgeMax) || "99"}
                          )
                        </p>
                      </div>
                      <div className="text-light">
                        <ReactSlider
                          className="horizontal-slider"
                          thumbClassName="example-thumb"
                          trackClassName="example-track"
                          min={18}
                          max={99}
                          value={rangervalues}
                          defaultValue={[
                            parseInt(AgeMin) ? parseInt(AgeMin) : 18,
                            parseInt(AgeMax) ? parseInt(AgeMax) : 99,
                          ]}
                          ariaLabel={["Lower thumb", "Upper thumb"]}
                          ariaValuetext={(state) =>
                            `Thumb value ${state.valueNow}`
                          }
                          renderThumb={(props, state) => (
                            <div {...props}>{state.valueNow}</div>
                          )}
                          pearling
                          minDistance={0}
                          onChange={(renderThumb) => {
                            setRangervalues(renderThumb);
                            setRangerChnage(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="col">
                        <p className=" pt-3">
                          Distance Range (miles):{" "}
                          {singlerangervalues
                            ? singlerangervalues
                            : parseInt(Distance) || 15000}
                        </p>
                      </div>
                      <div className="text-light">
                        <ReactSlider
                          className="distance-slider"
                          thumbClassName="example-thumb2"
                          trackClassName="example-track2"
                          min={1}
                          max={15000}
                          value={singlerangervalues}
                          defaultValue={
                            parseInt(Distance) ? parseInt(Distance) : 15000
                          }
                          renderThumb={(props, state) => (
                            <div {...props}>{state.valueNow}</div>
                          )}
                          onChange={(renderThumb) => {
                            setSinglerangervalues(renderThumb);
                            setDistanceChnage(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="col">
                        <p className=" pt-3">The distance between</p>
                      </div> 
                      <div className="d-flex justify-content-between mt-3 flex-wrap">
                        {distancesRef.map((i, k) => (
                          <div className="" key={k + 1}>
                            <div
                              className="border-0 mb-2 ctn_btn-gender py-2 rounded-5 w-auto px-2"
                              // style={{ width: "auto" }}
                              onClick={(e) => {
                                setOppSel(i.value);
                                setDistanceChnage(true);
                                setSinglerangervalues(i.value);
                                // localStorage.setItem("distance-range", i.value);
                              }}
                            >
                              {i.label}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="chipButtonId d-none">
                      <div className="d-flex justify-content-between ">
                        <p className=" ">Searching For :</p>
                      </div>

                      <div className="containerChip grid-container">
                        {chip.map((amenity, i) => {
                          const selected = selectedAmenities.includes(
                            amenity.name
                          );
                          return (
                            <Chip
                              key={i + 1}
                              selected={selected}
                              className="chip grid-item rounded-5"
                              value={amenity.name}
                              selectedIcon={false}
                              onClick={() => {
                                setSelected((prevSelected) => {
                                  if (prevSelected.includes(amenity.name)) {
                                    return prevSelected.filter(
                                      (am) => am !== amenity.name
                                    );
                                  }
                                  return [...prevSelected, amenity.name];
                                });
                              }}
                              onChange={() => setChipData(selected)}
                              style={
                                selected
                                  ? {
                                      border: "solid 2px #8270FF",
                                      borderRadius: "25px",
                                      backgroundColor: "#8270FF",
                                      color: "white",
                                    }
                                  : { margin: "5px" }
                              }
                            >
                              {amenity.name}
                            </Chip>
                          );
                        })}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center pt-4 px-3">
                      <button
                        type="submit"
                        className="gender-btn-1  btn_common px-5 py-2 w-100  rounded-5"
                        style={{ height: "50px" }}
                      >
                        {selectedAmenities.length > 0
                          ? `(${selectedAmenities.length})`
                          : null}{" "}
                        Save
                      </button>
                    </div>
                  </form>
                  <Footer2 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        <div className="col-md-6 col-sm-12 col-lg-5 col-xl-4 col-xxl-4 col-12 mx-auto">
          <Navbar3 />
        </div>
      </div> */}
      <Modal
        isOpen={modalIsOpenwarning}
        onRequestClose={closeModalWarning}
        style={customStyles}
        contentLabel="Example Modal Connections"
        ariaHideApp={false}
      >
        <div className="text-start">
          <h5>Clear Filter</h5>
          <p>Do you want to clear all the selections here?</p>

          <div className="mt-3 text-end">
            <button
              className="btn_common px-3 py-2 me-2"
              onClick={() => Unselect()}
            >
              Clear
            </button>
            <button
              className="btn_common px-3 py-2"
              onClick={() => closeModalWarning()}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Datafilter;
