import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getApi, postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import Footer2 from "../../layout/Footer/Footer2";
import "./profile.css";
import { FiPlus } from "react-icons/fi";
import Loader from "../../layout/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import Navbar3 from "../../layout/BottomNavbar/Navbar3";
import { updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../Firebase";
import { Icon } from "@iconify/react";
import dp from "../../assets/Dp.png";
import plus from "../../assets/Vector.png";
import { FaPlus, FaRegImage, FaRegUser } from "react-icons/fa";
import { RiDeleteBin6Line, RiDeleteBin7Line } from "react-icons/ri";
import { HiUserCircle } from "react-icons/hi2";
import { RxCross1 } from "react-icons/rx";
import { ImCross } from "react-icons/im";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    borderRadius: "0px",
  },
};
const ChangeImg = () => {
  const navigate = useNavigate();
  const [img, setImg] = useState("");
  const [allImages, setAllimages] = useState([]);
  const [avtarId, setAvatarId] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [imgURL1, setImgURL1] = useState("");
  const [imgURL2, setImgURL2] = useState("");
  const [imgURL3, setImgURL3] = useState("");
  const [imgURL4, setImgURL4] = useState("");
  const [imgURL5, setImgURL5] = useState("");
  const [ReportShow, setReportShow] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const openBottomList = (id) => {
    setReportShow(true);
    setImgURL5(id);
  };
  const CloseBottomList = () => {
    setReportShow(false);
    setImgURL5("");
  };
  useEffect(() => {
    ImagesData();
  }, []);
  const update_img = localStorage.getItem("image_update");
  const photo_id = localStorage.getItem("photo_id");

  const ImagesData = async () => {
    
      setLoading(true);
    
    try {
      const res = await getApi(API_PATH.PROFILE.GET_ALL_IMAGES);
      if (res.status) {
        setImg("");
        setAvatarId(res.data.avatar.id);
        setData(res.data.avatar.image);
        setAllimages(res.data.images);
        setImgURL1(res.data.images[0]);
        setImgURL2(res.data.images[1]);
        setImgURL3(res.data.images[2]);
        setImgURL4(res.data.images[3]);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const uploadImage = async (e) => {
    setImg(URL.createObjectURL(e.target.files[0]));
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      const res = await postApi(API_PATH.PROFILE.CHANGE_PROFILE, formData);
      if (res.status) {
        toast.success("Image Uploaded");
        setImg("");
        ImagesData();
        CloseBottomList();
      }
    } catch (error) {
      toast(error.response.data.message);
      setLoading(false);
    }
  };

  const setAvatar = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.PROFILE.SET_AVATAR, {
        id: id,
      });
      if (res.status) {
        setLoading(false);
        ImagesData();
        CloseBottomList();
        localStorage.removeItem("photo_id");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const setDeleteimage = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.PROFILE.DELETE_PROFILE_IMAGE, {
        id: id,
      });
      if (res.status) {
        ImagesData();
        setImg("");
        CloseBottomList();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const SettingData = async () => {
    try {
      const res = await getApi(API_PATH.PROFILE.USER_DATA);
      if (res.status) {
        localStorage.setItem("Image", res.data.image);
        localStorage.setItem("Name", res.data.name);
        if (res && auth?.currentUser) {
          await updateProfile(`${res.data.id}`, {
            displayName: `${res.data.name}`,
            photoURL: `${res.data.image}`,
            UserDob: `${res.data.birthday_report.date_name}`,
            UserID: `${res.data.id}`,
          });
          //create user on firestore
          await setDoc(doc(db, "users", `${res.data.id}`), {
            uid: auth.currentUser.uid,
            displayName: `${res.data.name}`,
            photoURL: `${res.data.image}`,
            UserDob: `${res.data.birthday_report.date_name}`,
            UserID: `${res.data.id}`,
          });

          //create empty user chats on firestore
          await setDoc(doc(db, "userChats", `${res.data.id}`), {});
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    SettingData();
  }, []);

  const [media_url, setMedia_url] = useState("");
  const [type, settype] = useState("");
  const [base64, setBase64] = useState("");
  const [base64Image, setBase64Image] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
    CloseBottomList();
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const openMedia = async (url, type) => {
    setMedia_url(url);
    settype(type);
    openModal();
  };
  const share = async (files) => {
    const response = await fetch(files);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File(
      [blob],
      type === "image" ? "share.jpg" : "share.mp4",
      { type: blob.type }
    );
    if (!navigator.canShare) {
      // console.log(`Your browser doesn't support the Web Share API.`);
      return;
    }

    if (navigator.canShare({ files: [file] })) {
      try {
        await navigator.share({
          files: [file],
        });
      } catch (error) {
        console.log(`Error: ${error.message}`);
      }
    } else {
      // alert(`Your system doesn't support sharing these files.`);
    }
  };
  return (
    <>
      {Loading ? <Loader /> : null}
      <div className="headerBAr">
        {/* <Header /> */}
        <ToastContainer />
      </div>
      <div className="container-fluid profileScreen p-0 m-0">
        <div className="fix_width mx-auto  px-2">
          <div
            className="fs-6  py-3 profileHeading navMatchbottom1"
            onClick={() => navigate(-1)}
          >
            <div className="arrow_back  btn-hover mps">
              <MdArrowBackIosNew />{" "}
              <span className="align-middle"> Go Back</span>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col ">
              <div className=" text-center pt-4 mx-auto">
                <img
                  src={data ? data : dp}
                  alt=""
                  className="getImg rounded-5 object-fit-cover chng-img"
                  // style={{ width: "90%" }}
                />
              </div>

              <div
                className="d-flex mx-auto justify-content-between  my-3 manageGap chng-img"
                style={{ flexWrap: "wrap" }}
              >
                <div className="  contain-img m-0 my-3">
                  <label
                    htmlFor="uploadImagechange"
                    className="uploadImage_change  addProfile text-center"
                  >
                    {!imgURL1 || imgURL1 === null ? (
                      <div className=" mx-auto my-auto">
                        <FaPlus className="Add_img_icon fs-1 position-absolute top-50  translate-middle" />
                      </div>
                    ) : (
                      <div className="">
                        <img
                          src={imgURL1.image}
                          className="rounded-4 text-center addProfile object-fit-cover"
                          alt=""
                          onClick={() => openBottomList(imgURL1)}
                        />
                        <div className="d-flex  pos_ition">
                          <div className="dropdown">
                            <div onClick={() => openBottomList(imgURL1)}>
                              <Icon
                                icon="solar:menu-dots-bold"
                                className="mt-2 fs-3 ms-auto active"
                              />
                            </div>
                            {/* <ul className="dropdown-menu dropdown-menu-dark">
                            <li>
                              <div
                                className=" button btn-upload ms-3 btn-hover"
                                onClick={() => {
                                  setAvatar(imgURL1.id);
                                }}
                                title="Set As Avatar"
                              >
                                <p className="mb-0">
                                  <span className="fs-6">
                                    Make Profile Image
                                  </span>
                                </p>
                              </div>
                            </li>
                            <li>
                              <div
                                className="button2 btn-upload2  ms-3 btn-hover"
                                onClick={() => setDeleteimage(imgURL1.id)}
                                title="Delete Image"
                              >
                                <p className="mb-0">
                                  {" "}
                                  <span className="fs-6">Remove Image</span>
                                </p>
                              </div>
                            </li>
                          </ul> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </label>
                  {!imgURL1 || imgURL1 === null ? (
                    <input
                      id="uploadImagechange"
                      name="uploadImage1"
                      type="file"
                      className="uploadImg01 d-none"
                      accept="image/png, image/jpeg"
                      onChange={uploadImage}
                    />
                  ) : null}
                </div>
                <div className=" contain-img m-0 my-3">
                  <label
                    htmlFor="uploadImagechange2"
                    className="uploadImage_change  addProfile text-center"
                  >
                    {!imgURL2 || imgURL2 === null ? (
                      <div className=" mx-auto my-auto">
                        <FaPlus className="Add_img_icon fs-1 position-absolute top-50  translate-middle" />
                      </div>
                    ) : (
                      <div className="">
                        <img
                          src={imgURL2.image}
                          className="rounded-4 text-center addProfile object-fit-cover"
                          alt=""
                          onClick={() => openBottomList(imgURL2)}
                        />
                        <div className="d-flex  pos_ition">
                          <div className="dropdown">
                            <div onClick={() => openBottomList(imgURL2)}>
                              <Icon
                                icon="solar:menu-dots-bold"
                                className="mt-2 fs-3 ms-auto active"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </label>
                  {!imgURL2 || imgURL2 === null ? (
                    <input
                      id="uploadImagechange2"
                      name="uploadImage1"
                      type="file"
                      className="uploadImg01 d-none"
                      accept="image/png, image/jpeg"
                      onChange={uploadImage}
                    />
                  ) : null}
                </div>
                <div className="  contain-img m-0">
                  <label
                    htmlFor="uploadImagechange3"
                    className="uploadImage_change    addProfile text-center"
                  >
                    {!imgURL3 || imgURL3 === null ? (
                      <div className=" mx-auto my-auto">
                        <FaPlus className="Add_img_icon fs-1 position-absolute top-50  translate-middle" />
                      </div>
                    ) : (
                      <div className="">
                        <img
                          src={imgURL3.image}
                          className="rounded-4 text-center addProfile object-fit-cover"
                          alt=""
                          onClick={() => openBottomList(imgURL3)}
                        />
                        <div className="d-flex  pos_ition">
                          <div className="dropdown">
                            <div onClick={() => openBottomList(imgURL3)}>
                              <Icon
                                icon="solar:menu-dots-bold"
                                className="mt-2 fs-3 ms-auto active"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </label>
                  {!imgURL3 || imgURL3 === null ? (
                    <input
                      id="uploadImagechange3"
                      name="uploadImage1"
                      type="file"
                      className="uploadImg01 d-none"
                      accept="image/png, image/jpeg"
                      onChange={uploadImage}
                    />
                  ) : null}
                </div>
                <div className="  contain-img m-0">
                  <label
                    htmlFor="uploadImagechange4"
                    className="uploadImage_change  addProfile text-center"
                  >
                    {!imgURL4 || imgURL4 === null ? (
                      <div className=" mx-auto my-auto">
                        <FaPlus className="Add_img_icon fs-1 position-absolute top-50  translate-middle" />
                      </div>
                    ) : (
                      <div className="">
                        <img
                          src={imgURL4.image}
                          className="rounded-4 text-center addProfile object-fit-cover"
                          alt=""
                          onClick={() => openBottomList(imgURL4)}
                        />
                        <div className="d-flex  pos_ition">
                          <div className="dropdown">
                            <div
                              type="button"
                              onClick={() => openBottomList(imgURL4)}
                            >
                              <Icon
                                icon="solar:menu-dots-bold"
                                className="mt-2 fs-3 ms-auto active"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </label>
                  {!imgURL4 || imgURL4 === null ? (
                    <input
                      id="uploadImagechange4"
                      name="uploadImage1"
                      type="file"
                      className="uploadImg01 d-none"
                      accept="image/png, image/jpeg"
                      // onChange={(e) => {
                      //   setImg(e.target.files);
                      //   setImgURL4(URL.createObjectURL(e.target.files[0]));
                      // }}
                      onChange={uploadImage}
                    />
                  ) : null}
                </div>
              </div>

              <div
                className={
                  ReportShow === false
                    ? "card p-4 card-report-pwa_image   bg-body-secondary border-0  mx-auto  "
                    : "card p-4 card-report-pwa_image   bg-body-secondary border-0  mx-auto  "
                }
                style={
                  ReportShow === false
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <div className="d-flex justify-content-end">
                  <RxCross1
                    className="text-end btn-hover  "
                    onClick={() => setReportShow(false)}
                  />
                </div>

                <div className="" style={{ height: "auto" }}>
                  <ul className="list-group list-group-flush changeList">
                    <li
                      className="list-group-item btn-hover d-flex align-items-center  list-group-item-dark"
                      onClick={() => openMedia(imgURL5.image, "image")}
                    >
                      <FaRegImage className="me-2 fs-5" /> View Photo
                    </li>
                    <li
                      className="list-group-item btn-hover  d-flex align-items-center list-group-item-dark"
                      onClick={() => {
                        setAvatar(imgURL5.id);
                      }}
                    >
                      <HiUserCircle className="me-2 fs-5" /> Make Profile Photo
                    </li>
                    <li
                      className="list-group-item btn-hover  d-flex align-items-center list-group-item-dark"
                      onClick={() => setDeleteimage(imgURL5.id)}
                    >
                      <ImCross className="fs-6  me-2" /> Remove Photo
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" " style={{ height: "150px" }}>
          <Footer2 />
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        <div className="fix_width mx-auto">
          <Navbar3 />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="d-flex justify-content-between mb-3">
          <RxCross1
            className=" modal-icon btn-hover ms-2 fs-4"
            onClick={() => closeModal()}
          />

          <Icon
            icon="mdi:share-outline"
            id="share"
            className="fs-3 btn-hover"
            onClick={() => share(media_url)}
          />
        </div>
        <div className=" mx-auto text-center">
          <div
            className=""
            style={{
              background: `url(${media_url}) 50% 50% no-repeat`,
              backgroundSize: "contain",
              width: "100%",
              height: "430px",
            }}
          ></div>
        </div>
      </Modal>
    </>
  );
};

export default ChangeImg;
