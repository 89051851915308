import React, { useMemo, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { deletetApi, getApi, postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import Footer2 from "../../layout/Footer/Footer2";
import "./userprofile.css";
import { BsThreeDots } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../layout/Loader/Loader";
import Carousel from "react-bootstrap/Carousel";
import { MdArrowBackIosNew } from "react-icons/md";
import Navbar3 from "../../layout/BottomNavbar/Navbar3";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "@iconify/react";
import Check from "../MatchesPage/Check";
import dp from "../../assets/Dp.png";
import { RWebShare } from "react-web-share";
import { Timestamp, arrayUnion, doc, setDoc } from "firebase/firestore";
import { db } from "../../Firebase";
import { v4 as uuid } from "uuid";
import ButtonLoader from "../../layout/Loader/ButtonLoader";
import { RxCross1 } from "react-icons/rx";
import { GiStarShuriken } from "react-icons/gi";
import circle from "../../assets/lightPrice.png";
import Footer from "../../layout/Footer/Footer";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

const customStyles = {
  content: {
    top: "10%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};

// stripe
const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "12px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const Userprofile = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const report = location.state;
  const pproId = queryParams.get("pendingprofileId");
  const pproid = parseInt(pproId);
  const fproId = queryParams.get("friendprofileId");
  const fproid = parseInt(fproId);
  const rproId = queryParams.get("requestprofileId");
  const rproid = parseInt(rproId);
  const navigate = useNavigate();
  const [acceptrejet, setAcceptrejet] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [birthday, setBirthday] = useState("");
  const [LoadingChat, setLoadingChat] = useState(false);
  const [SubModal, setSubModal] = useState(false);
  const [request_id, setrequest_id] = useState();
  const imgReg = useRef(null);
  useEffect(() => {
    if (pproid) {
      fetchDataId(pproid);
    }
    if (fproid) {
      fetchDataId(fproid);
    }
    if (rproid) {
      fetchDataId(rproid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.scroll({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    imgReg.current?.scrollIntoView({
      behavior: "instant",
    });
  }, []);
  async function fetchDataId(id) {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.USER_PROFILE_DATA.DATA_PROFILE, {
        id: id,
      });
      if (res.status) {
        setData(res.data);
        setBirthday(res.data.user_birthday);
        setData1(res.data.images);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  const connectbutton = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.COMMUNITY.SENDREQUEST, {
        id: id,
      });
      if (res.data.status === "success") {
        fetchDataId(pproid);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const withdrawbutton = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.COMMUNITY.WITHDRAW, {
        id: id,
      });
      if (res.data.status === "success") {
        fetchDataId(pproid);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "450px",
    },
  };

  const [modalIsOpenName, setIsOpenName] = React.useState(false);
  const [userinfo, setUserinfo] = React.useState("");
  function openModalName(data) {
    setIsOpenName(true);
  }

  function closeModalName() {
    setIsOpenName(false);
    setSubModal(false);
    setStartPayment(false);
    setPaymentLoading(false);
  }
  const handleReport = (report) => {
    const removeLastLine = report.split("*").shift();
    localStorage.setItem("acceptreport", removeLastLine);
  };
  const getStat = async (id) => {
    setLoadingChat(true);
    try {
      const res = await getApi(API_PATH.SUBSCRIPTION.CHECK_StATUS);
      if (res.data.isVerified === 1) {
        acceptbutton(id);
        setSubModal(false);
        openModalName();
      } else {
        openModalName();
        setSubModal(true);
      }
    } catch (err) {}
  };
  const acceptbutton = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.USER_PROFILE_DATA.ACCEPT_REQUEST, {
        id: id,
      });
      if (res.data.status === "success") {
        setAcceptrejet(true);
        handleSend(id);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSend = async (otherid) => {
    setLoadingChat(true);
    // console.log(imageUrl, videoUrl, "vid");
    const myId = localStorage.getItem("id");
    const myName = localStorage.getItem("Name");
    const myProfilePic = localStorage.getItem("Image");
    const Report = localStorage.getItem("acceptreport");
    const cmb1 = myId + otherid;
    const cmb2 = otherid + myId;
    const unique_id = uuid();
    const msg = {};
    msg._id = unique_id;
    msg.createdAt = new Date();
    msg.text = Report;
    msg.user = { _id: myId, name: myName + ",m", avatar: myProfilePic };
    let myMsg;
    myMsg = {
      ...msg,
      createdAt: Date.parse(msg.createdAt),
      image: "",
      video: "",
      thumbnail: "",
    };
    try {
      await setDoc(doc(db, "chats", cmb1), {
        messages: arrayUnion({
          ...myMsg,
          senderId: otherid,
          receiverId: myId,
          createdAt: Date.parse(msg.createdAt),
          date: Timestamp.now(),
        }),
      });
      // console.log("jj");
      await setDoc(doc(db, "chats", cmb2), {
        messages: arrayUnion({
          ...myMsg,
          senderId: otherid,
          receiverId: myId,
          createdAt: Date.parse(msg.createdAt),
          date: Timestamp.now(),
        }),
      });

      // img.type.slice(0, 5) !== "video" condition for sending image and video in api

      await postApi(`${API_PATH.CHAT.MESSAGE}/${otherid}`, {
        type: "text",
        content: "Report",
      });
      localStorage.removeItem("acceptreport");
      setLoadingChat(false);
    } catch (error) {
      console.log(error);
      setLoadingChat(false);
    }
  };
  const rejectbutton = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.USER_PROFILE_DATA.REJECT_REQUEST, {
        id: id,
      });
      if (res.data.status === "success") {
        setAcceptrejet(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const deletefriend = async (id) => {
    setLoading(true);
    try {
      const res = await deletetApi(API_PATH.DELETE.DELETE_FRIEND, {
        id: id,
      });
      if (res.data.status === "success") {
        toast(res.data.message);
        setLoading(false);
        // setTimeout(() => {
        //   navigate("/friends");
        // }, 1000);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const blockfriend = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.BLOCK.BLOCK_USER, {
        id: id,
        reason: "",
      });
      if (res.data.status === "success") {
        toast(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const reportfriend = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.REPORT.REPOR_USER, {
        id: id,
        reason: "",
      });
      if (res.data.status === "success") {
        toast(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const flagfriend = async (id) => {
    setLoading(true);
    try {
      const res = await postApi(API_PATH.FLAG.FLAG_API, {
        id: id,
        reason: "",
      });
      if (res.data.status === "success") {
        toast(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const chatMove = (data) => {
    localStorage.setItem("userprofilenaviagte", true);
    navigate({
      pathname: "/chatHome",
      search: `?Uid=${data}`,
    });
  };
  const pay = () => {
    window.open("https://buy.stripe.com/test_6oE8z78aCcCL676cMN", "_blank");
  };

  // Subscription Flow
  const [Paymentloading, setPaymentLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [SuccessSubscribe, setSuccessSubscribet] = useState(false);
  const [startPayment, setStartPayment] = useState(false);

  const [isValidName, setisValidName] = useState(true);
  const [isValidEmail, setisValidEmail] = useState(true);
  const [isValidCard, setisValidCard] = useState(true);
  const [isValidExpiry, setisValidExpiry] = useState(true);
  const [isValidCvc, setisValidCvc] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();

    var nameField = document.getElementById("cc-name");
    var nameValue = nameField.value.trim();
    if (name === "") {
      setisValidName(false);
      nameField.focus();
    } else {
      setisValidName(true);
    }
    var emailField = document.getElementById("cc-email");
    var emailValue = emailField.value.trim();
    if (email === "") {
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }

    const cardNumber = elements.getElement("cardNumber");
    const cardExpiry = elements.getElement("cardExpiry");
    const cardCvc = elements.getElement("cardCvc");
    const isInvalidCard = cardNumber._invalid;
    const isEmptyCard = cardNumber._empty;

    const isInvalidExpiry = cardExpiry._invalid;
    const isEmptyExpiry = cardExpiry._empty;

    const isInvalidCvc = cardCvc._invalid;
    const isEmptyCvc = cardCvc._empty;

    const isValidCard = !(isEmptyCard || isInvalidCard);
    const isValidExpiry = !(isInvalidExpiry || isEmptyExpiry);
    const isValidCvc = !(isInvalidCvc || isEmptyCvc);
    setisValidCard(isValidCard);
    setisValidExpiry(isValidExpiry);
    setisValidCvc(isValidCvc);
    if (!stripe || !elements || !isValidCard || !isValidExpiry || !isValidCvc) {
      setPaymentLoading(false);
      return;
    }

    try {
      setPaymentLoading(true);
      if (!stripe || !elements) {
        setPaymentLoading(false);
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });
      payload.email = email;
      payload.name = name;
      //   payload.product_id = "prod_PWUrmEVkHkegOH"
      payload.paymentId = payload.paymentMethod.id;
      const mydata = await stripeApiHandler(payload);
      const confirm = await stripe.confirmCardPayment(mydata.clientSecret);

      if (confirm.error) {
        return alert("Payment unsuccessful!");
      }
      confirm.paymentIntent.sub_id = mydata.sub_id;
      let objJsonStr = JSON.stringify(confirm.paymentIntent);
      var encoded = btoa(objJsonStr);
      await addReciept(encoded);
      // alert("Payment Successful! Subscription active.");
    } catch (error) {
      console.log(error);
      setPaymentLoading(false);
    }
  };

  async function stripeApiHandler(data) {
    try {
      const res = await postApi(
        API_PATH.SUBSCRIPTION.CREATE_SUBSCRIPTION,
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      setPaymentLoading(false);
      throw err;
      //   res.status(500).json({ message: "Internal server error" });
    }
  }

  async function addReciept(reciept) {
    try {
      const res = await postApi(API_PATH.SUBSCRIPTION.SUBSCRIPTION, {
        receipt: reciept,
      });
      if (res.status) {
        closeModalName();
        setSuccessSubscribet(true);
        setPaymentLoading(false);
        // acceptbutton(request_id)
        // setSubModal(false);
      }
    } catch (error) {
      setPaymentLoading(false);
    }
  }
  function afterOpenModalSucssessSub() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }
  function closeModalSucssessSub() {
    setSuccessSubscribet(false);
    getStat(request_id);
  }
  return (
    <>
      {" "}
      <ToastContainer />
      {Loading ? <Loader /> : null}
      <div className="container-fluid  Match-screen">
        <div className="container-xxl">
          <div className="row p-2 mx-auto fix_width">
            <div className="  mx-auto" ref={imgReg}>
              <div className="profileUpperpart navMatchbottom1  py-1 ">
                <div
                  className=" fs-6  py-3 profileHeading navMatchbottom1 "
                  onClick={() => navigate(-1)}
                >
                  <div className="arrow_back   btn-hover">
                    <MdArrowBackIosNew />{" "}
                    <span className="align-middle"> Go Back</span>
                  </div>
                </div>
                <div className="col fs-4 px-3 text-center py-3 profileHeading ">
                  {data.name ? data.name : "Profile"}
                </div>
                <div className="fs-5  py-3 profileHeading me-3">
                  <RWebShare
                    data={{
                      // text: `https://secretlanguage.network/v1/profile/share?id=${data.id}`,
                      url: `https://secretlanguage.network/v1/profile/share?id=${data.id}`,
                      title: "Share Profile",
                    }}
                  >
                    <Icon icon="mdi:share-outline" className="fs-3 btn-hover" />
                  </RWebShare>
                </div>

                <div
                  className=" Dots_user fs-5  py-3 profileHeading dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BsThreeDots className=" fs-4 " />
                </div>
                <ul className="dropdown-menu  backColor">
                  <li>
                    <a
                      href=""
                      className="dropdown-item  "
                      onClick={() => reportfriend(data.id)}
                    >
                      Report User
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      className="dropdown-item "
                      onClick={() => flagfriend(data.id)}
                    >
                      {" "}
                      Flag as Inappropriate
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      className="dropdown-item "
                      onClick={() => blockfriend(data.id)}
                    >
                      Block User
                    </a>
                  </li>
                </ul>
              </div>

              {fproId ? (
                <div className="col match-img">
                  <div className="rounded-3">
                    <Carousel
                      fade
                      interval={null}
                      autoPlay={false}
                      indicators={data1.length > 1 ? true : false}
                      controls={data1.length > 1 ? true : false}
                    >
                      {data1.map((el) => (
                        <Carousel.Item key={el.id}>
                          <div className="col d-flex justify-content-center">
                            <div
                              className="card_img"
                              style={{
                                background: `url(${el.image}) 50% 50% no-repeat`,
                                backgroundSize: "cover",
                                width: "100%",
                                height: "430px",
                              }}
                            ></div>
                            <div
                              className="matchInnerbox rounded-4 text-center d-flex justify-content-center"
                              id="section-1"
                            >
                              <div className="text-center">
                                <h5 className="m-0 text-light">
                                  {data.name}, {data.age}
                                </h5>
                                <p className="m-0 paraMatch fw-semibold text-light">
                                  {data.distance}
                                </p>
                                <p className="m-0 text-light">
                                  idealfor :&nbsp;
                                  <span className="text-light fw-bold text-capitalize">
                                    {data.ideal_for}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                  {data.name ? (
                    <div className="col">
                      <div className="col py-3 personalityBtns text-center">
                        <ul className="nav  px-auto switch_div w-100">
                          <li className="nav-item tab-all w-50">
                            <a
                              className="nav-link  show py-1 px-0 active"
                              href="#tab1"
                              data-bs-toggle="tab"
                            >
                              <button className="side01  fs-5 fw-bold">
                                Relationship
                              </button>
                            </a>
                          </li>
                          <li className="nav-item tab-all p-l-20 w-50">
                            <a
                              className="nav-link  py-1 px-4 "
                              href="#tab2"
                              data-bs-toggle="tab"
                            >
                              <button className="side01 fs-5 fw-bold">
                                Advice
                              </button>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane fontawesome-demo active"
                          id="tab1"
                        >
                          <div className="match-content3">
                            <p className="  line-para">{data.report}</p>
                          </div>
                        </div>
                        <div className="tab-pane" id="tab2">
                          <div className="container-fluid">
                            <div className="text-center">
                              <div className="">
                                <div className=" line-para text-center content-match-li">
                                  <p className="">{data.advice}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {pproId ? (
                <div className="col match-img">
                  <div className="rounded-3">
                    <Carousel
                      fade
                      interval={null}
                      autoPlay={false}
                      indicators={data1.length > 1 ? true : false}
                      controls={data1.length > 1 ? true : false}
                    >
                      {data1.map((el) => (
                        <Carousel.Item key={el.id}>
                          <div className="col d-flex justify-content-center">
                            <div
                              className="card_img "
                              style={{
                                background: `url(${el.image}) 50% 50% no-repeat`,
                                backgroundSize: "cover",
                                width: "100%",
                                height: "430px",
                              }}
                            ></div>
                            <div
                              className="matchInnerbox rounded-4 text-start d-flex justify-content-center"
                              id="section-1"
                            >
                              <div className="">
                                <h5 className="m-0 text-light">
                                  {data.name}, {data.age}
                                </h5>
                                <p className="m-0 paraMatch fw-semibold text-light">
                                  {data.distance}
                                </p>
                                <p className="m-0 text-light">
                                  idealfor : &nbsp;
                                  <span className="text-light fw-bold text-capitalize">
                                    {data.ideal_for}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                  {/* <div className="img-Name my-3">
                    {data.friendStatus === 1 ? (
                      <button
                        className="Add_btn p-2 pe-4"
                        onClick={() => connectbutton(data.id)}
                      >
                        <Icon
                          icon="zondicons:add-solid"
                          color="#7add84"
                          className="fs-2 me-2"
                        />
                        <span>Connect</span>
                      </button>
                    ) : (
                      <button
                        className="Add_btn p-2 pe-4 mx-3 btn-hover "
                        onClick={() => withdrawbutton(data.id)}
                      >
                        <Icon
                          icon="ph:x-circle-fill"
                          color="red"
                          className="fs-2 me-1"
                          rotate={2}
                        />
                        <span>Ignore</span>
                      </button>
                    )}
                  </div> */}
                  {data.name ? (
                    <div className="col">
                      <div className="col py-3 personalityBtns text-center">
                        <ul className="nav  px-auto switch_div w-100">
                          <li className="nav-item tab-all w-50">
                            <a
                              className="nav-link  show py-1 px-0 active"
                              href="#tab1"
                              data-bs-toggle="tab"
                            >
                              <button className="side01  fs-5 fw-bold">
                                Relationship
                              </button>
                            </a>
                          </li>
                          <li className="nav-item tab-all p-l-20 w-50">
                            <a
                              className="nav-link  py-1 px-4 "
                              href="#tab2"
                              data-bs-toggle="tab"
                            >
                              <button className="side01 fs-5 fw-bold">
                                Advice
                              </button>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane fontawesome-demo active"
                          id="tab1"
                        >
                          <div className="match-content3">
                            <p className="  line-para">{data.report}</p>
                          </div>
                        </div>
                        <div className="tab-pane" id="tab2">
                          <div className="container-fluid">
                            <div className="text-center">
                              <div className="">
                                <div className=" line-para text-center content-match-li">
                                  <p className="">{data.advice}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {rproId ? (
                <div className="col match-img">
                  <div className="rounded-3">
                    <Carousel
                      fade
                      interval={null}
                      autoPlay={false}
                      indicators={data1.length > 1 ? true : false}
                      controls={data1.length > 1 ? true : false}
                    >
                      {data1.map((el) => (
                        <Carousel.Item key={el.id}>
                          <div className="col d-flex justify-content-center">
                            <div
                              className="card_img"
                              style={{
                                background: `url(${el.image}) 50% 50% no-repeat`,
                                backgroundSize: "cover",
                                width: "100%",
                                height: "430px",
                              }}
                            ></div>
                            <div
                              className="matchInnerbox rounded-4 text-start d-flex justify-content-center"
                              id="section-1"
                            >
                              <div className="">
                                <h5 className="m-0 text-light">
                                  {data.name}, {data.age}
                                </h5>
                                <p className="m-0 paraMatch fw-semibold text-light">
                                  {data.distance}
                                </p>
                                <p className="m-0 text-light">
                                  idealfor : &nbsp;
                                  <span className="text-light fw-bold text-capitalize">
                                    {data.ideal_for}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                  <div className="img-Name my-3">
                    {acceptrejet === true ? null : (
                      <div className="d-flex justify-content-center align-items-center">
                        <button
                          className=" py-2  px-4 border-success border border-2 rounded-5 text-success bg-transparent"
                          onClick={() => {
                            handleReport(
                              report.data.result[0].message.content[0].message
                            );
                            getStat(data.id);
                            setUserinfo(data);
                            setrequest_id(data.id);
                          }}
                        >
                          <span>Accept</span>
                        </button>

                        <button
                          className=" py-2 px-4 mx-3 btn-hover border-danger border border-2 rounded-5 text-danger bg-transparent"
                          onClick={() => rejectbutton(data.id)}
                        >
                          <span>Reject</span>
                        </button>
                      </div>
                    )}
                  </div>
                  {data.name ? (
                    <div className="col">
                      <div className="col pb-3 personalityBtns text-center">
                        <ul className="nav  px-auto switch_div w-100">
                          <li className="nav-item tab-all w-50">
                            <a
                              className="nav-link  show py-1 px-2 active"
                              href="#tab1"
                              data-bs-toggle="tab"
                            >
                              <button className="side01  fs-5 fw-bold">
                                Relationship
                              </button>
                            </a>
                          </li>
                          <li className="nav-item tab-all p-l-20 w-50">
                            <a
                              className="nav-link  py-1 px-4 "
                              href="#tab2"
                              data-bs-toggle="tab"
                            >
                              <button className="side01 fs-5 fw-bold">
                                Advice
                              </button>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane fontawesome-demo active"
                          id="tab1"
                        >
                          <div className="match-content3">
                            <p className="  line-para">{data.report}</p>
                          </div>
                        </div>
                        <div className="tab-pane" id="tab2">
                          <div className="container-fluid">
                            <div className="text-center">
                              <div className="">
                                <div className=" line-para text-center content-match-li">
                                  <p className="">{data.advice}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="pb-5 mb-5">
                      <Footer2 />
                    </div> */}
                    </div>
                  ) : null}
                </div>
              ) : null}
              {data.name ? (
                <div className="pb-5 mb-5">
                  <Footer2 />
                </div>
              ) : null}
            </div>
          </div>{" "}
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        <div className="fix_width mx-auto">
          <Navbar3 />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpenName}
        onRequestClose={closeModalName}
        style={customStyles}
        contentLabel="Example Modal Connections"
        ariaHideApp={false}
      >
        {SubModal ? (
          <div className="text-center">
            <div
              className="btn-hover text-end"
              onClick={() => closeModalName()}
            >
              <RxCross1 className="btn-hover" />
            </div>
            <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
              <div className="container-xxl">
                <div className="row suv mx-0 ">
                  {startPayment === false ? (
                    <div className="px-0">
                      <div className="col  subcription-bg ">
                        <div className="mb-4">
                          <img
                            src={circle}
                            alt=""
                            className="img_clr_sub "
                            width={250}
                          />
                          <p className="sub-content pt-2 fw-normal  mx-auto">
                            See Who You're Matched With And Connect!
                          </p>
                          <div className="Sub-content2">
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Messaging
                              <GiStarShuriken className="star" />
                            </span>{" "}
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Plus All The Standard Features
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Birthday Reports
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Relationship Reports
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                            <span className="sub2Content">
                              <GiStarShuriken className="star" />
                              Unlimited Matchmaking Reports
                              <GiStarShuriken className="star" />
                            </span>
                            <br />
                          </div>
                        </div>

                        <div className="d-grid justify-content-center my-2">
                          <button
                            className="sub-btn-1 mb-3"
                            onClick={() => {
                              // Subscribe();
                              setStartPayment(true);
                            }}
                            style={{
                              backgroundColor: "#8270FF",
                              borderColor: "#8270FF",
                            }}
                          >
                            Subscribe
                          </button>
                          <button
                            className="sub-btn-2 border-0 border-none"
                            onClick={() => {
                              closeModalName();
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col  subcription-bg ">
                      <h4 className="d-flex justify-content-center align-items-center mb-3 text-center">
                        Pay with card
                      </h4>
                      <form onSubmit={handleSubmit}>
                        <div className="row text-start">
                          <div
                            className="col-12 mb-3 "
                            style={{ minHeight: "90px" }}
                          >
                            <label htmlFor="cc-name">Name on card</label>
                            <input
                              id="cc-name"
                              type="text"
                              className="form-control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                            {!isValidName ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                Invalid Name
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="col-12 mb-3"
                            style={{ minHeight: "90px" }}
                          >
                            <label htmlFor="cc-email">Email</label>
                            <input
                              id="cc-email"
                              type="email"
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {!isValidEmail ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                Invalid Email Address
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="row text-start">
                          <div
                            className="col-md-12 mb-3"
                            style={{ minHeight: "90px" }}
                          >
                            <label htmlFor="cc-number">Card Number</label>
                            <CardNumberElement
                              id="cc-number"
                              className="form-control"
                              options={CARD_ELEMENT_OPTIONS}
                            />
                            {!isValidCard ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                Invalid Card Number
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="row text-start">
                          <div
                            className="col-6 mb-3"
                            style={{ minHeight: "90px" }}
                          >
                            <label htmlFor="expiry">Expiration Date</label>
                            <CardExpiryElement
                              id="expiry"
                              className="form-control"
                              options={CARD_ELEMENT_OPTIONS}
                            />
                            {!isValidExpiry ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                Invalid Expiration Date
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="col-6 mb-3"
                            style={{ minHeight: "90px" }}
                          >
                            <label htmlFor="cvc">CVC</label>
                            <CardCvcElement
                              id="cvc"
                              className="form-control"
                              options={CARD_ELEMENT_OPTIONS}
                            />
                            {!isValidCvc ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                {" "}
                                Invalid CVC
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <hr className="mb-4" />
                        <p style={{ fontSize: "12px" }}>
                          Note - We do not store your CVV/CVC number
                        </p>
                        {Paymentloading === false ? (
                          <button
                            type="submit"
                            className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                            style={{ height: "45px" }}
                          >
                            Pay
                          </button>
                        ) : (
                          <button
                            className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                            style={{ height: "45px" }}
                          >
                            <ButtonLoader />
                          </button>
                        )}
                        {errorMsg && (
                          <div className="text-danger mt-2">{errorMsg}</div>
                        )}
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <img
              src={userinfo.image !== "undefined" || null ? userinfo.image : dp}
              alt=""
              className="rounded-3 object-fit-cover"
              width={100}
              height={130}
            />
            <div className="emoji">
              <Icon
                icon="noto:party-popper"
                color="white"
                className="fs-3 party_emoji"
              />
            </div>

            <p className="subHeadOne fs-4">Keep it up!</p>
            <p className="subHeadOne">
              Now you are friends with {userinfo.name}!
            </p>

            {LoadingChat ? (
              <button
                className="btn_common px-5 w-100 border-none rounded-5"
                onClick={() => chatMove(userinfo.id)}
                style={{
                  height: "50px",
                }}
              >
                <ButtonLoader />
              </button>
            ) : (
              <button
                className="btn_common px-5 w-100  border-none rounded-5"
                onClick={() => chatMove(userinfo.id)}
                style={{
                  height: "50px",
                }}
              >
                Chat with {userinfo.name}
              </button>
            )}
            <p
              className="fs-6 mt-3  btn-hover"
              onClick={() => closeModalName()}
            >
              Skip
            </p>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={SuccessSubscribe}
        onAfterOpen={afterOpenModalSucssessSub}
        onRequestClose={closeModalSucssessSub}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="text-center form-width">
          <div
            className="btn-hover text-end"
            onClick={() => closeModalSucssessSub()}
          >
            <RxCross1 className="btn-hover" />
          </div>
          <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
            <div className="container-xxl">
              <div className="row suv mx-0 ">
                <div className="col  subcription-bg ">
                  <div className="mb-5">
                    <div className="fs-4 text-center pt-3 pb-3 section-heading">
                      idealfor Subscription Confirmed!
                    </div>
                    {/* <p className="mb-0   fs-4">
                          You successfully started a{" "}
                          <span className="active">
                            IdealFor Subscription - 1 Month
                          </span>
                        </p> */}

                    <p>
                      Your 1-Month idealfor Subscription is now active. Please
                      note that your account will be automatically billed every
                      month once any existing subscription period concludes. If
                      you ever wish to cancel your idealfor subscription, you
                      have the flexibility to do so at any time through your
                      profile settings.
                    </p>
                  </div>
                </div>
                <div className="px-2 ">{/* <Footer /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Userprofile;
