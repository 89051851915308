
import React from 'react';
import { arrayOf, string } from 'prop-types'; 
// import showNotification from '../helpers/showNotification';
import { BiShare } from 'react-icons/bi';
import { ShareFontIcon } from 'react-md';

const handleShareError = (message) => {
//   showNotification({
//     type: 'error',
//     message,
//   });
alert(message)
};

const urlToObject = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], 'image.jpg', { type: blob.type });
  return file;
};

async function testWebShare(text ) {
    console.log(text)
  let files;
  // Test compatibility
  if (navigator.share === undefined) {
    handleShareError('Unsupported share feature');
    return;
  }

  // Handle file urls
//   if (filesUrl && filesUrl.length > 0) {
//     const filesGetter = filesUrl.map((file) => urlToObject(file));
//     const newFiles = await Promise.all(filesGetter);

//     if (!navigator.canShare || !navigator.canShare({ files: newFiles })) {
//       handleShareError('Unsupported share feature');
//       return;
//     }

//     files = newFiles;
//   }

  // Share content
  try {
    await navigator.share(text);
  } catch (error) {
    handleShareError(`Error sharing: ${error}`);
  }
}

const ShareButton = ({ ...props }) => {
    console.log(props.props)
  return (
    <button  onClick={() => testWebShare(props.props)} ><ShareFontIcon /></button>
  );
};

ShareButton.propTypes = {
  text: string,
  files: arrayOf(string),
};

ShareButton.defaultProps = {
  text: null,
  files: null,
};

export default ShareButton; 
