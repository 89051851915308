/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../Profile/profile.css";
import Footer2 from "../../../layout/Footer/Footer2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApi } from "../../../Api/Api";
import { API_PATH } from "../../../Api/ApiPAth";
import Loader from "../../../layout/Loader/Loader";
import Navbar3 from "../../../layout/BottomNavbar/Navbar3";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "@iconify/react";
import { RWebShare } from "react-web-share";

const Breport7 = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const [relation, setRelation] = useState([]);
  const [date1, seDate1] = useState("");
  const [relationShareId, setRelationSareId] = useState("");
  const [Loading, setLoading] = useState(false);
  const tok = localStorage.getItem("token");
  const date = localStorage.getItem("bdate");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${tok}`,
    },
  };
  const imgReg = useRef(null);
  useEffect(() => {
    async function PersonalityData7() {
      setLoading(true);
      try {
        const res = await getApi(
          `${API_PATH.BIRTHDAYPAID.ALL_REPORT_BIRTHDAY}/${id}`,
          {
            birthday: date,
          },
          axiosConfig
        );
        if (res.status) {
          setRelation(res.data.relationship_report);
          setRelationSareId(res.data.relationshipShareId);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    // PersonalityData7();
    const ReportData = JSON.parse(
      localStorage.getItem("PurchasedBirthdayReport")
    );
    setRelation(ReportData.relationship_report);
    setRelationSareId(ReportData.relationship_report.sId);
    const d = ReportData.relationship_report.birthday_1_name.split(",").shift();
    seDate1(d);
    imgReg.current?.scrollIntoView({
      behavior: "instant",
    });
  }, []);
  // console.log(personality)
  return (
    <>
      <ToastContainer />
      {Loading ? <Loader /> : null}
      {/* <div className="headerBAr">
                <Header />
            </div> */}
      <div className="container-fluid profileScreen">
        {/* <div className="profileUpperpart headerBAr pt-2 ">
                    <div className="col fs-3 text-light text-end profileHeading headerBAr " id='ForShare' >
                        <CopyToClipboard text={`https://idealfor.xyz/v1/relationship/share?id=${relation.shareId}`} onCopy={() => toast('url copied')}>
                            <FiShare2 className='boxSquare ' />
                        </CopyToClipboard>
                    </div>
                </div> */}

        <div className="container-lg personalityContent  ">
          <div className="row personalityBtn mx-auto fix_width ">
            <div className="  mx-auto" ref={imgReg}>
              <div className="position-sticky top-0 backColor">
                <div className="profileUpperpart navMatchbottom1">
                  <div className="  py-3  " onClick={() => navigate(-1)}>
                    <div className="arrow_back  btn-hover">
                      <MdArrowBackIosNew />{" "}
                      <span className="align-middle"> Go Back</span>
                    </div>
                  </div>
                  <div className="fs-5  py-3 profileHeading ">
                    <RWebShare
                      data={{
                        // text: `https://secretlanguage.network/v1/relationship/share?id=${relationShareId}`,
                        url: `https://secretlanguage.network/v1/relationship/share?id=${relationShareId}`,
                        title: "Share Relationship Report",
                      }}
                      // onClick={() =>
                      //   toast("url copied")
                      // }
                    >
                      <Icon
                        icon="mdi:share-outline"
                        className="fs-3 btn-hover"
                      />
                    </RWebShare>
                    {/* <CopyToClipboard
                    text={`https://secretlanguage.network/v1/relationship/share?id=${relationShareId}`}
                    onCopy={() => toast("url copied")}
                  >
                    <Icon icon="mdi:share-outline" className="fs-3" />
                  </CopyToClipboard> */}
                  </div>
                </div>
                {relation.famous ? (
                  <div className="col personalityBtns pb-2">
                    <ul className="nav  px-auto switch_div w-100">
                      <li className="nav-item tab-all w-50">
                        <a
                          className="nav-link  show py-1 px-3 active"
                          href="#tab1"
                          data-bs-toggle="tab"
                          onClick={() =>
                            imgReg.current?.scrollIntoView({
                              behavior: "instant",
                            })
                          }
                        >
                          <button className="side01  fs-5 fw-bold">
                            Personality
                          </button>
                        </a>
                      </li>
                      <li className="nav-item tab-all p-l-20 w-50">
                        <a
                          className="nav-link  py-1 px-4 "
                          href="#tab2"
                          data-bs-toggle="tab"
                          onClick={() =>
                            imgReg.current?.scrollIntoView({
                              behavior: "instant",
                            })
                          }
                        >
                          <button className="side01 fs-5 fw-bold">
                            Famous
                          </button>
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              <div className="col py-2 bg-light m-2 rounded-3">
                <div className="weekPersonality w-100">
                  <div className="week-blockPersonality ps-2 w-50">
                    <span className="week-headerPersonality text-center text-dark">
                      {relation.birthday_1}
                    </span>
                    <span className="datePersonality text-center text-dark">
                      {date1}
                    </span>
                  </div>
                  <span className="andPersonality text-dark px-3">&amp;</span>
                  <div className="week-blockPersonality pe-2 text-dark w-50">
                    <span className="week-headerPersonality text-center">
                      {relation.birthday_2}
                    </span>
                    <span className="datePersonality text-center">
                      {relation.birthday_2_name}
                    </span>
                  </div>
                </div>
                <div className="calledPersonality text-dark">IS CALLED</div>
                <div className="image-sectionPersonality ">
                  <img
                    src={relation.image}
                    className="border-iconPersonality"
                    alt=""
                  />
                </div>
                <div className="rel-namePersonality text-dark">
                  {relation.name}
                </div>
                <div className="week2Personality text-dark">
                  <div className="week-block2Personality px-4">
                    <span className="date1Personality mx-2 mx-lg-3">
                      {relation.s1}
                    </span>
                    <span className="date1Personality mx-2 mx-lg-3">
                      {relation.s2}
                    </span>
                    <span className="date1Personality mx-2 mx-lg-3">
                      {relation.s3}
                    </span>
                    {/* <span className="date1Personality mx-2 mx-lg-3">{relation.w1}</span>
                                        <span className="date1Personality mx-2 mx-lg-3">{relation.w2}</span>
                                        <span className="date1Personality mx-2 mx-lg-3">{relation.w3}</span> */}
                  </div>
                </div>
                <div className="idealPersonality text-dark">
                  Ideal for:
                  <span className="reIdealPersonality text-dark">
                    {relation.ideal_for}{" "}
                  </span>
                </div>
              </div>

              <div className="tab-content">
                <div className="tab-pane fontawesome-demo active" id="tab1">
                  <div className="">
                    <div className="description-sectionPersonality pt-0">
                      <p className="section-titlePersonality my-3 text-capitalize">
                        Personality
                      </p>
                      <p className=" line-para">{relation.report}</p>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="text-center">
                      <div className="description-sectionPersonality pt-0">
                        <p className="section-titlePersonality text-capitalize">
                          Advice
                        </p>
                        <p className=" mb-0  line-para">{relation.advice}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tab2"></div>
              </div>

              <div className=" pb-5 mb-5 ">
                <Footer2 />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        <div className=" fix_width  mx-auto">
          <Navbar3 />
        </div>
      </div>
    </>
  );
};

export default Breport7;
