export const API_PATH = {
  AUTH: {
    SIGNUP: "auth/v2-sign-up",
    LOGIN: "auth/v2-sign-in",
    GENRATE_OTP: "auth/sign-up/check-code",
    GENDER: "genders",
    INTRESTED_IN: "interests",
    GENDER_PREFERENCE: "genders",
    LOGOUT: "user/logout",
    LOGIN_MAIL: "auth/v2-sign-in-info",
    SIGNUP_MAIL: "auth/v2-sign-up-info",
  },
  UPDATE_PROFILE: {
    UPDATE: "user/updateProfile",
    USER_DELETE: "user/deleteMe",
  },
  PROFILE: {
    USER_DATA: "user/me",
    FRIEND_LIST: "user/myFriends",
    PENDING_LIST: "user/pendingRequests",
    DELETE_FRIEND: "user/deleteFriend",
    REQUEST_LIST: "user/myRequests",
    CHANGE_PROFILE: "user/addProfileImage",
    DELETE_PROFILE_IMAGE: "user/deleteProfileImage",
    GET_ALL_IMAGES: "user/profileImages",
    SET_AVATAR: "user/setAvatar",
    ALL_RESPONSES: "user/allRequests",
  },
  USER_PROFILE_DATA: {
    DATA_PROFILE: "user/getUserProfile",
    ACCEPT_REQUEST: "user/acceptFriendRequest",
    REJECT_REQUEST: "user/rejectFriendRequest",
  },
  MATCHES: {
    USER_PROFILE: "user/getUserProfile",
    MATCH_PROFILE: "user/searchAndroidUsers",
    MATCH_PROFILE2: "user/searchAndroidUsers/1",
    SWIPE_LEFT: "user/addSwipeLeftUser",
  },
  DATA_FILTER: {
    CHIP_FILTER: "relationship/getIdealForTags",
  },
  COMMUNITY: {
    WITHDRAW: "user/withdrawFriendRequest",
    SENDREQUEST: "user/sendFriendRequest",
  },
  REPORT: {
    REPOR_USER: "user/report",
    BIRTH_REPORT: "user/showBirthdayReport",
    RELATIONSHIP_REPORT: "user/showRelationshipReport",
    RELATIONSHIP_REPORT_WITHOUT_CHECK:
      "user/showRelationshipReportWithOutCheck",
    GET_REPORT: "payment/addWebPaidReport",
  },
  BLOCK: {
    BLOCK_USER: "user/block",
  },
  FLAG: {
    FLAG_API: "user/flag",
  },
  DELETE: {
    DELETE_FRIEND: "user/deleteFriend",
  },
  LOCATION: {
    USER_LOCATION: "user/addAndroidUserLocation",
  },
  PAYMENT: {
    ONE_TIME: "/checkout/init",
    SUBSCRIPTION: "/checkout/subscription",
  },
  SUBSCRIPTION: {
    CREATE_SUBSCRIPTION:"payment/createStripeSubscription",
    SUBSCRIPTION: "payment/verifyWebReceipt",
    CREATE_ONE_TIME_PAYMENT:"payment/createPaymentIntent",
    GET_INFO: "payment/verifyWebUserSubscriptionStatus",
    CHECK_StATUS: "payment/verifyAndroidUserSubscriptionStatus",
    CHECK_StATUS_web: "payment/verifyWebUserSubscriptionStatus",
    CHECK_AVAILABLE: "payment/checkStripeCancelAvail",
    CANCEL_SUB: "payment/cancelSubscription",
  },
  SETTING: {
    USER_SETTING: "user/settings",
  },
  BIRTHDAYPAID: {
    BIRTHDAYMAIN: "user/getBirthdayReport",
    BIRTH_DAY: "user/getDayReport",
    BIRTH_MONTH: "user/getMonthReport",
    BIRTH_WEEK: "user/getWeekReport",
    BIRTH_SEASON: "user/getSeasonReport",
    BIRTH_PATH: "user/getPathReport",
    BIRTH_WAY: "user/getWayReport",
    BIRTH_RELATION: "user/getRelationshipReport",
    ALL_REPORT_BIRTHDAY: "user/getBirthdayReport",
  },
  CHAT: {
    CHAT: "chats/webUserChat/1",
    MESSAGE: "chats/sendGreetingMessage",
    PENDING_LIST: "user/allRequestsChat",
    UPLOAD_FILE : 'user/uploadMedia'
  },
};
