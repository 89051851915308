import React from 'react';
import './matchesLoader.css';
const MatchesLoader = () => {
    return ( 
            <div className='main-load-matches'>
                <div className="lds-roller-matches">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div> 
    )
}

export default MatchesLoader;