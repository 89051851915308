import React from 'react';
import './loader.css';
const Loader = () => {
    return ( 
            <div className='main-load'>
                <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div> 
    )
}

export default Loader;