import React from "react";
import "./buttonloader.scss";
const ButtonLoader = () => {
  return (
    <>
      <div className="btn-load">
        {" "}
        <span className="load loading"></span>
      </div>
    </>
  );
};

export default ButtonLoader;
