import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = { 
    apiKey: "AIzaSyDrHQt4hBrJfHyeb0VkgGP57pdVXm8_ouA", 
    authDomain: "secret-language-15223.firebaseapp.com", 
    projectId: "secret-language-15223", 
    storageBucket: "secret-language-15223.appspot.com", 
    messagingSenderId: "206791381143", 
    appId: "1:206791381143:web:810272eb066a048ad999d2", 
    measurementId: "G-3DM3YK4FNZ" 
  
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()