import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../auth/context/AuthContext";
import { ChatContext } from "../../../auth/context/ChatContext";
import Moment from "react-moment";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import Modal from "react-modal";
import { RxCross1 } from "react-icons/rx";
import { FaRegCirclePlay, FaRegPlayCircle } from "react-icons/fa";
import { RWebShare } from "react-web-share";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};

const Message = ({ message, dates }) => {
  const [isOpen, setOpen] = useState(false);

  const { currentUser } = useContext(AuthContext);
  // console.log(dates);
  const Opp_id = localStorage.getItem("oppid");
  // eslint-disable-next-line no-unused-vars
  const { data } = useContext(ChatContext);
  const ref = useRef();

  useEffect(() => {
    if (message.senderId !== Opp_id) {
      // localStorage.setItem("progress", false);
      // localStorage.setItem("Share_blob", []);
    }
    ref.current?.scrollIntoView({ behavior: "instant" });
    window.scrollTo(ref.scrollHeight);
    // console.log("message");
  }, [message]);
  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  const lineBreakRegex = /\\r?\\n/;
  const linkRegex = /^http(s)?:\/\//;

  const renderContentWithLineBreaks = (content) => {
    const lines = content?.split(lineBreakRegex);
    return lines?.map((line, index) => {
      if (linkRegex.test(line)) {
        return (
          <React.Fragment key={index}>
            <a
              href={line}
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none text-color line-hover"
            >
              {line}
            </a>
          </React.Fragment>
        );
      }
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };
  const naviagte = useNavigate();
  const [media_url, setMedia_url] = useState("");
  const [type, settype] = useState("");
  const [base64, setBase64] = useState("");
  const [base64Image, setBase64Image] = useState();
  const openMedia = async (url, type) => {
    setMedia_url(url);
    settype(type);
    urlToObject(url);
    openModal();
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const urlToObject = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], "image.png", { type: blob.type });
      setBase64Image(file);
      // convertToBase64(file);
      return file;
    } catch (error) {
      console.log(error);
    }
  };
  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64Image(reader.result);
      openModal();
    };
  };

  const share = async (files) => {
    const response = await fetch(files);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File(
      [blob],
      type === "image" ? "share.jpg" : "share.mp4",
      { type: blob.type }
    );
    if (!navigator.canShare) {
      // console.log(`Your browser doesn't support the Web Share API.`);
      return;
    }

    if (navigator.canShare({ files: [file] })) {
      try {
        await navigator.share({
          files: [file],
        });
      } catch (error) {
        console.log(`Error: ${error.message}`);
      }
    } else {
      // alert(`Your system doesn't support sharing these files.`);
    }
  };

  const handleOnSubmit = async (image) => {
    const response = await fetch(image);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], "share.jpg", { type: blob.type });
    if (navigator.share) {
      await navigator.share({
        title: "title",
        text: "your text",
        url: "url to share",
        files: [file],
      });
      // .then(() => console.log("Successful share"))
      // .catch((error) =>
      // console.log("Error in sharing", error)
      // );
    } else {
      alert(`system does not support sharing files.`);
    }
  };
  // console.log(message);
  return (
    <>
      {/* <ChatScreen /> */}
      <div
        ref={ref}
        className={`message ${
          message.user._id.toString() !== Opp_id && "owner"
        }`}
        key={message.id}
      >
        <div className="messageInfo"></div>
        <div className="messageContent">
          {message.text && (
            <p className="ms-2 mb-2">
              {renderContentWithLineBreaks(message.text)}
              {/* <br/> */}
              {/* <span className="time-chat " > */}
              <Moment className="time-chat ms-2 " format="h:mm a">
                {message.createdAt}
              </Moment>
              {/* </span> */}
            </p>
          )}
          {message.image && (
            <div className="position-relative  handle_share">
              <div className="img_div ms-2 object-fit-cover position-relative mb-2  ">
                <img
                  onClick={() => openMedia(message.image, "image")}
                  src={message.image}
                  alt=""
                  className="w-100 object-fit-cover"
                />
                <span className="time-chat">
                  <Moment format="h:mm a">{message.createdAt}</Moment>
                </span>
              </div>
            </div>
          )}
          {message.video && (
            <div className="position-relative  handle_share">
              <div className="img_div ms-2 position-relative  object-fit-cover">
                {message.thumbnail ? (
                  <img
                    src={message.thumbnail}
                    alt=""
                    className="w-100 object-fit-cover"
                    onClick={() => openMedia(message.video, "video")}
                  />
                ) : (
                  <video
                    src={message.video}
                    // controls={true}
                    controlsList="nodownload"
                    className="my-auto w-100 object-fit-cover"
                    onClick={() => openMedia(message.video, "video")}
                  />
                )}
                {message.thumbnail ? (
                  <FaRegPlayCircle
                    className="position-absolute top-50 start-50 translate-middle"
                    style={{ fontSize: "50px" }}
                    onClick={() => openMedia(message.video, "video")}
                  />
                ) : null}

                <span className="time-chat">
                  <Moment format="h:mm a">{message.createdAt}</Moment>
                </span>
              </div>
            </div>
          )}
        </div>
        {/* {ref.current?.scrollIntoView({
          behavior: "instant",
        })} */}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="d-flex justify-content-between mb-3">
          <RxCross1
            className=" modal-icon btn-hover ms-2 fs-4"
            onClick={() => closeModal()}
          />

          <Icon
            icon="mdi:share-outline"
            id="share"
            className="fs-3 btn-hover"
            onClick={() => share(media_url)}
          />
        </div>
        <div className="object-fit-contain  mx-auto text-center">
          {type === "image" ? (
            <img
              src={media_url}
              alt=""
              height={500}
              className="object-fit-contain w-100"
            />
          ) : (
            <video
              src={media_url}
              autoPlay
              controls={true}
              height={400}
              controlsList="nodownload"
              className="my-auto w-100 mx-auto object-fit-contain"
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default Message;
