import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import Footer2 from "../../layout/Footer/Footer2";
import Loader from "../../layout/Loader/Loader";
import { MdArrowBackIosNew } from "react-icons/md";
import Navbar3 from "../../layout/BottomNavbar/Navbar3";
import { ToastContainer } from "react-toastify";

const Birthdaydata = () => {
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [carddata, setCarddata] = useState([]);
  const [weekdata, setWeekdata] = useState([]);
  const [monthdata, setMonthdata] = useState([]);
  const [seasondata, setSeasondata] = useState([]);
  const [relationdata, setRelationdata] = useState([]);
  const [waydata, setwaydata] = useState([]);
  const [pathdata, setpathdata] = useState([]);
  const date = localStorage.getItem("bdate");
  const navigate = useNavigate();
  useEffect(() => {
    const ReportData = JSON.parse(
      localStorage.getItem("PurchasedBirthdayReport")
    );
    setData(ReportData);
    setCarddata(ReportData.day_report);
    setWeekdata(ReportData.week_report);
    setMonthdata(ReportData.month_report);
    setSeasondata(ReportData.season_report);
    setwaydata(ReportData.way_report);
    setpathdata(ReportData.path_report);
    setRelationdata(ReportData.relationship_report);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.scroll({
      top: -50,
      left: 0,
      behavior: "instant",
    });
  }, []);
  // eslint-disable-next-line no-unused-vars
  const Onclickback = () => {
    navigate(-1);
  };
  const birthdayReport = () => {
    navigate({
      pathname: "/birth-dayreport",
      search: `?id=${carddata.id}`,
      state: { data: carddata },
    });
  };
  const birthweekReport = () => {
    navigate({ pathname: "/birth-weekreport", search: `?id=${weekdata.id}` });
  };
  const birthmonthReport = () => {
    navigate({ pathname: "/birth-monthreport", search: `?id=${monthdata.id}` });
  };
  const birthseasonReport = () => {
    navigate({
      pathname: "/birth-seasonreport",
      search: `?id=${seasondata.id}`,
    });
  };
  const birthwayReport = () => {
    navigate({
      pathname: "/birth-wayreport",
      search: `?id=${waydata.id}`,
    });
  };
  const birthpathReport = () => {
    navigate({
      pathname: "/birth-pathreport",
      search: `?id=${pathdata.id}`,
    });
  };
  const birthrelationReport = () => {
    navigate({
      pathname: "/birth-relationreport",
      search: `?id=${data.shareId}`,
    });
  };
  return (
    <>
      <ToastContainer />
      {Loading ? <Loader /> : null}
      <div className="container-fluid profileScreen ">
        <div className="  mx-auto fix_width">
          <div className="row mx-0">
            <div className="position-sticky top-0 backColor px-0">
              <div className="profileUpperpart navMatchbottom1  py-1 ">
                <div
                  className="col  py-3  "
                  onClick={() => {
                    localStorage.removeItem("PurchasedBirthdayReport");
                    navigate("/reports");
                  }}
                >
                  <div className="arrow_back   btn-hover">
                    <MdArrowBackIosNew />{" "}
                    <span className="align-middle"> Go Back</span>
                  </div>
                </div>
                <div className="col-5 fs-5  text-center py-3 profileHeading ">
                  Birthday Report
                </div>
                <div className="col fs-5 p-2 py-3 profileHeading ">
                  {/* <CopyToClipboard
                  text={`https://idealfor.xyz/v1/birthday/share?id=${data.shareId}`}
                  onCopy={() => toast("url copied")}
                >
                  <Icon icon="mdi:share-outline" className="fs-3" />
                </CopyToClipboard> */}
                </div>
              </div>
            </div>
          </div>
          <div className="container-xxl ">
            <div className="row d-flex justify-content-center text-center mx-0 fix_width">
              <div className=" ">
                <p className="line-para text-center">
                  {" "}
                  The Secret Language Name For {data.date_name} is
                </p>
                <p className="fs-2 text-center">{data.sln}</p>
                <p className="line-para text-center"> {data.sln_description}</p>
                <div className="row text-light justify-content-center">
                  <div
                    className="col-5 col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 m-sm-2 m-2 profileCard px-0"
                    id="profileData"
                  >
                    <div
                      className=" text-dark p-2 rounded-3"
                      style={{ background: "#FFF", height: "100%" }}
                    >
                      <img
                        src={carddata.image}
                        className="cardsimg  rounded "
                        width="85%"
                        height="120px"
                        alt=""
                      />
                      <div className=" mt-1 cardHeading">
                        {carddata.day_name}
                      </div>
                      <div className="  py-1 text-center CardSubhaeding">
                        {carddata.date_name}
                      </div>
                      <div className="pt-3 PointAdd px-2">
                        <div
                          className=" ProfileBtn "
                          onClick={() => birthdayReport()}
                        >
                          Read Report
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-5 col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 m-sm-2 m-2 profileCard px-0"
                    id="profileData"
                  >
                    <div
                      className=" text-dark p-2 rounded-3"
                      style={{ background: "#FFF", height: "100%" }}
                    >
                      <img
                        src={weekdata.image}
                        className="cardsimg  rounded"
                        width="85%"
                        height="120px"
                        alt=""
                      />
                      <div className=" mt-1 cardHeading">
                        {weekdata.name_long}
                      </div>
                      <div className=" py-1 text-center CardSubhaeding ">
                        {weekdata.date_span}
                      </div>
                      <div className="pt-3  PointAdd px-2">
                        <div
                          className=" ProfileBtn"
                          onClick={() => birthweekReport()}
                        >
                          Read Report
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-5 col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 m-sm-2 m-2 profileCard px-0"
                    id="profileData"
                  >
                    <div
                      className=" text-dark p-2 rounded-3"
                      style={{ background: "#FFF", height: "100%" }}
                    >
                      <img
                        src={monthdata.image}
                        className="cardsimg  rounded "
                        width="85%"
                        height="120px"
                        alt=""
                      />
                      <div className=" mt-1 cardHeading">{monthdata.name}</div>
                      <div className=" py-1 text-center CardSubhaeding">
                        {monthdata.span1}
                      </div>
                      <div className="pt-3 PointAdd px-2">
                        <div
                          className=" ProfileBtn"
                          onClick={() => birthmonthReport()}
                        >
                          Read Report
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-5 col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 m-sm-2 m-2 profileCard px-0"
                    id="profileData"
                  >
                    <div
                      className=" text-dark p-2 rounded-3"
                      style={{ background: "#FFF", height: "100%" }}
                    >
                      <img
                        src={seasondata.image}
                        className="cardsimg  rounded"
                        width="85%"
                        height="120px"
                        alt=""
                      />
                      <div className="mt-1 cardHeading">{seasondata.name}</div>
                      <div className=" py-1 text-center CardSubhaeding">
                        {seasondata.span1}
                      </div>
                      <div className="pt-3 PointAdd px-2">
                        <div
                          className=" ProfileBtn"
                          onClick={() => birthseasonReport()}
                        >
                          Read Report
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-5 col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 m-sm-2 m-2 profileCard px-0"
                    id="profileData"
                  >
                    <div
                      className=" text-dark p-2 rounded-3"
                      style={{ background: "#FFF", height: "100%" }}
                    >
                      <img
                        src={pathdata.image}
                        className="cardsimg  rounded"
                        width="85%"
                        height="120px"
                        alt=""
                      />
                      <div className="mt-3 cardHeading">
                        {" "}
                        {pathdata.name_medium}
                      </div>
                      <div className="py-2 text-center CardSubhaeding ">
                        {pathdata.way_name}
                      </div>
                      <div className="pt-3 PointAdd px-2">
                        <div
                          className=" ProfileBtn"
                          onClick={() => birthpathReport()}
                        >
                          Read Report
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-5 col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 m-sm-2 m-2 profileCard px-0"
                    id="profileData"
                  >
                    <div
                      className=" text-dark p-2 rounded-3"
                      style={{ background: "#FFF", height: "100%" }}
                    >
                      <img
                        src={waydata.image}
                        className="cardsimg  rounded"
                        width="85%"
                        height="120px"
                        alt=""
                      />
                      <div className="mt-3 cardHeading">{waydata.name}</div>
                      <div className="py-3 mt-1 text-center CardSubhaeding"></div>
                      <div className="pt-2 pt-sm-3 pt-md-3 pt-lg-3 pt-lg-3 pt-xl-3 pt-xxl-3 PointAdd px-2">
                        <div
                          className=" ProfileBtn"
                          onClick={() => birthwayReport()}
                        >
                          Read Report
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-5 col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 m-sm-2 m-2 profileCard px-0"
                    id="profileData"
                  >
                    <div
                      className=" text-dark p-2 rounded-3"
                      style={{ background: "#FFF", height: "100%" }}
                    >
                      <img
                        src={relationdata.image}
                        className="cardsimg  rounded"
                        width="85%"
                        height="120px"
                        alt=""
                      />
                      <div className="mt-3 cardHeading"></div>
                      <div className="py-2 text-center CardSubhaeding">
                        {relationdata.name}
                      </div>
                      <div className="pt-3 PointAdd px-2">
                        <div
                          className=" ProfileBtn"
                          onClick={() => birthrelationReport()}
                        >
                          Read Report
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-5 mb-5">
                  <Footer2 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        <div className="  mx-auto fix_width">
          <Navbar3 />
        </div>
      </div>
    </>
  );
};

export default Birthdaydata;
