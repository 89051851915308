import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import "./profile.css";
import Footer2 from "../../layout/Footer/Footer2";
import { getApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import Loader from "../../layout/Loader/Loader";
import Navbar3 from "../../layout/BottomNavbar/Navbar3";
import { ToastContainer, toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "@iconify/react";
import {
  HiDotsVertical,
  HiOutlineArrowSmLeft,
  HiOutlineArrowSmRight,
} from "react-icons/hi";
import ShareButton from "../../layout/share";
import { RWebShare } from "react-web-share";
import dp from "../../assets/Dp.png";
import { RxCross1 } from "react-icons/rx";
import { SlEnvolope } from "react-icons/sl";
import { RiShareForwardLine } from "react-icons/ri";

const Profile = () => {
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  // eslint-disable-next-line no-unused-vars
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${token}`,
    },
  };
  const [img] = useState("");
  const { handleSubmit } = useForm({
    defaultValues: {
      image: img.name,
    },
  });
  const [data, setData] = useState([]);
  const [birdata, setBirData] = useState([]);
  const [carddata, setCarddata] = useState([]);
  const [weekdata, setWeekdata] = useState([]);
  const [monthdata, setMonthdata] = useState([]);
  const [seasondata, setSeasondata] = useState([]);
  const [relationdata, setRelationdata] = useState([]);
  const [waydata, setWaydata] = useState([]);
  const [pathdata, setPathdata] = useState([]);
  const onSubmit = (data) => {};
  useEffect(() => {
    async function ProfileData() {
      try {
        const res = await getApi(API_PATH.PROFILE.USER_DATA);
        if (res.status) {
          setData(res.data);
          localStorage.setItem("ProfileData", JSON.stringify(res.data));
          setBirData(res.data.birthday_report);
          setCarddata(res.data.birthday_report.day_report);
          setWeekdata(res.data.birthday_report.week_report);
          setMonthdata(res.data.birthday_report.month_report);
          setSeasondata(res.data.birthday_report.season_report);
          setRelationdata(res.data.birthday_report.relationship_report);
          setWaydata(res.data.birthday_report.way_report);
          setPathdata(res.data.birthday_report.path_report);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }

    const storedData = localStorage.getItem("ProfileData");

    if (storedData) {
      setLoading(false);
      // If data is available in local storage, parse and set it
      const profileData = JSON.parse(storedData);
      setData(JSON.parse(storedData));
      setBirData(profileData.birthday_report);
      setCarddata(profileData.birthday_report.day_report);
      setWeekdata(profileData.birthday_report.week_report);
      setMonthdata(profileData.birthday_report.month_report);
      setSeasondata(profileData.birthday_report.season_report);
      setRelationdata(profileData.birthday_report.relationship_report);
      setWaydata(profileData.birthday_report.way_report);
      setPathdata(profileData.birthday_report.path_report);
      setLoading(false);
    } else {
      // If data is not available in local storage, fetch it
      setLoading(true);
    }
    ProfileData();
    window.scroll({
      top: -50,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const profileSetting = () => {
    navigate("/profilesetting");
  };
  const [Zodiac_sign, setZodiaC] = useState("");
  function zodiac_sign(day, month) {
    let astro_sign = "";
    // checks month and date within the
    // valid range of a specified zodiac
    if (month === "December") {
      if (day < 22) astro_sign = "Sagittarius";
      else astro_sign = "Capricorn";
    } else if (month === "January") {
      if (day < 20) astro_sign = "Capricorn";
      else astro_sign = "Aquarius";
    } else if (month === "February") {
      if (day < 19) astro_sign = "Aquarius";
      else astro_sign = "Pisces";
    } else if (month === "March") {
      if (day < 21) astro_sign = "Pisces";
      else astro_sign = "Aries";
    } else if (month === "April") {
      if (day < 20) astro_sign = "Aries";
      else astro_sign = "Taurus";
    } else if (month === "May") {
      if (day < 21) astro_sign = "Taurus";
      else astro_sign = "Gemini";
    } else if (month === "June") {
      if (day < 21) astro_sign = "Gemini";
      else astro_sign = "Cancer";
    } else if (month === "July") {
      if (day < 23) astro_sign = "Cancer";
      else astro_sign = "Leo";
    } else if (month === "August") {
      if (day < 23) astro_sign = "Leo";
      else astro_sign = "Virgo";
    } else if (month === "September") {
      if (day < 23) astro_sign = "Virgo";
      else astro_sign = "Libra";
    } else if (month === "October") {
      if (day < 23) astro_sign = "Libra";
      else astro_sign = "Scorpio";
    } else if (month === "November") {
      if (day < 22) astro_sign = "Scorpio";
      else astro_sign = "Sagittarius";
    }
    setZodiaC(astro_sign);
  }
  useEffect(() => {
    if (data.date_name) {
      const date = new Date(data.date_name);
      const Month = date.toLocaleString("default", { month: "long" });
      const Day = date.getDate();
      zodiac_sign(Day, Month);
    }
    window.scroll({
      top: -50,
      left: 0,
      behavior: "instant",
    });
  }, []);

  // scroll

  const [leftscroll, setscrollShow] = useState(false);
  const [rightscroll, setscrollRShow] = useState(true);
  const containerRef = useRef(null);

  const scrollLeft = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollLeft -= 100;
      setscrollRShow(true); // Adjust the scrolling distance as needed
      if (container.scrollLeft === 0) {
        setscrollShow(false);
      }
    }
  };

  const checkMaxScrollWidth = (containerRef) => {
    const container = containerRef.current;
    if (container) {
      const scrollWidth = container.scrollWidth;
      const clientWidth = container.clientWidth;
      return scrollWidth - clientWidth;
    }
    return 0;
  };

  const scrollRight = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollLeft += 120;
      setscrollShow(true); // Adjust the scrolling distance as needed
      var maxScrollLeft = container.scrollWidth - container.clientWidth;
      if (container.scrollLeft >= maxScrollLeft) {
        setscrollRShow(false);
      }
    }
  };

  //scroll whee;l
  const handleScroll = (e) => {
    const container = containerRef.current;
    if (container) {
      container.scrollLeft += e.deltaY;
      if (container.scrollLeft === 0) {
        setscrollShow(false);
        setscrollRShow(true);
      }
      if (container.scrollLeft === container.scrollLeftMax) {
        setscrollShow(true);
        setscrollRShow(false);
      }
    }
  };

  const [ReportShow, setReportShow] = useState(false);
  const openBottomList = () => {
    setReportShow(true);
  };
  const CloseBottomList = () => {
    setReportShow(false);
  };
  return (
    <>
      <ToastContainer />
      {Loading ? <Loader /> : null}
      <div className="container-fluid profileScreen ">
        <div className=" fix_width   mx-auto ">
          <div className="position-sticky top-0 backColor profileUpperpart navMatchbottom1 px-2 py-1 z-3">
            <div className="col fs-5  text-left py-3 ">My Profile</div>

            <div className="fs-5  py-3 profileHeading ">
              <div className="dropdown">
                <div
                  className=" dropdown-toggle "
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical
                    className="fs-3 btn-hover"
                    // onClick={() => openBottomList()}
                  />
                </div>
                <ul className="dropdown-menu dropdown-menu-dark">
                  <li className="my-2 py-1">
                    <div
                      className=" button btn-upload mx-3 btn-hover "
                      title="Set As Avatar"
                    >
                      <RWebShare
                        data={{
                          url: `https://secretlanguage.network/v1/profile/share?id=${data.id}`,
                          title: "Share Profile",
                        }}
                      >
                        <span className="d-flex align-items-center">
                          <RiShareForwardLine className="fs-5 me-2 btn-hover" />
                          Share Profile
                        </span>
                      </RWebShare>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider border-secondary mx-2" />
                  </li>
                  <li className="my-2 py-1">
                    <div
                      className="button2 btn-upload2  mx-3 btn-hover"
                      title="Delete Image"
                    >
                      <RWebShare
                        data={{
                          text: `Idealfor - Based on Secret Language of Birthday and relationships. Download free from `,
                          url: `https://idealfor.xyz/download`,
                          title: "Invite Friends",
                        }}
                      >
                        <span className="d-flex align-items-center">
                          <SlEnvolope className="me-2 btn-hover" />
                          Tell a Friend
                        </span>
                      </RWebShare>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row d-flex justify-content-center text-center mx-0">
              <div className="col mx-auto px-0">
                <div className="col">
                  <form
                    className="d-flex flex-column justify-content-center align-items-center "
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className=" uploadimageUser mb-2  ">
                      <div htmlFor="image">
                        {" "}
                        {img ? (
                          <img
                            src={img}
                            alt="..."
                            className=" uploadimageUser img_brdr"
                          />
                        ) : (
                          <img
                            src={data.image ? data.image : dp}
                            height={"110px"}
                            width={"110px"}
                            className=" rounded-circle object-fit-cover img_brdr"
                            style={{ objectPosition: "center" }}
                            alt="..."
                          />
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="  py-2 profileUserName">
                  <div className="fs-5">
                    {data.name} {data.name ? "," : null} <span>{data.age}</span>
                  </div>
                </div>
                <div className="btn_actions d-flex justify-content-center my-2">
                  <button
                    className="Add_btn_profile p-2 border-none"
                    style={{ width: "130px" }}
                    onClick={profileSetting}
                  >
                    Edit Profile
                  </button>
                  {data.instagram ? (
                    <div>
                      <a
                        href={`https://www.instagram.com/${data.instagram}`}
                        target="blank"
                        className="ms-2"
                      >
                        <Icon
                          icon="jam:instagram"
                          className="fs-1  p-1 text-light insta  rounded-circle"
                        />
                      </a>
                    </div>
                  ) : null}
                </div>

                {Zodiac_sign ? (
                  <p className=" my-3">
                    {data.date_name} - {Zodiac_sign}
                  </p>
                ) : null}

                <div className=" col d-flex flex-row justify-content-center mt-2">
                  <div className="col-3  profileContent1">
                    <Link to="/friends" state={{ friendsLength: data.friends }}>
                      <div className="">
                        <span className="profileFont">{data.friends}</span>
                        <p className="fontPro">Connections</p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-3 profileContent1">
                    <Link
                      to="/pendings"
                      state={{ PendingLength: data.pending }}
                    >
                      <div className="">
                        <span className="profileFont">{data.pending}</span>
                        <p className="fontPro">Pending</p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-3  profileContent1">
                    <Link
                      to="/requests"
                      state={{ requestsLength: data.requests }}
                    >
                      <div className="">
                        <span className="profileFont">{data.requests}</span>
                        <p className="fontPro">Requests</p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col my-2 px-2">
                  <div
                    className=" py-4 profilePara1 Add_btn_profile mx-auto rounded-5 fs-5 w-100"
                    // style={{ width: "350px" }}
                  >
                    {birdata.sln
                      ? birdata.sln
                      : "Instuctional Emotional Orange "}
                  </div>
                  <div className="my-3 profilePara2 px-1 text-center ">
                    {birdata.sln_description
                      ? birdata.sln_description
                      : " This name describe the life energy of this day. People born during this day will retain and radiate its energy and will exhibit most of the personality traits we discovered for their day, week, month, season, and year as shown."}
                  </div>
                </div>
                <div className="row   justify-content-center ForproMargin px-2 mx-0">
                  <p className="px-0  text-start">My Personology Reports :</p>
                  <div className="d-flex position-relative align-items-center px-0">
                    <div
                      className="d-flex justify-content-between  div-scroll-btn scrollhide position-absolute"
                      style={{ width: "98%" }}
                    >
                      <button
                        className={
                          leftscroll === true
                            ? " ms-1 p-2 border-none scroll scrollhide rounded-5 z-1"
                            : "z-n1"
                        }
                        onClick={scrollLeft}
                      >
                        <HiOutlineArrowSmLeft />
                      </button>
                      <button
                        className={
                          rightscroll !== true
                            ? "z-n1"
                            : "p-2 border-none scroll scrollhide rounded-5 z-1"
                        }
                        onClick={scrollRight}
                      >
                        {" "}
                        <HiOutlineArrowSmRight />
                      </button>
                    </div>
                    <div
                      className="d-flex overflow-x-scroll scrollhide pb-3 pt-1"
                      id="container"
                      onWheel={handleScroll}
                      // onWheelCapture={element}
                      ref={containerRef}
                      style={{
                        width: "100%",
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div
                        className="col-5  me-3 profileCard px-0"
                        id="profileData"
                      >
                        <div className="col imagePersonality">
                          <div className="col bg-light  py-3  rounded-3">
                            <img
                              src={carddata.image}
                              className="cardsimg p-2 bg-light rounded "
                              alt=""
                              width={120}
                            />{" "}
                            <div className="mt-xl-2 mt-sm-2  mt-2 cardHeading res-card1 text-dark mb-0">
                              {carddata.day_name}
                            </div>
                            <div className=" fw-bold py-1 text-center CardSubhaeding text-dark">
                              {carddata.date_name}
                            </div>
                            <div className="pt-xl-3   res-card1-btn">
                              <Link
                                to="/user-day_report"
                                className=" ProfileBtn text-light"
                              >
                                Read Report
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-5   profileCard px-0"
                        id="profileData"
                      >
                        <div className="col imagePersonality">
                          <div className="col bg-light  py-3  rounded-3">
                            <img
                              src={weekdata.image}
                              className="cardsimg p-2 bg-light rounded "
                              alt=""
                              width={120}
                            />{" "}
                            <div className="mt-xl-2 mt-sm-2  mt-2 cardHeading res-card1 text-dark">
                              {weekdata.name_long}
                            </div>
                            <div className=" fw-bold py-1 text-center CardSubhaeding text-dark">
                              {weekdata.date_span}
                            </div>
                            <div className="pt-xl-3   res-card1-btn">
                              <Link
                                to="/user-week_report"
                                className=" ProfileBtn text-light"
                              >
                                Read Report
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-5   mx-3  profileCard px-0"
                        id="profileData"
                      >
                        <div className="col imagePersonality">
                          <div className="col bg-light  py-3  rounded-3">
                            <img
                              src={monthdata.image}
                              className="cardsimg p-2 bg-light rounded "
                              alt=""
                              width={120}
                            />{" "}
                            <div className="mt-xl-2 mt-sm-2  mt-2 cardHeading res-card1 text-dark">
                              {monthdata.name}
                            </div>
                            <div className=" fw-bold py-1 text-center CardSubhaeding text-dark">
                              {monthdata.span1}
                            </div>
                            <div className="pt-xl-3   res-card1-btn">
                              <Link
                                to="/user-month_report"
                                className=" ProfileBtn text-light"
                              >
                                Read Report
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-5   profileCard px-0"
                        id="profileData"
                      >
                        <div className="col imagePersonality">
                          <div className="col bg-light  py-3  rounded-3">
                            <img
                              src={seasondata.image}
                              className="cardsimg p-2 bg-light rounded "
                              alt=""
                              width={120}
                            />{" "}
                            <div className="mt-xl-2 mt-sm-2  mt-2 cardHeading res-card1 text-dark">
                              {seasondata.name}
                            </div>
                            <div className=" fw-bold py-1 text-center CardSubhaeding text-dark">
                              {seasondata.span1}
                            </div>
                            <div className="pt-xl-3  res-card1-btn">
                              <Link
                                to="/user-season_report"
                                className=" ProfileBtn text-light"
                              >
                                Read Report
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-5  mx-3  profileCard px-0"
                        id="profileData"
                      >
                        <div className="col imagePersonality">
                          <div className="col bg-light  py-3  rounded-3">
                            <img
                              src={pathdata.image}
                              className="cardsimg p-2 bg-light rounded "
                              alt=""
                              width={120}
                            />{" "}
                            <div className="mt-xl-2 mt-sm-2 pb-1 pb-sm-0 mb-sm-2 mt-2 cardHeading res-card1 text-dark">
                              {pathdata.way_name}
                            </div>
                            <div className=" fw-bold py-lg-3 py-sm-2 py-2 text-center CardSubhaeding text-dark"></div>
                            <div className="pt-xl-1   res-card1-btn">
                              <Link
                                to="/user-path_report"
                                className=" ProfileBtn text-light"
                              >
                                Read Report
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-5   profileCard px-0"
                        id="profileData"
                      >
                        <div className="col imagePersonality">
                          <div className="col bg-light  py-3  rounded-3">
                            <img
                              src={waydata.image}
                              className="cardsimg p-2 bg-light rounded "
                              width={120}
                              alt=""
                            />{" "}
                            <div className="mt-xl-2 mt-sm-2  mt-2 cardHeading res-card1 text-dark">
                              {waydata.name}
                            </div>
                            <div className=" fw-bold py-1 text-center CardSubhaeding text-dark">
                              {/* <div className=" py-1 text-center CardSubhaeding"> */}
                              Their Life Journey Runs
                              {/* </div> */}
                            </div>
                            <div className="pt-xl-3   res-card1-btn">
                              <Link
                                to="/user-way_report"
                                className=" ProfileBtn text-light"
                              >
                                Read Report
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-5  mx-3  profileCard px-0"
                        id="profileData"
                      >
                        <div className="col imagePersonality">
                          <div className="col bg-light  py-3  rounded-3">
                            <img
                              src={relationdata.image}
                              className="cardsimg p-3 bg-light rounded "
                              alt=""
                              width={120}
                            />{" "}
                            <div className="mt-xl-2 mt-sm-2 pb-1 pb-sm-0 mb-sm-2 mt-2 cardHeading res-card1 text-dark">
                              {" "}
                              {relationdata.name}
                            </div>
                            <div className=" fw-bold py-lg-3 py-sm-2 py-2 text-center CardSubhaeding text-dark"></div>
                            <div className=" pt-xl-1   res-card1-btn">
                              <Link
                                to="/user-relationship_report"
                                className=" ProfileBtn text-light"
                              >
                                Read Report
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pb-5 px-2 mb-5">
                    <Footer2 />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
          <div className="fix_width mx-auto">
            <Navbar3 />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
