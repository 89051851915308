import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { API_PATH } from "./Api/ApiPAth";
import { postApi } from "./Api/Api";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};

const StripeOneTime = () => {
  const [modalIsOpenName, setIsOpenName] = React.useState(false);

  function closeModalName() {
    setIsOpenName(false);
  }

  async function createPaymentIntent() {
    setIsOpenName(true);
  }
  return (
    <div>
      <button
        onClick={() => {
          createPaymentIntent();
        }}
      >
        hellll
      </button>
      <Modal
        isOpen={modalIsOpenName}
        onRequestClose={closeModalName}
        style={customStyles}
        contentLabel="Example Modal Connections"
        ariaHideApp={false}
      >
        <div className="">
          <CheckoutForm />
        </div>
      </Modal>
    </div>
  );
};

export default StripeOneTime;
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CheckoutForm() {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setErrorMsg("");

    const paymentMethodObj = {
      type: "card",

      billing_details: {
        name,
        email,
      },
    };

    const mydata = await stripeApiHandler(paymentMethodObj.billing_details);
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      mydata.clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: name,
            email: email,
          },
        },
      }
    );
    console.log(error, "hsudbshvfusgv");
    if (error) {
      // setMessage(error.message);
      console.log(error, "1");
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      console.log(paymentIntent);
      let objJsonStr = JSON.stringify(paymentIntent);
      var encoded = btoa(objJsonStr);
      const res = await postApi(API_PATH.REPORT.GET_REPORT, {
        birthday: "February 17",
        receipt: encoded,
      });
      console.log(res.data);

      return alert("Payment successful!");
    } else {
      // setMessage("An unexpected error occured.");
      console.log("error", "1");
    }
  };

  async function stripeApiHandler(data) {
    try {
      const res = await postApi(
        API_PATH.SUBSCRIPTION.CREATE_ONE_TIME_PAYMENT,
        data
      );
      console.log(res.data, "create");
      return res.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  return (
    <React.Fragment>
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Pay with card</span>
      </h4>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-name">Name on card</label>
            <input
              id="cc-name"
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-email">Email</label>
            <input
              id="cc-email"
              type="text"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Card Number</label>
            <CardNumberElement
              id="cc-number"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="expiry">Expiration Date</label>
            <CardExpiryElement
              id="expiry"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <hr className="mb-4" />
        <button className="btn btn-dark w-100" type="submit" disabled={loading}>
          Submit
          {/* {loading ? <div className="spinner-border spinner-border-sm text-light" role="status"></div> : `PAY ₹${props.amount}`} */}
        </button>
        {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
      </form>
    </React.Fragment>
  );
}
