/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../profile.css";
import Footer2 from "../../../layout/Footer/Footer2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApi } from "../../../Api/Api";
import { API_PATH } from "../../../Api/ApiPAth";
import Loader from "../../../layout/Loader/Loader";
import Navbar3 from "../../../layout/BottomNavbar/Navbar3";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "@iconify/react";
import { RWebShare } from "react-web-share";

const Personality7 = () => {
  const navigate = useNavigate();
  const [personality, setPersonality] = useState([]);
  const [PersonalityShare, setPersonalityShare] = useState([]);
  const [Loading, setLoading] = useState(false);
  const tok = localStorage.getItem("token");
  const [date1, seDate1] = useState("");
  const [date2, seDate2] = useState("");

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${tok}`,
    },
  };
  const imgReg = useRef(null);
  useEffect(() => {
    const ProfileData = JSON.parse(localStorage.getItem("ProfileData"));
    setPersonality(ProfileData.birthday_report.relationship_report);
    setPersonalityShare(ProfileData.birthday_report.relationshipShareId);
    const d = ProfileData.birthday_report.relationship_report.birthday_1_name
      .split(",")
      .shift();
    seDate1(d);
    const d2 = ProfileData.birthday_report.relationship_report.birthday_2_name
      .split(",")
      .shift();
    seDate2(d2);
    // window.scroll({
    //   top: -50,
    //   left: 0,
    //   behavior: "instant",
    // });

    imgReg.current?.scrollIntoView({
      behavior: "instant",
    });
  }, []);

  return (
    <>
      <ToastContainer />
      {Loading ? <Loader /> : null}
      <div className="container-fluid profileScreen bg-alternaive">
        <div className="container-lg personalityContent  ">
          <div className="row personalityBtn mx-auto fix_width ">
            <div className=" mx-auto " ref={imgReg}>
              <div className="profileUpperpart navMatchbottom1 position-sticky top-0 backColor">
                <div className="  py-3  " onClick={() => navigate(-1)}>
                  <div className="arrow_back   btn-hover">
                    <MdArrowBackIosNew />{" "}
                    <span className="align-middle"> Go Back</span>
                  </div>
                </div>
                <div className="fs-5  py-3 profileHeading ">
                  <RWebShare
                    data={{
                      // text: `https://secretlanguage.network/v1/relationship/share?id=${personality.id}`,
                      url: `https://secretlanguage.network/v1/relationship/share?id=${PersonalityShare}`,
                      title: "Share Relationship Report",
                    }}
                    // onClick={() =>
                    //   toast("url copied")
                    // }
                  >
                    <Icon icon="mdi:share-outline" className="fs-3 btn-hover" />
                  </RWebShare>
                </div>
              </div>
              <div className="col py-2 my-2 mx-2 rounded-3 bg-light text-dark">
                <div className="weekPersonality">
                  <div className="week-blockPersonality ps-2">
                    <span className="week-headerPersonality text-dark text-center fs-6">
                      {personality.birthday_1}
                    </span>
                    <span className="datePersonality text-dark text-center">
                      {date1}
                    </span>
                  </div>
                  <span className="andPersonality text-dark px-3">&amp;</span>
                  <div className="week-blockPersonality pe-2">
                    <span className="week-headerPersonality text-dark text-center fs-6">
                      {personality.birthday_2}
                    </span>
                    <span className="datePersonality text-dark text-center">
                      {date2}
                    </span>
                  </div>
                </div>
                <div className="calledPersonality">IS CALLED</div>
                <div className="image-sectionPersonality ">
                  <img
                    src={personality.image}
                    className="border-iconPersonality"
                    alt=""
                  />
                </div>
                <div className="rel-namePersonality text-dark">
                  {personality.name}
                </div>
                {/* <div className="week2Personality">
                  <div className="week-block2Personality px-4">
                    <span className="date1Personality mx-2 mx-lg-3">
                      {personality.s1}
                    </span>
                    <span className="date1Personality mx-2 mx-lg-3">
                      {personality.s2}
                    </span>
                    <span className="date1Personality mx-2 mx-lg-3">
                      {personality.s3}
                    </span>
                  </div>
                </div> */}
                <div className="idealPersonality text-dark">
                  Ideal for:
                  <span className="reIdealPersonality ms-1 text-capitalize">
                    {personality.ideal_for}{" "}
                  </span>
                </div>
              </div>
              <div className="description-sectionPersonality pt-0">
                <p className="section-titlePersonality my-2 text-capitalize">
                  Personality
                </p>
                <p className="section-descriptionPersonality px-3">
                  {personality.report}
                </p>
              </div>
              <div className="description-sectionPersonality pt-0">
                <p className="section-titlePersonality mb-1  text-capitalize">
                  Advice
                </p>
                <p className="section-descriptionPersonality mb-0 ">
                  {personality.advice}
                </p>
              </div>
              <div className="  pb-5  mb-5">
                <Footer2 />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        <div className="fix_width mx-auto">
          <Navbar3 />
        </div>
      </div>
    </>
  );
};

export default Personality7;
