import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import "./App.css";
import "./pages/Chat/Chatcomponents/style.scss";
import VerifyYourself from "./auth/VerifyYourself";
import Subscription from "./pages/Subscription/Subscription";
import Datafilter from "./pages/MatchesPage/Datafilter";
import ReportSubscription from "./pages/Subscription/ReportSubscription";
import Setting from "./pages/SettingPage/Setting";
import Profile from "./pages/Profile/Profile";
import Personality from "./pages/Profile/Innerpage/Personality";
import Friends from "./pages/Profile/Friends";
import Pendings from "./pages/Profile/Pendings";
import Requests from "./pages/Profile/Requests";
import { UserAuthContextProvider } from "./auth/context/userAuthContext";
import Personality2 from "./pages/Profile/Innerpage/Personality2";
import Personality3 from "./pages/Profile/Innerpage/Personality3";
import Personality4 from "./pages/Profile/Innerpage/Personality4";
import Personality5 from "./pages/Profile/Innerpage/Personality5";
import Personality6 from "./pages/Profile/Innerpage/Personality6";
import Personality7 from "./pages/Profile/Innerpage/Personality7";
import ChangeImg from "./pages/Profile/ChangeImg";
import Userprofile from "./pages/UserProfile/Userprofile";
import Birthdaydata from "./pages/ReportPage/Birthdaydata";
import Breport1 from "./pages/ReportPage/Innerpage/Breport1";
import Breport2 from "./pages/ReportPage/Innerpage/Breport2";
import Breport3 from "./pages/ReportPage/Innerpage/Breport3";
import Breport4 from "./pages/ReportPage/Innerpage/Breport4";
import Breport7 from "./pages/ReportPage/Innerpage/Breport7";
import Relationshipreport from "./pages/ReportPage/RelationshipReport/Relationshipreport";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import CardsMatch from "./pages/MatchesPage/CardsMatch";
import ChatHome from "./pages/Chat/Chatcomponents/ChatHome";
import { AuthContext } from "./auth/context/AuthContext";
import ReportBirthday1 from "./pages/ReportPage/ReportBirthday1";
import SignUpNew from "./auth/Authrization/SignUpNew";
import PhoneNew from "./auth/Authrization/PhoneNew";
import { disableCache } from "@iconify/react";
import Breport5 from "./pages/ReportPage/Innerpage/Breport5";
import Breport6 from "./pages/ReportPage/Innerpage/Breport6";
import Sidebar from "./layout/Sidebars/Sidebar";
import Chat from "./pages/Chat/Chatcomponents/ChatCheck";
import Otp from "./auth/Authrization/Otp";
import CardScreen from "./pages/MatchesPage/CardScreen";
import PrivacyPolicy from "./pages/privacy&Term/PrivacyPolicy";
import Terms from "./pages/privacy&Term/Terms";
import RequestChatUI from "./pages/Chat/Chatcomponents/RequestChatUI";
import UserLocation from "./pages/SettingPage/Location";
import SplitForm from "./StripeForm";
import StripeOneTime from "./StripeOneTime";
import Share from "./pages/Share/share"; 

// Disable all caching
disableCache("all");
function App() {
  const token = localStorage.getItem("token");

  const { currentUser } = useContext(AuthContext);
  const ProtectedRoute = ({ isAllowed, redirectPath = "/", children }) => {
    if (!isAllowed || currentUser === null) {
      return <Navigate to={redirectPath} replace={true} />;
    }
    return children ? children : <Outlet />;
  };
  const ProtectedRoute1 = ({ Component }) => {
    if (token && currentUser) {
      return <Navigate to="/matches" replace={true} />;
    }
    return <Component />;
  };
  const ProtectedRoute2 = ({ Component }) => {
    if (token && currentUser) {
      return <Navigate to="/matches" replace={true} />;
    }
    return <Component />;
  };

  const [darktheme] = useState(true);
  useEffect(() => {
    const systemDarkMode = window.matchMedia("(prefers-color-scheme: dark)");

    const body = document.body;
    if (systemDarkMode.matches === true) {
      body.classList.add("data-main");
      localStorage.setItem("dark", true);
    } else {
      body.classList.remove("data-main");
      localStorage.setItem("dark", false);
    }
  }, [darktheme]);

  // location

  return (
    <div className="App"> 
      <BrowserRouter>
        <UserAuthContextProvider>
          <Routes>
            <Route
              path="/"
              element={<ProtectedRoute1 Component={SignUpNew} />}
            />
            <Route
              path="/auth-Phone"
              element={<ProtectedRoute2 Component={PhoneNew} />}
            />
            <Route path="/otp" element={<Otp />} />

            <Route path="/verify-yourself" element={<VerifyYourself />} />
            <Route element={<ProtectedRoute isAllowed={!!token} />}>
              <Route path="/signUp" element={<SignUpNew />} />
              <Route path="/reports" element={<ReportBirthday1 />} />
              <Route path="/matches" element={<CardScreen />} />
              <Route path="/datafilter" element={<Datafilter />} />
              <Route
                path="/report-subscription"
                element={<ReportSubscription />}
              />
              <Route path="/profilesetting" element={<Setting />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/friends" element={<Friends />} />
              <Route path="/pendings" element={<Pendings />} />
              <Route path="/requests" element={<Requests />} />
              <Route path="/changeimage" element={<ChangeImg />} />
              <Route path="/userprofile" element={<Userprofile />} />
              <Route path="/user-day_report" element={<Personality />} />
              <Route path="/user-week_report" element={<Personality2 />} />
              <Route path="/user-month_report" element={<Personality3 />} />
              <Route path="/user-season_report" element={<Personality4 />} />
              <Route path="/user-path_report" element={<Personality5 />} />
              <Route path="/user-way_report" element={<Personality6 />} />
              <Route
                path="/user-relationship_report"
                element={<Personality7 />}
              />
              <Route path="/birthdayreport" element={<Birthdaydata />} />
              <Route path="/birth-dayreport" element={<Breport1 />} />
              <Route path="/birth-weekreport" element={<Breport2 />} />
              <Route path="/birth-monthreport" element={<Breport3 />} />
              <Route path="/birth-seasonreport" element={<Breport4 />} />
              <Route path="/birth-pathreport" element={<Breport5 />} />
              <Route path="/birth-wayreport" element={<Breport6 />} />
              <Route path="/birth-relationreport" element={<Breport7 />} />
              <Route
                path="/relationship_report"
                element={<Relationshipreport />}
              />
              <Route path="/location" element={<UserLocation />} />
              <Route path="/chat" element={<ChatHome />} />
              <Route path="/chatHome" element={<Chat />} />
              <Route path="/Reqchat" element={<RequestChatUI />} />

              <Route path="/subscription" element={<SplitForm />} />
              <Route path="/StripeOneTime" element={<StripeOneTime />} />
            </Route>
            <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
            <Route path="/terms" element={<PrivacyPolicy />}></Route>
            <Route path="/download" element={<Share />}></Route>
            <Route path="/*" element={<Unauthorized />}></Route>
          </Routes>
        </UserAuthContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
