/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../Profile/profile.css";
import Footer2 from "../../../layout/Footer/Footer2";
import { getApi } from "../../../Api/Api";
import { API_PATH } from "../../../Api/ApiPAth";
import Loader from "../../../layout/Loader/Loader";
import Navbar3 from "../../../layout/BottomNavbar/Navbar3";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "@iconify/react";
import { RWebShare } from "react-web-share";

const Breport2 = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const [personality, setPersonality] = useState([]);
  const [famous, setFamous] = useState([]);
  const [Loading, setLoading] = useState(false);
  const tok = localStorage.getItem("token");
  const date = localStorage.getItem("bdate");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${tok}`,
    },
  };
  const imgReg = useRef(null);
  useEffect(() => {
    async function PersonalityData2() {
      setLoading(true);
      try {
        const res = await getApi(
          `${API_PATH.BIRTHDAYPAID.BIRTH_WEEK}/${id}`,
          {
            birthday: date,
          },
          axiosConfig
        );
        if (res.status) {
          setPersonality(res.data);
          setFamous(res.data.famous);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    // PersonalityData2();
    const ReportData = JSON.parse(
      localStorage.getItem("PurchasedBirthdayReport")
    );
    setPersonality(ReportData.week_report);
    setFamous(ReportData.week_report.famous);
    imgReg.current?.scrollIntoView({
      behavior: "instant",
    });
  }, []);
  const ad = personality.advice;

  return (
    <>
      <ToastContainer />
      {Loading ? <Loader /> : null}
      {/* <div className="headerBAr">
                <Header />
            </div> */}
      <div className="container-fluid profileScreen">
        {/* <div className="profileUpperpart headerBAr pt-2 ">
                    <div className="col fs-3 text-light text-end profileHeading headerBAr " id='ForShare' >
                        <CopyToClipboard text={`https://idealfor.xyz/v1/week/share?id=${personality.id}`} onCopy={() => toast('url copied')}>
                            <FiShare2 className='boxSquare ' />
                        </CopyToClipboard>
                    </div>
                </div> */}

        <div className="container-lg personalityContent">
          <div className="row personalityBtn mx-auto fix_width">
            <div className="  mx-auto" ref={imgReg}>
              <div className="position-sticky top-0 backColor">
                <div className="profileUpperpart navMatchbottom1 ">
                  <div className="  py-3  " onClick={() => navigate(-1)}>
                    <div className="arrow_back  btn-hover">
                      <MdArrowBackIosNew />{" "}
                      <span className="align-middle"> Go Back</span>
                    </div>
                  </div>
                  <div className="fs-5  py-3 profileHeading ">
                    <RWebShare
                      data={{
                        // text: `https://secretlanguage.network/v1/week/share?id=${personality.id}`,
                        url: `https://secretlanguage.network/v1/week/share?id=${personality.id}`,
                        title: "Share Week Report",
                      }}
                      // onClick={() =>
                      //   toast("url copied")
                      // }
                    >
                      <Icon
                        icon="mdi:share-outline"
                        className="fs-3 btn-hover"
                      />
                    </RWebShare>
                  </div>
                </div>
                {personality.famous ? (
                  <div className="col personalityBtns pb-2">
                    <ul className="nav  px-auto switch_div w-100">
                      <li className="nav-item tab-all w-50">
                        <a
                          className="nav-link  show py-1 px-3 active"
                          href="#tab1"
                          data-bs-toggle="tab"
                          onClick={() =>
                            imgReg.current?.scrollIntoView({
                              behavior: "instant",
                            })
                          }
                        >
                          <button className="side01  fs-5 fw-bold">
                            Personality
                          </button>
                        </a>
                      </li>
                      <li className="nav-item tab-all p-l-20 w-50">
                        <a
                          className="nav-link  py-1 px-4 "
                          href="#tab2"
                          onClick={() =>
                            imgReg.current?.scrollIntoView({
                              behavior: "instant",
                            })
                          }
                          data-bs-toggle="tab"
                        >
                          <button className="side01 fs-5 fw-bold">
                            Famous
                          </button>
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              <div className="col pb-2">
                <div className="col imagePersonality">
                  <div className="col bg-light py-3 m-2 rounded-3">
                    <p className="mb-0 text-dark">{personality.date_span}</p>

                    <img
                      src={personality.image}
                      className="personalityImg"
                      alt=""
                    />

                    <div className="fs-4 fw-semibold text-dark">
                      {personality.name_long}
                    </div>
                    {/* <div className="col fs-6 ImageContent pt-2">
                      {" "}
                      {personality.s1}, {personality.s2}, {personality.s3},{" "}
                      {personality.w1}, {personality.w2}, {personality.w3}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div className="tab-pane fontawesome-demo active" id="tab1">
                  <div className="">
                    <div className="fs-3 fw-bold personalityPara pt-1 pb-2 text-center">
                      Personality
                    </div>
                    <p className="  mb-0 line-para"> {personality.report} </p>
                    <p className="  mb-0 line-para">{personality.numbers} </p>
                    <p className=" mb-0 line-para">{personality.archetype} </p>
                    <p className="  mb-0 line-para">{personality.health} </p>
                  </div>
                  <div className="">
                    <div className="fs-3 fw-bold personalityPara pt-3 ">
                      Advice
                    </div>
                    <div className=" line-para  content-match-li">
                      <p className="fs-6 advice-p mb-0">{ad}</p>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tab2">
                  <div className="container-fluid">
                    <div className="text-center">
                      {famous.map((q) => (
                        <div
                          className="py-5 famousbottom mx-2 mb-3 rounded-3"
                          key={q.id}
                        >
                          <img
                            src={q.image}
                            className="rounded-circle"
                            height={"60px"}
                            width={"60px"}
                            alt=""
                          />
                          <div className="fs-5  text-light">{q.sln}</div>
                          <div className="fs-6 text-light">{q.name}</div>
                          <span className="famousContent text-capitalize">
                            {q.age}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-5 mb-5">
                <Footer2 />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        <div className="fix_width mx-auto">
          <Navbar3 />
        </div>
      </div>
    </>
  );
};

export default Breport2;
