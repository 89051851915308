import React, { useMemo, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { error } from "jquery";
import axios from "axios";
import Modal from "react-modal";
import { API_PATH } from "./Api/ApiPAth";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "12px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const SplitForm = () => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [modalIsOpenName, setIsOpenName] = React.useState(false);

  function closeModalName() {
    setIsOpenName(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (!stripe || !elements) {
        setLoading(false);
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });
      payload.email = email;
      payload.name = name;
      //   payload.product_id = "prod_PWUrmEVkHkegOH"
      payload.paymentId = payload.paymentMethod.id;
      console.log(payload);
      const mydata = await stripeApiHandler(payload);
      console.log(mydata, "payload");
      const confirm = await stripe.confirmCardPayment(mydata.clientSecret);

      if (confirm.error) {
        setLoading(false);
        return alert("Payment unsuccessful!");
      }
      console.log(confirm, "lllll");
      console.log(confirm.paymentIntent);
      confirm.paymentIntent.sub_id = mydata.sub_id;
      let objJsonStr = JSON.stringify(confirm.paymentIntent);
      var encoded = btoa(objJsonStr);
      await addReciept(encoded);
      setLoading(false);
      setIsOpenName(false);
      // alert("Payment Successful! Subscription active.");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  async function stripeApiHandler(data) {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.post(
        "https://secretlanguage.network/v1/payment/createStripeSubscription",
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      //   const res = await postApi(API_PATH.PAYMENT.CREATE_SUBSCRIPTION,data)
      console.log(res.data);
      return res.data;
    } catch (err) {
      console.error(err);
      throw err;
      //   res.status(500).json({ message: "Internal server error" });
    }
  }

  async function addReciept(reciept) {
    console.log(reciept)
    try {
      const token = localStorage.getItem("token");
      const res = await axios.post(
        "https://secretlanguage.network/v1/" +
          API_PATH.SUBSCRIPTION.SUBSCRIPTION,
        {
          receipt: reciept,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status) {
        console.log(res.data, "kkkk");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <button
        onClick={() => {
          setIsOpenName(true);
        }}
      >
        hellll
      </button>
      <Modal
        isOpen={modalIsOpenName}
        onRequestClose={closeModalName}
        style={customStyles}
        contentLabel="Example Modal Connections"
        ariaHideApp={false}
      >
        <div className="DemoWrapper">
          <div className="Demo">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <h4 className="d-flex justify-content-between align-items-center mb-3">
                  <span className="text-muted">Pay with card</span>
                </h4>
                <div className="col-md-6 mb-3">
                  <label htmlFor="cc-name">Name on card</label>
                  <input
                    id="cc-name"
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="cc-email">Email</label>
                  <input
                    id="cc-email"
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="cc-number">Card Number</label>
                  <CardNumberElement
                    id="cc-number"
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="expiry">Expiration Date</label>
                  <CardExpiryElement
                    id="expiry"
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="cvc">CVC</label>
                  <CardCvcElement
                    id="cvc"
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>

              <hr className="mb-4" />
              <button
                className="btn btn-dark w-100"
                type="submit"
                disabled={loading}
              >
                Submit
                {/* {loading ? <div className="spinner-border spinner-border-sm text-light" role="status"></div> : `PAY ₹${props.amount}`} */}
              </button>
              {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SplitForm;
