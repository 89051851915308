import React, { useState } from "react";
import "./SignUp.css";
import img1 from "../../assets/NewTop.png";
import img2 from "../../assets/taylorGif.gif";
import { FaApple, FaPhoneAlt } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import Footer2 from "../../layout/Footer/Footer2";
import { ToastContainer, toast } from "react-toastify";

let cdnUri = "https://www.youtube.com/shorts/0Ug2Eyl-Hrw";

const SignUpNew = () => {
  const navigate = useNavigate();
  const [setErr] = useState(false);

  function openLink() {
    window.open(cdnUri, "_newtab");
  }

  //Google Firebase

  const dummySignIN = async (navicheck) => {
    if (navicheck.email) {
      try {
        const res = await postApi(API_PATH.AUTH.LOGIN_MAIL, {
          email: navicheck.email,
        });
        if (res) {
          localStorage.setItem("id", res.data.id);
          localStorage.setItem("token", res.data.token);
          //  navigate("/matches");
          window.location.replace("/matches");
        }
      } catch (error) {
        navigate("/verify-yourself");
      }
    } else {
      toast.error(
        "Please try another login option or attempt to sign in with a different Apple ID."
      );
    }
  };
  const auth = getAuth();

  const provider = new GoogleAuthProvider();
  const handleSubmitWithGoogle = async (e) => {
    localStorage.setItem("PhoneNumber", "false");
    try {
      signInWithPopup(auth, provider).then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        GoogleAuthProvider.credentialFromResult(result);

        // The signed-in user info.

        //Update profile
        updateProfile(auth.currentUser, {
          photoURL: `${result._tokenResponse.photoUrl}`,
        });
        if (result._tokenResponse) {
          dummySignIN(result._tokenResponse);
          // CreatedMailDatabase(result._tokenResponse)
          localStorage.setItem(
            "mail_data",
            JSON.stringify(result._tokenResponse)
          );
          //
        }
      });
    } catch (err) {
      setErr(true);
    }
  };

  // Apple

  const providerApple = new OAuthProvider("apple.com");
  providerApple.addScope("name");
  providerApple.addScope("email");
  const SignInWithApple = () => {
    signInWithPopup(auth, providerApple)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        // console.log(result);
        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        if (result._tokenResponse) {
          dummySignIN(result._tokenResponse);
          // CreatedMailDatabase(result._tokenResponse)
          localStorage.setItem(
            "mail_data",
            JSON.stringify(result._tokenResponse)
          );
          //
        }

        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        console.log(error);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

        // ...
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid  mx-0 px-0 Match-screen">
        <div className="row mx-auto fix_width">
          <div className=" mx-auto py-3">
            <div className="imgDiv pb-2  ">
              <div className="mx-auto">
                <h1 className="Head-one">idealfor</h1>
                <p className="font13 title1 px-0  ">
                  It’s both the Secret Language of Birthday’s and Relationship
                  books in your pocket!
                </p>
                <div className="img1 text-center">
                  <img src={img1} alt="" className="phone_input_width" />
                </div>
                <div className="img2">
                  {/* <img src={img2} alt="" className="imgProp2" /> */}
                </div>
              </div>

              <h2 className="font13 title2 phone_input_width mx-auto mt-2">
                Based on Gary Goldschneider's "The Secret Language" series,
                drawing from a 40-year study of over 20,000 people, offers
                personality reports based on birth dates. These reports,
                combining astrology, numerology, and tarot, are recognized for
                their high accuracy over two decades of use.
              </h2>
            </div>
            <div className="signUpbtn d-flex flex-column justify-content-center align-items-center fix_width w-100">
              <button
                className="btn-signup px-2 phone_input_width d-flex justify-content-center align-items-center"
                onClick={() => navigate("/auth-Phone")}
              >
                {" "}
                <FaPhoneAlt className="icon me-2 ms-0 " />{" "}
                <p className="mb-0  d-inline" style={{ verticalAlign: "sub" }}>
                  Continue With Phone
                </p>
              </button>
              <button
                className="btn-signup px-2 my-2 phone_input_width my-3 d-flex justify-content-center align-items-center"
                onClick={() => SignInWithApple()}
              >
                <FaApple className="icon me-2 ms-0" />
                <p
                  className="mb-0 align-sub d-inline "
                  style={{ verticalAlign: "sub" }}
                >
                  {" "}
                  Continue With Apple
                </p>
              </button>
              <button
                className="btn-signup px-2 mb-2 phone_input_width   d-flex justify-content-center align-items-center"
                onClick={() => handleSubmitWithGoogle()}
              >
                {" "}
                <FcGoogle className="icon me-2 ms-0" />{" "}
                <p
                  className="mb-0 align-sub d-inline "
                  style={{ verticalAlign: "sub" }}
                >
                  Continue With Google
                </p>{" "}
              </button>
            </div>

            <div className="img1 text-center" onClick={openLink}>
              <p className="font13 title3  px-0">
                “It’s accurate, it’s completely accurate” - Taylor Swift
              </p>
              <img src={img2} alt="" className="phone_input_width" />
            </div>
            <div className=" mt-5">
              <p className="text-footer mb-0 ">
                By using the app, you agree to our{" "}
                <a
                  href="/terms"
                  target="_blank"
                  style={{ fontSize: "13px" }}
                  className="font13 text-decoration-underline line-para"
                  rel="noreferrer"
                >
                  Term
                </a>
                <span
                  className="line-para text-decoration-underline "
                  style={{ fontSize: "13px" }}
                >
                  {" "}
                  &{" "}
                </span>
                {/* https://www.privacypolicies.com/live/8fafa61f-59d3-4bcf-8921-cf04d36f8f98 */}
                <a
                  href="/privacyPolicy"
                  target="_blank"
                  style={{ fontSize: "13px" }}
                  className="font13 text-decoration-underline line-para"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <Footer2 />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpNew;
