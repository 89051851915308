/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { getApi, postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import { useForm } from "react-hook-form";
import axios from "axios";
import Loader from "../../layout/Loader/Loader";
import "./report.css";
import Footer2 from "../../layout/Footer/Footer2";
import Navbar3 from "../../layout/BottomNavbar/Navbar3";
import ButtonLoader from "../../layout/Loader/ButtonLoader";
import Footer from "../../layout/Footer/Footer";
import { RxCross1 } from "react-icons/rx";
import Modal from "react-modal";
import payWhite from "../../assets/pay-white.png";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { ToastContainer, toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "800 !important",
    backgroundColor: "white !important",
    width: "450px",
    padding: "10px",
  },
};

const prodConfig = {
  baseURL: `https://api.stripe.com`,
  withCredentials: true,
};

const axiosInstance = axios.create(prodConfig);

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

let mydate = new Date();
mydate.setFullYear(mydate.getFullYear() - 16);
const ReportBirthday1 = () => {
  const { handleSubmit } = useForm();
  const { handleSubmit: handleSubmitRelation } = useForm();
  const navigate = useNavigate();
  useEffect(() => {
    async function SettingData() {
      try {
        const res = await getApi(API_PATH.SETTING.USER_SETTING);
        if (res.status) {
          localStorage.setItem("birthday1", res.data.date_name.slice(0, -6));
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    SettingData();
  }, []);
  const [endDate] = useState(new Date());
  const [Loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [myBirthday, setmyBirthday] = useState(new Date());
  const [sampleDate] = useState(new Date());
  const My_Birthday = myBirthday
    ? myBirthday.toLocaleDateString("en-us", {
        day: "numeric",
        month: "long",
      })
    : sampleDate.toLocaleDateString("en-us", { day: "numeric", month: "long" });
  const date = startDate
    ? startDate.toLocaleDateString("en-us", { day: "numeric", month: "long" })
    : sampleDate.toLocaleDateString("en-us", { day: "numeric", month: "long" });
  const [relationstartDate, setRelationStartDate] = useState(new Date());
  const relationshipdate = relationstartDate
    ? relationstartDate.toLocaleDateString("en-us", {
        day: "numeric",
        month: "long",
      })
    : sampleDate.toLocaleDateString("en-us", { day: "numeric", month: "long" });
  const bdate = localStorage.getItem("bdate");
  const birthday = localStorage.getItem("birthday1");
  const birthdayNew = birthday;
  const rdate = localStorage.getItem("rdate");
  const [recipt, setRecipt] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const sessionID = queryParams.get("session_id");
  const SubsessionID = queryParams.get("Sub_session_id");
  const [Subrecipt, setSubRecipt] = useState("");
  const [SubModal, setSubModal] = useState(false);
  const onSubmitBithday = (data) => {
    localStorage.setItem("bdate", date);
    if (bdate) {
      birthreport();
    }
  };
  const onSubmitRelation = (data) => {
    localStorage.setItem("rdate", relationshipdate);
    if (rdate) {
      relationreport();
    }
  };
  const birthreport = async (e) => {
    localStorage.setItem("bdate", date);
    setLoading(true);
    try {
      const res = await postApi(API_PATH.REPORT.BIRTH_REPORT, {
        birthday: date,
      });
      if (res.data) {
        localStorage.setItem(
          "PurchasedBirthdayReport",
          JSON.stringify(res.data)
        );
        navigate("/birthdayreport");
        setPaymentLoading(false);
        setLoading(false);
      }
    } catch (error) {
      if (error.response.data.statusCode === 422) {
        // navigate("/report-subscription");
        setSubModal(true);
        localStorage.setItem("reportType", "birthday");
        setLoading(false);
      }
    }
  };

  const relationreport = async (e) => {
    localStorage.setItem("rdate", relationshipdate);
    localStorage.setItem("rBdate", My_Birthday);
    setLoading(true);
    try {
      const res = await postApi(API_PATH.REPORT.RELATIONSHIP_REPORT, {
        birthday_1: My_Birthday,
        birthday_2: relationshipdate,
      });
      if (res.data) {
        navigate("/relationship_report", { state: res.data });
        setLoading(false);
      }
    } catch (error) {
      // navigate("/report-subscription");
      setSubModal(true);
      localStorage.setItem("reportType", "relationship");
      setLoading(false);
    }
  };

  const sID = process.env.REACT_APP_STRIPE_SECRET_KEY;
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const res = await axiosInstance.get(
          `/v1/checkout/sessions/${sessionID}`,
          {
            headers: {
              Authorization: `Bearer ${sID}`,
            },
          }
        );
        if (res.status) {
          let objJsonStr = JSON.stringify(res.data);
          var encoded = btoa(objJsonStr);
          setRecipt(encoded);
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (sessionID) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function birthdayData() {
      const bdate = localStorage.getItem("bdate");
      setLoading(true);
      try {
        const res = await postApi(API_PATH.REPORT.GET_REPORT, {
          birthday: bdate,
          receipt: recipt,
        });
        if (res.status) {
          // birthreport();
          const res1 = await postApi(API_PATH.REPORT.BIRTH_REPORT, {
            birthday: bdate,
          });
          if (res1.data) {
            localStorage.setItem(
              "PurchasedBirthdayReport",
              JSON.stringify(res1.data)
            );
            navigate("/birthdayreport");
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }

    async function RelationData() {
      const rdate = localStorage.getItem("rdate");
      const rbdate = localStorage.getItem("rBdate");
      setLoading(true);
      try {
        const res = await postApi(API_PATH.REPORT.GET_REPORT, {
          birthday_1: rbdate,
          birthday_2: rdate,
          receipt: recipt,
        });
        if (res.status) {
          // relationreport();
          const res1 = await postApi(API_PATH.REPORT.RELATIONSHIP_REPORT, {
            birthday_1: rbdate,
            birthday_2: rdate,
          });
          if (res1.data) {
            navigate("/relationship_report", { state: res1.data });
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }

    const reprtType = localStorage.getItem("reportType");

    if (reprtType === "birthday" && recipt) {
      birthdayData();
    }
    if (reprtType === "relationship" && recipt) {
      RelationData();
    }
  }, [recipt]);

  // subscription

  const getStat = async () => {
    try {
      const res = await getApi(API_PATH.SUBSCRIPTION.CHECK_StATUS);
      if (res.status) {
      }
    } catch (error) {}
  };

  const tab = localStorage.getItem("tab");

  function afterOpenSub() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModalSub() {
    setSubModal(false);
    setStartPayment(false);
    setPaymentLoading(false);
  }
  const pay = () => {
    // window.open(
    //   "https://apps.apple.com/us/app/idealfor/id1558133582",
    //   "_blank"

    // );
    // window.open("https://buy.stripe.com/test_7sIaHo9AV1492yseUW", "_blank"); /Subscription
    window.open("https://buy.stripe.com/test_8wM5mV1Me7iranm144", "_blank");
  };

  useEffect(() => {
    const handleCalendarOpen = (id) => {
      const inputElement = document.getElementById(id);
      if (inputElement) {
        inputElement.blur();
        document.body.focus();
      }
    };

    const setReadOnly = (id) => {
      const inputElement = document.getElementById(id);
      if (inputElement) {
        inputElement.readOnly = true;
      }
    };

    setReadOnly("datepicker-input1");
    setReadOnly("datepicker-input2");
    setReadOnly("datepicker-input3");

    handleCalendarOpen("datepicker-input1");
    handleCalendarOpen("datepicker-input2");
    handleCalendarOpen("datepicker-input3");
  }, []);

  // Stripe New Flow

  const [startpayment, setStartPayment] = useState(false);
  const [Paymentloading, setPaymentLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const [isValidName, setisValidName] = useState(true);
  const [isValidEmail, setisValidEmail] = useState(true);
  const [isValidCard, setisValidCard] = useState(true);
  const [isValidExpiry, setisValidExpiry] = useState(true);
  const [isValidCvc, setisValidCvc] = useState(true);
  const handleSubmitPayment = async (event) => {
    event.preventDefault();

    var nameField = document.getElementById("cc-name");
    var nameValue = nameField.value.trim();
    if (name === "") {
      nameField.focus();
      setisValidName(false);
    } else {
      setisValidName(true);
    }
    var emailField = document.getElementById("cc-email");
    var emailValue = emailField.value.trim();
    if (email === "") {
      // emailField.focus();
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setisValidEmail(false);
    } else {
      setisValidEmail(true);
    }

    const cardNumber = elements.getElement("cardNumber");
    const cardExpiry = elements.getElement("cardExpiry");
    const cardCvc = elements.getElement("cardCvc");
    const isInvalidCard = cardNumber._invalid;
    const isEmptyCard = cardNumber._empty;

    const isInvalidExpiry = cardExpiry._invalid;
    const isEmptyExpiry = cardExpiry._empty;

    const isInvalidCvc = cardCvc._invalid;
    const isEmptyCvc = cardCvc._empty;

    const isValidCard = !(isEmptyCard || isInvalidCard);
    const isValidExpiry = !(isInvalidExpiry || isEmptyExpiry);
    const isValidCvc = !(isInvalidCvc || isEmptyCvc);
    setisValidCard(isValidCard);
    setisValidExpiry(isValidExpiry);
    setisValidCvc(isValidCvc);
    if (!stripe || !elements || !isValidCard || !isValidExpiry || !isValidCvc) {
      return;
    }

    setPaymentLoading(true);
    setErrorMsg("");

    const paymentMethodObj = {
      type: "card",

      billing_details: {
        name,
        email,
      },
    };

    const mydata = await stripeApiHandler(paymentMethodObj.billing_details);
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      mydata.clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: name,
            email: email,
          },
        },
      }
    );
    if (error) {
      setPaymentLoading(false);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      toast.success("Report Purchased");
      let objJsonStr = JSON.stringify(paymentIntent);
      var encoded = btoa(objJsonStr);

      const reprtType = localStorage.getItem("reportType");
      if (reprtType === "birthday") {
        const bdate = localStorage.getItem("bdate");
        setLoading(true);
        try {
          const res = await postApi(API_PATH.REPORT.GET_REPORT, {
            birthday: bdate,
            receipt: encoded,
          });
          if (res.status) {
            // birthreport();
            const res1 = await postApi(API_PATH.REPORT.BIRTH_REPORT, {
              birthday: bdate,
            });
            if (res1.data) {
              localStorage.setItem(
                "PurchasedBirthdayReport",
                JSON.stringify(res1.data)
              );
              navigate("/birthdayreport");
            }
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
      if (reprtType === "relationship") {
        const rdate = localStorage.getItem("rdate");
        const rbdate = localStorage.getItem("rBdate");
        setLoading(true);
        try {
          const res = await postApi(API_PATH.REPORT.GET_REPORT, {
            birthday_1: rbdate,
            birthday_2: rdate,
            receipt: encoded,
          });
          if (res.status) {
            const res1 = await postApi(API_PATH.REPORT.RELATIONSHIP_REPORT, {
              birthday_1: rbdate,
              birthday_2: rdate,
            });
            if (res1.data) {
              navigate("/relationship_report", { state: res1.data });
            }
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    } else {
      // setMessage("An unexpected error occured.");
      setPaymentLoading(false);
    }
  };

  async function stripeApiHandler(data) {
    try {
      const res = await postApi(
        API_PATH.SUBSCRIPTION.CREATE_ONE_TIME_PAYMENT,
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  return (
    <div>
      <ToastContainer />
      <div className="container-fluid   text-center overflow-hidden Match-screen  fix_width">
        <div className=" mx-auto">
          <div className="row  mx-auto px-0">
            <div className=" my-3">
              <p
                className="mb-1 fs-6 text-start"
                onClick={() => navigate("/reports")}
              >
                Reports
              </p>
              <div className="text-center">
                <p
                  className=" fw-bold mb-0"
                  style={{ fontSize: "40px", lineHeight: "35px" }}
                >
                  idealfor
                </p>
                <p className="  text-center mb-1 mt-1">
                  Secret language of Birthday
                </p>
                <p className="mb-1">
                  You can look up the personality of anyone you know from their
                  birthday!
                </p>
              </div>
            </div>

            <div className="row mx-auto">
              <div className="col pb-3 personalityBtns">
                <ul className="nav  px-auto switch_div w-100">
                  <li className="nav-item tab-all w-50">
                    <a
                      className={
                        tab === "tab1" || tab === null
                          ? "nav-link  show py-2 px-3 px-sm-4 px-md-4 px-lg-4 px-xl-4 px-xxl-4 active"
                          : "nav-link  show py-2 px-3 px-sm-4 px-md-4 px-lg-4 px-xl-4 px-xxl-4 "
                      }
                      href="#tab1"
                      data-bs-toggle="tab"
                      onClick={() => localStorage.setItem("tab", "tab1")}
                    >
                      <button className="side01 fw-bold ">Birthday</button>
                    </a>
                  </li>
                  <li className="nav-item tab-all p-l-20 w-50">
                    <a
                      className={
                        tab === "tab2"
                          ? "nav-link  show py-2 px-3 px-sm-4 px-md-4 px-lg-4 px-xl-4 px-xxl-4 active"
                          : "nav-link  show py-2 px-3 px-sm-4 px-md-4 px-lg-4 px-xl-4 px-xxl-4 "
                      }
                      href="#tab2"
                      data-bs-toggle="tab"
                      onClick={() => localStorage.setItem("tab", "tab2")}
                    >
                      <button className="side01 fw-bold ">Relationship</button>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content" style={{ height: "300px" }}>
                <div
                  className={
                    tab === "tab1" || tab === null
                      ? "tab-pane fontawesome-demo active"
                      : "tab-pane fontawesome-demo "
                  }
                  id="tab1"
                >
                  <div className="container-fluid">
                    <div className="row mx-0">
                      <div className="col  d-flex justify-content-center birthInp px-0">
                        <form
                          className="birthForm"
                          onSubmit={handleSubmit(onSubmitBithday)}
                          style={{ width: "100%" }}
                        >
                          <div className="">
                            <DatePicker
                              id="datepicker-input1"
                              className="inputUsername text-center py-3 px-5 w-100 my-3"
                              placeholderText={"Birthday Date"}
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              dateFormat="MMMM dd"
                              showMonthDropdown
                              dropdownMode="select"
                              required
                              onFocus={(e) => e.target.blur()}
                            />
                          </div>

                          {Loading == true ? (
                            <button
                              className=" btn_common  px-5 rounded-5 mt-2 w-100"
                              style={{ height: "52px" }}
                            >
                              <ButtonLoader />
                            </button>
                          ) : (
                            <button
                              className=" btn_common  px-5 rounded-5 mt-2 w-100"
                              style={{ height: "52px" }}
                              onClick={() => {
                                localStorage.setItem("bdate", date);
                                birthreport();
                              }}
                            >
                              View Report
                            </button>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    tab === "tab2"
                      ? "tab-pane fontawesome-demo active"
                      : "tab-pane fontawesome-demo "
                  }
                  id="tab2"
                >
                  <div className="container-fluid">
                    <div className="text-center">
                      <div className="row mx-0">
                        <div className="col d-flex justify-content-center px-0">
                          <form
                            className="birthForm"
                            onSubmit={handleSubmitRelation(onSubmitRelation)}
                            style={{ width: "100%" }}
                          >
                            <div className="">
                              <p className=" birthName mb-1 text-start">
                                {" "}
                                Your Birthday
                              </p>
                              <DatePicker
                                id="datepicker-input2"
                                className=" inputUsername py-3 px-5 text-center w-100"
                                placeholderText={"2nd person Birthday Date"}
                                selected={myBirthday}
                                onChange={(date) => setmyBirthday(date)}
                                dateFormat="MMMM dd "
                                showMonthDropdown
                                dropdownMode="select"
                              />

                              <p className=" birthName mt-2 mb-1 text-start">
                                {" "}
                                Companion's Birthday
                              </p>

                              <DatePicker
                                id="datepicker-input3"
                                className=" inputUsername px-5 py-3 mb-2 text-center w-100"
                                placeholderText={"2nd person Birthday Date"}
                                selected={relationstartDate}
                                onChange={(date) => setRelationStartDate(date)}
                                dateFormat="MMMM dd "
                                showMonthDropdown
                                dropdownMode="select"
                              />
                            </div>
                            {Loading == true ? (
                              <button
                                className=" btn_common  px-5 rounded-5 mt-3 w-100"
                                style={{ height: "52px" }}
                              >
                                <ButtonLoader />
                              </button>
                            ) : (
                              <button
                                className="btn_common  px-5   mt-3 rounded-5 w-100"
                                style={{ height: "52px" }}
                                type="submit"
                                onClick={() => relationreport()}
                              >
                                View Report
                              </button>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer2 />
          </div>
        </div>

        <div className="row ">
          <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
            <div className="fix_width  mx-auto">
              <Navbar3 />
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={SubModal}
        onAfterOpen={afterOpenSub}
        onRequestClose={closeModalSub}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="text-center form-width">
          <div className="btn-hover text-end" onClick={() => closeModalSub()}>
            <RxCross1 className=" btn-hover" />
          </div>
          <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
            <div className="container-xxl">
              <div className="row suv mx-0 ">
                {startpayment === false ? (
                  <div className="px-0">
                    <div className="col  subcription-bg ">
                      <div className="mb-5">
                        <img
                          src={payWhite}
                          alt=""
                          className="img_clr_sub"
                          width={250}
                        />
                        <p className="mb-0   fs-4">Unlock each report!</p>
                        <p className="mb-0   fs-6">
                          Pay only 99 cents to unlock each report
                        </p>
                      </div>

                      <div className="d-grid justify-content-center my-2">
                        <button
                          className="sub-btn-1 mb-3 line-para"
                          onClick={() => {
                            setStartPayment(true);
                          }}
                          style={{
                            backgroundColor: "#8270FF",
                            borderColor: "#8270FF",
                            color: "#fff",
                          }}
                        >
                          Unlock
                        </button>
                        <button
                          className="sub-btn-2 "
                          onClick={() => closeModalSub()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col  subcription-bg ">
                    <h4 className="d-flex justify-content-center align-items-center mb-3 text-center">
                      Pay with card
                    </h4>
                    <form onSubmit={handleSubmitPayment}>
                      <div className="row text-start">
                        <div
                          className="col-12 mb-3"
                          style={{ minHeight: "90px" }}
                        >
                          <label htmlFor="cc-name">Name on card</label>
                          <input
                            id="cc-name"
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          {!isValidName ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              Invalid Name
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className="col-12 mb-3"
                          style={{ minHeight: "90px" }}
                        >
                          <label htmlFor="cc-email">Email</label>
                          <input
                            id="cc-email"
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {!isValidEmail ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              Invalid Email Address
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="row text-start">
                        <div
                          className="col-md-12 mb-3"
                          style={{ minHeight: "90px" }}
                        >
                          <label htmlFor="cc-number">Card Number</label>
                          <CardNumberElement
                            id="cc-number"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                          />
                          {!isValidCard ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              Invalid Card Number
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="row text-start">
                        <div
                          className="col-6 mb-3"
                          style={{ minHeight: "90px" }}
                        >
                          <label htmlFor="expiry">Expiration Date</label>
                          <CardExpiryElement
                            id="expiry"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                          />
                          {!isValidExpiry ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              Invalid Expiration Date
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className="col-6 mb-3"
                          style={{ minHeight: "90px" }}
                        >
                          <label htmlFor="cvc">CVC</label>
                          <CardCvcElement
                            id="cvc"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                          />
                          {!isValidCvc ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {" "}
                              Invalid CVC
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <hr className="mb-4" />
                      <p style={{ fontSize: "12px" }}>
                        Note - We do not store your CVV/CVC number
                      </p>
                      {Paymentloading === false ? (
                        <button
                          type="submit"
                          className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                          style={{ height: "45px" }}
                        >
                          Pay
                        </button>
                      ) : (
                        <button
                          className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                          style={{ height: "45px" }}
                        >
                          <ButtonLoader />
                        </button>
                      )}
                      {errorMsg && (
                        <div className="text-danger mt-2">{errorMsg}</div>
                      )}
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReportBirthday1;
