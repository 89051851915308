import React from "react";
import "./relation.css";
import { useNavigate } from "react-router";
import { MdArrowBackIosNew } from "react-icons/md";
import Navbar3 from "../../../layout/BottomNavbar/Navbar3";
import Footer2 from "../../../layout/Footer/Footer2";
import { ToastContainer, toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { RWebShare } from "react-web-share";
const Relationshipreport = () => {
  const location = useLocation();
  const relationReport = location.state;
  const navigate = useNavigate();

  return (
    <>
      {" "}
      <ToastContainer />
      <div className="container-fluid profileScreen ">
        <div className="container-lg personalityContent  ">
          <div className="row personalityBtn mx-auto fix_width ">
            <div className=" mx-auto">
              <div className="profileUpperpart navMatchbottom1  py-1 ">
                <div
                  className=" fs-6 pt-2 profileHeading navMatchbottom1 d-flex align-items-center"
                  onClick={() => {
                    navigate("/reports");
                  }}
                >
                  <div className="arrow_back  btn-hover">
                    <MdArrowBackIosNew />{" "}
                    <span className="align-middle"> Go Back</span>
                  </div>
                </div>
                <div className="fs-5  py-3 profileHeading me-3">
                  <RWebShare
                    data={{
                      // text: `https://secretlanguage.network/v1/relationship/share?id=${relationReport.shareId}`,
                      url: `https://secretlanguage.network/v1/relationship/share?id=${relationReport.shareId}`,
                      title: "Share Relationship Report",
                    }}
                    // onClick={() =>
                    //   toast("url copied")
                    // }
                  >
                    <Icon icon="mdi:share-outline" className="fs-3 btn-hover" />
                  </RWebShare>
                  {/* <CopyToClipboard
                    text={`https://secretlanguage.network/v1/relationship/share?id=${relationReport.shareId}`}
                    onCopy={() => toast("url copied")}
                  >
                    <Icon icon="mdi:share-outline" className="fs-3" />
                  </CopyToClipboard> */}
                </div>
              </div>

              <div className="col py-2 bg-light my-3 mx-3 rounded-3">
                <div className="weekPersonality text-dark">
                  <div className="week-blockPersonality ps-2">
                    <span className="week-headerPersonality text-center fs-4">
                      {relationReport.birthday_1}
                    </span>
                    <span className="datePersonality text-center">
                      {relationReport.birthday_1_name}
                    </span>
                  </div>
                  <span className="andPersonality px-3">&amp;</span>
                  <div className="week-blockPersonality pe-2">
                    <span className="week-headerPersonality text-center fs-4">
                      {relationReport.birthday_2}
                    </span>
                    <span className="datePersonality text-center">
                      {relationReport.birthday_2_name}
                    </span>
                  </div>
                </div>
                <div className="calledPersonality text-dark">IS CALLED</div>
                <div className="image-sectionPersonality ">
                  <img
                    src={relationReport.image}
                    className="border-iconPersonality "
                    alt=""
                  />
                </div>
                <div className="rel-namePersonality text-dark lh-base">
                  {relationReport.name}
                </div>

                <div className="idealPersonality text-dark fs-5">
                  Ideal for:&nbsp;
                  <span
                    className="reIdealPersonality text-dark "
                    style={{ textTransform: "capitalize" }}
                  >
                    {relationReport.ideal_for}{" "}
                  </span>
                </div>
              </div>

              <div className="tab-content">
                <div className="tab-pane fontawesome-demo active" id="tab1">
                  <div className="description-section pt-0">
                    <p className="section-title mt-2 mb-3 fs-4 text-capitalize">
                      Personality
                    </p>
                    <p className="section-description fs-6 ">
                      {relationReport.report}
                    </p>
                  </div>
                  <div className="text-center">
                    <p className="section-title mt-2 mb-3 fs-4 text-capitalize">
                      Advice
                    </p>
                    <p className="section-description fs-6 mb-0">
                      {relationReport.advice}
                    </p>
                  </div>
                </div>
              </div>

              <div className=" footer-relation pb-5  mb-5">
                <Footer2 />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        <div className="fix_width mx-auto">
          <Navbar3 />
        </div>
      </div>
    </>
  );
};

export default Relationshipreport;
