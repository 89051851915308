import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { Configuration } from "@react-md/layout";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ChatContextProvider } from "./auth/context/ChatContext";
import { AuthContextProvider } from "./auth/context/AuthContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const root = ReactDOM.createRoot(document.getElementById("root"));
const stripePromise = loadStripe(
  "pk_live_51OcSDuEYqvSYylBc9J6kiMOs4yRRBEriIh9k3e9D2KwdIp67palcE9bQxeH11sZmJUmQXiQ6Sd0JWEEM1ft2bGHi00drxq2kM0"
);
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId="182519282140-hbc62t3t9ds2boa5nntv0butcf1s0jh7.apps.googleusercontent.com">
    <Configuration>
      <Elements stripe={stripePromise}>
        <AuthContextProvider>
          <ChatContextProvider>
            <App />
          </ChatContextProvider>
        </AuthContextProvider>
      </Elements>
    </Configuration>
  </GoogleOAuthProvider>
  // {/* </React.StrictMode> */}
);

reportWebVitals();
