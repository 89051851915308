import React, { useEffect, useRef, useState } from "react";
import "./matches.scss";
import aries1 from "../../assets/zodiac/zodiac1.png";
import Taurus from "../../assets/zodiac/zodiac2.png";
import Gemini from "../../assets/zodiac/zodiac3.png";
import Cancer from "../../assets/zodiac/zodiac4.png";
import Leo from "../../assets/zodiac/zodiac5.png";
import Virgo from "../../assets/zodiac/zodiac6.png";
import Libra from "../../assets/zodiac/zodiac7.png";
import Scorpio from "../../assets/zodiac/zodiac8.png";
import Sagittarius from "../../assets/zodiac/zodiac9.png";
import Capricorn from "../../assets/zodiac/zodiac10.png";
import Aquarius from "../../assets/zodiac/zodiac11.png";
import Pisces from "../../assets/zodiac/zodiac12.png";
function TwoZodiac(props) {
  const birthday = localStorage.getItem("birthdaysign");
  const [Zodiac_sign, setZodiaC] = useState("");
  const [Zodiac_img, setZimg] = useState("");

  function Self_zodiac_sign(date) {
    let date_format = date.split(" ");
    let month = date_format[0];
    let day = date_format[1];
    let astro_sign = "";
    // checks month and date within the
    // valid range of a specified zodiac
    if (month === "December") {
      if (day < 22) astro_sign = "Sagittarius";
      else astro_sign = "Capricorn";
    } else if (month === "January") {
      if (day < 20) astro_sign = "Capricorn";
      else astro_sign = "Aquarius";
    } else if (month === "February") {
      if (day < 19) astro_sign = "Aquarius";
      else astro_sign = "Pisces";
    } else if (month === "March") {
      if (day < 21) astro_sign = "Pisces";
      else astro_sign = "Aries";
    } else if (month === "April") {
      if (day < 20) astro_sign = "Aries";
      else astro_sign = "Taurus";
    } else if (month === "May") {
      if (day < 21) astro_sign = "Taurus";
      else astro_sign = "Gemini";
    } else if (month === "June") {
      if (day < 21) astro_sign = "Gemini";
      else astro_sign = "Cancer";
    } else if (month === "July") {
      if (day < 23) astro_sign = "Cancer";
      else astro_sign = "Leo";
    } else if (month === "August") {
      if (day < 23) astro_sign = "Leo";
      else astro_sign = "Virgo";
    } else if (month === "September") {
      if (day < 23) astro_sign = "Virgo";
      else astro_sign = "Libra";
    } else if (month === "October") {
      if (day < 23) astro_sign = "Libra";
      else astro_sign = "Scorpio";
    } else if (month === "November") {
      if (day < 22) astro_sign = "Scorpio";
      else astro_sign = "Sagittarius";
    }
    setZodiaC(astro_sign);

    if (astro_sign === "Aries") {
      setZimg(aries1);
    } else if (astro_sign === "Capricorn") {
      setZimg(Capricorn);
    } else if (astro_sign === "Taurus") {
      setZimg(Taurus);
    } else if (astro_sign === "Sagittarius") {
      setZimg(Sagittarius);
    } else if (astro_sign === "Scorpio") {
      setZimg(Scorpio);
    } else if (astro_sign === "Libra") {
      setZimg(Libra);
    } else if (astro_sign === "Virgo") {
      setZimg(Virgo);
    } else if (astro_sign === "Leo") {
      setZimg(Leo);
    } else if (astro_sign === "Gemini") {
      setZimg(Gemini);
    } else if (astro_sign === "Cancer") {
      setZimg(Cancer);
    } else if (astro_sign === "Aquarius") {
      setZimg(Aquarius);
    } else if (astro_sign === "Pisces") {
      setZimg(Pisces);
    }
  }
  const [Zodiac_sign_OP, setZodiaC_OP] = useState("");
  const [Zodiac_img_OP, setZimg_OP] = useState("");
  function OP_zodiac_sign(date) {
    let date_format = date.split(" ");
    let month = date_format[0];
    let day = date_format[1];
    let astro_sign = "";
    // checks month and date within the
    // valid range of a specified zodiac
    if (month === "December") {
      if (day < 22) astro_sign = "Sagittarius";
      else astro_sign = "Capricorn";
    } else if (month === "January") {
      if (day < 20) astro_sign = "Capricorn";
      else astro_sign = "Aquarius";
    } else if (month === "February") {
      if (day < 19) astro_sign = "Aquarius";
      else astro_sign = "Pisces";
    } else if (month === "March") {
      if (day < 21) astro_sign = "Pisces";
      else astro_sign = "Aries";
    } else if (month === "April") {
      if (day < 20) astro_sign = "Aries";
      else astro_sign = "Taurus";
    } else if (month === "May") {
      if (day < 21) astro_sign = "Taurus";
      else astro_sign = "Gemini";
    } else if (month === "June") {
      if (day < 21) astro_sign = "Gemini";
      else astro_sign = "Cancer";
    } else if (month === "July") {
      if (day < 23) astro_sign = "Cancer";
      else astro_sign = "Leo";
    } else if (month === "August") {
      if (day < 23) astro_sign = "Leo";
      else astro_sign = "Virgo";
    } else if (month === "September") {
      if (day < 23) astro_sign = "Virgo";
      else astro_sign = "Libra";
    } else if (month === "October") {
      if (day < 23) astro_sign = "Libra";
      else astro_sign = "Scorpio";
    } else if (month === "November") {
      if (day < 22) astro_sign = "Scorpio";
      else astro_sign = "Sagittarius";
    }
    setZodiaC_OP(astro_sign);

    if (astro_sign === "Aries") {
      setZimg_OP(aries1);
    } else if (astro_sign === "Capricorn") {
      setZimg_OP(Capricorn);
    } else if (astro_sign === "Taurus") {
      setZimg_OP(Taurus);
    } else if (astro_sign === "Sagittarius") {
      setZimg_OP(Sagittarius);
    } else if (astro_sign === "Scorpio") {
      setZimg_OP(Scorpio);
    } else if (astro_sign === "Libra") {
      setZimg_OP(Libra);
    } else if (astro_sign === "Virgo") {
      setZimg_OP(Virgo);
    } else if (astro_sign === "Leo") {
      setZimg_OP(Leo);
    } else if (astro_sign === "Gemini") {
      setZimg_OP(Gemini);
    } else if (astro_sign === "Cancer") {
      setZimg_OP(Cancer);
    } else if (astro_sign === "Aquarius") {
      setZimg_OP(Aquarius);
    } else if (astro_sign === "Pisces") {
      setZimg_OP(Pisces);
    }
  }
  useEffect(() => {
    if (props.data !== undefined || "") {
      Self_zodiac_sign(birthday);
      OP_zodiac_sign(props.data);
    }
  }, []);
  return (
    <>
      <div className="d-flex  w-100">
        <div className="text-center w-50" style={{ height: "100px" }}>
          <img
            src={Zodiac_img}
            alt=""
            width={80}
            height={80}
            className="img_clr object-fit-contain mb-2"
          />
          <p className="line-para fs-6">
            {birthday} - {Zodiac_sign}
          </p>
        </div>
        <div className="text-center w-50" style={{ height: "100px" }}>
          <img
            src={Zodiac_img_OP}
            alt=""
            width={80}
            height={80}
            className="img_clr  object-fit-contain mb-2"
          />
          <p className="line-para fs-6">
            {props.data} - {Zodiac_sign_OP}
          </p>
        </div>
      </div>
    </>
  );
}

export default TwoZodiac;
