/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import customAxios, { getApi, postApi } from "../../../Api/Api";
import { API_PATH } from "../../../Api/ApiPAth";
import { BsArrowLeft } from "react-icons/bs";
import "./chathome.scss";
import Navbar3 from "../../../layout/BottomNavbar/Navbar3";
import { useContext } from "react";
import { useEffect } from "react";
import {
  Timestamp,
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { auth, db, storage } from "../../../Firebase";
import { AuthContext } from "../../../auth/context/AuthContext";
import { ChatContext } from "../../../auth/context/ChatContext";
import nochat from "../../../assets/no_chat.png";
import Message from "./Message";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Footer2 from "../../../layout/Footer/Footer2";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import { FaAngleLeft, FaArrowUp, FaChevronLeft } from "react-icons/fa";
import Moment from "react-moment";
import moment from "moment";
import { IoMdAttach } from "react-icons/io";
import dp from "../../../assets/Dp.png";
import MatchesLoader from "../../../layout/Loader/MatchesLoader";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import InfiniteScroll from "react-infinite-scroll-component";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

import { Line, Circle } from "rc-progress";
import {
  isAndroid,
  isDesktop,
  isIOS,
  isMacOs,
  isMobile,
  isWindows,
} from "react-device-detect";
import { MdOutlineArrowUpward } from "react-icons/md";

const thumbnail_link =
  "https://firebasestorage.googleapis.com/v0/b/secret-language-15223.appspot.com/o/video-thumbnail.jpeg?alt=media&token=4752dddc-66ad-402b-b3e4-26d39ac2f177";
const Chat = () => {
  const { state } = useLocation();
  // console.log(state)
  const queryParams = new URLSearchParams(window.location.search);
  const Uid = queryParams.get("Uid");
  const shareId = queryParams.get("rid");
  if (Uid) {
    localStorage.setItem("uid", Uid);
  }
  const navigate = useNavigate();
  const [check1, setCheck1] = useState([]);
  const [Chat, setChat] = useState(Uid || shareId ? true : false);
  const [ChatwUid] = useState(false);
  const [ReportShow, setReportShow] = useState(false);
  const [active, setActive] = useState(false);
  const [ReportContent, setReportContent] = useState("");
  const [ShowFooter, setshowfooter] = useState(false);
  const [friends, setFriends] = useState([]);
  const [friendsList, setFriendsList] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Nolist, setNolist] = useState(false);
  const [page, setPage] = useState(1);
  const [Fpage, setPageF] = useState(1);
  const [pageCount] = useState(0);
  const [setDataNone3] = useState(false);
  const [setDataNone4] = useState(false);

  // input states
  const [img, setImg] = useState(null);
  const [Share_file, setShare_file] = useState([]);
  const [Progress, setProgress] = useState([]);
  const [type, setType] = useState("");
  const [text, setText] = useState("");
  const uid = localStorage.getItem("uid");
  //User Id for Chat

  const [chats, setChats] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);

  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        setChats(doc.data());
      });

      return () => {
        unsub();
      };
    };

    currentUser.uid && getChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.uid]);

  // user name screen
  const { data } = useContext(ChatContext);
  // messages

  const [messages, setMessages] = useState([]);
  const [messagesReq, setMessagesReq] = useState("");

  useEffect(() => {
    async function userdata() {
      // setLoading(true);
      try {
        const res = await getApi(API_PATH.PROFILE.USER_DATA);
        if (res.status) {
          setuser(res.data);
          localStorage.setItem("id", res.data.id);
          localStorage.setItem(
            "birthday1",
            res.data.birthday_report.date_name.slice(0, -6)
          );
          setName(`${res.data.name}`);
          setShare_file([]);
          setcheckName(true);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    userdata();
  }, []);

  const [Data, setData] = useState([]);
  const [uid_data, setuid_data] = useState([]);
  const Friends = async () => {
    setNolist(false);
    const userId = localStorage.getItem("id");
    const ProfilePic = localStorage.getItem("Image");
    const MyName = localStorage.getItem("Name");

    try {
      const res = await getApi(`${API_PATH.CHAT.CHAT}`);
      if (res.status) {
        if (Fpage === 1) {
          setFriendsList(res.data);
        } else {
          const new_friend = friendsList.concat(res.data);
          setFriendsList(new_friend);
        }

        if (res.data.length === 0) {
          setNolist(true);
        }
        if (Uid && uid !== "" && uid !== null) {
          let pendingData = [];
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            if (element.user.id === parseInt(uid)) {
              pendingData.push(element);
            }
          }
          setuid_data(pendingData[0]);
          setCheck1(pendingData[0]);
          localStorage.setItem("friendstatus", true);
          setActive(true);
          chatSet(pendingData[0].user.id);
          handleSelectChange(pendingData[0].user.id);
          setChat(true);
          setshowfooter(false);
          // statusCheck(pendingData[0].user.id);
          const combinedId =
            userId > pendingData[0].user.id
              ? userId + pendingData[0].user.id
              : pendingData[0].user.id + userId;
          findChat(combinedId);
        }
        let chatData = [];
        res.data.map((item) => {
          item.myId = userId;
          item.myProfilePic = ProfilePic;
          item.myName = MyName;
          item.userInfo = {
            id: item.user.id,
            displayName: item.chatName,
            profilePhoto: item.user.image,
          };
          chatData.push(item);
        });
        setData(chatData);
        setLoading(false);
        if (!Chat) {
          setshowfooter(true);
        }
      }
      if (res.data.length === 0 && Fpage > 1) {
        setDataNone4(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    Friends();
  }, [Fpage, uid !== "" && null]);

  const [search_check, setSearch] = useState(false);
  // handle next
  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1);
  };

  const [userSearch, setUserSearch] = useState({});

  const chatSet = async (params) => {
    localStorage.setItem("oppid", params);
    const q = query(
      collection(db, "users"),
      where("UserID", "==", JSON.stringify(params))
      // where("displayName", "==", params)
    );
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUserSearch(doc.data());
        // handleSelectSearch(doc.data());
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSelectSearch = async (userSearch) => {
    //check whether the group(chats in firestore) exists, if not create
    const combinedId =
      user.id > userSearch.UserID
        ? user.id + userSearch.UserID
        : userSearch.UserID + user.id;
    localStorage.setItem("combinedId", combinedId);
    try {
      const res = await getDoc(doc(db, "chats", combinedId));
      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(db, "chats", `${combinedId}`), { messages: [] });
        //create user chats
        await updateDoc(doc(db, "userChats", user.id), {
          [combinedId + ".userInfo"]: {
            uid: userSearch.uid,
            displayName: userSearch.displayName,
            photoURL: userSearch.photoURL,
            UserDob: userSearch.UserDob,
            UserID: userSearch.UserID,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", user.id), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }
    } catch (err) {}
    setUserSearch(null);
  };
  const handleSelectChange = async (u) => {
    const q = query(
      collection(db, "users"),
      where("UserID", "==", JSON.stringify(u))
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      dispatch({ type: "CHANGE_USER", payload: doc.data() });
    });

    setChat(true);
    setshowfooter(false);
  };

  const [username, setUsername] = useState("");

  const search = (data) => {
    if (data.length > 0) {
      const q = friendsList.filter((blog) =>
        blog.chatName.toLowerCase().includes(data.toLowerCase())
      );
      setFriendsList(data.length === 0 ? friendsList : q);

      if (q.length === 0) {
        setSearch(true);
        setNolist(true);
      }
    } else {
      Friends();
    }
  };
  useEffect(() => {
    search(username);
    if (username.length > 0) {
      search(username);
    } else if (username.length === 0) {
      Friends();
      setSearch(false);
    }
  }, [username, username.length]);

  const findChat = (id) => {
    const unSub = onSnapshot(doc(db, "chats", id), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });

    return () => {
      unSub();
    };
  };

  const [user, setuser] = useState([]);

  const [displayname, setName] = useState("");
  const [checkName, setcheckName] = useState(false);
  const Phone = localStorage.getItem("PhoneNumber");
  //User Details Update with firebase

  const [reportdata, setReportdata] = useState([]);

  const myId = localStorage.getItem("id");
  const combinedId = myId > uid ? myId + uid : uid + myId;

  const isfriend = localStorage.getItem("friendstatus");
  const [reciverstatus, setReciverStatus] = useState(false);

  ///INput
  const Share_blob = localStorage.getItem("Share_blob");

  const friendstatus = localStorage.getItem("friendstatus");
  const id = localStorage.getItem("id");

  const Opp_id = localStorage.getItem("oppid");
  const scrollToBottom = () => {
    imgRef.current?.scrollIntoView({ behavior: "instant" });
  };

  const [isProgressing, setIsProgressing] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isProgressing) {
      intervalRef.current = setInterval(() => {
        setProgress((prev) => (prev < 95 ? prev + 1 : prev));
      }, 200); // Adjust the interval as needed (100ms = 0.1s)
    } else if (!isProgressing && intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isProgressing]);

  const handleShareFile = (img) => {
    // const urls = Array.from(img).map((file) => URL.createObjectURL(file));
    for (let i = 0; i < img.length; i++) {
      const files = img[i];
      let ccType = files.type.split("/").shift();
      const oppid = localStorage.getItem("oppid");
      const unique_id = uuid();
      const d = new Date();
      let ms = d.getMilliseconds();
      const share_obj = {
        id: ms,
        name: files.name,
        progressId: parseInt(oppid),
        Share_file: URL.createObjectURL(files),
        type: ccType,
      };
      setShare_file((prevShareFile) => {
        // Avoid duplicates by filtering
        const updatedShareFile = [...prevShareFile, share_obj];
        const filteredArr = updatedShareFile.reduce((acc, current) => {
          const x = acc.find((item) => item.name === current.name);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        return filteredArr;
      });
      // localStorage.setItem(oppid, JSON.stringify(filteredArr));

      scrollToBottom();
      imgRef.current.scrollIntoView({ behavior: "instant" });
      // imgReg.current.scrollIntoView({
      //   behavior: "instant",
      // });
      localStorage.setItem("progressId", check1.userInfo.id);
      // uploadImageFirebase(img, filteredArr, ms);
      setProgress(0); // Reset progress to 0 each time you start
      setIsProgressing(true);
      uploadToAWS(img, share_obj, ms);
    }
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  // useEffect(() => {
  //   const Share_file = JSON.parse(localStorage.getItem("Share_file"));
  //   if (Share_file) {
  //     const oppId = localStorage.getItem("oppid");
  //     const updated = Share_file.filter(
  //       (deleteItem) => deleteItem.progressId === parseInt(oppId)
  //     );
  //     console.log(updated, "updated");
  //     setShare_file(updated);
  //   }
  // }, []);

  // useEffect(() => {
  //   const progressBar = localStorage.getItem("progressBar");
  //   console.log(progressBar,"lkjhgfds");
  //   if (progressBar) {
  //     setProgress(parseInt(progressBar));
  //   }
  // }, [Progress]);

  // useEffect(() => {
  //   // Function to update state with the current localStorage value
  //   const updateStateFromLocalStorage = () => {
  //     const oppId = localStorage.getItem("oppid");
  //     const Share_file = JSON.parse(localStorage.getItem(oppId));
  //     // setShare_file(Share_file !== null ? Share_file : []);
  //     if (Share_file) {
  //       const updated = Share_file.filter(
  //         (deleteItem) => deleteItem.progressId === parseInt(oppId)
  //       );
  //       const re = updated.filter(
  //         (deleteItem) => deleteItem.Progress !== "100" || null
  //       );
  //       for (let index = 0; index < Share_file.length; index++) {
  //         const element = Share_file[index];
  //         element.Progress = localStorage.getItem(element.id);
  //       }
  //       setShare_file(re);
  //     }
  //   };

  //   // Set up interval to periodically check for changes
  //   const intervalId = setInterval(updateStateFromLocalStorage, 1000); // Check every second

  //   // Clean up
  //   return () => {
  //     clearInterval(intervalId); // Clear the interval on component unmount
  //   };
  // }, []);
  const progressId = localStorage.getItem("progressId");
  const FileType = localStorage.getItem("fileType");
  const [OS, setOS] = useState("");
  const [cancelUpload, setCancelUpload] = useState(null);
  const [cancelUploadThumb, setCancelUploadThumb] = useState(null);
  const [scroll, setScroll] = useState(false);
  const DetectDevice = () => {
    const navigator = window.navigator;
    const userAgent = navigator.userAgent;
    const normalizedUserAgent = userAgent.toLowerCase();
    const standalone = navigator.standalone;
    const isIos =
      /ip(ad|hone|od)/.test(normalizedUserAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    const Android = /android/.test(normalizedUserAgent);
    const isSafari = /safari/.test(normalizedUserAgent);
    const isWebview =
      (Android && /; wv\)/.test(normalizedUserAgent)) ||
      (isIos && !standalone && !isSafari);

    const osText = isIos ? "iOS" : Android ? "Android" : "Other";
    const webviewText = isWebview ? "Yes" : "No";
    return setOS(osText);

    if (osText === "iOS") {
    } else if (osText === "Android") {
    } else if (osText === "Other") {
    }
    if (isAndroid && isMobile) {
    } else if (isIOS && isMobile) {
    } else if (isWindows && isDesktop) {
    } else if (isMacOs && isDesktop) {
    }
  };
  const pathname = useLocation().pathname;
  localStorage.setItem("pathname", pathname);

  const uploadImageFirebase = async (imageArray, new_Share_file, unique_id) => {
    localStorage.setItem("progress", true);
    // imgReg.current?.scrollIntoView({
    //   behavior: "instant",
    // });
    for (let i = 0; i < imageArray.length; i++) {
      const image = imageArray[i];

      try {
        let ab = image.name;
        let thumbName = ab.split(".").shift();
        let cc = ab.split(".").pop();
        let ccType = image.type.split("/").shift();
        localStorage.setItem("fileType", ccType);
        if (cc === "mp4" || cc === "mov") {
          // console.log("vid");
          const thumbnail_genrated = await generateVideoThumbnail(image);
          let filename = `${thumbName}.png`;
          const storageRefthumb = ref(storage, filename);
          // Convert the base64 string to a Blob
          const byteCharacters = atob(thumbnail_genrated.split(",")[1]);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/png" });
          const uploadTaskThumb = uploadBytesResumable(storageRefthumb, blob);

          setCancelUploadThumb(uploadTaskThumb);
          const storageRef = ref(storage, `${thumbName}.mp4`);
          const uploadTask = uploadBytesResumable(storageRef, image);
          setCancelUpload(uploadTask);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Observe the state change events (progress, pause, and resume)
              // You can use this to show a progress bar if needed
              const Progress1 =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

              for (let index = 0; index < new_Share_file.length; index++) {
                const element = new_Share_file[index];
                localStorage.setItem(element.id, Progress1);
              }
            },
            (error) => {
              localStorage.setItem(check1.user.id, JSON.stringify([]));
              //             // Handle unsuccessful uploads
              //             // console.error("Error uploading image:", error);
            },
            () => {
              // localStorage.removeItem("progressBar");

              getDownloadURL(uploadTask.snapshot.ref).then(
                async (downloadURL) => {
                  if (OS === "iOS") {
                    await handleSend(
                      "",
                      downloadURL,
                      thumbnail_link,
                      unique_id
                    );
                  } else {
                    // console.log(uploadTask);
                    // Monitor the upload progress
                    getDownloadURL(uploadTaskThumb.snapshot.ref).then(
                      async (ThumbNailURL) => {
                        await handleSend(
                          "",
                          downloadURL,
                          ThumbNailURL,
                          unique_id
                        );
                        // console.log("File available at", ThumbNailURL);
                      }
                    );
                  }
                }
              );
            }
          );
        } else {
          // console.log("img");
          const storageRef = ref(storage, ab);
          var uploadTask = uploadBytesResumable(storageRef, image);
          // uploadTask.cancel();
          setCancelUpload(uploadTask);
          const path = localStorage.getItem(pathname);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Observe the state change events (progress, pause, and resume)
              // You can use this to show a progress bar if needed
              const Progress1 =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

              for (let index = 0; index < new_Share_file.length; index++) {
                const element = new_Share_file[index];
                localStorage.setItem(element.id, Progress1);
              }

              // console.log(Progress1, snapshot);
            },
            (error) => {
              // Handle unsuccessful uploads
              const updated = new_Share_file.filter(
                (deleteItem) => deleteItem.id == unique_id
              );
              localStorage.setItem(check1.user.id, JSON.stringify([]));
              // console.log("Error uploading image:", error);
            },
            // eslint-disable-next-line no-loop-func
            () => {
              // Handle successful uploads on complete
              // console.log("Image uploaded successfully");
              // You can get the download URL if needed
              // localStorage.removeItem("progressBar");
              getDownloadURL(uploadTask.snapshot.ref).then(
                async (downloadURL) => {
                  await handleSend(downloadURL, "", "", unique_id);
                  // console.log("File available at", downloadURL);
                }
              );
            }
          );
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const uploadToAWS = async (img, new_Share_file, unique_id) => {
    localStorage.setItem("progress", true);

    for (let i = 0; i < img.length; i++) {
      const image = img[i];
      try {
        let ab = image.name;
        let thumbName = ab.split(".").shift();
        let cc = ab.split(".").pop(); 
        let formData = new FormData();
        formData.append("file", image);
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization" : "Bearer " + token,
          },
          onUploadProgress: (progressEvent) => {
            console.log(progressEvent.loaded, "config");
          },
        };
        const resV = await postApi(API_PATH.CHAT.UPLOAD_FILE, formData, config); 
        if (resV.data && resV.data.key) {
          if (cc === "mp4" || cc === "mov") {
            const thumbnail_genrated = await generateVideoThumbnail(image);
            let filename = `${thumbName}.png`;
            const byteCharacters = atob(thumbnail_genrated.split(",")[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "image/png" });
            let formData = new FormData();
            formData.append("file", blob, filename);

            const res = await postApi(API_PATH.CHAT.UPLOAD_FILE, formData);
            if (res.data && res.data.key) {
              await handleSend("", resV.data.key, res.data.key, unique_id);
            }
          } else {
            handleSend(resV.data.key, "", "", unique_id);
          }
        }
      } catch (error) {}
    }
  };
 
  const cancelUploadIMage = () => {
    if (cancelUpload) {
      cancelUpload.cancel();
    } else if (cancelUploadThumb || cancelUpload) {
      cancelUploadThumb.cancel();
      cancelUpload.cancel();
    }
    // Navigate away
    navigate(userprofilenaviagte === "true" ? "/request" : -1);
  };

  const messagesEndRef = useRef(null);
  const imgRef = useRef(null);
  const Prog = localStorage.getItem("progress");
  const inputRef = useRef(null);
  const handleSend = async (imageUrl, videoUrl, Thumbnail, id) => {
    setProgress(100)
    if (text) {
      inputRef.current.focus();
    }
    setType("");

    // console.log(imageUrl, videoUrl, "vid");
    const cmb1 = check1.myId + check1.userInfo.id;
    const cmb2 = check1.userInfo.id + check1.myId;
    const unique_id = uuid();
    const msg = {};
    msg._id = unique_id;
    msg.createdAt = new Date();
    msg.text = text ? text : "";
    msg.user = {
      _id: check1.myId,
      name: check1.myName + ",m",
      avatar: check1.myProfilePic,
    };
    let myMsg;
    myMsg = {
      ...msg,
      createdAt: Date.parse(msg.createdAt),
      image: imageUrl ? imageUrl : "",
      video: videoUrl ? videoUrl : "",
      thumbnail: Thumbnail ? Thumbnail : "",
    };
    await setText("");
    // await chatSet(check1.user.id);
    const res = await getDoc(doc(db, "chats", cmb1));
    // console.log(res.exists());
    if (res.exists()) {
      try {
        localStorage.removeItem("progressId");
        await updateDoc(doc(db, "chats", cmb1), {
          messages: arrayUnion({
            ...myMsg,
            senderId: check1.myId,
            receiverId: check1.userInfo.id,
            createdAt: Date.parse(msg.createdAt),
            date: Timestamp.now(),
          }),
        });
        await updateDoc(doc(db, "chats", cmb2), {
          messages: arrayUnion({
            ...myMsg,
            senderId: check1.myId,
            receiverId: check1.userInfo.id,
            createdAt: Date.parse(msg.createdAt),
            date: Timestamp.now(),
          }),
        });
        // const oppId = localStorage.getItem("oppid");
        // const updated_shared = Share_file.filter(
        //   (deleteItem) => deleteItem.progressId == parseInt(check1.user.id)
        // );
        // const updated = updated_shared.filter(
        //   (deleteItem) =>
        //     deleteItem.Progress !== ("100" || 100) || null || "null"
        // );
        // localStorage.setItem(check1.user.id, JSON.stringify(updated));
        // localStorage.setItem("progress", false);
        // // setShare_file(updated);
        // localStorage.removeItem(id);

        // }
        // console.log("q"); 
        if (friendstatus === "true") {
          // data.chatId !== "null" ? data.user.UserID : check1.user.id
          await postApi(`${API_PATH.CHAT.MESSAGE}/${check1.user.id}`, {
            type: "text",
            content: text ? text : text || imageUrl ? "Image" : "video",
          });
        }
        messagesEndRef.current?.scrollIntoView({ behavior: "instant" });
        // console.log("qD");
        await setImg(null);
        await setShare_file([]);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await setDoc(doc(db, "chats", cmb1), {
          messages: arrayUnion({
            ...myMsg,
            senderId: check1.myId,
            receiverId: check1.userInfo.id,
            createdAt: Date.parse(msg.createdAt),
            date: Timestamp.now(),
          }),
        });
        // console.log("jj");
        await setDoc(doc(db, "chats", cmb2), {
          messages: arrayUnion({
            ...myMsg,
            senderId: check1.myId,
            receiverId: check1.userInfo.id,
            createdAt: Date.parse(msg.createdAt),
            date: Timestamp.now(),
          }),
        });

        // img.type.slice(0, 5) !== "video" condition for sending image and video in api
        if (friendstatus === "true") {
          await postApi(`${API_PATH.CHAT.MESSAGE}/${check1.user.id}`, {
            type: "text",
            content: text ? text : text || imageUrl ? "Image" : "video",
          });
        }
        // console.log("q1");
        await updateDoc(doc(db, "userChats", check1.myId), {
          [data.chatId + ".lastMessage"]: {
            myMsg,
            senderId: check1.myId,
            receiverId: check1.userInfo.id,
          },
          [data.chatId + ".date"]: serverTimestamp(),
        });
        // console.log("qr");
        await updateDoc(doc(db, "userChats", check1.userInfo.id), {
          lastMessage: myMsg,
          [data.chatId + ".lastMessage"]: {
            myMsg,
            senderId: check1.myId,
            receiverId: check1.userInfo.id,
          },
          [data.chatId + ".date"]: serverTimestamp(),
        });
        localStorage.setItem("progress", false);
        // console.log("qs");

        messagesEndRef.current?.scrollIntoView({ behavior: "instant" });
        // console.log("qD1");
        await setShare_file([]);
        await setImg(null);
      } catch (error) {
        console.log(error);
      }
    }
    setIsProgressing(false)
    localStorage.setItem("progress", false);
    setShare_file([]);
    // console.log(Share_file, "ander");
    setProgress(0);
    setScroll(false);
  };
 

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "instant",
    });
    if (Prog === "true") {
      imgRef.current?.scrollIntoView({
        behavior: "instant",
      });
    }
    DetectDevice();
  }, [messages, Prog, img]);

  // Mobile code
  // const [state, setstate] = useState([]);

  //
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const groupedMessages = {};

  messages.forEach((message) => {
    const date = formatDate(message.createdAt);
    if (!groupedMessages[date]) {
      groupedMessages[date] = [];
    }
    groupedMessages[date].push(message);
  });

  const isKeyboardOpen = useDetectKeyboardOpen();

  const userprofilenaviagte = localStorage.getItem("userprofilenaviagte");

  return (
    <div className="container-chat-app_Home   mx-auto">
      <div className="col px-0 fix_width">
        <div className="chat fix_width mx-auto">
          <div className="chatInfo d-flex flex-space-between border-bottom border-dark">
            <FaChevronLeft
              className=" ms-1 pointerIcon fs-5 line-para"
              onClick={() => {
                setChat(false);
                setCheck1([]);
                setReportShow(false);
                localStorage.setItem("pathname", "/chat");
                localStorage.setItem("friendstatus", "");
                localStorage.setItem("uid", "");
                cancelUploadIMage();

                localStorage.removeItem("userprofilenaviagte");
                setshowfooter(true);
                Friends();
                setMessages([]);
              }}
            />
            <div
              className="d-flex align-items-center btn-hover ms-3"
              onClick={() =>
                navigate({
                  pathname: `/userprofile`,
                  search: `?friendprofileId=${Opp_id}`,
                })
              }
            >
              <img
                src={check1?.image ? check1?.image : dp}
                alt=""
                height="40px"
                width="40px"
                className="rounded-circle ms-1 ms-auto me-2 object-fit-cover img_brdr"
              />
              <div className="">
                <span className="mx-auto line-para text-capitalize">
                  {check1?.chatName}
                </span>
              </div>
            </div>
            <div className="chatIcons" key={check1?.id}></div>
          </div>
          {isfriend === "true" ? (
            <div className="messages mt-2 mb-3">
              {Object.entries(groupedMessages).map(([date, messages]) => (
                <div key={date}>
                  <div className="text-center fs-6 pb-2">{date}</div>
                  {messages.map((message) => (
                    <Message
                      message={message}
                      key={message.id}
                      dates={message.createdAt}
                    />
                  ))}
                </div>
              ))}
              {isKeyboardOpen ? inputRef.current.focus() : null}
              {/* // Progress !== "" && Progress <= 100 ? */}
              {parseInt(progressId) === parseInt(Opp_id) && check1 ? (
                <div
                  style={messages ? { display: "block" } : { display: "none" }}
                >
                  {Prog === "true" ? (
                    <div className="message owner ">
                      <div className="messageContent">
                        {Share_file?.map((i) => (
                          <div className="img_div position-relative">
                            {i.type === "video" ? (
                              <video
                                src={i.Share_file}
                                alt=""
                                className="w-100 object-fit-cover"
                              />
                            ) : (
                              <img
                                src={i.Share_file}
                                alt=""
                                className="w-100 object-fit-cover"
                              />
                            )}
                            <Circle
                              percent={Progress}
                              strokeWidth={6}
                              width={50}
                              trailWidth={5}
                              strokeColor="#8270ff"
                              className="position-absolute top-50 start-50 translate-middle"
                            />

                            <Moment
                              format="h:mm a"
                              style={{ fontSize: "12px" }}
                            >
                              {new Date()}
                            </Moment>
                          </div>
                        ))}

                        {/* {imgReg.current?.scrollIntoView({
                          behavior: "instant",
                        })} */}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div ref={imgRef}></div>
              {/* {Prog === "true"
                ? imgRef.current?.scrollIntoView(
                    {
                      behavior: "instant",
                    }, 
                  )
                : null} */}
            </div>
          ) : null}

          <div className="col-12 ">
            {/* <Input /> */}
            <form action="" onSubmit={(e) => handleSend(e.preventDefault())}>
              <div className="input border-top border-dark">
                <input
                  ref={inputRef}
                  type="text"
                  className="ms-0"
                  placeholder="Write a message..."
                  onChange={(e) => {
                    setText(e.target.value);
                    setType(e.target.value);
                  }}
                  onBlur={(e) => e.preventDefault()}
                  value={type}
                  disabled={friendstatus === "false"}
                  style={{ width: "80%" }}
                />
                <div className="send me-3 ">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="file"
                    accept="image/* , video/mp4"
                    onChange={(e) => {
                      handleShareFile(e.target.files);
                      setScroll(true);
                      // setImg(e.target.files[0]);
                      // uploadImageFirebase(e.target.files);
                      // console.log(e.target.files)
                      localStorage.setItem("progress", true);
                    }}
                    disabled={friendstatus === "false"}
                  />
                  <label htmlFor="file">
                    <IoMdAttach className="attachfile fs-2" />
                  </label>
                </div>
                <button
                  className="btn_common   rounded-circle me-3 "
                  style={{ padding: "8px" }}
                  disabled={text === "" || type === ""}
                  onClick={(e) => handleSend(e.preventDefault())}
                  onKeyDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  <MdOutlineArrowUpward className="fs-5" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
