import React, { useState } from "react";
import "./cardsmatch.scss";
import { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useRef } from "react";
import { getApi, patchApi, postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import { useNavigate } from "react-router-dom";
import { GiSettingsKnobs } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";
import Modal from "react-modal";
import MatchesLoader from "../../layout/Loader/MatchesLoader";
import { auth, db } from "../../Firebase";
import { updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import axios from "axios";
import Footer2 from "../../layout/Footer/Footer2";
import NameLoader from "../../layout/Loader/NameLoader";
import { ToastContainer } from "react-toastify";
import { Icon } from "@iconify/react";
import Navbar3 from "../../layout/BottomNavbar/Navbar3";
import Check from "./Check";
import TwoZodiac from "./TwoZodiac";
import PayImg from "../../assets/Pay.png";
import payWhite from "../../assets/pay-white.png";
import ButtonLoader from "../../layout/Loader/ButtonLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "800 !important",
    backgroundColor: "white !important",
    width: "350px",
    padding: "10px",
  },
};

const prodConfig = {
  baseURL: `https://api.stripe.com`,
  withCredentials: true,
};

const axiosInstance = axios.create(prodConfig);

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const CardsMatch = () => {
  const [btn_pressed, setBtn_pressed] = useState();
  const [count, setCount] = useState(0);
  const connections = localStorage.getItem("connection");
  const oppGen = localStorage.getItem("gender_pref");
  const chips = localStorage.getItem("Chips");
  const [gen] = useState(oppGen);
  const [Loading, setLoading] = useState(false);
  const [LoadingReport] = useState(false);
  const [Subscription, setSubscription] = useState(false);
  const [data, setData] = useState([]);
  const [dataNone, setDataNone] = useState(false);
  const [nove] = useState(false);
  const [SelectedUSerName, setselectedUserName] = useState("");
  const [relationReport, setselectedUserrelationReport] = useState([]);
  const [SelectedUSerId, setselectedUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function SettingData() {
      try {
        const res = await getApi(API_PATH.SETTING.USER_SETTING);
        if (res.status) {
          const checkB = res.data.date_name.split(",");
          localStorage.setItem("birthdaysign", checkB[0]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    SettingData();
    localStorage.removeItem("flag");
  }, []);

  const getLocation = async () => {
    setLoading(true);
    const location = await axios.get("https://ipapi.co/json");
    matchData(location.data);
  };
  const tok = localStorage.getItem("token");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${tok}`,
    },
  };
  const updateDataName = async (data) => {
    const birthday = localStorage.getItem("birthday");
    const nameval = localStorage.getItem("Name");
    const gen = localStorage.getItem("gender");
    const oppgen = localStorage.getItem("gender_pref");
    const location = localStorage.getItem("country_name");
    const InstaName = localStorage.getItem("instagram");
    const checkKey = {
      date_name: birthday,
      name: nameval,
      interested_in: data,
      gender: parseInt(gen),
      gender_preference: parseInt(oppgen),
      country_name: location,
      instagram: InstaName,
    };
    try {
      const res = await patchApi(
        API_PATH.UPDATE_PROFILE.UPDATE,
        checkKey,
        axiosConfig
      );
      if (res.status) {
        getLocation();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // getLocation()
    if (count === data.length) {
      getLocation();
    }
  }, [count === data.length]);

  const matchData = async (data) => {
    const connections = localStorage.getItem("connection");
    try {
      const res = await postApi(API_PATH.MATCHES.MATCH_PROFILE, {
        gender: gen ? parseInt(gen) : 2,
        interestedIn: connections ? parseInt(connections) : 0,
        lat: data.latitude ? data.latitude.toString() : "44.152264",
        lng: data.longitude ? data.longitude.toString() : "44.152264",
        // lat : 30.7333,
        // lng: 76.7794,
        idealFor: chips ? JSON.parse(chips) : [],
        minAge: 18,
        maxAge: 99,
        range: 15000,
      });
      if (res.status) {
        setCount(0);
        setData(res.data);
        if (res.data.length > 0) {
          setLoading(false);
        }
      }
      if (res.data.length === 0) {
        setDataNone(true);
      }
    } catch (error) {}
  };

  const addLeft = async (did) => {
    try {
      const res = await postApi(API_PATH.MATCHES.SWIPE_LEFT, {
        id: did,
      });
      if (res.data.status === "success") {
        onNextClick(did);
        setCount(count + 1);
        // if (count === data.length  ) {
        //   matchData();
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [Com_load, setCom_load] = useState(false);
  const getStat = async (id) => {
    setCom_load(true);
    try {
      const res = await getApi(API_PATH.SUBSCRIPTION.CHECK_StATUS);
      if (res.data.isVerified === 0) {
        setSubscription(true);
        // pay();
      } else if (res.data.isVerified === 1) {
        addRight(id);
        setSubscription(false);
      }
    } catch (err) {}
  };
  const addRight = async (did) => {
    try {
      const res = await postApi(API_PATH.COMMUNITY.SENDREQUEST, {
        id: did,
      });
      if (res.data.status === "success") {
        onNextClick(did);
        setCount(count + 1);
        setCom_load(false);
        navigate({
          pathname: "/chatHome",
          search: `?rid=${did}`,
        });
      }
    } catch (error) {}
  };
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const ref = useRef(null);

  const onNextClick = async (did) => {
    if (index >= 9) {
      matchData();
    }
    // data.slice(index , 0)
    // setNov(false);
    ref.current.next();
    //  const updates = data.filter((idx) => idx.id !== did);
    // setData(updates)
  };

  const [modalIsOpenCommunicate, setIsOpenCommunicate] = React.useState(false);
  // eslint-disable-next-line no-unused-vars

  const getRelationshipreport = async (data) => {
    const ourBirthday = localStorage.getItem("birthdaysign");
    const My_B = ourBirthday.split(",");
    try {
      const res = await postApi(
        API_PATH.REPORT.RELATIONSHIP_REPORT_WITHOUT_CHECK,
        {
          birthday_1: My_B[0],
          birthday_2: data,
        }
      );
      if (res) {
        setselectedUserrelationReport(res.data);
        localStorage.setItem("rreport", res.data.report);
      }
    } catch {
      // closeModalCommunication()
      setSubscription(true);
      // openModal()
    }
  };
  //Communication
  function openModalCommunication(data, age) {
    setselectedUserName(data);
    getRelationshipreport(age);
    // setselectedUserReport(report)
    setIsOpenCommunicate(true);
  }

  function afterOpenModalCommunication() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModalCommunication() {
    setIsOpenCommunicate(false);
    setCom_load(false);
  }
  const datafilters = () => {
    navigate("/datafilter");
  };
  const SettingData = async () => {
    const intresede_in = localStorage.getItem("connection");
    setBtn_pressed(parseInt(intresede_in));
    try {
      const res = await getApi(API_PATH.PROFILE.USER_DATA);
      if (res.status) {
        localStorage.setItem("Image", res.data.image);
        if (!intresede_in) {
          localStorage.setItem("connection", res.data.interested_in.id);
          setBtn_pressed(res.data.interested_in.id);
        }
        localStorage.setItem("Name", res.data.name);
        localStorage.setItem("birthday", res.data.date_name);
        localStorage.setItem("gender", res.data.gender.id);
        localStorage.setItem("gender_pref", res.data.gender_preference);
        localStorage.setItem("country_name", res.data.country_name);
        localStorage.setItem("instagram", res.data.instagram);
        if (res && auth?.currentUser) {
          try {
            await updateProfile(`${res.data.id}`, {
              displayName: `${res.data.name}`,
              photoURL: `${res.data.image}`,
              UserDob: `${res.data.birthday_report.date_name}`,
              UserID: `${res.data.id}`,
            });
            //create user on firestore
            await setDoc(doc(db, "users", `${res.data.id}`), {
              uid: auth.currentUser.uid,
              displayName: `${res.data.name}`,
              photoURL: `${res.data.image}`,
              UserDob: `${res.data.birthday_report.date_name}`,
              UserID: `${res.data.id}`,
            });

            //create empty user chats on firestore
            await setDoc(doc(db, "userChats", `${res.data.id}`), {});
          } catch (error) {}
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    SettingData();
  }, []);

  const pay = () => {
    window.open(
      "https://apps.apple.com/us/app/idealfor/id1558133582",
      "_blank"
    );
  };
  const theme = localStorage.getItem("dark");

  const Subscribe = () => {
    window.open("https://buy.stripe.com/test_7sIaHo9AV1492yseUW");
  };

  const queryParams = new URLSearchParams(window.location.search);
  const sessionID = queryParams.get("session_id");
  const SubsessionID = queryParams.get("Sub_session_id");
  const [Subrecipt, setSubRecipt] = useState("");
  const sID =
    "sk_test_51MJssBSIOG3lP4WJ5pTZH6clVf9PkN0WyYnWzLY0zj7fpFRmtoA77IS5xJuu6zsp7mzJO29cxQW3eroKfFcEJLcH00BIrNp8VZ";

  // subscription
  // useEffect(() => {
  //   async function fetchData() {
  //     setLoading(true)
  //     try {
  //       const res = await axiosInstance.get(`/v1/checkout/sessions/${SubsessionID}`, {
  //         headers: {
  //           "Authorization": `Bearer ${sID}`,
  //         }
  //       })
  //       if (res.status) {
  //         let objJsonStr = JSON.stringify(res.data);
  //         var encoded = btoa(objJsonStr)
  //         setSubRecipt(encoded)
  //         setLoading(false)
  //       }
  //     } catch (error) {
  //       console.log(error)
  //       setLoading(false)

  //     }
  //   }
  //   if (SubsessionID) {
  //     fetchData();
  //   }
  // }, []);

  // const getSubscriptionStatus = async () => {
  //   const res = await getApi(API_PATH.SUBSCRIPTION.GET_INFO)
  //   if (res.status) {
  //   }
  // }
  // useEffect(() => {
  //   async function birthdayData() {
  //     try {
  //       const res = await postApi(API_PATH.SUBSCRIPTION.SUBSCRIPTION, {
  //         "receipt": Subrecipt
  //       })
  //       if (res.status) {
  //         getSubscriptionStatus()
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   if (Subrecipt) {
  //     birthdayData();
  //   }
  // }, [Subrecipt]);

  return (
    <>
      <ToastContainer />
      <div className="  ">
        <div className="container-fluid">
          <div className="  mx-auto fix_width">
            {dataNone ? (
              <div className="d-flex flex-md-row flex-column justify-content-center align-content-center text-center  mt-5 pt-5">
                <p className="text-light err-head fs-5 mb-0">No Data Found </p>,{" "}
                <p className="mb-0 fs-5 text-light">
                  {" "}
                  &nbsp; Please use filter
                </p>{" "}
                <p className="mt-2 fs-5 mt-md-0">
                  <GiSettingsKnobs
                    className="boxSquare data-filter-box-none-data text-light  mx-2"
                    onClick={datafilters}
                  />
                </p>
              </div>
            ) : null}
            <div className="">
              <div className="pp">
                <div className="main-head-cards mx-auto">
                  <div className="d-flex justify-content-between pt-3 pb-2 px-2">
                    <h6 className="head head-light mb-0">
                      Connections ideal for:
                    </h6>
                    {/* <Icon
                      icon="lucide:settings-2"
                      color={theme === "true" ? "white" : "black"}
                      className="fs-3 btn-hover"
                      onClick={() => datafilters()}
                    /> */}
                  </div>
                  <div className="d-flex justify-content-between overflow-scroll scrollhide pb-2 px-2">
                    <button
                      className={
                        btn_pressed === 1 || connections === "1"
                          ? "btn_common_card px-2 py-2 btn-color"
                          : "btn_common_unpressed_card px-2 py-2 btn-color"
                      }
                      onClick={() => {
                        setBtn_pressed(1);
                        localStorage.setItem("connection", 1);
                        updateDataName(1);
                      }}
                    >
                      Romance
                    </button>
                    <button
                      className={
                        btn_pressed === 2 || connections === "2"
                          ? "btn_common_card px-2 py-2 btn-color"
                          : "btn_common_unpressed_card px-2 py-2 btn-color"
                      }
                      onClick={() => {
                        setBtn_pressed(2);
                        localStorage.setItem("connection", 2);
                        updateDataName(2);
                      }}
                    >
                      Friendship
                    </button>
                    <button
                      className={
                        btn_pressed === 3 || connections === "3"
                          ? "btn_common_card px-2 py-2 btn-color"
                          : "btn_common_unpressed_card px-2 py-2 btn-color"
                      }
                      onClick={() => {
                        setBtn_pressed(3);
                        localStorage.setItem("connection", 3);
                        updateDataName(3);
                      }}
                    >
                      Business
                    </button>
                    <button
                      className={
                        btn_pressed === 0
                          ? "btn_common_card px-2 py-2 btn-color"
                          : "btn_common_unpressed_card px-2 py-2 text-decoration-none"
                      }
                      onClick={() => {
                        setBtn_pressed(0);
                        localStorage.setItem("connection", 0);
                        getLocation();
                      }}
                    >
                      All
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {Loading ? (
              <MatchesLoader />
            ) : (
              <div className=" px-0 mx-0 dataContainer ">
                <Carousel
                  ref={ref}
                  activeIndex={index}
                  onSelect={handleSelect}
                  indicators={false}
                  controls={false}
                  interval={null}
                  className="carousel-fade "
                  touch={false}
                >
                  {data.map((item) => (
                    <Carousel.Item key={item.id} className="impCarousel">
                      <div className=" mx-auto">
                        <div className=" text-center section1 scrollbar rounded-top-3">
                          <div className="ff1 pt-5 mt-5">
                            <div className="">
                              <div className="section2 my-2 pb-1">
                                <Carousel
                                  fade
                                  interval={null}
                                  autoPlay={false}
                                  controls={
                                    item.images.length > 1 ? true : false
                                  }
                                  indicators={
                                    item.images.length > 1 ? true : false
                                  }
                                >
                                  {item.images.map((el) => (
                                    <Carousel.Item key={el.id}>
                                      <div className="col d-flex justify-content-center card_img height-card px-2 ">
                                        <img
                                          src={el.image}
                                          alt=""
                                          className=" card_img w-100 object-fit-cover"
                                        />
                                        <div
                                          className="matchInnerbox rounded-4 text-start d-flex justify-content-between"
                                          id="section-1"
                                        >
                                          <div className="">
                                            <h5 className="m-0 text-light text-capitalize">
                                              {item.name}, {item.age}
                                            </h5>
                                            <p className="m-0 paraMatch fw-semibold text-light">
                                              {item.distance}
                                            </p>
                                            <p className="m-0 text-light">
                                              idealfor :
                                              <span className="text-light ms-1  fw-bold">
                                                {item.ideal_for}
                                              </span>
                                            </p>
                                          </div>

                                          <div className="">
                                            <Check data={item.user_birthday} />
                                          </div>
                                        </div>
                                      </div>
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                              </div>
                              <div className="btn_actions d-flex justify-content-evenly  py-3 px-2 btn-hover align-items-center">
                                <div
                                  className="Add_btn  px-3 ps-sm-3    py-2  d-flex justify-content-start align-items-center"
                                  onClick={() => {
                                    openModalCommunication(
                                      item.name,
                                      item.user_birthday
                                    );
                                    setselectedUserId(item.id);
                                  }}
                                >
                                  <Icon
                                    icon="zondicons:add-solid"
                                    color="#7add84"
                                    className="fs-3  me-2"
                                  />
                                  <span className="fs-5">Connect</span>
                                </div>

                                <div
                                  className="Add_btn px-3 ps-sm-3  py-2  btn-hover d-flex justify-content-start align-items-center"
                                  onClick={() => addLeft(item.id)}
                                >
                                  <Icon
                                    icon="ph:x-circle-fill"
                                    color="red"
                                    className="fs-2 me-2"
                                    rotate={2}
                                  />
                                  <span className="fs-5">Ignore</span>
                                </div>
                                {item.instagram !== "" ? (
                                  <div>
                                    <a
                                      href={`https://www.instagram.com/${item.instagram}`}
                                      target="blank"
                                      className=""
                                    >
                                      <Icon
                                        icon="jam:instagram"
                                        className="fs-1  p-1 text-light insta  rounded-circle"
                                      />
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div
                              className={
                                nove
                                  ? "AboutContent-New px-2"
                                  : "AboutContent px-2   "
                              }
                            >
                              <div className="Z-signs my-4">
                                <TwoZodiac data={item.user_birthday} />
                              </div>

                              <div
                                className="d-flex justify-content-center "
                                style={{ height: "60px" }}
                              >
                                <div className="img_bg_back d-flex justify-content-center">
                                  <img
                                    src={item.rel_image}
                                    alt=""
                                    width={100}
                                    className="my-3 object-fit-contain"
                                  />
                                </div>
                              </div>
                              <div className="card_sln pt-5 mx-2">
                                <p className="fs-5 pt-3 pb-0 mb-1">
                                  {item.sln}
                                </p>
                                <p
                                  className="pb-3"
                                  style={{ color: "#8270FF" }}
                                >
                                  {item.looking_for}
                                </p>
                              </div>
                              <p className="line-para my-4 px-3">
                                {item.sln_description}
                              </p>

                              <div className="col personalityBtns  ">
                                <ul className="nav  px-auto switch_div  w-100">
                                  <li className="nav-item tab-all w-50">
                                    <a
                                      className="nav-link  show py-1 px-3 active "
                                      href="#tab1"
                                      data-bs-toggle="tab"
                                    >
                                      <button className="side01  fs-5 fw-bold">
                                        Personality
                                      </button>
                                    </a>
                                  </li>
                                  <li className="nav-item tab-all p-l-20 w-50">
                                    <a
                                      className="nav-link  py-1 px-4 "
                                      href="#tab2"
                                      data-bs-toggle="tab"
                                    >
                                      <button className="side01 fs-5 fw-bold">
                                        Advice
                                      </button>
                                    </a>
                                  </li>
                                </ul>
                              </div>

                              <div className="tab-content">
                                <div
                                  className="tab-pane fontawesome-demo active"
                                  id="tab1"
                                >
                                  <div className="">
                                    <p className="   line-para mt-4 pb-3 px-2">
                                      {item.report}
                                    </p>
                                  </div>
                                </div>
                                <div className="tab-pane" id="tab2">
                                  <div className="container-fluid">
                                    <div className="text-center">
                                      <div className="text-center mt-4">
                                        <div className=" text-center content-match-li">
                                          <p className="advice-p pb-4 line-para">
                                            {item.advice}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pb-5 mb-5">
                                <Footer2 />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
                <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
                  <div className="  fix_width mx-auto">
                    <Navbar3 />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpenCommunicate}
        onAfterOpen={afterOpenModalCommunication}
        onRequestClose={closeModalCommunication}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {LoadingReport === false ? (
          <div className="text-center form-width">
            {Subscription === false ? (
              <div className="">
                <div
                  className="btn-hover text-end"
                  onClick={() => closeModalCommunication()}
                >
                  <RxCross1 />
                </div>
                <div className="communicate-report">
                  {LoadingReport === true ? (
                    <h2 style={{ height: "350px" }}>
                      <NameLoader />
                    </h2>
                  ) : (
                    <div className="container personalityContent  relationreport-cards border-bottom">
                      <div className="row personalityBtn mx-0 ">
                        {/* <div className="text-center py-2 reportrelation fs-5">IdealFor Birthdays & Relationships </div> */}
                        <div className="col py-1 clr-text  mx-3 rounded-3 manage_color">
                          <div className="weekPersonality m-0">
                            <div className="week-blockPersonality">
                              <span className="week-headerPersonality text-start  fs-6 lh-sm">
                                {relationReport.birthday_1}
                              </span>
                              <span className="datePersonality text-start ">
                                {relationReport.birthday_1_name}
                              </span>
                            </div>
                            <span className="andPersonality  ">&amp;</span>
                            <div className="week-blockPersonality">
                              <span className="week-headerPersonality text-end  fs-6 lh-sm">
                                {relationReport.birthday_2}
                              </span>
                              <span className="datePersonality text-end ">
                                {relationReport.birthday_2_name}
                              </span>
                            </div>
                          </div>
                          <div className="calledPersonality ">IS CALLED</div>
                          <div className="image-sectionPersonality text-center ">
                            <img
                              src={relationReport.image}
                              className="border-iconPersonality "
                              alt=""
                              width={150}
                            />
                          </div>
                          <div
                            className="rel-namePersonality  lh-base"
                            style={{ fontSize: "18px" }}
                          >
                            {relationReport.name}
                          </div>
                          <div className="lh-sm">
                            <div className="week-block2Personality ">
                              <span
                                className="date1Personality lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.s1},
                              </span>
                              <span
                                className="date1Personality ms-1 lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.s2},
                              </span>
                              <span
                                className="date1Personality ms-1 lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.s3},
                              </span>
                              <br />
                              <span
                                className="date1Personality ms-1 lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.w1},
                              </span>
                              <span
                                className="date1Personality ms-1 lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.w2},
                              </span>
                              <span
                                className="date1Personality ms-1 lh-base"
                                style={{ fontSize: "14px" }}
                              >
                                {relationReport.w3},
                              </span>
                            </div>
                          </div>
                          <div
                            className="idealPersonality "
                            style={{ fontSize: "18px" }}
                          >
                            Ideal for :
                            <span className="reIdealPersonality">
                              {" "}
                              {relationReport.ideal_for}{" "}
                            </span>
                          </div>
                        </div>

                        <div className="tab-content">
                          <div
                            className="tab-pane fontawesome-demo active"
                            id="tab23"
                          >
                            <div className="">
                              <div className="description-section pt-0">
                                <p className="section-title mt-1 mb-1 fs-5">
                                  Relationship report
                                </p>
                                <p className="section-description fs-6 w-100 lh-base">
                                  {relationReport.report}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" footer-relation pb-1  px-0">
                          {/* <Footer2 /> */}
                          <p
                            className=" mb-0 line-para"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            Powered by Secret Language of Birthdays &
                            Relationships
                            <br />{" "}
                            <a
                              href="https://doejo.com/"
                              target="blank"
                              className="color-manage px-1"
                            >
                              Made by doejo{" "}
                            </a>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {Com_load !== true ? (
                  <button
                    className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                    onClick={() => getStat(SelectedUSerId)}
                    style={{ height: "45px" }}
                  >
                    Communicate
                  </button>
                ) : (
                  <button
                    className="btn_common p-2 mb-4 mt-2  rounded-5 px-4 w-50"
                    style={{ height: "45px" }}
                  >
                    <ButtonLoader />
                  </button>
                )}{" "}
                <br />
                <span className=" " style={{ fontSize: "12px" }}>
                  {" "}
                  When {SelectedUSerName} accepts your request you will be able
                  to freely communicate
                </span>
              </div>
            ) : (
              <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
                <div className="container-xxl">
                  <div className="row suv mx-0 ">
                    <div className="col  subcription-bg ">
                      <div className="mb-5">
                        {theme === "true" ? (
                          <img src={PayImg} alt="" width={250} />
                        ) : (
                          <img src={payWhite} alt="" width={250} />
                        )}
                        <p className="mb-0   fs-4">Unlock each report!</p>
                        <p className="mb-0   fs-6">
                          Pay only 99 cents to unlock each report
                        </p>
                      </div>

                      <div className="d-grid justify-content-center my-2">
                        <button
                          className="sub-btn-1 mb-3"
                          onClick={() => {
                            // Subscribe();
                            pay();
                          }}
                          style={{
                            backgroundColor: "#8270FF",
                            borderColor: "#8270FF",
                          }}
                        >
                          Unlock
                        </button>
                        <button
                          className="sub-btn-2 "
                          onClick={() => {
                            setSubscription(false);
                            closeModalCommunication();
                            setCom_load(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className="px-2  ">
                      <Footer2 />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center form-width" style={{ height: "505px" }}>
            {" "}
            <h2>
              <NameLoader />
            </h2>
          </div>
        )}
      </Modal>
    </>
  );
};

export default CardsMatch;
