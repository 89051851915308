import React, { useEffect, useState } from "react";
import "./subscription.css";
import Footer2 from "../../layout/Footer/Footer2";
import { useNavigate } from "react-router-dom";
import PayImg from "../../assets/Pay.png";
import payWhite from "../../assets/pay-white.png";

const ReportSubscription = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const updateConnection = queryParams.get("Sub");
  const navigate = useNavigate();
  const pay = () => {
    window.open(
      "https://apps.apple.com/us/app/idealfor/id1558133582",
      "_blank"
    );
  };
  const theme = localStorage.getItem("dark");
  const [dub_img, setSub_img] = useState("");
  useEffect(() => {
    if (theme === "true") {
      setSub_img(PayImg);
    } else {
      setSub_img(payWhite);
    }
  }, [theme]);

  return (
    <>
      <div className="container-fluid subscription-img" id="subId">
        <div className="row mx-auto fix_width">
          <div className=" mx-auto">
            <div className="row mx-0">
              <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
                <div className="container-xxl">
                  <div className="mt-3 text-start">
                    <p
                      className="btn-hover"
                      onClick={() => navigate("/reports")}
                    >
                      Reports
                    </p>
                  </div>
                  <div className="row suv mx-0 my-5">
                    <div className="col  subcription-bg ">
                      <div className="mb-5">
                        <img
                          src={payWhite}
                          alt=""
                          className="img_clr_sub"
                          width={250}
                        />
                        <p className="mb-0   fs-4">Unlock each report!</p>
                        <p className="mb-0   fs-6">
                          Pay only 99 cents to unlock each report
                        </p>
                      </div>

                      <div className="d-grid justify-content-center my-2">
                        <button
                          className="sub-btn-1 mb-3 line-para"
                          onClick={() => {
                            pay();
                          }}
                          style={{
                            backgroundColor: "#8270FF",
                            borderColor: "#8270FF",
                            color: "#fff",
                          }}
                        >
                          Unlock
                        </button>
                        <button
                          className="sub-btn-2 "
                          onClick={() => {
                            updateConnection
                              ? navigate(-1)
                              : navigate("/reports");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className="px-2  ">
                      <Footer2 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportSubscription;
