import React, { useContext, useEffect, useState } from "react";
import "./verifyflow.css";
import { MdArrowBackIosNew } from "react-icons/md";
import ButtonLoader from "../layout/Loader/ButtonLoader";
import { useForm } from "react-hook-form";
import { getApi, patchApi, postApi } from "../Api/Api";
import { API_PATH } from "../Api/ApiPAth";
import "../layout/Wheel/styles.css";
import { toast } from "react-toastify";
import FiPlus from "../assets/Vector.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import aries1 from "../assets/zodiac/zodiac1.png";
import Taurus1 from "../assets/zodiac/zodiac2.png";
import Gemini1 from "../assets/zodiac/zodiac3.png";
import Cancer1 from "../assets/zodiac/zodiac4.png";
import Leo1 from "../assets/zodiac/zodiac5.png";
import Virgo1 from "../assets/zodiac/zodiac6.png";
import Libra1 from "../assets/zodiac/zodiac7.png";
import Scorpio1 from "../assets/zodiac/zodiac8.png";
import Sagittarius1 from "../assets/zodiac/zodiac9.png";
import Capricorn1 from "../assets/zodiac/zodiac10.png";
import Aquarius1 from "../assets/zodiac/zodiac11.png";
import Pisces1 from "../assets/zodiac/zodiac12.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import axios from "axios";
import { RxCross1 } from "react-icons/rx";
import Footer2 from "../layout/Footer/Footer2";

let mydate = new Date("1979-01-01");
let enddate = new Date();
enddate.setFullYear(enddate.getFullYear() - 16);

const MAX_AGE = 100;
const MIN_AGE = 18;

const getMinAndMaxDateForDob = () => {
  const currenTime = new Date();
  const currentYear = currenTime.getFullYear();
  const currentMonth = currenTime.getMonth();
  const minYear = currentYear - MAX_AGE;
  const maxYear = currentYear - MIN_AGE;
  const minDate = new Date(`${minYear}-${currentMonth}-01`);
  const maxDate = new Date(`${maxYear}-${currentMonth}-01`);
  return { minDate, maxDate };
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
  },
};
const VerifyYourself = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const updateConnection = queryParams.get("update");
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [gender, setGender] = useState([]);
  const name = localStorage.getItem("Name");
  const [gendersel, setGenderSel] = useState();
  const [ReportShow, setReportShow] = useState(false);

  const [connection, setConnection] = useState([]);
  const [oppositegender, setGOppositegnder] = useState([]);
  const oppgender = oppositegender.slice(0, 2);
  const [cngScreen, setCngScreen] = useState(
    updateConnection ? parseInt(updateConnection) : 1
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    localStorage.setItem("Name", data.name);
    localStorage.setItem("gender", data.gender);
    if (!updateConnection) {
      await setCngScreen(2);
    }
  };

  const ConnectionData = async () => {
    try {
      const res = await getApi(API_PATH.AUTH.INTRESTED_IN);
      if (res.status) {
        localStorage.setItem("verifyConnection", JSON.stringify(res.data));
        setConnection(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const OppoGen = async () => {
    try {
      const res = await getApi(API_PATH.AUTH.GENDER_PREFERENCE);
      if (res.status) {
        localStorage.setItem("verifyGender", JSON.stringify(res.data));
        setGOppositegnder(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    async function GenderData() {
      setLoading(true);
      try {
        const res = await getApi(API_PATH.AUTH.GENDER);
        if (res.status) {
          setGender(res.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    const verifyConnection = JSON.parse(
      localStorage.getItem("verifyConnection")
    );
    const verifyGender = JSON.parse(localStorage.getItem("verifyGender"));
    if (verifyConnection) {
      setConnection(verifyConnection);
    }
    if (verifyGender) {
      setGOppositegnder(verifyGender);
    }
    if (updateConnection) {
      const Gen = localStorage.getItem("gender");
      const oppGen = localStorage.getItem("gender_pref");
      const conn = localStorage.getItem("connection");
      setGenderSel(parseInt(Gen));
      setOppSel(parseInt(oppGen));
      setConnSel(parseInt(conn));
    }
    GenderData();
    ConnectionData();
    OppoGen();
  }, []);

  // Birthday

  const birthday = localStorage.getItem("birthday");

  const [startDate, setStartDate] = useState(
    updateConnection ? new Date(birthday) : mydate
  );

  const [endDate] = useState(new Date());

  const [sampleDate] = useState(new Date(birthday));

  const [Zodiac_sign, setZodiaC] = useState("");
  const [Zodiac_img, setZimg] = useState("");

  function zodiac_sign(day, month) {
    let astro_sign = "";
    // checks month and date within the
    // valid range of a specified zodiac
    if (month === "December") {
      if (day < 22) astro_sign = "Sagittarius";
      else astro_sign = "Capricorn";
    } else if (month === "January") {
      if (day < 20) astro_sign = "Capricorn";
      else astro_sign = "Aquarius";
    } else if (month === "February") {
      if (day < 19) astro_sign = "Aquarius";
      else astro_sign = "Pisces";
    } else if (month === "March") {
      if (day < 21) astro_sign = "Pisces";
      else astro_sign = "Aries";
    } else if (month === "April") {
      if (day < 20) astro_sign = "Aries";
      else astro_sign = "Taurus";
    } else if (month === "May") {
      if (day < 21) astro_sign = "Taurus";
      else astro_sign = "Gemini";
    } else if (month === "June") {
      if (day < 21) astro_sign = "Gemini";
      else astro_sign = "Cancer";
    } else if (month === "July") {
      if (day < 23) astro_sign = "Cancer";
      else astro_sign = "Leo";
    } else if (month === "August") {
      if (day < 23) astro_sign = "Leo";
      else astro_sign = "Virgo";
    } else if (month === "September") {
      if (day < 23) astro_sign = "Virgo";
      else astro_sign = "Libra";
    } else if (month === "October") {
      if (day < 23) astro_sign = "Libra";
      else astro_sign = "Scorpio";
    } else if (month === "November") {
      if (day < 22) astro_sign = "scorpio";
      else astro_sign = "Sagittarius";
    }
    setZodiaC(astro_sign);

    if (astro_sign === "Aries") {
      setZimg(aries1);
    } else if (astro_sign === "Capricorn") {
      setZimg(Capricorn1);
    } else if (astro_sign === "Taurus") {
      setZimg(Taurus1);
    } else if (astro_sign === "Sagittarius") {
      setZimg(Sagittarius1);
    } else if (astro_sign === "Scorpio") {
      setZimg(Scorpio1);
    } else if (astro_sign === "Libra") {
      setZimg(Libra1);
    } else if (astro_sign === "Virgo") {
      setZimg(Virgo1);
    } else if (astro_sign === "Leo") {
      setZimg(Leo1);
    } else if (astro_sign === "Gemini") {
      setZimg(Gemini1);
    } else if (astro_sign === "Cancer") {
      setZimg(Cancer1);
    } else if (astro_sign === "Aquarius") {
      setZimg(Aquarius1);
    } else if (astro_sign === "Pisces") {
      setZimg(Pisces1);
    }
  }

  useEffect(() => {
    if (startDate) {
      const date = new Date(startDate);
      const Month = date.toLocaleString("default", { month: "long" });
      const Day = date.getDate();
      zodiac_sign(Day, Month);
    }
  }, [startDate]);

  const changeNext = () => {
    if (updateConnection) {
      localStorage.setItem(
        "birthdaysign",
        startDate.toLocaleDateString("en-us", {
          day: "numeric",
          month: "long",
        })
      );
    } else {
      localStorage.setItem(
        "birthdaysign",
        startDate.toLocaleDateString("en-us", {
          day: "numeric",
          year: "numeric",
          month: "long",
        })
      );
    }

    localStorage.setItem(
      "birthday",
      startDate.toLocaleDateString("en-us", {
        day: "numeric",
        year: "numeric",
        month: "long",
      })
    );
    if (updateConnection) {
      updateDataName();
    } else {
      setCngScreen(3);
    }
  };

  // Connections
  const [connsel, setConnSel] = useState();

  const [Oppsel, setOppSel] = useState();
  const {
    register: registerConnection,
    handleSubmit: SubmitConnections,
    formState: { errors: errorsConnection },
  } = useForm();

  const onSubmitConn = (data) => {
    localStorage.setItem("connection", data.connection);
    localStorage.setItem("OppositeGend", data.OppConn);
    if (!updateConnection) {
      setCngScreen(4);
    }
  };
  const updateDataName = async (data) => {
    const birthday = localStorage.getItem("birthday");
    const nameval = localStorage.getItem("Name");
    const conn = localStorage.getItem("connection");
    const gen = localStorage.getItem("gender");
    const oppgen = localStorage.getItem("gender_pref");
    const location = localStorage.getItem("location");
    const InstaName = localStorage.getItem("instagram");
    const checkKey = {
      date_name: birthday,
      name: nameval,
      interested_in: connsel ? connsel : parseInt(conn),
      gender: gendersel ? gendersel : parseInt(gen),
      gender_preference: Oppsel ? Oppsel : parseInt(oppgen),
      country_name: location,
      instagram: InstaName,
    };
    try {
      const res = await patchApi(
        API_PATH.UPDATE_PROFILE.UPDATE,
        checkKey,
        axiosConfig
      );
      if (res.status) {
        setLoading(false);
        navigate("/profilesetting");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // Image
  const [img, setImg] = useState();
  const [imgURL, setImgURL] = useState("");
  const onInputClick = (event) => {
    event.target.value = "";
  };
  const uploadImage = async (e) => {
    // event.preventDefault()
    // setImg(URL.createObjectURL(e.target.files[0]));
    // setLoading(true);
    try {
      let formData = new FormData();
      formData.append("image", img[0]);
      const res = await postApi(API_PATH.PROFILE.CHANGE_PROFILE, formData);
      if (res.status) {
        // toast.success("Image Uploaded");
        getLocation();
        //  navigate("/matches");
        // setImg("");
      }
    } catch (error) {
      toast(error.response.data.message);
      setLoading(false);
    }
  };

  const tok = localStorage.getItem("token");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${tok}`,
    },
  };

  const Bsignup = async () => {
    const number = localStorage.getItem("Phone");
    try {
      setLoading(true);
      const res = await postApi(API_PATH.AUTH.SIGNUP, {
        phoneNumber: number,
        birthday: "January 1,1999",
      });
      if (res) {
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("token", res.data.token);
        const skip = localStorage.getItem("Skip");
        if (skip) {
          getLocation();
        } else {
          uploadImage();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const CreatedMailDatabase = async (data) => {
    const mail = localStorage.getItem("mail_data");
    const mail_data = JSON.parse(mail);
    const birthday = localStorage.getItem("birthdaysign");
    const gender = localStorage.getItem("gender");
    const gender_more = localStorage.getItem("gender_more");
    const name = localStorage.getItem("Name");
    try {
      setLoading(true);
      const res = await postApi(API_PATH.AUTH.SIGNUP_MAIL, {
        uid: mail_data.localId,
        birthday: birthday,
        name: name,
        profileUrl: "",
        gender: gender_more ? parseInt(gender_more) : parseInt(gender),
        email: mail_data.email,
      });
      if (res) {
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("token", res.data.token);
        const skip = localStorage.getItem("Skip");
        if (skip) {
          getLocation();
        } else {
          uploadImage();
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getLocation = async () => {
    const location = await axios.get("https://ipapi.co/json");
    UpdateDetails(location.data);
  };

  const UpdateDetails = async (data) => {
    const birthday = localStorage.getItem("birthdaysign");
    const gender = localStorage.getItem("gender");
    const gender_more = localStorage.getItem("gender_more");
    const name = localStorage.getItem("Name");
    const OppConn = localStorage.getItem("OppositeGend");
    const intrestedIn = localStorage.getItem("connection");
    try {
      const res = await patchApi(
        API_PATH.UPDATE_PROFILE.UPDATE,
        {
          date_name: birthday,
          name: name,
          interested_in: parseInt(intrestedIn),
          gender: gender_more ? parseInt(gender_more) : parseInt(gender),
          gender_preference: parseInt(OppConn),
          country_name: data.country_name,
          instagram: "",
        },
        axiosConfig
      );
      if (res.status === 200) {
        localStorage.removeItem("mail_data");
        localStorage.setItem("firstTime", "1");
        window.location.replace("/matches");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const Phone = localStorage.getItem("PhoneNumber");
  const checkFn = () => {
    Phone === "true" ? Bsignup() : CreatedMailDatabase();
  };

  useEffect(() => {
    if (tok) {
      // uploadImage();
    }

    if (updateConnection) {
      window.scrollTo({ top: -50, left: 0, behavior: "instant" });
    }
  }, []);

  const [modalIsOpenName, setIsOpenName] = React.useState(false);

  function closeModalName() {
    setIsOpenName(false);
  }
  return (
    <>
      <div className="container-fluid mx-0 px-0 Match-screen">
        <div className="row mx-auto fix_width">
          <div className="  mx-auto">
            <div className="row my-3 ">
              <div
                className="arrow_back   btn-hover w-50"
                onClick={() => {
                  updateConnection
                    ? navigate("/profilesetting")
                    : cngScreen === 1
                    ? navigate("/")
                    : setCngScreen(cngScreen - 1);
                }}
              >
                <MdArrowBackIosNew />{" "}
                <span className="align-middle"> Go Back</span>
              </div>
              <div className="w-50 text-end">
                {cngScreen === 4 && !updateConnection ? (
                  <span
                    style={{ verticalAlign: "middle" }}
                    className="btn-hover"
                    onClick={() => setIsOpenName(true)}
                  >
                    Skip
                  </span>
                ) : null}
              </div>
            </div>
            {cngScreen === 1 ? (
              <div className="SetUp">
                <div className="row">
                  <div className="div">
                    <h6 className="Phone_head">Set Up Profile</h6>
                    {updateConnection ? null : (
                      <div className="row my-3 d-flex justify-content-center">
                        <div className="bar"></div>
                        <div className="bar-blank mx-3"></div>
                        <div className="bar-blank me-3"></div>
                        <div className="bar-blank"></div>
                      </div>
                    )}

                    <p className="subHeadOne">
                      Unlock the Power of Your Name with Personology
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 pt-4">
                    <form onSubmit={handleSubmit(onSubmit)} className="">
                      <div
                        className="formSignup d-flex flex-column phone_input_width mx-auto"
                        style={{ height: "370px" }}
                      >
                        <label htmlFor="username" className="  mb-2">
                          Full Name
                        </label>
                        <input
                          type="text"
                          defaultValue={
                            (currentUser ? currentUser.displayName : "") ||
                            (updateConnection ? name : null)
                          }
                          placeholder="Enter Full Name"
                          style={{ height: "50px" }}
                          className={
                            errors.name
                              ? ` inputUsername mt-2 mb-3 ${
                                  updateConnection ? null : "border-danger"
                                } ms-2 p-2`
                              : "inputUsername mt-2 mb-2 p-2"
                          }
                          onKeyUpCapture={(e) =>
                            localStorage.setItem("Name", e.target.value)
                          }
                          {...register("name", {
                            required: true,
                          })}
                        />
                        <br />
                        <label className="  mb-3 InputUsername">Gender</label>

                        {/* {gendersel <= 3  ? ( */}
                        <div className="d-flex justify-content-between gap-3">
                          {gender.slice(0, 2).map((a) => (
                            // <div className="" key={a.id}>
                            <label
                              key={a.id}
                              htmlFor={a.gender_name}
                              className={
                                gendersel !== a.id
                                  ? " mb-2 ctn_btn-gender-unselect py-2 rounded-5 w-100"
                                  : " mb-2 ctn_btn-gender py-2 rounded-5 w-100"
                              }
                            >
                              {a.gender_name}

                              <input
                                type="radio"
                                id={a.gender_name}
                                value={a.id}
                                style={{
                                  width: "0%",
                                }}
                                className={
                                  errors.gender
                                    ? "numInput w-0 border-danger ms-2"
                                    : " numInput inputUsername w-0"
                                }
                                {...register("gender", {
                                  required: true,
                                })}
                                onClick={(e) => {
                                  setGenderSel(a.id);
                                  // localStorage.setItem("gender", a.id);
                                }}
                              />
                            </label>

                            // </div>
                          ))}
                        </div>
                        {/* ) : null} */}
                        {gendersel > 2 ? (
                          <div className=" ">
                            <label className=" my-2 ctn_btn-gender py-2 w-100">
                              {gendersel === 1
                                ? "Male"
                                : null || gendersel === 2
                                ? "Female"
                                : null || gendersel === 3
                                ? "Neutral"
                                : null || gendersel === 4
                                ? "Trans Woman"
                                : null || gendersel === 5
                                ? "Transsexual"
                                : null || gendersel === 6
                                ? "Transsexual Female"
                                : null || gendersel === 7
                                ? "Transsexual Male"
                                : null || gendersel === 8
                                ? "Transgender"
                                : null || gendersel === 9
                                ? "Transfeminine"
                                : null}
                            </label>{" "}
                          </div>
                        ) : null}
                        <p
                          className="subHeadOne btn-hover text-decoration-underline mt-2"
                          onClick={() => setReportShow(true)}
                        >
                          I Identify Differently
                        </p>
                      </div>
                      <div
                        className={
                          ReportShow === false
                            ? "card p-4 card-report-pwa_verify vert-move-card-pwa bg-body-secondary  mx-auto  "
                            : "card p-4 card-report-pwa_verify vert-move-out-card-pwa bg-body-secondary  mx-auto  "
                        }
                        style={
                          ReportShow === false
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <div className="d-flex">
                          <h5 className="text-center w-100 mb-3">
                            Your Genders
                          </h5>

                          <RxCross1
                            className="text-end btn-hover  "
                            onClick={() => setReportShow(false)}
                          />
                        </div>

                        <div className="" style={{ height: "370px" }}>
                          {gender.map((a) => (
                            <div
                              className="code-list"
                              key={a.id}
                              htmlFor={a.gender_name}
                            >
                              <input
                                type="radio"
                                id={a.gender_name}
                                value={a.id}
                                className={
                                  errors.gender
                                    ? "numInput  mt-2 mb-3 border-danger ms-2"
                                    : " numInput inputUsername mt-2 mb-2 ms-2"
                                }
                                {...register("gender", {
                                  required: true,
                                })}
                                onChange={(e) => {
                                  setGenderSel(a.id);
                                  // localStorage.setItem("gender", a.id);
                                }}
                              />
                              <label
                                htmlFor={a.gender_name}
                                className={
                                  gendersel !== a.id
                                    ? "   py-3 fs-6 fs-sm-6 fs-md-5 fs-lg-5 fs-xl-5 fs-xxl-5"
                                    : "   py-3 fs-6 fs-sm-6 fs-md-5 fs-lg-5 fs-xl-5 fs-xxl-5 "
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  localStorage.setItem("gender_more", a.id);
                                  setReportShow(false);
                                }}
                              >
                                {a.gender_name}
                              </label>{" "}
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* {updateConnection ? ( */}
                      <div className=" text-center btn-div border border-0 mx-auto phone_input_width text-center">
                        {Loading ? (
                          <div
                            className="ctn_btn py-2 mx-auto rounded-5 w-100"
                            style={{ height: "50px" }}
                          >
                            <ButtonLoader />
                          </div>
                        ) : (
                          <button
                            className="ctn_btn py-2 border border-0 mx-auto rounded-5 w-100"
                            style={{ height: "50px" }}
                            type="submit"
                            onClick={
                              updateConnection ? () => updateDataName() : null
                            }
                          >
                            {updateConnection ? "Update" : "Next"}
                          </button>
                        )}
                        <div className="">
                          <Footer2 />
                        </div>
                      </div>
                      {/* ) : null} */}
                    </form>
                  </div>
                </div>
              </div>
            ) : null}

            {/* ////////////////////////////// Select birthday///////////////////////////////// */}

            {cngScreen === 2 ? (
              <div className="row">
                <div className="div">
                  <h6 className="Phone_head">Date of Birth</h6>
                  {updateConnection ? null : (
                    <div className="row my-3 d-flex justify-content-center">
                      <div className="bar"></div>
                      <div className="bar mx-3"></div>
                      <div className="bar-blank me-3"></div>
                      <div className="bar-blank"></div>
                    </div>
                  )}

                  <p className="subHeadOne px-4">
                    We're glad you joined! Your date of birth is important to
                    determine your secret name
                  </p>
                </div>

                <div className="" style={{ height: "390px" }}>
                  <div className="text-center">
                    <img
                      src={Zodiac_img ? Zodiac_img : aries1}
                      alt=""
                      width={90}
                      height={100}
                      className="img_clr object-fit-contain"
                    />
                    <p className="  text-center fs-4 mt-1">
                      {Zodiac_sign ? Zodiac_sign : "Aries"}
                    </p>
                  </div>
                  <div className="text-center" style={{ height: "50px" }}>
                    <DatePicker
                      id="DOB"
                      className="inputUsername date-input_info mb-2 phone_input_width 2 ps-2"
                      placeholderText={"Birthday Date"}
                      selected={startDate ? startDate : sampleDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      // minDate={getMinAndMaxDateForDob().minDate}
                      // maxDate={getMinAndMaxDateForDob().maxDate}
                      dateFormat="MMMM dd, yyyy"
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      required
                    />
                  </div>
                </div>

                <div className="text-center btn-div mx-auto phone_input_width text-center">
                  {Loading ? (
                    <div
                      className="ctn_btn py-2 border border-0  mx-auto  rounded-5 w-100"
                      style={{ height: "50px" }}
                    >
                      <ButtonLoader />
                    </div>
                  ) : (
                    <button
                      className="ctn_btn py-2 border border-0 mx-auto  rounded-5 w-100"
                      style={{ height: "50px" }}
                      type="submit"
                      onClick={() => changeNext()}
                    >
                      {updateConnection ? "Update" : "Next"}
                    </button>
                  )}
                  <div className="  ">
                    <Footer2 />
                  </div>
                </div>
              </div>
            ) : null}

            {/* /////////////////LOOKING for /////////////////// */}
            {cngScreen === 3 ? (
              <div className="div">
                <h6 className="Phone_head">I’m looking for</h6>
                {updateConnection ? null : (
                  <div className="row my-3 d-flex justify-content-center">
                    <div className="bar"></div>
                    <div className="bar mx-3"></div>
                    <div className="bar me-3"></div>
                    <div className="bar-blank"></div>
                  </div>
                )}
                <p className="subHeadOne">Specify your preferences</p>

                <div className="phone_input_width mx-auto">
                  <div className="row">
                    <div className="col-12">
                      <form
                        onSubmit={SubmitConnections(onSubmitConn)}
                        className="formSignup"
                      >
                        <div
                          className="form-height"
                          style={{ height: "413px" }}
                        >
                          {connection.map((i) => (
                            <div key={i.id} className="text-center">
                              <input
                                type="radio"
                                id={i.name}
                                value={i.id}
                                className={
                                  errors.mobile
                                    ? "numInput  mt-2 mb-3 border-danger ms-2 d-none"
                                    : " numInput inputUsername mt-2 mb-2 ms-2 d-none"
                                }
                                {...registerConnection("connection", {
                                  required: true,
                                })}
                                onClick={(e) => {
                                  setConnSel(i.id);
                                  // localStorage.setItem("connection", i.id);
                                }}
                              />

                              <label
                                htmlFor={i.name}
                                style={{ height: "50px" }}
                                className={
                                  connsel !== i.id
                                    ? " mb-2 ctn_btn-gender-unselect my-2 d-flex justify-content-center align-items-center w-100 rounded-5"
                                    : " mb-2 ctn_btn-gender my-2 d-flex justify-content-center align-items-center w-100 rounded-5"
                                }
                              >
                                {i.name}
                              </label>
                            </div>
                          ))}
                          <p className="subHeadOne">With...</p>

                          <div className="d-flex justify-content-between mt-2 gap-2">
                            {oppgender.map((i) => (
                              <div className="" key={i.id}>
                                <input
                                  type="radio"
                                  id={i.gender_name}
                                  value={i.id}
                                  placeholder="Enter Full Name"
                                  className={
                                    errors.mobile
                                      ? "numInput  border-danger d-none"
                                      : " numInput inputUsername d-none"
                                  }
                                  {...registerConnection("OppConn", {
                                    required: true,
                                  })}
                                  onClick={(e) => {
                                    setOppSel(i.id);
                                    localStorage.setItem("gender_pref", i.id);
                                  }}
                                />

                                <label
                                  htmlFor={i.gender_name}
                                  className={
                                    Oppsel !== i.id
                                      ? " mb-2 ctn_btn-gender-unselect py-2 rounded-5"
                                      : "mb-2 ctn_btn-gender py-2 rounded-5"
                                  }
                                  style={{ width: "100px" }}
                                >
                                  {i.gender_name}
                                </label>
                              </div>
                            ))}

                            <input
                              type="radio"
                              id="All"
                              value="0"
                              placeholder="Enter Full Name"
                              className={
                                errors.mobile
                                  ? "numInput  mt-2 mb-3 border-danger ms-2 d-none"
                                  : " numInput inputUsername  d-none"
                              }
                              {...registerConnection("OppConn", {
                                required: true,
                              })}
                              onClick={(e) => {
                                setOppSel(0);
                                localStorage.setItem("gender_pref", 0);
                              }}
                            />

                            <label
                              htmlFor="All"
                              className={
                                Oppsel !== 0
                                  ? " mb-2 ctn_btn-gender-unselect py-2 rounded-5"
                                  : "mb-2 ctn_btn-gender py-2 rounded-5"
                              }
                              style={{ width: "100px" }}
                            >
                              All
                            </label>
                          </div>
                        </div>

                        <div className="">
                          <div className="btn-div text-center mx-auto  text-center">
                            {Loading ? (
                              <div
                                className="ctn_btn py-2 border border-0 mx-auto  rounded-5 w-100"
                                style={{ height: "50px" }}
                              >
                                <ButtonLoader />
                              </div>
                            ) : (
                              <button
                                className="ctn_btn py-2 border border-0 mx-auto  rounded-5 w-100"
                                style={{ height: "50px" }}
                                type="submit"
                                onClick={
                                  updateConnection
                                    ? () => updateDataName()
                                    : null
                                }
                              >
                                {updateConnection ? "Update" : "Next"}
                              </button>
                            )}
                            <div className=" ">
                              <Footer2 />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* /////////////////// Image /////////////////// */}
            {cngScreen === 4 ? (
              <div className="">
                <h6 className="Phone_head fs-3 mb-0">My Photo</h6>
                <div className="row my-3 d-flex justify-content-center">
                  <div className="bar"></div>
                  <div className="bar mx-3"></div>
                  <div className="bar me-3"></div>
                  <div className="bar"></div>
                </div>
                <p className="subHeadOne">Upload your best photo</p>

                <p
                  className="w-75 mx-auto  "
                  style={{ fontSize: "10px", color: "#827c7c !important" }}
                >
                  FYI : adding a profile picture is better for networking with
                  people that you are potentially ideal for, or you will not be
                  in the swipe and search
                </p>
                <div className="">
                  <div className="row">
                    <div
                      className="col-12 text-center position-relative "
                      style={{ height: "420px" }}
                    >
                      <label
                        htmlFor="uploadImage1"
                        className="uploadImage01 text-center py-3"
                      >
                        {!imgURL || imgURL === null ? (
                          <div className=" mx-auto my-auto">
                            <img
                              src={FiPlus}
                              className="Add_img_icon position-absolute top-50  translate-middle"
                              alt=""
                            />
                          </div>
                        ) : (
                          <div className="">
                            <img src={imgURL} className="plus1" alt="" />
                            <div className="  pos_ition_New">
                              <div
                                className=" button d-none btn-upload fs-4 text-light   avtar1_new"
                                // onClick={() => setAvatar(i.id)}
                                title="Set As Avatar"
                              >
                                <p> </p>
                              </div>
                              <div
                                className="button2 btn-upload2 fs-4 text-danger avtar2_new del_"
                                onClick={(e) => {
                                  setImg(null);
                                  setImgURL(null);
                                }}
                                title="Delete Image"
                              >
                                <p className=" mb-0">
                                  {" "}
                                  <RiDeleteBin6Line />{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </label>
                      <input
                        id="uploadImage1"
                        name="uploadImage1"
                        type="file"
                        onClick={onInputClick}
                        className="uploadImg01 d-none"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          setImg(e.target.files);
                          setImgURL(URL.createObjectURL(e.target.files[0]));
                        }}
                        // onChange={uploadImage}
                      />
                    </div>

                    <div className="btn-div mx-auto mx-auto phone_input_width text-center">
                      {Loading ? (
                        <div
                          className="ctn_btn py-2 border border-0 mx-auto rounded-5 w-100"
                          style={{ height: "50px" }}
                        >
                          <ButtonLoader />
                        </div>
                      ) : (
                        <div
                          className="ctn_btn py-2 border border-0 mx-auto rounded-5 text-base w-100 d-flex justify-content-center align-items-center"
                          style={{ height: "50px" }}
                          type="submit"
                          onClick={() => checkFn()}
                        >
                          <span style={{ verticalAlign: "sub" }}>
                            Let’s Get Started
                          </span>
                        </div>
                      )}

                      <div className=" ">
                        <Footer2 />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <Modal
            isOpen={modalIsOpenName}
            onRequestClose={closeModalName}
            style={customStyles}
            contentLabel="Example Modal Connections"
            ariaHideApp={false}
          >
            <div className="">
              <p
                className=" mx-auto  line-para"
                style={{ fontSize: "15px", color: "#827c7c !important" }}
              >
                FYI : adding a profile picture is better for networking with
                people that you are potentially ideal for, or you will not be in
                the swipe and search
              </p>
              <div className="text-end">
                <span
                  className="btn-hover"
                  onClick={() => setIsOpenName(false)}
                >
                  Add Photo
                </span>
                <span
                  className="ms-3 btn-hover"
                  onClick={() => {
                    checkFn();
                    localStorage.setItem("Skip", true);
                    closeModalName();
                  }}
                >
                  Skip
                </span>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default VerifyYourself;
