import React, { useEffect, useRef, useState } from "react";
import "./profile.css";
import Footer2 from "../../layout/Footer/Footer2";
import { deletetApi, getApi, postApi } from "../../Api/Api";
import { API_PATH } from "../../Api/ApiPAth";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../layout/Loader/Loader";
import Navbar3 from "../../layout/BottomNavbar/Navbar3";
import { MdArrowBackIosNew } from "react-icons/md";
import { Icon } from "@iconify/react";
import dp from "../../assets/Dp.png";
import InfiniteScroll from "react-infinite-scroll-component";

const Friends = (props) => {
  const location = useLocation();
  const { friendsLength } = location.state;
  const [friendsLengthUpdate, setfriendsLength] = useState();

  const [friends, setFriends] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount] = useState(0);

  async function Friends() {
    try {
      const res = await getApi(`${API_PATH.PROFILE.FRIEND_LIST}/${page}`);
      if (res.status) {
        if (page === 1) {
          setFriends(res.data);
          localStorage.setItem("friendsPageData", JSON.stringify(res.data));
        } else {
          const new_friend = friends.concat(res.data);
          setFriends(new_friend);
          localStorage.setItem("friendsPageData", JSON.stringify(new_friend));
        }

        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    setfriendsLength(friendsLength);
    const friendsData = localStorage.getItem("friendsPageData");
    if (friendsData) {
      setFriends(JSON.parse(friendsData));
    } else {
      setLoading(true);
    }
    Friends();
    localStorage.setItem("friendstatus", "");
    localStorage.setItem("uid", "");
    if (page === 1) {
      window.scrollTo({ top: -50, left: 0, behavior: "instant" });
    }
  }, [page]);

  // handle next
  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1);
  };

  const navigate = useNavigate();
  const nextpage = (id) => {
    navigate({
      pathname: `/userprofile`,
      search: `?friendprofileId=${id}`,
    });
  };
  const movemnet = (id) => {
    navigate({
      pathname: "/chatHome",
      search: `?Uid=${id} `,
    });
  };

  const DeleteFriend = async (id) => {
    setLoading(true);
    try {
      const res = await deletetApi(API_PATH.DELETE.DELETE_FRIEND, {
        id: id,
      });
      if (res.data.status === "success") {
        const updatedData = friends.filter((item) => item.id !== id);
        setFriends(updatedData);
        setfriendsLength(friendsLength - 1);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const containerRef_friends = useRef(null);

  const handleScroll_friends = (e) => {
    const container = containerRef_friends.current;
    if (container.scrollHeight === container.clientHeight) {
      handleNext();
    }
  };
  return (
    <>
      {Loading ? <Loader /> : null}
      <div className="container-fluid ">
        <div className="container-fluid mx-0 px-0 Match-screen">
          <div className="row pendingFilter mx-auto fix_width">
            <div className="  mx-auto ">
              <div className="profileUpperpart navMatchbottom1 py-1 position-sticky top-0 backColor">
                <div className="  py-3  " onClick={() => navigate(-1)}>
                  <div className="arrow_back   btn-hover">
                    <MdArrowBackIosNew />{" "}
                    <span className="align-middle"> Go Back</span>
                  </div>
                </div>
                <div className="col fs-5  text-center py-3  ">
                  <p className="subHeadOne mb-0 px-0">
                    Connections ({friends.length})
                  </p>
                </div>
                <div className="col-2"></div>
              </div>
              <div className="col mb-4 profile-border2 ">
                <InfiniteScroll
                  dataLength={friends.length}
                  hasMore={true}
                  next={handleNext}
                >
                  {friends.map((p) => (
                    <div
                      key={p.id}
                      className="pendingPara py-2 profile-border"
                      // ref={containerRef_friends}
                      // onWheel={handleScroll_friends}
                    >
                      <div className="PointAdd" onClick={() => nextpage(p.id)}>
                        <img
                          src={p.image !== "undefined" || null ? p.image : dp}
                          className="rounded-circle PointAdd  object-fit-cover img_brdr"
                          height={"60px"}
                          width={"60px"}
                          alt=""
                        />
                        <div className="d-inline-block pendingContent ps-2">
                          <p className="  mb-0 text-start">{p.name}</p>
                          <span className="famousContent text-start  ">
                            Ideal For:{" "}
                            <span className="text-capitalize">{p.ideal}</span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <button
                          className="connection_btn "
                          onClick={() => movemnet(p.id)}
                        >
                          Chat
                        </button>
                        <Icon
                          icon="mingcute:user-x-fill"
                          color="#909090"
                          className="fs-3 ms-2 btn-hover"
                          onClick={() => DeleteFriend(p.id)}
                        />
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
              {friends.length === 0 && !Loading ? (
                <p className="text-center position-absolute start-50 top-50 translate-middle fs-4 w-75">
                  Sorry you don't have Friend
                </p>
              ) : null}
              {friends.length >= 6 ? (
                <div className="backColor">
                  <Footer2 />
                </div>
              ) : null}
              <div
                className="position-relative  "
                style={{ height: "100px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        {friends.length < 6 ? (
          <div className="backColor">
            <Footer2 />
          </div>
        ) : null}
        <div className="fix_width mx-auto">
          <Navbar3 />
        </div>
      </div>
    </>
  );
};
export default Friends;
