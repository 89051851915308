import React, { useContext, useEffect, useState } from 'react';
import './sidebar.scss';
import { BiLogOut, BiShare, BiUser } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { getApi, postApi } from '../../Api/Api';
import { API_PATH } from '../../Api/ApiPAth';
import { useUserAuth } from '../../auth/context/userAuthContext';
import { AiFillStar } from 'react-icons/ai';
import { BsArrowLeft } from 'react-icons/bs';
import { AuthContext } from '../../auth/context/AuthContext';
import { ChatContext } from '../../auth/context/ChatContext';
import { collection, doc, getDoc, getDocs, onSnapshot, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import { db } from '../../Firebase';
import Search from '../../pages/Chat/Chatcomponents/Search'
import Input from '../../pages/Chat/Chatcomponents/Input';
import { getAuth, onAuthStateChanged, updateProfile } from 'firebase/auth';
import Message from '../../pages/Chat/Chatcomponents/Message';
import { MdWavingHand } from 'react-icons/md';
import { Pagination } from 'react-bootstrap';
import axios from 'axios';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';



const prodConfig = {
  baseURL: `https://api.stripe.com`,
  withCredentials: true
};

const axiosInstance = axios.create(prodConfig);

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  }, (error) => {
    return Promise.reject(error)
  });

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  }, (error) => {
    return Promise.reject(error);
  });
const Sidebar = () => {
  const { logOut } = useUserAuth();
  const navigate = useNavigate()
  const [Chat, setChat] = useState(false);
  const [ReportShow, setReportShow] = useState(false);
  const [ReportContent, setReportContent] = useState(false);
  const [chats, setChats] = useState([]);
  const [userinfo, setUserInfo] = useState([]);
  const [friends, setFriends] = useState([]);
  const [friendsPending, setFriendsPending] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pagePending, setPagePending] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataNone3, setDataNone3] = useState(false);
  const [active, setActive] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);
  const { data } = useContext(ChatContext);
  const auth = getAuth();
  const user = auth.currentUser;
  const [recipt, setRecipt] = useState('');
  const [isfriend, setisFriend] = useState(false);

  const queryParams = new URLSearchParams(window.location.search)
  const sessionID = queryParams.get('session_id')
  const uid = queryParams.get('Uid');
  const shareId = queryParams.get('rid');

  const Jump = () => {
    navigate('/profile')
  }
  const handleLogout = async () => {
    try {
      const res = await getApi(`${API_PATH.AUTH.LOGOUT}`)
    if (res.status) {
      // console.log(res) 
      logOut();
      localStorage.clear()
      navigate('/')

    }
    } catch (error) {
      
    }
  };

  const getDocumnent = () => {
    const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      setUserInfo(doc.data());
    });
    return () => {
      unsub();
    };
  };

  //User Id for Chat 
  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        setChats(doc.data());
      });

      return () => {
        unsub();
      };
    };

    currentUser.uid && getChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // getDocumnent()
  }, [currentUser.uid]);

  // console.log(chats);

  const handleSelect = (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
  };

  // messages

  const [messages, setMessages] = useState([]);
  useEffect(() => {
    const unSub = onSnapshot(doc(db, "chats", data.chatId), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });

    return () => {
      unSub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.chatId]);

  ///matches
  useEffect(() => {
    async function Friends() {
      setLoading(true)
      try {
        const res = await getApi(`${API_PATH.PROFILE.FRIEND_LIST}/${page}`)
        if (res.status) {
          setFriends(res.data)
          setLoading(false)
        }
        if (res.data.length === 0 && page > 1) {
          setDataNone3(true)
        }

      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    Friends();

    async function FriendsPending() {
      setLoading(true)
      try {
        const res = await getApi(`${API_PATH.PROFILE.PENDING_LIST}/${page}`)
        if (res.status) {
          setFriendsPending(res.data)
          setLoading(false)
        }
        if (res.data.length === 0 && page > 1) {
          setDataNone3(true)
        }

      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    FriendsPending();
  }, [page, uid]);



  // handle next
  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1)
  }

  // handle previous
  const handlePrevios = () => {
    if (page === 1) return page;
    setPage(page - 1)
  }

  // handle next
  const handleNextPending = () => {
    if (pagePending === pageCount) return pagePending;
    setPagePending(page + 1)
  }

  // handle previous
  const handlePreviosPending = () => {
    if (pagePending === 1) return pagePending;
    setPagePending(pagePending - 1)
  }
  const nextpage = (id) => {
    navigate(
      {
        pathname: `/userprofile`,
        search: `?friendprofileId=${id}`,
      }
    )
  }

  const handleSelectChange = (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
    setChat(true)
  };

  const [userSearch, setUserSearch] = useState({})

  const chatSet = async (params) => {
    const q = query(
      collection(db, "users"),
      where("UserID", "==", uid ? uid : JSON.stringify(params))
    );

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUserSearch(doc.data());
        handleSelectSearch(doc.data())
        handleSelectChange(doc.data())
      });
    } catch (err) {
    }
  }
  const handleSelectSearch = async (userSearch) => {
    //check whether the group(chats in firestore) exists, if not create 
    const combinedId =
      currentUser.uid > userSearch.uid
        ? currentUser.uid + userSearch.uid
        : userSearch.uid + currentUser.uid;
    try {
      const res = await getDoc(doc(db, "chats", combinedId));
      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });
        //create user chats
        await updateDoc(doc(db, "userChats", currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: userSearch.uid,
            displayName: userSearch.displayName,
            photoURL: userSearch.photoURL,
            UserDob: userSearch.UserDob,
            UserID: userSearch.UserID
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", userSearch.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }
    } catch (err) { }
    findChat(combinedId)
    setUserSearch(null);
  };
  useEffect(() => {
    if (uid) {
      chatSet(uid)
    }
  }, [uid])
  const findChat = (id) => {
    const unSub = onSnapshot(doc(db, "chats", id), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });
    return () => {
      unSub();
    };
  }

  const pay = async () => {
    window.location.replace('https://buy.stripe.com/test_14k5n4fZj4glfle3cg')
  }

  useEffect(() => {
    async function SettingData() {
      setLoading(true)
      try {
        const res = await getApi(API_PATH.SETTING.USER_SETTING)
        if (res.status) {
          setLoading(false)
          localStorage.setItem('birthday1', res.data.date_name.slice(0, -6))
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    SettingData();


  }, []);


  const sID = 'sk_test_51MJssBSIOG3lP4WJ5pTZH6clVf9PkN0WyYnWzLY0zj7fpFRmtoA77IS5xJuu6zsp7mzJO29cxQW3eroKfFcEJLcH00BIrNp8VZ'
  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const res = await axiosInstance.get(`/v1/checkout/sessions/${sessionID}`, {
          headers: {
            "Authorization": `Bearer ${sID}`,
          }
        })
        if (res.status) {
          let objJsonStr = JSON.stringify(res.data);
          var encoded = btoa(objJsonStr)
          setRecipt(encoded)
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
        setLoading(false)

      }
    }
    if (sessionID) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function RelationData() {
      const ourBirth = localStorage.getItem('birthday1')
      try {
        const res = await postApi(API_PATH.REPORT.GET_REPORT, {
          "birthday_1": ourBirth,
          "birthday_2": data.user?.UserDob,
          "receipt": recipt
        })
        if (res.status) {
        }
      } catch (error) {
      }
    }

    const reprtType = localStorage.getItem('reportType')

    if (reprtType === 'relationship' && recipt) {
      RelationData();
    }

  }, [recipt]);

  const [reportdata, setReportdata] = useState([])
  const relationreport = async (e) => {
    // setLoading(true) 
    const ourBirth = localStorage.getItem('birthday1')
    try {
      const res = await postApi(API_PATH.REPORT.RELATIONSHIP_REPORT, {
        "birthday_1": ourBirth,
        "birthday_2": data.user?.UserDob
      })
      if (res.data) {
        setReportdata(res.data)
        setReportContent(false)
      }
    } catch (error) {
      setReportShow(true)
      setReportContent(true)
      localStorage.setItem('reportType', 'relationship')

    }
  }
  const IdealFor = localStorage.getItem('ideal')

  const SettingData = async () => { 
    const id = localStorage.getItem('id')
    try {
      const res = await getApi(API_PATH.PROFILE.USER_DATA)
      if (res.status) {
        localStorage.setItem('Image', res.data.image)
        localStorage.setItem('Name', res.data.name)
        if (res) {
          await updateProfile(`${res.data.id}`, {
            displayName: `${res.data.name}`,
            photoURL: `${res.data.image}`,
            UserDob: `${res.data.birthday_report.date_name}`,
            UserID: `${res.data.id}`
          });
          //create user on firestore
          await setDoc(doc(db, "users", `${res.data.id}`), {
            uid: auth.currentUser.uid,
            displayName: `${res.data.name}`,
            photoURL: `${res.data.image}`,
            UserDob: `${res.data.birthday_report.date_name}`,
            UserID: `${res.data.id}`
          });

          //create empty user chats on firestore
          await setDoc(doc(db, "userChats", `${res.data.id}`), {});
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {

    SettingData()

  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const statusCheck = (i) => {
    let blog = friends.find((blog) => blog.id === parseInt(uid ? uid : i));
    if (blog) {
      // console.log("True")
      setisFriend(true)
      localStorage.setItem('friendstatus', true)
    } else if (!blog) {
      setisFriend(false)
      localStorage.setItem('friendstatus', false)
    }
  }
  useEffect(() => {
    if (uid) {
      statusCheck(uid)
    }
  }, [uid, statusCheck]);

  // const [posts, setPosts] = useState([])
  // const postRef = collection(db, "userChats")
  // useEffect(() => {
  //   const getPost = async () => {
  //     const q = query(postRef, where("uid", "==", 'DpDktLK9AGNsih4ohC76E9yedSH2'));
  //     const data = await getDocs(q)
  //     console.log(data)
  //     setPosts(data.docs.map((doc) => ({
  //       ...doc.data(),
  //       id: doc.id
  //     })));
  //   };
  //   const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
  //      console.log(doc)
  //   });

  //   // getPost();
  //   unsub();
  // }, []); 

  const tab2 = localStorage.getItem("tab2");
  
  return (
    <div>
      <ToastContainer />
      <div className="container-fluid ">
        <div className="row headerRight ">
          {Chat ? null : <div className="col d-flex headerBg py-2 border-bottom-0 ps-0"  >
            <div className="col d-flex profileHeader my-1 py-1 ms-1 PointAdd" onClick={() => Jump()} >
              <img src={currentUser.photoURL} alt="" height='33px' width='33px' className='rounded-circle ms-1 ' />
              <p className=' fw-bold m-0 pt-1 mx-2 nameInput' title={currentUser.displayName}>{currentUser.displayName}</p>
            </div>
            <div className="col d-flex justify-content-around py-2">
              <Link to='/profile' className='m-0 borderForIcon'><BiUser /></Link>
              <Link to='/reports' className='m-0 borderForIcon'><AiFillStar /></Link>
              <Link to='/' className='m-0  borderForIcon1' ><BiLogOut className='a' onClick={() => handleLogout()} /></Link>
            </div>
          </div>}
          <div className="contentPart border-top-0 px-0">
            {
              Chat ? null : <ul className="nav py-2 px-auto">
                <li className="nav-item tab-all"><a className={tab2 === 'tab1' ? "nav-link active  show py-0 px-0 ms-3" : "nav-link show  py-0 px-0 ms-3"} href="#tab1" data-bs-toggle="tab"><button className='side-chat-btn  fs-6 fw-bold'>Friends</button></a></li>
                <li className="nav-item tab-all"><a className={tab2 === 'tab2' ? "nav-link active show py-0 px-0 ms-3" : "nav-link    show  py-0 px-0 ms-3"} href="#tab2" data-bs-toggle="tab"><button className='side-chat-btn  fs-6 fw-bold'>Pending</button></a></li>
              </ul>
            }
            <div className="tab-content">
              <div className={tab2 === 'tab1' ? "tab-pane active fontawesome-demo pb-5" : "tab-pane  fontawesome-demo pb-5"} id="tab1" >
                {friends.length > 0 ?
                  <div className="row pendingFilter mx-0">
                    <div className="col-11 px-0 pb-4" style={Chat ? { display: 'none' } : { display: 'block' }}>
                      {friends.map(p => (
                        <div key={p.id} className="pendingPara py-2">
                          <div className="PointAdd" onClick={() => nextpage(p.id)}>
                            <img src={p.image} className='rounded-circle PointAdd' height={'40px'} width={'40px'} alt='' />
                            <div className="d-inline-block pendingContent ps-3">
                              <p className='text-light mb-0 text-start nameInput-chat'>{p.name}</p>
                              <span className='famousContent text-start  nameInput-chat'>Ideal For: {p.ideal}</span>
                            </div>
                          </div>
                          <div className="">
                            <p className='friendIcon pt-1 fs-4 icon-hand'><MdWavingHand onClick={() => { setActive(true); chatSet(p.id, p.name); localStorage.setItem('ideal', p.ideal); statusCheck(p.id); localStorage.setItem('tab2', 'tab1') }} /></p>
                          </div>
                        </div>
                      ))}
                      {
                        friends.length > 14 ? <div className='d-flex justify-content-center align-item-center'>
                          <Pagination>
                            <Pagination.Prev onClick={() => { handlePrevios(); setDataNone3(false) }} disabled={page === 1} />
                            {
                              Array(pageCount).fill(null).map((ele, index) => {
                                return (
                                  <>
                                    <Pagination.Item active={page === index + 1 ? true : false} onClick={() => setPage(index + 1)}>{index + 1}</Pagination.Item>
                                  </>
                                )
                              })
                            }
                            <Pagination.Next onClick={handleNext} disabled={page === pageCount} />
                          </Pagination>
                        </div> : null
                      }
                    </div>
                    <div className="col px-0" style={Chat ? { display: 'block' } : { display: 'none' }}>
                      <div className="chat">
                        <div className="chatInfo d-flex flex-space-between">
                          <BsArrowLeft className=' ms-1 me-auto pointerIcon fs-5 text-light' onClick={() => {
                            setChat(false); setReportShow(false); navigate({ pathname: '/matches' }); localStorage.setItem('friendstatus', '')
                          }} />
                          <span className='mx-auto text-light'>{data.user?.displayName}</span>
                          <img src={data.user?.photoURL} alt="" height='40px' width='40px' className='rounded-circle ms-1 ms-auto' />
                          <div className="chatIcons">
                          </div>
                        </div>
                        <div className="Report_chat_button bg-black">
                          <div className="">
                            <p className='text-chat my-3'><span>Ideal For <b>{IdealFor}</b></span></p>
                          </div>
                          <div className="badget-chat text-center py-2">
                            <p className='mb-1'>Relationship Report</p>
                            <button className=' badget-btn' onClick={() => {
                              relationreport();
                              setReportShow(true);
                            }}>Read </button>
                          </div>
                        </div>
                        <div className="messages">
                          {messages.map((m) => (
                            <Message message={m} key={m.id} />

                          ))}
                          {isfriend === false ? <div className="text-center"><button className=' badget-btn'>Communicate</button></div> : null}
                        </div>
                        <Input />
                        <div className={ReportShow === false ? "card p-4 card-report  vert-move-card " : "card p-4 card-report  vert-move-out-card height-vert"} style={ReportShow === false ? { display: 'none' } : { display: 'block' }}>
                          <hr className='text-dark mt-0  mx-auto' onClick={() => setReportShow(false)} />
                          {ReportContent ? <div className="overflow-hidden">
                            <div className="row suv mx-0">
                              <div className="col ">
                                <div className="col p-5 sub2-img">
                                  <div className="BlackImg2 text-center text-light">
                                    <p className='fs-3 fw-bold mb-0 pb-2 blackImgContent'>$0.99</p>
                                    <span className='BlackColor'>report</span>
                                  </div>
                                </div>
                                <div className="sub-content py-5 text-dark">
                                  Pay only 99 cents to unlock each report!
                                </div>
                                <div className="d-grid justify-content-center">
                                  <button className='sub-btn-1 mt-3 border' onClick={() => { pay() }}  >UNLOCK REPORT</button>
                                  <button className='sub-btn-2 mt-3' onClick={() => setReportShow(false)}>CANCEL</button>
                                </div>
                              </div>

                            </div>
                          </div> : <div className="">
                            <h5 className='text-dark'>Ideal For <span><b>{IdealFor}</b></span></h5>
                            <div className="ForCardScroll">
                              <p className='text-start text-dark para-chat-report'>{reportdata.report}</p>
                            </div>
                            {/* text={`https://idealfor.xyz/v1/profile/share?id=${reportdata.id}`} */}
                            <CopyToClipboard text={`${reportdata.report}`} onCopy={() => toast('Report copied')}>
                              <button className='badget-btn-report d-block mx-auto' ><BiShare className='align-middle' /> Share Report</button>
                            </CopyToClipboard>
                          </div>}

                        </div>
                      </div>
                    </div>
                  </div> :
                  <div className="">
                    <div className="col" style={Chat ? { display: 'none' } : { display: 'block' }}>
                      {dataNone3 ? <div className="d-flex flex-md-column flex-column justify-content-center align-content-center text-center  py-5"> <p className="mb-0 fs-5 text-light"> You don't have any friend yet.</p> <br />
                        <div className='d-flex justify-content-center align-item-center'>
                          <Pagination>
                            <Pagination.Prev onClick={() => { handlePrevios(); setDataNone3(false) }} disabled={page === 1} />
                            {
                              Array(pageCount).fill(null).map((ele, index) => {
                                return (
                                  <>
                                    <Pagination.Item active={page === index + 1 ? true : false} onClick={() => setPage(index + 1)}>{index + 1}</Pagination.Item>
                                  </>
                                )
                              })
                            }
                            <Pagination.Next onClick={handleNext} disabled={page === pageCount} />
                          </Pagination>
                        </div></div> :
                        <div>
                          <div className="card  mx-auto mt-5 mb-3 matches-card">
                          </div>
                          <h4 className='text-center'>Start Matching</h4>
                          <p className='matches-text w-75 mx-auto text-secondary'>People Ideal for match with you will appear here.</p>
                        </div>
                      }

                    </div>
                    <div className="col px-0" style={Chat ? { display: 'block' } : { display: 'none' }}>
                      <div className="chat">
                        <div className="chatInfo d-flex flex-space-between">
                          <BsArrowLeft className=' ms-1 me-auto pointerIcon fs-5 text-light' onClick={() => {
                            setChat(false); setReportShow(false); navigate({
                              pathname: '/matches'
                            })
                          }} />
                          <span className='mx-auto text-light'>{data.user?.displayName}</span>
                          <img src={data.user?.photoURL} alt="" height='40px' width='40px' className='rounded-circle ms-1 ms-auto' />
                          <div className="chatIcons">
                          </div>
                        </div>
                        <div className="Report_chat_button bg-black">
                          <div className="">
                            <p className='text-chat my-3'><span>Ideal For <b>{IdealFor}</b></span></p>
                          </div>
                          <div className="badget-chat text-center py-2">
                            <p className='mb-1'>Relationship Report</p>
                            <button className=' badget-btn' onClick={() => {
                              relationreport();
                              setReportShow(true);
                            }}>Read </button>
                          </div>
                        </div>
                        <div className="messages">
                          {messages.map((m) => (
                            <Message message={m} key={m.id} />

                          ))}
                          {messages.length === 4 ? <button className=' badget-btn'>Communicate</button> : null}
                        </div>
                        <Input report={reportdata.report} />
                        <div className={ReportShow === false ? "card p-4 card-report  vert-move-card " : "card p-4 card-report  vert-move-out-card height-vert"} style={ReportShow === false ? { display: 'none' } : { display: 'block' }}>
                          <hr className='text-dark mt-0  mx-auto' onClick={() => setReportShow(false)} />
                          {ReportContent ? <div className="overflow-hidden">
                            <div className="row suv mx-0">
                              <div className="col ">
                                <div className="col p-5 sub2-img">
                                  <div className="BlackImg2 text-center text-light">
                                    <p className='fs-3 fw-bold mb-0 pb-2 blackImgContent'>$0.99</p>
                                    <span className='BlackColor'>report</span>
                                  </div>
                                </div>
                                <div className="sub-content py-5 text-dark">
                                  Pay only 99 cents to unlock each report!
                                </div>
                                <div className="d-grid justify-content-center">
                                  <button className='sub-btn-1 mt-3 border' onClick={() => { pay() }}  >UNLOCK REPORT</button>
                                  <button className='sub-btn-2 mt-3' onClick={() => setReportShow(false)}>CANCEL</button>
                                </div>
                              </div>

                            </div>
                          </div> : <div className="">
                            <h5 className='text-dark'>Ideal For <span><b>{IdealFor}</b></span></h5>
                            <div className="ForCardScroll">
                              <p className='text-start text-dark para-chat-report'>{reportdata.report}</p>
                            </div>
                            {/* text={`https://idealfor.xyz/v1/profile/share?id=${reportdata.id}`} */}
                            <CopyToClipboard text={`${reportdata.report}`} onCopy={() => toast('Report copied')}>
                              <button className='badget-btn-report d-block mx-auto' ><BiShare className='align-middle' /> Share Report</button>
                            </CopyToClipboard>
                          </div>}

                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className={tab2 === 'tab2' ? "tab-pane active fontawesome-demo pb-5" : "tab-pane  fontawesome-demo pb-5"}  id="tab2" >
                {friendsPending.length > 0 ?
                  <div className="row pendingFilter mx-0">
                    <div className="col-11 px-0 pb-4" style={Chat ? { display: 'none' } : { display: 'block' }}>
                      {friends.map(p => (
                        <div key={p.id} className="pendingPara py-2">
                          <div className="PointAdd" onClick={() => nextpage(p.id)}>
                            <img src={p.image} className='rounded-circle PointAdd' height={'40px'} width={'40px'} alt='' />
                            <div className="d-inline-block pendingContent ps-3">
                              <p className='text-light mb-0 text-start nameInput-chat'>{p.name}1</p>
                              <span className='famousContent text-start  nameInput-chat'>Ideal For: {p.ideal}</span>
                            </div>
                          </div>
                          <div className="">
                            <p className='friendIcon pt-1 fs-4 icon-hand'><MdWavingHand onClick={() => { chatSet(p.id, p.name); localStorage.setItem('ideal', p.ideal); statusCheck(p.id); localStorage.setItem('tab2', 'tab2') }} /></p>
                          </div>
                        </div>
                      ))}
                      {
                        friendsPending.length > 14 ? <div className='d-flex justify-content-center align-item-center'>
                          <Pagination>
                            <Pagination.Prev onClick={() => { handlePreviosPending(); setDataNone3(false) }} disabled={page === 1} />
                            {
                              Array(pageCount).fill(null).map((ele, index) => {
                                return (
                                  <>
                                    <Pagination.Item active={page === index + 1 ? true : false} onClick={() => setPage(index + 1)}>{index + 1}</Pagination.Item>
                                  </>
                                )
                              })
                            }
                            <Pagination.Next onClick={handleNextPending} disabled={page === pageCount} />
                          </Pagination>
                        </div> : null
                      }
                    </div>
                    <div className="col px-0" style={Chat ? { display: 'block' } : { display: 'none' }}>
                      <div className="chat">
                        <div className="chatInfo d-flex flex-space-between">
                          <BsArrowLeft className=' ms-1 me-auto pointerIcon fs-5 text-light' onClick={() => {
                            setChat(false); setReportShow(false); navigate({ pathname: '/matches' }); localStorage.setItem('friendstatus', ''); localStorage.setItem('rreport', '');
                          }} />
                          <span className='mx-auto text-light'>{data.user?.displayName}</span>
                          <img src={data.user?.photoURL} alt="" height='40px' width='40px' className='rounded-circle ms-1 ms-auto' />
                          <div className="chatIcons">
                          </div>
                        </div>
                        <div className="Report_chat_button bg-black">
                          <div className="">
                            <p className='text-chat my-3'><span>Ideal For <b>{IdealFor}</b></span></p>
                          </div>
                          <div className="badget-chat text-center py-2">
                            <p className='mb-1'>Relationship Report</p>
                            <button className=' badget-btn' onClick={() => {
                              relationreport();
                              setReportShow(true);
                            }}>Read </button>
                          </div>
                        </div>
                        <div className="messages">
                          {messages.map((m) => (
                            <Message message={m} key={m.id} />

                          ))}
                          {isfriend === false ? <div className="text-center"><button className=' badget-btn'>Communicate</button></div> : null}
                        </div>
                        <Input />
                        <div className={ReportShow === false ? "card p-4 card-report  vert-move-card " : "card p-4 card-report  vert-move-out-card height-vert"} style={ReportShow === false ? { display: 'none' } : { display: 'block' }}>
                          <hr className='text-dark mt-0  mx-auto' onClick={() => setReportShow(false)} />
                          {ReportContent ? <div className="overflow-hidden">
                            <div className="row suv mx-0">
                              <div className="col ">
                                <div className="col p-5 sub2-img">
                                  <div className="BlackImg2 text-center text-light">
                                    <p className='fs-3 fw-bold mb-0 pb-2 blackImgContent'>$0.99</p>
                                    <span className='BlackColor'>report</span>
                                  </div>
                                </div>
                                <div className="sub-content py-5 text-dark">
                                  Pay only 99 cents to unlock each report!
                                </div>
                                <div className="d-grid justify-content-center">
                                  <button className='sub-btn-1 mt-3 border' onClick={() => { pay() }}  >UNLOCK REPORT</button>
                                  <button className='sub-btn-2 mt-3' onClick={() => setReportShow(false)}>CANCEL</button>
                                </div>
                              </div>

                            </div>
                          </div> : <div className="">
                            <h5 className='text-dark'>Ideal For <span><b>{IdealFor}</b></span></h5>
                            <div className="ForCardScroll">
                              <p className='text-start text-dark para-chat-report'>{reportdata.report}</p>
                            </div>
                            {/* text={`https://idealfor.xyz/v1/profile/share?id=${reportdata.id}`} */}
                            <CopyToClipboard text={`${reportdata.report}`} onCopy={() => toast('Report copied')}>
                              <button className='badget-btn-report d-block mx-auto' ><BiShare className='align-middle' /> Share Report</button>
                            </CopyToClipboard>
                          </div>}

                        </div>
                      </div>
                    </div>
                  </div> :
                  <div className="">
                    <div className="col" style={Chat ? { display: 'none' } : { display: 'block' }}>
                      {dataNone3 ? <div className="d-flex flex-md-column flex-column justify-content-center align-content-center text-center  py-5"> <p className="mb-0 fs-5 text-light"> You don't have any friend yet.</p> <br />
                        <div className='d-flex justify-content-center align-item-center'>
                          <Pagination>
                            <Pagination.Prev onClick={() => { handlePreviosPending(); setDataNone3(false) }} disabled={page === 1} />
                            {
                              Array(pageCount).fill(null).map((ele, index) => {
                                return (
                                  <>
                                    <Pagination.Item active={page === index + 1 ? true : false} onClick={() => setPage(index + 1)}>{index + 1}</Pagination.Item>
                                  </>
                                )
                              })
                            }
                            <Pagination.Next onClick={handleNextPending} disabled={page === pageCount} />
                          </Pagination>
                        </div></div> :
                        <div>
                          <div className="card  mx-auto mt-5 mb-3 matches-card">
                          </div>
                          <h4 className='text-center'>Start Matching</h4>
                          <p className='matches-text w-75 mx-auto text-secondary'>People Ideal for match with you will appear here.</p>
                        </div>
                      }

                    </div>
                    <div className="col px-0" style={Chat ? { display: 'block' } : { display: 'none' }}>
                      <div className="chat">
                        <div className="chatInfo d-flex flex-space-between">
                          <BsArrowLeft className=' ms-1 me-auto pointerIcon fs-5 text-light' onClick={() => {
                            setChat(false); setReportShow(false); navigate({
                              pathname: '/matches'
                            })
                          }} />
                          <span className='mx-auto text-light'>{data.user?.displayName}</span>
                          <img src={data.user?.photoURL} alt="" height='40px' width='40px' className='rounded-circle ms-1 ms-auto' />
                          <div className="chatIcons">
                          </div>
                        </div>
                        <div className="Report_chat_button bg-black">
                          <div className="">
                            <p className='text-chat my-3'><span>Ideal For <b>{IdealFor}</b></span></p>
                          </div>
                          <div className="badget-chat text-center py-2">
                            <p className='mb-1'>Relationship Report</p>
                            <button className=' badget-btn' onClick={() => {
                              relationreport();
                              setReportShow(true);
                            }}>Read </button>
                          </div>
                        </div>
                        <div className="messages">
                          {messages.map((m) => (
                            <Message message={m} key={m.id} />

                          ))}
                          {messages.length === 4 ? <button className=' badget-btn'>Communicate</button> : null}
                        </div>
                        <Input report={reportdata.report} />
                        <div className={ReportShow === false ? "card p-4 card-report  vert-move-card " : "card p-4 card-report  vert-move-out-card height-vert"} style={ReportShow === false ? { display: 'none' } : { display: 'block' }}>
                          <hr className='text-dark mt-0  mx-auto' onClick={() => setReportShow(false)} />
                          {ReportContent ? <div className="overflow-hidden">
                            <div className="row suv mx-0">
                              <div className="col ">
                                <div className="col p-5 sub2-img">
                                  <div className="BlackImg2 text-center text-light">
                                    <p className='fs-3 fw-bold mb-0 pb-2 blackImgContent'>$0.99</p>
                                    <span className='BlackColor'>report</span>
                                  </div>
                                </div>
                                <div className="sub-content py-5 text-dark">
                                  Pay only 99 cents to unlock each report!
                                </div>
                                <div className="d-grid justify-content-center">
                                  <button className='sub-btn-1 mt-3 border' onClick={() => { pay() }}  >UNLOCK REPORT</button>
                                  <button className='sub-btn-2 mt-3' onClick={() => setReportShow(false)}>CANCEL</button>
                                </div>
                              </div>

                            </div>
                          </div> : <div className="">
                            <h5 className='text-dark'>Ideal For <span><b>{IdealFor}</b></span></h5>
                            <div className="ForCardScroll">
                              <p className='text-start text-dark para-chat-report'>{reportdata.report}</p>
                            </div>
                            {/* text={`https://idealfor.xyz/v1/profile/share?id=${reportdata.id}`} */}
                            <CopyToClipboard text={`${reportdata.report}`} onCopy={() => toast('Report copied')}>
                              <button className='badget-btn-report d-block mx-auto' ><BiShare className='align-middle' /> Share Report</button>
                            </CopyToClipboard>
                          </div>}

                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Sidebar;