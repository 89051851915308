/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../profile.css";
import Footer2 from "../../../layout/Footer/Footer2";
import { getApi } from "../../../Api/Api";
import { API_PATH } from "../../../Api/ApiPAth";
import Loader from "../../../layout/Loader/Loader";
import Navbar3 from "../../../layout/BottomNavbar/Navbar3";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { RWebShare } from "react-web-share";

const Personality = () => {
  const navigate = useNavigate();
  const [personality, setPersonality] = useState([]);
  const [famous, setFamous] = useState([]);
  const [Loading, setLoading] = useState(false);
  const tok = localStorage.getItem("token");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${tok}`,
    },
  };
  const imgReg = useRef(null);
  useEffect(() => {
    const ProfileData = JSON.parse(localStorage.getItem("ProfileData"));

    setPersonality(ProfileData.birthday_report.day_report);
    setFamous(ProfileData.birthday_report.day_report.famous);
  }, []);
  useEffect(() => {
    // 👇️ scroll to top on page load
    // window.scrollTo({ top: -50, left: 0, behavior: "instant" });
    imgReg.current?.scrollIntoView({
      behavior: "instant",
    });
  }, []);
  return (
    <>
      <ToastContainer />
      {Loading ? <Loader /> : null}
      <div className="container-fluid profileScreen bg-alternative">
        <div className="container-lg personalityContent">
          <div className="row personalityBtn mx-auto fix_width">
            <div className="  mx-auto " ref={imgReg}>
              <div className="position-sticky top-0 backColor">
                <div className="profileUpperpart navMatchbottom1 ">
                  <div className="  py-3  " onClick={() => navigate(-1)}>
                    <div className="arrow_back  btn-hover">
                      <MdArrowBackIosNew />{" "}
                      <span className="align-middle"> Go Back</span>
                    </div>
                  </div>
                  <div className="fs-5  py-3 profileHeading ">
                    <RWebShare
                      data={{
                        // text: `https://secretlanguage.network/v1/day/share?id=${personality.id}`,
                        url: `https://secretlanguage.network/v1/day/share?id=${personality.id}`,
                        title: "Share Day Report",
                      }}
                      // onClick={() =>
                      //   toast("url copied")
                      // }
                    >
                      <Icon
                        icon="mdi:share-outline"
                        className="fs-3 btn-hover"
                      />
                    </RWebShare>
                    {/* <CopyToClipboard
                    text={`https://idealfor.xyz/v1/day/share?id=${personality.id}`}
                    onCopy={() => toast("url copied")}
                  > 
                    <Icon icon="mdi:share-outline" className="fs-1"/>
                  </CopyToClipboard> */}
                  </div>
                </div>
                {personality.famous ? (
                  <div className="col personalityBtns pb-2">
                    <ul className="nav  px-auto switch_div w-100">
                      <li className="nav-item tab-all w-50">
                        <a
                          className="nav-link  show py-1 px-2 active"
                          href="#tab1"
                          data-bs-toggle="tab"
                          onClick={() =>
                            imgReg.current?.scrollIntoView({
                              behavior: "instant",
                            })
                          }
                        >
                          <button className="side01  fs-5 fw-bold">
                            Personality
                          </button>
                        </a>
                      </li>
                      <li className="nav-item tab-all p-l-20 w-50">
                        <a
                          className="nav-link  py-1 px-4 "
                          href="#tab2"
                          data-bs-toggle="tab"
                          onClick={() =>
                            imgReg.current?.scrollIntoView({
                              behavior: "instant",
                            })
                          }
                        >
                          <button className="side01 fs-5 fw-bold">
                            Famous
                          </button>
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              <div className="col ">
                <div className="col imagePersonality">
                  <div className="col bg-light text-dark py-3 my-3 mx-2 rounded-3">
                    <p className="mb-0">{personality.date_name}</p>
                    <img
                      src={personality.image}
                      className="personalityImg"
                      alt=""
                    />{" "}
                    <div className="fs-4 fw-semibold text-dark">
                      {personality.day_name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div className="tab-pane fontawesome-demo active" id="tab1">
                  <div className="">
                    <div className="fs-3 fw-bold personalityPara   text-center">
                      Meditation
                    </div>
                    <p className="mb-0  text-center line-para">
                      {" "}
                      {personality.meditation}
                    </p>
                  </div>
                  <div className="">
                    <div className="fs-3 fw-bold  personalityPara pt-3 text-center">
                      Personality
                    </div>
                    <p className="  mb-0 line-para"> {personality.report}</p>
                  </div>
                  <div className="">
                    <div className="fs-3 fw-bold  personalityPara pt-3 text-center">
                      Advice
                    </div>
                    <div className="  text-center content-match-li">
                      <p className="line-para mb-0">{personality.advice}</p>
                    </div>
                  </div>
                  <div className="">
                    <div className="fs-3 fw-bold  personalityPara pt-3 text-center">
                      Health
                    </div>
                    <p className="  mb-0 line-para">{personality.health} </p>
                  </div>
                  <div className="">
                    <div className="fs-3 fw-bold  personalityPara pt-3 text-center">
                      Numerology
                    </div>
                    <p className="line-para  mb-0">{personality.numbers} </p>
                  </div>
                  <div className="">
                    <div className="fs-3 fw-bold  personalityPara pt-3 text-center">
                      Archetype
                    </div>
                    <p className="  mb-0 line-para">{personality.archetype} </p>
                  </div>
                </div>
                <div className="tab-pane" id="tab2">
                  <div className="container-fluid">
                    <div className="text-center">
                      {famous.map((q) => (
                        <div
                          className="py-5 famousbottom mx-2 mb-3 rounded-3"
                          key={q.id}
                        >
                          <img
                            src={q.image}
                            className="rounded-circle"
                            height={"60px"}
                            width={"60px"}
                            alt=""
                          />
                          <div className="fs-5 fw-semibold text-light">
                            {q.sln}
                          </div>
                          <div className="fs-5 fw-semibold text-light">
                            {q.name}
                          </div>
                          <span className="famousContent text-capitalize">
                            {q.age}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-5 mb-5">
                <Footer2 />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 navMatchbottom1 nav-bottom mx-0 px-0">
        <div className="fix_width mx-auto">
          <Navbar3 />
        </div>
      </div>
    </>
  );
};

export default Personality;
