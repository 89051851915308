import React from "react";
import "./subscription.css";
import Footer2 from "../../layout/Footer/Footer2";
import { GiStarShuriken } from "react-icons/gi";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const prodConfig = {
  baseURL: `https://api.stripe.com`,
  withCredentials: true,
};

const axiosInstance = axios.create(prodConfig);

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const Subscription = () => {
  const navigate = useNavigate();
 
 
  const pay = () => { 
    window.open('https://buy.stripe.com/test_7sIaHo9AV1492yseUW',"_blank");
    // window.location.replace( "https://play.google.com/store/apps/details?id=com.sln.app&hl=en-IN" );
  };

  return (
    <>
      <div className="container-fluid subscription-img d-flex justify-content-center flex-column align-content-center form-width">
        <div className="text-center text-light  fs-5 profileHeading mb-3">
          Subscription
        </div>
        <div className="container-xxl">
          <div className="row suv mx-0">
            <div className="col  subcription-bg ">
              <div className="col p-3 sub-img">
                <div className="BlackImg text-center text-light">
                  <p className="fs-4 mb-0 pb-0 blackImgContent">$9.99</p>
                  <span className="BlackColor">monthly</span>
                </div>
              </div>
              <div className="sub-content pt-2 text-light">
                See Who You're Matched With And Connect!
              </div>
              <div className="Sub-content2">
                <span className="sub2Content">
                  <GiStarShuriken className="star" />
                  Unlimited Messaging
                  <GiStarShuriken className="star" />
                </span>{" "}
                <br />
                <span className="sub2Content">
                  <GiStarShuriken className="star" />
                  Plus All The Standard Features
                  <GiStarShuriken className="star" />
                </span>
                <br />
                <span className="sub2Content">
                  <GiStarShuriken className="star" />
                  Unlimited Birthday Reports
                  <GiStarShuriken className="star" />
                </span>
                <br />
                <span className="sub2Content">
                  <GiStarShuriken className="star" />
                  the standard Features: Free
                  <GiStarShuriken className="star" />
                </span>
                <br />
                <span className="sub2Content">
                  <GiStarShuriken className="star" />
                  Unlimited Relationship Reports
                  <GiStarShuriken className="star" />
                </span>
                <br />
                <span className="sub2Content">
                  <GiStarShuriken className="star" />
                  Unlimited Matchmaking Reports
                  <GiStarShuriken className="star" />
                </span>
                <br />
              </div>
              <div className="d-grid justify-content-center my-2">
                <button
                  className="sub-btn-1 mb-2"
                  onClick={() => {
                    pay();
                  }}
                >
                  SUBSCRIBE
                </button>
                <button className="sub-btn-2 " onClick={() => navigate(-1)}>
                  Cancel
                </button>
              </div>
            </div>
            <div className="px-2 bg-black">
              <Footer2 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
